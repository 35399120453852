import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validacoes } from '../../../../utils/validacoes';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { TermosDinamicos } from 'src/app/models/Helper/TermosDinamicos';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { CommonService } from 'src/app/services/common.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { ConfigClinicaService } from 'src/app/services/config-clinica.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

@Component({
  selector: 'app-tornozelo-bilateral',
  templateUrl: './tornozelo-bilateral.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./tornozelo-bilateral.component.css']
})
export class TornozeloBilateralComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[1385],blockID:119},
    {listTerm:[1388],blockID:120},
    {listTerm:[1392],blockID:121},
    {listTerm:[1398],blockID:122},
    {listTerm:[1403],blockID:123},
    {listTerm:[1417],blockID:124},
    {listTerm:[1423],blockID:125},
    {listTerm:[1433],blockID:126},
    {listTerm:[1439],blockID:127},
  ]

  descricoesDireita: DescricaoConclusao[] = [
    { value: 'Pele e tecido subcutâneo preservados.', blockID: 119, textTermID: 0, visivel: true },
    { value: 'Ausência de derrame articular significativo.', blockID: 120, textTermID: 0, visivel: true },
    { value: 'Superfícies ósseas sem alterações significativas ao método.', blockID: 121, textTermID: 0, visivel: true },
    { value: 'Tendões do compartimento anterior com ecogenicidade e espessura preservadas.', blockID: 122, textTermID: 0, visivel: true },
    { value: 'Tendões do compartimento lateral com ecogenicidade e espessura preservadas.', blockID: 123, textTermID: 0, visivel: true },
    { value: 'Tendões do compartimento medial com ecogenicidade e espessura preservadas.', blockID: 124, textTermID: 0, visivel: true },
    { value: 'Tendão calcâneo com ecogenicidade e espessura preservadas.', blockID: 125, textTermID: 0, visivel: true },
    { value: 'Ligamentos talofibular anterior e calcaneofibular sem particularidades ao método.', blockID: 126, textTermID: 0, visivel: true },
    { value: 'Fáscia plantar proximal com espessura e ecogenicidades preservadas.', blockID: 127, textTermID: 0, visivel: true }
  ];

  descricoesEsquerda: DescricaoConclusao[] = [
    { value: 'Pele e tecido subcutâneo preservados.', blockID: 119, textTermID: 0, visivel: true },
    { value: 'Ausência de derrame articular significativo.', blockID: 120, textTermID: 0, visivel: true },
    { value: 'Superfícies ósseas sem alterações significativas ao método.', blockID: 121, textTermID: 0, visivel: true },
    { value: 'Tendões do compartimento anterior com ecogenicidade e espessura preservadas.', blockID: 122, textTermID: 0, visivel: true },
    { value: 'Tendões do compartimento lateral com ecogenicidade e espessura preservadas.', blockID: 123, textTermID: 0, visivel: true },
    { value: 'Tendões do compartimento medial com ecogenicidade e espessura preservadas.', blockID: 124, textTermID: 0, visivel: true },
    { value: 'Tendão calcâneo com ecogenicidade e espessura preservadas.', blockID: 125, textTermID: 0, visivel: true },
    { value: 'Ligamentos talofibular anterior e calcaneofibular sem particularidades ao método.', blockID: 126, textTermID: 0, visivel: true },
    { value: 'Fáscia plantar proximal com espessura e ecogenicidades preservadas.', blockID: 127, textTermID: 0, visivel: true }
  ];

  conclusoesDireita: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 119, textTermID: 0, visivel: true },
    { value: '', blockID: 120, textTermID: 0, visivel: true },
    { value: '', blockID: 121, textTermID: 0, visivel: true },
    { value: '', blockID: 122, textTermID: 0, visivel: true },
    { value: '', blockID: 123, textTermID: 0, visivel: true },
    { value: '', blockID: 124, textTermID: 0, visivel: true },
    { value: '', blockID: 125, textTermID: 0, visivel: true },
    { value: '', blockID: 126, textTermID: 0, visivel: true },
    { value: '', blockID: 127, textTermID: 0, visivel: true }
  ];

  conclusoesEsquerda: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 119, textTermID: 0, visivel: true },
    { value: '', blockID: 120, textTermID: 0, visivel: true },
    { value: '', blockID: 121, textTermID: 0, visivel: true },
    { value: '', blockID: 122, textTermID: 0, visivel: true },
    { value: '', blockID: 123, textTermID: 0, visivel: true },
    { value: '', blockID: 124, textTermID: 0, visivel: true },
    { value: '', blockID: 125, textTermID: 0, visivel: true },
    { value: '', blockID: 126, textTermID: 0, visivel: true },
    { value: '', blockID: 127, textTermID: 0, visivel: true }
  ];

  genericPeleTecidoDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1387, term: '[Localizacao]' }
  ];

  genericPeleTecidoEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1387, term: '[Localizacao]' }
  ];


  genericDerrameArticularDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1390, term: '[Articulacao]' },
    { value: '', selected: false, termID: 1391, term: '[Volume]' },
  ];

  genericDerrameArticularEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1390, term: '[Articulacao]' },
    { value: '', selected: false, termID: 1391, term: '[Volume]' },
  ];

  genericTendoesAnterioresDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1402, term: '[Tendao]' }
  ];

  genericTendoesAnterioresEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1402, term: '[Tendao]' }
  ];

  genericTendoesMedialDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1421, term: '[Tendao]' },
    { value: '', selected: false, termID: 1422, term: '[Segmento]' }
  ];

  genericTendoesMedialEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1421, term: '[Tendao]' },
    { value: '', selected: false, termID: 1422, term: '[Segmento]' }
  ];

  genericTendaoCalcaneoDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1426, term: '[Area transversa]' },
    { value: '', selected: false, termID: 1427, term: '[Distancia]' },
    { value: '', selected: false, termID: 1430, term: '[Distancia calcaneana]' },
    { value: '', selected: false, termID: 1431, term: '[Medida]' },
    { value: '', selected: false, termID: 1432, term: '[Segmento]' }
  ];

  genericTendaoCalcaneoEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1426, term: '[Area transversa]' },
    { value: '', selected: false, termID: 1427, term: '[Distancia]' },
    { value: '', selected: false, termID: 1430, term: '[Distancia calcaneana]' },
    { value: '', selected: false, termID: 1431, term: '[Medida]' },
    { value: '', selected: false, termID: 1432, term: '[Segmento]' }
  ];

  genericFasciaPlantarDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1441, term: '[Espessura]' },
    { value: '', selected: false, termID: 1443, term: '[Dist calcaneana]' },
    { value: '', selected: false, termID: 1446, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1447, term: '[Banda]' },
    { value: '', selected: false, termID: 1448, term: '[Medida]' }
  ];

  genericFasciaPlantarEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1441, term: '[Espessura]' },
    { value: '', selected: false, termID: 1443, term: '[Dist calcaneana]' },
    { value: '', selected: false, termID: 1446, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1447, term: '[Banda]' },
    { value: '', selected: false, termID: 1448, term: '[Medida]' }
  ];

  genericSuperficieOsseaDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1456, term: '[Localizacao]' }
  ];

  genericSuperficieOsseaEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1456, term: '[Localizacao]' }
  ];

  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  htmlContentDireita: string;
  htmlContentEsquerda: string;
  maskDireita: string;
  maskEsquerda: string;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  objetoGrupoLesao: any;
  dinamicLesions: TermosDinamicos[] = [];
  dinamicLesion: any;
  lesionRow = 1;
  cabecalhoTexto = '';
  indicacaoTextoDireita = '';
  indicacaoTextoEsquerda = '';
  achadosAdTextoDireita = '';
  achadosAdTextoEsquerda = '';
  Lado = '';
  rodapeTexto = '';

  cabecalho = false;
  lateralidade = false;
  peleTecido = false;
  derrameArticular = false;
  superficieOssea = false;
  tendoesAnterior = false;
  tendoesLateral = false;
  tendoesMedial = false;
  tendaoCalcaneo = false;
  ligamentosTalofibular = false;
  fasciaPlantar = false;
  indicacao = true;
  achadosTexto = false;
  rodape = false;

  medicalReportTypeID = 61;
  peleTecidoID = 119;
  derrameArticularID = 120;
  superficieOsseaID = 121;
  tendoesAnteriorID = 122;
  tendoesLateralID = 123;
  tendoesMedialID = 124;
  tendaoCalcaneoID = 125;
  ligamentosTalofibularID = 126;
  fasciaPlantarID = 127;
  public switchFormReport = true;
  public generoLateralidade: string;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  unidadeMedida: string;
  infoLogotipo:string|any= '';
  editLaudoMode: boolean = false;

  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _copyPaste: CopyPaste,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _configClinica:ConfigClinicaService,
    private _config: ConfigLaudoService,
    public _addText: AddText,
    private dataService:DataTermService,
    public dialog: MatDialog) { }

    ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("tornozeloAnalise", this.descricoesDireita, this.conclusoesDireita);
    this._pouchService.loadDatabase("tornozeloAnalise", this.descricoesEsquerda, this.conclusoesEsquerda);
    this.resetaDialog();
    this.generoLateralidade = 'masculino';
    this.Subtitulo = 'Indicação';
    this.subtituloBloco = 'Preencha o campo indicação';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    let configClinicaRule = JSON.parse(localStorage.getItem('configClinicaRule'));
    if(configClinicaRule && configClinicaRule.active){
      this._configClinica.handleSelectUserClinic();
      this._configClinica.maskClinica.subscribe(res=>{
        this.infoLogotipo=res;
        this.AdicionaTextoDireita();
        this.AdicionaTextoEsquerda();
      })
    };

    const listBlocks = [
      this.peleTecidoID,
      this.derrameArticularID,
      this.superficieOsseaID,
      this.tendoesAnteriorID,
      this.tendoesLateralID,
      this.tendoesMedialID,
      this.tendaoCalcaneoID,
      this.ligamentosTalofibularID,
      this.fasciaPlantarID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "tornozeloBilateralMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.maskDireita = obj.mask.replace('{0}', 'DIREITO');
      this.maskEsquerda = obj.mask.replace('{0}', 'ESQUERDO');
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "tornozelo");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-tornozelo');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-tornozelo');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
      });
      dialogRef.afterClosed().subscribe(result =>{
        if(result){
          this.resetaLaudo();
        }
      });
    });
  }

  public resetaLaudo(){
    this._pouchService.getAll("tornozeloAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoesDireita[i].value = obj[i].doc.value;
            this.descricoesEsquerda[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoesDireita[concCalc].value = obj[i].doc.value;
            this.conclusoesEsquerda[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.Lado = "";
    this.cabecalhoTexto = "";
    this.indicacaoTextoDireita = "";
    this.indicacaoTextoEsquerda = "";
    this.achadosAdTextoDireita = "";
    this.achadosAdTextoEsquerda = "";
    this.rodapeTexto = "";
    this.showForm = false;

    setTimeout(() => {
      this.showForm = true;
      this.AdicionaTexto(this.newEditor);
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: true
    },
    {
      titulo: 'Pele e tecido subcutâneo',
      id: this.peleTecidoID,
      name: 'pele-tecido',
      selected: false,
    },
    {
      titulo: 'Derrame articular',
      id: this.derrameArticularID,
      name: 'derrame-articular',
      selected: false
    },
    {
      titulo: 'Superfície óssea',
      id: this.superficieOsseaID,
      name: 'superficie-ossea',
      selected: false
    },
    {
      titulo: 'Tendões do compart. anterior',
      id: this.tendoesAnteriorID,
      name: 'tendoes-anterior',
      selected: false
    },
    {
      titulo: 'Tendões do compart. lateral',
      id: this.tendoesLateralID,
      name: 'tendoes-lateral',
      selected: false
    },
    {
      titulo: 'Tendões do compart. medial',
      id: this.tendoesMedialID,
      name: 'tendoes-medial',
      selected: false
    },
    {
      titulo: 'Tendão calcâneo',
      id: this.tendaoCalcaneoID,
      name: 'tendao-calcaneo',
      selected: false
    },
    {
      titulo: 'Ligamento talofibular',
      id: this.ligamentosTalofibularID,
      name: 'ligamento-talofibular',
      selected: false
    },
    {
      titulo: 'Fáscia plantar proximal',
      id: this.fasciaPlantarID,
      name: 'facia-plantar',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.lateralidade = (itemSelecionado.id === 'lateralidade');
    this.peleTecido = (itemSelecionado.id === this.peleTecidoID);
    this.derrameArticular = (itemSelecionado.id === this.derrameArticularID);
    this.superficieOssea = (itemSelecionado.id === this.superficieOsseaID);
    this.tendoesAnterior = (itemSelecionado.id === this.tendoesAnteriorID);
    this.tendoesLateral = (itemSelecionado.id === this.tendoesLateralID);
    this.tendoesMedial = (itemSelecionado.id === this.tendoesMedialID);
    this.tendaoCalcaneo = (itemSelecionado.id === this.tendaoCalcaneoID);
    this.ligamentosTalofibular = (itemSelecionado.id === this.ligamentosTalofibularID);
    this.fasciaPlantar = (itemSelecionado.id === this.fasciaPlantarID);
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.dinamicLesions = [];
    this.lesionRow = 1;

    this.genericPeleTecidoDireita = [
      { value: '', selected: false, termID: 1387, term: '[Localizacao]' }
    ];

    this.genericPeleTecidoEsquerda = [
      { value: '', selected: false, termID: 1387, term: '[Localizacao]' }
    ];


    this.genericDerrameArticularDireita = [
      { value: '', selected: false, termID: 1390, term: '[Articulacao]' },
      { value: '', selected: false, termID: 1391, term: '[Volume]' },
    ];

    this.genericDerrameArticularEsquerda = [
      { value: '', selected: false, termID: 1390, term: '[Articulacao]' },
      { value: '', selected: false, termID: 1391, term: '[Volume]' },
    ];

    this.genericTendoesAnterioresDireita = [
      { value: '', selected: false, termID: 1402, term: '[Tendao]' }
    ];

    this.genericTendoesAnterioresEsquerda = [
      { value: '', selected: false, termID: 1402, term: '[Tendao]' }
    ];

    this.genericTendoesMedialDireita = [
      { value: '', selected: false, termID: 1421, term: '[Tendao]' },
      { value: '', selected: false, termID: 1422, term: '[Segmento]' }
    ];

    this.genericTendoesMedialEsquerda = [
      { value: '', selected: false, termID: 1421, term: '[Tendao]' },
      { value: '', selected: false, termID: 1422, term: '[Segmento]' }
    ];

    this.genericTendaoCalcaneoDireita = [
      { value: '', selected: false, termID: 1426, term: '[Area transversa]' },
      { value: '', selected: false, termID: 1427, term: '[Distancia]' },
      { value: '', selected: false, termID: 1430, term: '[Distancia calcaneana]' },
      { value: '', selected: false, termID: 1431, term: '[Medida]' },
      { value: '', selected: false, termID: 1432, term: '[Segmento]' }
    ];

    this.genericTendaoCalcaneoEsquerda = [
      { value: '', selected: false, termID: 1426, term: '[Area transversa]' },
      { value: '', selected: false, termID: 1427, term: '[Distancia]' },
      { value: '', selected: false, termID: 1430, term: '[Distancia calcaneana]' },
      { value: '', selected: false, termID: 1431, term: '[Medida]' },
      { value: '', selected: false, termID: 1432, term: '[Segmento]' }
    ];

    this.genericFasciaPlantarDireita = [
      { value: '', selected: false, termID: 1441, term: '[Espessura]' },
      { value: '', selected: false, termID: 1443, term: '[Dist calcaneana]' },
      { value: '', selected: false, termID: 1446, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1447, term: '[Banda]' },
      { value: '', selected: false, termID: 1448, term: '[Medida]' }
    ];

    this.genericFasciaPlantarEsquerda = [
      { value: '', selected: false, termID: 1441, term: '[Espessura]' },
      { value: '', selected: false, termID: 1443, term: '[Dist calcaneana]' },
      { value: '', selected: false, termID: 1446, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1447, term: '[Banda]' },
      { value: '', selected: false, termID: 1448, term: '[Medida]' }
    ];

    this.genericSuperficieOsseaDireita = [
      { value: '', selected: false, termID: 1456, term: '[Localizacao]' }
    ];

    this.genericSuperficieOsseaEsquerda = [
      { value: '', selected: false, termID: 1456, term: '[Localizacao]' }
    ];
  }

  enviaTermosDireita($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaTermosEsquerda($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaRodapeCabecalho({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaLadosDireita($event) {
    this.Lado = $event;
    this.AdicionaTextoDireita();
  }

  enviaLadosEsquerda($event) {
    this.Lado = $event;
    this.AdicionaTextoEsquerda();
  }

  enviaIndicacaoDireita(texto) {
    if (texto)
      this.indicacaoTextoDireita = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTextoDireita = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaDireita({
        blockId: 'indicacao',
        indicacao: this.indicacaoTextoDireita
      });
    }
  }

  enviaIndicacaoEsquerda(texto) {
    if (texto)
      this.indicacaoTextoEsquerda = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTextoEsquerda = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaEsquerda({
        blockId: 'indicacao',
        indicacao: this.indicacaoTextoEsquerda
      });
    }
  }

  enviaAchadosDireita(texto) {
    if (texto)
      this.achadosAdTextoDireita = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTextoDireita = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaDireita({
        blockId: 'achados',
        achados: this.achadosAdTextoDireita
      });
    }
  }

  enviaAchadosEsquerda(texto) {
    if (texto)
      this.achadosAdTextoEsquerda = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTextoEsquerda = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaEsquerda({
        blockId: 'achados',
        achados: this.achadosAdTextoEsquerda
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaRodapeCabecalho({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  incluirLesoesDinamicas($event) {
    let lesoesValue = $event.elemento.value;

    if ($event.linha == 1 && ($event.posicao == 0 || $event.posicao == 1)) {
      if ($event.isPrimeiraLesao == true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaTermosDireita($event.elemento);
        this.enviaTermosEsquerda($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      // Fascia Plantar
      case '1450':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1450,
          lesionID: 1449,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1451':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1451,
          lesionID: 1449,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Banda]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1452':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1452,
          lesionID: 1449,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Ligamentos
      case '1438':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1438,
          lesionID: 0,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ligamento]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Tendoes Compart. Lateral
      case '1415':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1415,
          lesionID: 0,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Tendao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1416':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1416,
          lesionID: 0,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      //SuperficieOssea
      case '1397':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1397,
          lesionID: 0,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;

    }

    this.lesionRow = $event.linha;
  }

  adicionaSelecaoPeleTecidoDireita($event) {
    this.genericPeleTecidoDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoPeleTecidoEsquerda($event) {
    this.genericPeleTecidoEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoDerrameArticularDireita($event) {
    this.genericDerrameArticularDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoDerrameArticularEsquerda($event) {
    this.genericDerrameArticularEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoTendoesAnterioresDireita($event) {
    this.genericTendoesAnterioresDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoTendoesAnterioresEsquerda($event) {
    this.genericTendoesAnterioresEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoTendoesMedialDireita($event) {
    this.genericTendoesMedialDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoTendoesMedialEsquerda($event) {
    this.genericTendoesMedialEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoTendaoCalcaneoDireita($event) {
    this.genericTendaoCalcaneoDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoTendaoCalcaneoEsquerda($event) {
    this.genericTendaoCalcaneoEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoFasciaPlantarDireita($event) {
    this.genericFasciaPlantarDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoFasciaPlantarEsquerda($event) {
    this.genericFasciaPlantarEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoSuperficieOsseaDireita($event) {
    this.genericSuperficieOsseaDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoSuperficieOsseaEsquerda($event) {
    this.genericSuperficieOsseaEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  consultarAPIDireita() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){

      let desc;
      if (this.peleTecido) {
        this.term.blockID = this.peleTecidoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.peleTecidoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaPeleTecidoDireita(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.peleTecidoID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.peleTecidoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaPeleTecidoDireita(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.peleTecidoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.peleTecidoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.derrameArticular) {
        this.term.blockID = this.derrameArticularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.derrameArticularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDerrameArticularDireita(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.derrameArticularID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.derrameArticularID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDerrameArticularDireita(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.derrameArticularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.derrameArticularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendoesAnterior) {
        this.term.blockID = this.tendoesAnteriorID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.tendoesAnteriorID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaTendoesAnterioresDireita(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.tendoesAnteriorID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendoesAnteriorID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaTendoesAnterioresDireita(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.tendoesAnteriorID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendoesAnteriorID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendoesMedial) {
        this.term.blockID = this.tendoesMedialID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.tendoesMedialID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaTendoesMedialDireita(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.tendoesMedialID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendoesMedialID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaTendoesMedialDireita(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.tendoesMedialID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendoesMedialID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendaoCalcaneo) {
        this.term.blockID = this.tendaoCalcaneoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.tendaoCalcaneoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaTendaoCalcaneoDireita(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.tendaoCalcaneoID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendaoCalcaneoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaTendaoCalcaneoDireita(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.tendaoCalcaneoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendaoCalcaneoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.fasciaPlantar) {
        this.term.blockID = this.fasciaPlantarID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.fasciaPlantarID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoFasciaPlantar(resultado.text, contadorTexto);
                contadorTexto++;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.fasciaPlantarID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.fasciaPlantarID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoFasciaPlantarDireita(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.fasciaPlantarID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.fasciaPlantarID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ligamentosTalofibular) {
        this.term.blockID = this.ligamentosTalofibularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.ligamentosTalofibularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoLigamentos(resultado.text, contadorTexto);
                contadorTexto++;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.ligamentosTalofibularID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.ligamentosTalofibularID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDescricaoLigamentos(resultado.conclusion, contadorTexto);
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.ligamentosTalofibularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ligamentosTalofibularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendoesLateral) {
        this.term.blockID = this.tendoesLateralID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.tendoesLateralID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoTendoesLateral(resultado.text, contadorTexto);
                contadorTexto++;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.tendoesLateralID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendoesLateralID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDescricaoTendoesLateral(resultado.conclusion, contadorTexto);
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.tendoesLateralID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendoesLateralID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.superficieOssea) {

        this.term.blockID = this.superficieOsseaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.superficieOsseaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoSuperficieOsseaDireita(resultado.text, contadorTexto);
                contadorTexto++;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.superficieOsseaID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.superficieOsseaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDescricaoSuperficieOsseaDireita(resultado.conclusion, contadorTexto);
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.superficieOsseaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.superficieOsseaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  consultarAPIEsquerda() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){

      let desc;
      if (this.peleTecido) {
        this.term.blockID = this.peleTecidoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.peleTecidoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaPeleTecidoEsquerda(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.peleTecidoID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.peleTecidoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaPeleTecidoEsquerda(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.peleTecidoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.peleTecidoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.derrameArticular) {
        this.term.blockID = this.derrameArticularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.derrameArticularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDerrameArticularEsquerda(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.derrameArticularID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.derrameArticularID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDerrameArticularEsquerda(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.derrameArticularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.derrameArticularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendoesAnterior) {
        this.term.blockID = this.tendoesAnteriorID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.tendoesAnteriorID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaTendoesAnterioresEsquerda(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.tendoesAnteriorID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendoesAnteriorID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaTendoesAnterioresEsquerda(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.tendoesAnteriorID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendoesAnteriorID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendoesMedial) {
        this.term.blockID = this.tendoesMedialID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.tendoesMedialID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaTendoesMedialEsquerda(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.tendoesMedialID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendoesMedialID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaTendoesMedialEsquerda(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.tendoesMedialID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendoesMedialID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendaoCalcaneo) {
        this.term.blockID = this.tendaoCalcaneoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.tendaoCalcaneoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaTendaoCalcaneoEsquerda(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.tendaoCalcaneoID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendaoCalcaneoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaTendaoCalcaneoEsquerda(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.tendaoCalcaneoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendaoCalcaneoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.fasciaPlantar) {
        this.term.blockID = this.fasciaPlantarID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.fasciaPlantarID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoFasciaPlantarEsquerda(resultado.text, contadorTexto);
                contadorTexto++;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.fasciaPlantarID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.fasciaPlantarID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoFasciaPlantarEsquerda(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.fasciaPlantarID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.fasciaPlantarID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ligamentosTalofibular) {
        this.term.blockID = this.ligamentosTalofibularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.ligamentosTalofibularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoLigamentos(resultado.text, contadorTexto);
                contadorTexto++;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.ligamentosTalofibularID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.ligamentosTalofibularID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDescricaoLigamentos(resultado.conclusion, contadorTexto);
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.ligamentosTalofibularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ligamentosTalofibularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendoesLateral) {
        this.term.blockID = this.tendoesLateralID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.tendoesLateralID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoTendoesLateral(resultado.text, contadorTexto);
                contadorTexto++;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.tendoesLateralID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendoesLateralID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDescricaoTendoesLateral(resultado.conclusion, contadorTexto);
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.tendoesLateralID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendoesLateralID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.superficieOssea) {

        this.term.blockID = this.superficieOsseaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.superficieOsseaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoSuperficieOsseaEsquerda(resultado.text, contadorTexto);
                contadorTexto++;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.superficieOsseaID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.superficieOsseaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDescricaoSuperficieOsseaEsquerda(resultado.conclusion, contadorTexto);
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.superficieOsseaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.superficieOsseaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  MontaPeleTecidoDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1387) {
        this.genericPeleTecidoDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaPeleTecidoEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1387) {
        this.genericPeleTecidoEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDerrameArticularDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1390 || +term === 1391) {
        this.genericDerrameArticularDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDerrameArticularEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1390 || +term === 1391) {
        this.genericDerrameArticularEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaTendoesAnterioresDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1402) {
        this.genericTendoesAnterioresDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaTendoesAnterioresEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1402) {
        this.genericTendoesAnterioresEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaTendoesMedialDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1421 || +term === 1422) {
        this.genericTendoesMedialDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaTendoesMedialEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1421 || +term === 1422) {
        this.genericTendoesMedialEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaTendaoCalcaneoDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1426 || +term === 1427 || +term === 1430
        || +term === 1431 || +term === 1432) {
        this.genericTendaoCalcaneoDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }


  MontaTendaoCalcaneoEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1426 || +term === 1427 || +term === 1430
        || +term === 1431 || +term === 1432) {
        this.genericTendaoCalcaneoEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }


  MontaDescricaoFasciaPlantar(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoFasciaPlantarDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoFasciaPlantarDescNormalDireita(texto);
    }
    return texto;
  }

  MontaDescricaoFasciaPlantarEsquerda(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoFasciaPlantarDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoFasciaPlantarDescNormalEsquerda(texto);
    }
    return texto;
  }

  TextoFasciaPlantarDescNormalDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1441 || +term === 1443 || +term === 1446
        || +term === 1447 || +term === 1448) {
        this.genericFasciaPlantarDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  TextoFasciaPlantarDescNormalEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1441 || +term === 1443 || +term === 1446
        || +term === 1447 || +term === 1448) {
        this.genericFasciaPlantarEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  TextoFasciaPlantarDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0] + '</br>';
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (+item.termID == 1450 || +item.termID == 1451 || +item.termID == 1452) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (+item.termID == 1450 || +item.termID == 1451 || +item.termID == 1452) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicLesions = this.dinamicLesions.filter((din) => {
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  MontaConclusaoFasciaPlantarDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1441 || +term === 1443 || +term === 1446
        || +term === 1447 || +term === 1448) {
        this.genericFasciaPlantarDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoFasciaPlantarEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1441 || +term === 1443 || +term === 1446
        || +term === 1447 || +term === 1448) {
        this.genericFasciaPlantarEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoLigamentos(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoLigamentosDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = texto;
    }
    return texto;
  }

  TextoLigamentosDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length > 1) {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (+item.termID == 1438) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length > 1) {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID == 1438) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    return texto;
  }

  MontaDescricaoTendoesLateral(texto, contadorTexto) {

    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoTendoesLateralDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = texto;
    }
    return texto;
  }

  TextoTendoesLateralDescDinamica(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length > 1) {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (+item.termID == 1415 || +item.termID == 1416) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length > 1) {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID == 1415 || +item.termID == 1416) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    return texto;
  }

  MontaDescricaoSuperficieOsseaDireita(texto, contadorTexto) {

    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoSuperficieOsseacDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.MontaSuperficieOsseaDireita(texto);
    }
    return texto;
  }

  MontaDescricaoSuperficieOsseaEsquerda(texto, contadorTexto) {

    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoSuperficieOsseacDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.MontaSuperficieOsseaEsquerda(texto);
    }
    return texto;
  }

  TextoSuperficieOsseacDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length > 1) {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (+item.termID == 1397) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length > 1) {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID == 1397) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    return texto;
  }

  MontaSuperficieOsseaDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1456) {
        this.genericSuperficieOsseaDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaSuperficieOsseaEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1456) {
        this.genericSuperficieOsseaEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }


  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else {
      this.AdicionaTextoDireita();
      this.AdicionaTextoEsquerda();
    }

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor(){
    let maskDireita = this.maskDireita.split('|');
    let maskEsquerda = this.maskEsquerda.split('|');

    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    //lado direito -- lado direito -- lado direito

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskDireita[0]+this.Lado.toUpperCase()}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskDireita[1]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao-D`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTextoDireita}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskDireita[2]}</p>`,
      }
    });

    this.descricoesDireita.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });
    this.descricoesDireita.forEach(descricao => {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}-D`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskDireita[3]}</p>`,
      }
    });

    this.conclusoesDireita.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoesDireita[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao-D`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados-D`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTextoDireita}</p>`,
      }
    });

    //lado esquedo -- lado esquedo -- lado esquedo

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskEsquerda[0]+this.Lado.toUpperCase()}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskEsquerda[1]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao-E`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTextoEsquerda}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskEsquerda[2]}</p>`,
      }
    });

    this.descricoesEsquerda.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });
    this.descricoesEsquerda.forEach(descricao => {
              this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}-E`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskEsquerda[3]}</p>`,
      }
    });

    this.conclusoesEsquerda.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoesEsquerda[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao-E`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados-E`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTextoEsquerda}</p>`,
      }
    });

   //Rodape
    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });
  }

  AdicionaTextoDireita() {
    let i;
    let maskSplit = this.maskDireita.split('|');
    let cabecalho = this.cabecalhoTexto;
    let rodape = this.rodapeTexto;
    let logo = this.infoLogotipo;

    this.htmlContentDireita = maskSplit[0];
    this.htmlContentDireita += this.Lado.toUpperCase();
    this.htmlContentDireita += maskSplit[1];
    this.htmlContentDireita += this.indicacaoTextoDireita;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContentDireita += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContentDireita += maskSplit[2];

    for (i = 0; i < this.descricoesDireita.length; i++) {
      var newValue = this.descricoesDireita[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoesDireita[i].value = newValue2;
    }
    for (i = 0; i < this.descricoesDireita.length; i++) {
      if (this.descricoesDireita[i].value) {
        this.htmlContentDireita += '<br/>' + this.descricoesDireita[i].value + '<br/>';
      }
    }
    this.htmlContentDireita += maskSplit[3];
    for (i = 0; i < this.conclusoesDireita.length; i++) {
      if (this.conclusoesDireita[i].value) {
        this.htmlContentDireita += '<br/>' + this.conclusoesDireita[i].value + '<br/>';
      }
    }
    this.htmlContentDireita += this.achadosAdTextoDireita;

    if (this.cabecalhoTexto !== '' && this.rodapeTexto === ''){
      if(this.infoLogotipo)
      this.htmlContent = logo + cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
      else
      this.htmlContent =  cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    }

    if (this.cabecalhoTexto === '' && this.rodapeTexto !== ''){
      if(this.infoLogotipo)
      this.htmlContent = logo + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
      else
      this.htmlContent =  this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    }

    if (this.cabecalhoTexto !== '' && this.rodapeTexto !== ''){
      if(this.infoLogotipo)
        this.htmlContent =  logo + cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
      else
        this.htmlContent =  cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    }

    if (this.cabecalhoTexto === '' && this.rodapeTexto === ''){
      if(this.infoLogotipo)
        this.htmlContent = logo + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
      else
        this.htmlContent = this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    }
  }

  AdicionaTextoEsquerda() {
    let i;
    let maskSplit = this.maskEsquerda.split('|');
    let cabecalho = this.cabecalhoTexto;
    let rodape = this.rodapeTexto;
    let logo = this.infoLogotipo;

    this.htmlContentEsquerda = maskSplit[0];
    this.htmlContentEsquerda += this.Lado.toUpperCase();
    this.htmlContentEsquerda += maskSplit[1];
    this.htmlContentEsquerda += this.indicacaoTextoEsquerda;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContentEsquerda += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContentEsquerda += maskSplit[2];

    for (i = 0; i < this.descricoesEsquerda.length; i++) {
      var newValue = this.descricoesEsquerda[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoesEsquerda[i].value = newValue2;
    }
    for (i = 0; i < this.descricoesEsquerda.length; i++) {
      if (this.descricoesEsquerda[i].value) {
        this.htmlContentEsquerda += '<br/>' + this.descricoesEsquerda[i].value + '<br/>';
      }
    }
    this.htmlContentEsquerda += maskSplit[3];
    for (i = 0; i < this.conclusoesEsquerda.length; i++) {
      if (this.conclusoesEsquerda[i].value) {
        this.htmlContentEsquerda += '<br/>' + this.conclusoesEsquerda[i].value + '<br/>';
      }
    }
    this.htmlContentEsquerda += this.achadosAdTextoEsquerda;

    if (this.cabecalhoTexto !== '' && this.rodapeTexto === ''){
      if(this.infoLogotipo)
      this.htmlContent = logo + cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
      else
      this.htmlContent =  cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    }

    if (this.cabecalhoTexto === '' && this.rodapeTexto !== ''){
      if(this.infoLogotipo)
      this.htmlContent = logo + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
      else
      this.htmlContent =  this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    }

    if (this.cabecalhoTexto !== '' && this.rodapeTexto !== ''){
      if(this.infoLogotipo)
        this.htmlContent =  logo + cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
      else
        this.htmlContent =  cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    }

    if (this.cabecalhoTexto === '' && this.rodapeTexto === ''){
      if(this.infoLogotipo)
        this.htmlContent = logo + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
      else
        this.htmlContent = this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    }
  }

  atualizaLinhaDireita({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
    tabela,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoesDireita.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId}-D p`);
        const conclusion = document.querySelector('#e-conclusao-D p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesDireita.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesDireita.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
        //tabela
        if (tabela !== undefined) {
          const table = document.querySelector(`#e-${blockId}-D`);

          if (table) {
            table.innerHTML = `<b>ÂNGULOS DE GRAF</b><br/>${tabela}<br/>`;
          }
        }

			} else if(indicacao !== undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const indicacaoTxt = document.querySelector(`#e-${blockId}-D p`);

          if(indicacaoTxt) {
            indicacaoTxt.innerHTML = indicacao;
          }
				}

        if(achados !== undefined) {
          const achadosTxt = document.querySelector(`#e-${blockId}-D p`);

          if(achadosTxt) {
						achadosTxt.innerHTML = achados;
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  atualizaLinhaEsquerda({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
    tabela,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoesEsquerda.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId}-E p`);
        const conclusion = document.querySelector('#e-conclusao-E p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesEsquerda.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesEsquerda.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
        //tabela
        if (tabela !== undefined) {
          const table = document.querySelector(`#e-${blockId}-E`);

          if (table) {
            table.innerHTML = `<b>ÂNGULOS DE GRAF</b><br/>${tabela}<br/>`;
          }
        }
			} else if(indicacao !== undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const indicacaoTxt = document.querySelector(`#e-${blockId}-E`);

          if(indicacaoTxt) {
            indicacaoTxt.innerHTML = indicacao;
          }
				}

        if(achados !== undefined) {
          const achadosTxt = document.querySelector(`#e-${blockId}-E`);

          if(achadosTxt) {
						achadosTxt.innerHTML = achados;
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  atualizaRodapeCabecalho({
		blockId,
    cabecalho,
    rodape,
	}: IAtualizaLinha): void {
    if(cabecalho !== undefined) {
      const header = document.querySelector(`#e-${blockId}`);

      if(header) {
        header.innerHTML = cabecalho;
      }
    }

    if(rodape !== undefined) {
      const footer = document.querySelector(`#e-${blockId}`);

      if(footer) {
        footer.innerHTML = rodape;
      }
    }
    this._addText.lineHeight();
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
