import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatDialog, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { LiquidoAmnioticoDialogComponent } from 'src/app/utils/dialog/table/liquido-amniotico-dialog/liquido-amniotico-dialog.component';

@Component({
  selector: 'app-liquido-amniotico',
  templateUrl: './liquido-amniotico.component.html',
  styleUrls: ['./liquido-amniotico.component.css']
})
export class LiquidoAmnioticoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdOligo') rdOligo: MatRadioButton;
  @ViewChild('rdPoli') rdPoli: MatRadioButton;
  @ViewChild('inMBV') inMBV: ElementRef;


  constructor(public dialog: MatDialog) { }
  unidadeMedida: string;

  ngOnInit() {
    this.unidadeMedida = ' cm';
  }

  blurParametros() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.inMBV && this.inMBV.nativeElement.value) {
      if (this.inMBV.nativeElement.value < 2) {
        this.termoSelecionado.emit(this.rdOligo.id);
        this.changeChecked(this.rdOligo.id);
      } else if (this.inMBV.nativeElement.value > 8) {
        this.termoSelecionado.emit(this.rdPoli.id);
        this.changeChecked(this.rdPoli.id);
      } else {
        this.termoSelecionado.emit(this.rdNormal.id);
        this.changeChecked(this.rdNormal.id);
      }
    }

    if (this.inMBV && this.inMBV.nativeElement.value) {
      const objetoElemento = { id: this.inMBV.nativeElement.id, value: this.inMBV.nativeElement.value + ' cm' };
      const objetoCriado = { elemento: objetoElemento };
      this.termosGenericos.emit(objetoCriado);
    }

    this.listaCompleta.emit();
  }

  changeChecked(id) {
    if (id === '2002') {
      this.rdNormal.checked = true;
      this.rdOligo.checked = false;
      this.rdPoli.checked = false;
    } else if (id === '2003') {
      this.rdNormal.checked = false;
      this.rdOligo.checked = true;
      this.rdPoli.checked = false;
    } else if (id === '2004') {
      this.rdNormal.checked = false;
      this.rdOligo.checked = false;
      this.rdPoli.checked = true;
    }
  }
}
