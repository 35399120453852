import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Injuries, Term } from '../../../../models/BO/Term';
import { Validacoes } from '../../../../utils/validacoes';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { TermResult } from '../../../../models/BO/TermResult';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfigClinicaService } from 'src/app/services/config-clinica.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

@Component({
  selector: 'app-arterial-membro-superior',
  templateUrl: './arterial-membro-superior.component.html',
  styleUrls: ['./arterial-membro-superior.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
})
export class ArterialMembroSuperiorComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  htmlContent: string;
  mask: string;
  Subtitulo: string;
  subtituloBloco: string;
  public generoLateralidade: string;
  public Lado = '';
  term: any = new Term();
  injuries = new Injuries();
  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;

  cabecalho = false;
  public lateralidade: Boolean = true;
  ArteriaRadial = false;
  ArteriaUlnar = false;
  ArteriaBraquial = false;
  ArteriaAxilar = false;
  ArteriaSubclavia = false;
  Ateromatose = false;
  public EdemaSuperior: Boolean = false;
  public achadosTexto: Boolean = false;
  public rodape: Boolean = false;
  public rodapeTexto: any = '';
  indicacao = false;
  cabecalhoTexto = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  editLaudoMode: boolean = false;

  medicalReportTypeID: number = 41;
  ArteriaRadialId = 194;
  ArteriaUlnarId = 195;
  ArteriaBraquialId = 196;
  ArteriaAxilarId = 197;
  ArteriaSubclaviaId = 198;
  AteromatoseId = 208;
  AchadosAdicionaisId = 209;
  public switchFormReport = true;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  unidadeMedida: string;
  infoLogotipo:string|any= '';

  constructor(
    private _selecteditememiter: SelectedItemEmiter,
    private _toastr: ToastrManager,
    private _copyPaste: CopyPaste,
    private _validacoes: Validacoes,
    private _laudoService: LaudosService,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _configClinica:ConfigClinicaService,
    private _config: ConfigLaudoService,
    public _addText: AddText,
    public dialog: MatDialog,
    private dataService:DataTermService) { }

  public blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Lateralidade',
      id: 'lateralidade',
      name: 'lateralidade',
      selected: true,
    },
    {
      id: this.ArteriaRadialId,
      name: 'ArteriaRadial',
      titulo: 'Artéria Radial',
      selected: false
    },
    {
      id: this.ArteriaUlnarId,
      name: 'ArteriaUlnar',
      titulo: 'Artéria Ulnar',
      selected: false,
    },
    {
      id: this.ArteriaBraquialId,
      name: 'ArteriaBraquial',
      titulo: 'Artéria Braquial',
      selected: false,
    },
    {
      id: this.ArteriaAxilarId,
      name: 'ArteriaAxilar',
      titulo: 'Artéria Axilar',
      selected: false,
    },
    {
      id: this.ArteriaSubclaviaId,
      name: 'ArteriaSubclavia',
      titulo: 'Artéria Subclávia',
      selected: false,
    },
    {
      titulo: 'Ateromatose',
      id: this.AteromatoseId,
      name: 'Ateromatose',
      selected: false,
    },
    {
      titulo: 'edema',
      id: this.AchadosAdicionaisId,
      name: 'edema',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  listTermDefault=[
    {listTerm:[2059],blockID:194},
    {listTerm:[2122],blockID:195},
    {listTerm:[2159],blockID:196},
    {listTerm:[2196],blockID:197},
    {listTerm:[2228],blockID:198},
    {listTerm:[2260],blockID:208},
    {listTerm:[3745],blockID:209},
  ]

  public descricoes: DescricaoConclusao[] = [
    { value: '<b>Artéria radial</b> de trajeto e calibre preservados. ', blockID: this.ArteriaRadialId, textTermID: 0, visivel: true },
    { value: '<b>Artéria ulnar</b> de trajeto e calibre preservados.', blockID: this.ArteriaUlnarId, textTermID: 0, visivel: true },
    { value: '<b>Artéria braquial</b> de trajeto e calibre preservados.', blockID: this.ArteriaBraquialId, textTermID: 104, visivel: true },
    { value: '<b>Artéria axilar</b> de trajeto e calibre preservados.', blockID: this.ArteriaAxilarId, textTermID: 0, visivel: true },
    {
      value: '<b>Artéria subclávia</b> nos segmentos avaliados de trajeto e calibre preservados.', blockID: this.ArteriaSubclaviaId, textTermID: 0, visivel: true
    },
    {
      value: 'Ausência de placa ateromatosa ou espessamento intimal nos segmentos vasculares avaliados.',
      blockID: this.AteromatoseId, textTermID: 0, visivel: true
    },
    {
      value: 'Não há estenoses hemodinamicamente significativas.', blockID: 0, textTermID: 0, visivel: true
    },
    {
      value: 'A análise espectral evidenciou fluxo trifásico com velocidades de pico sistólico normais em todos os vasos estudados.',
      blockID: 0, textTermID: 0, visivel: true
    },

    {
      value: '',
      blockID: this.AchadosAdicionaisId, textTermID: 0, visivel: true
    }
  ];

  public opiniao: DescricaoConclusao[] = [
    {
      value: 'Ultrassonografia com Doppler colorido das artérias analisadas dentro dos limites da normalidade.',
      blockID: 0, textTermID: 0, visivel: true
    },
    { value: '', blockID: this.ArteriaRadialId, textTermID: 0, visivel: true },
    { value: '', blockID: this.ArteriaUlnarId, textTermID: 0, visivel: true },
    { value: '', blockID: this.ArteriaBraquialId, textTermID: 0, visivel: true },
    { value: '', blockID: this.ArteriaAxilarId, textTermID: 0, visivel: true },
    { value: '', blockID: this.ArteriaSubclaviaId, textTermID: 0, visivel: true },
    { value: '', blockID: this.AteromatoseId, textTermID: 0, visivel: true },
    { value: '', blockID: this.AchadosAdicionaisId, textTermID: 0, visivel: true },
  ];

  genericRadial: TermosGenericos[] = [
    { value: '', selected: false, termID: 2071, term: '[Motivo]' },
    { value: '', selected: false, termID: 2090, term: '[ecogenicidade]' },
    { value: '', selected: false, termID: 2091, term: '[ecotextura]' },
    { value: '', selected: false, termID: 2092, term: '[superfície]' },
    { value: '', selected: false, termID: 2093, term: '[espessura]' },
    { value: '', selected: false, termID: 2094, term: '[estenose]' },
    { value: '', selected: false, termID: 2095, term: '[localização]' },
    { value: '', selected: false, termID: 2103, term: '[fluxo distal]' },
    { value: '', selected: false, termID: 2106, term: '[localização]' },
    { value: '', selected: false, termID: 2107, term: '[extensão]' },
    { value: '', selected: false, termID: 2111, term: '[localização]' },
    { value: '', selected: false, termID: 2112, term: '[estenose]' },
    { value: '', selected: false, termID: 2115, term: '[trombo]' },
    { value: '', selected: false, termID: 2116, term: '[calibre]' },
    { value: '', selected: false, termID: 2117, term: '[localização]' },
    { value: '', selected: false, termID: 2569, term: '[extensao]' },
  ];

  genericUlnar: TermosGenericos[] = [
    { value: '', selected: false, termID: 2124, term: '[motivo]' },
    { value: '', selected: false, termID: 2127, term: '[localização]' },
    { value: '', selected: false, termID: 2128, term: '[ecogenicidade]' },
    { value: '', selected: false, termID: 2129, term: '[ecotextura]' },
    { value: '', selected: false, termID: 2130, term: '[superfície]' },
    { value: '', selected: false, termID: 2131, term: '[espessura]' },
    { value: '', selected: false, termID: 2132, term: '[estenose]' },
    { value: '', selected: false, termID: 2140, term: '[fluxo distal]' },
    { value: '', selected: false, termID: 2143, term: '[localização]' },
    { value: '', selected: false, termID: 2144, term: '[extensão]' },
    { value: '', selected: false, termID: 2148, term: '[localização]' },
    { value: '', selected: false, termID: 2149, term: '[estenose]' },
    { value: '', selected: false, termID: 2152, term: '[trombo]' },
    { value: '', selected: false, termID: 2153, term: '[calibre]' },
    { value: '', selected: false, termID: 2154, term: '[localização]' },
    { value: '', selected: false, termID: 2570, term: '[extensao]' }
  ];

  genericBraquial: TermosGenericos[] = [
    { value: '', selected: false, termID: 2161, term: '[motivo]' },
    { value: '', selected: false, termID: 2164, term: '[localização]' },
    { value: '', selected: false, termID: 2165, term: '[ecogenicidade]' },
    { value: '', selected: false, termID: 2166, term: '[ecotextura]' },
    { value: '', selected: false, termID: 2167, term: '[superfície]' },
    { value: '', selected: false, termID: 2168, term: '[espessura]' },
    { value: '', selected: false, termID: 2169, term: '[estenose]' },
    { value: '', selected: false, termID: 2177, term: '[fluxo distal]' },
    { value: '', selected: false, termID: 2180, term: '[localização]' },
    { value: '', selected: false, termID: 2181, term: '[extensão]' },
    { value: '', selected: false, termID: 2185, term: '[localização]' },
    { value: '', selected: false, termID: 2186, term: '[estenose]' },
    { value: '', selected: false, termID: 2189, term: '[trombo]' },
    { value: '', selected: false, termID: 2190, term: '[calibre]' },
    { value: '', selected: false, termID: 2191, term: '[localização]' },
    { value: '', selected: false, termID: 2571, term: '[extensao]' }
  ];

  genericAxilar: TermosGenericos[] = [
    { value: '', selected: false, termID: 2198, term: '[motivo]' },
    { value: '', selected: false, termID: 2201, term: '[localização]' },
    { value: '', selected: false, termID: 2202, term: '[ecogenicidade]' },
    { value: '', selected: false, termID: 2203, term: '[ecotextura]' },
    { value: '', selected: false, termID: 2204, term: '[superfície]' },
    { value: '', selected: false, termID: 2205, term: '[espessura]' },
    { value: '', selected: false, termID: 2206, term: '[estenose]' },
    { value: '', selected: false, termID: 2214, term: '[fluxo distal]' },
    { value: '', selected: false, termID: 2217, term: '[extensão]' },
    { value: '', selected: false, termID: 2220, term: '[estenose]' },
    { value: '', selected: false, termID: 2226, term: '[trombo]' },
    { value: '', selected: false, termID: 2227, term: '[calibre]' },
    { value: '', selected: false, termID: 2572, term: '[extensao]' },
  ];

  genericSubclavica: TermosGenericos[] = [
    { value: '', selected: false, termID: 2230, term: '[motivo]' },
    { value: '', selected: false, termID: 2233, term: '[localização]' },
    { value: '', selected: false, termID: 2234, term: '[ecogenicidade]' },
    { value: '', selected: false, termID: 2235, term: '[ecotextura]' },
    { value: '', selected: false, termID: 2236, term: '[superfície]' },
    { value: '', selected: false, termID: 2237, term: '[espessura]' },
    { value: '', selected: false, termID: 2238, term: '[estenose]' },
    { value: '', selected: false, termID: 2246, term: '[fluxo distal]' },
    { value: '', selected: false, termID: 2249, term: '[extensão]' },
    { value: '', selected: false, termID: 2252, term: '[estenose]' },
    { value: '', selected: false, termID: 2258, term: '[trombo]' },
    { value: '', selected: false, termID: 2259, term: '[calibre]' },
    { value: '', selected: false, termID: 2573, term: '[extensao]' },
  ];

  genericAchados: TermosGenericos[] = [
    { value: '', selected: false, termID: 2265, term: '[lâminas líquidas]' },
    { value: '', selected: false, termID: 2266, term: '[localização face]' },
    { value: '', selected: false, termID: 2267, term: '[localização]' },
  ];

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("arteria-do-membro-superior-aortaAnalise", this.descricoes, this.opiniao);
    this.resetaDialog();
    this.generoLateralidade = 'masculino';
    this.Subtitulo = 'Lateralidade';
    this.subtituloBloco = 'Lateralidade';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });

    let configClinicaRule = JSON.parse(localStorage.getItem('configClinicaRule'));
      if(configClinicaRule && configClinicaRule.active){
      this._configClinica.handleSelectUserClinic();
      this._configClinica.maskClinica.subscribe(res=>{
        this.infoLogotipo=res;
        this.AdicionaTexto(this.newEditor);
      })
    };

    const listBlocks = [
      this.ArteriaRadialId,
      this.ArteriaUlnarId,
      this.ArteriaBraquialId,
      this.ArteriaAxilarId,
      this.ArteriaSubclaviaId,
      this.AteromatoseId,
      this.AchadosAdicionaisId
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "arteria-do-membro-superior-aortaMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase( listBlocks, "arterialSuperior");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-arteria-membro-superior-doppler');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-arteria-membro-superior-doppler');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("arteria-do-membro-superior-aortaAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.opiniao[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.Lado = "";
    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.achadosAdTexto = "";
    this.showForm = false;
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();


    this.genericRadial = [
      { value: '', selected: false, termID: 2071, term: '[Motivo]' },
      { value: '', selected: false, termID: 2090, term: '[ecogenicidade]' },
      { value: '', selected: false, termID: 2091, term: '[ecotextura]' },
      { value: '', selected: false, termID: 2092, term: '[superfície]' },
      { value: '', selected: false, termID: 2093, term: '[espessura]' },
      { value: '', selected: false, termID: 2094, term: '[estenose]' },
      { value: '', selected: false, termID: 2095, term: '[localização]' },
      { value: '', selected: false, termID: 2103, term: '[fluxo distal]' },
      { value: '', selected: false, termID: 2106, term: '[localização]' },
      { value: '', selected: false, termID: 2107, term: '[extensão]' },
      { value: '', selected: false, termID: 2111, term: '[localização]' },
      { value: '', selected: false, termID: 2112, term: '[estenose]' },
      { value: '', selected: false, termID: 2115, term: '[trombo]' },
      { value: '', selected: false, termID: 2116, term: '[calibre]' },
      { value: '', selected: false, termID: 2117, term: '[localização]' },
      { value: '', selected: false, termID: 2569, term: '[extensao]' },
    ];

    this.genericUlnar = [
      { value: '', selected: false, termID: 2124, term: '[motivo]' },
      { value: '', selected: false, termID: 2127, term: '[localização]' },
      { value: '', selected: false, termID: 2128, term: '[ecogenicidade]' },
      { value: '', selected: false, termID: 2129, term: '[ecotextura]' },
      { value: '', selected: false, termID: 2130, term: '[superfície]' },
      { value: '', selected: false, termID: 2131, term: '[espessura]' },
      { value: '', selected: false, termID: 2132, term: '[estenose]' },
      { value: '', selected: false, termID: 2140, term: '[fluxo distal]' },
      { value: '', selected: false, termID: 2143, term: '[localização]' },
      { value: '', selected: false, termID: 2144, term: '[extensão]' },
      { value: '', selected: false, termID: 2148, term: '[localização]' },
      { value: '', selected: false, termID: 2149, term: '[estenose]' },
      { value: '', selected: false, termID: 2152, term: '[trombo]' },
      { value: '', selected: false, termID: 2153, term: '[calibre]' },
      { value: '', selected: false, termID: 2154, term: '[localização]' },
      { value: '', selected: false, termID: 2570, term: '[extensao]' }
    ];

    this.genericBraquial = [
      { value: '', selected: false, termID: 2161, term: '[motivo]' },
      { value: '', selected: false, termID: 2164, term: '[localização]' },
      { value: '', selected: false, termID: 2165, term: '[ecogenicidade]' },
      { value: '', selected: false, termID: 2166, term: '[ecotextura]' },
      { value: '', selected: false, termID: 2167, term: '[superfície]' },
      { value: '', selected: false, termID: 2168, term: '[espessura]' },
      { value: '', selected: false, termID: 2169, term: '[estenose]' },
      { value: '', selected: false, termID: 2177, term: '[fluxo distal]' },
      { value: '', selected: false, termID: 2180, term: '[localização]' },
      { value: '', selected: false, termID: 2181, term: '[extensão]' },
      { value: '', selected: false, termID: 2185, term: '[localização]' },
      { value: '', selected: false, termID: 2186, term: '[estenose]' },
      { value: '', selected: false, termID: 2189, term: '[trombo]' },
      { value: '', selected: false, termID: 2190, term: '[calibre]' },
      { value: '', selected: false, termID: 2191, term: '[localização]' },
      { value: '', selected: false, termID: 2571, term: '[extensao]' }
    ];

    this.genericAxilar = [
      { value: '', selected: false, termID: 2198, term: '[motivo]' },
      { value: '', selected: false, termID: 2201, term: '[localização]' },
      { value: '', selected: false, termID: 2202, term: '[ecogenicidade]' },
      { value: '', selected: false, termID: 2203, term: '[ecotextura]' },
      { value: '', selected: false, termID: 2204, term: '[superfície]' },
      { value: '', selected: false, termID: 2205, term: '[espessura]' },
      { value: '', selected: false, termID: 2206, term: '[estenose]' },
      { value: '', selected: false, termID: 2214, term: '[fluxo distal]' },
      { value: '', selected: false, termID: 2217, term: '[extensão]' },
      { value: '', selected: false, termID: 2220, term: '[estenose]' },
      { value: '', selected: false, termID: 2226, term: '[trombo]' },
      { value: '', selected: false, termID: 2227, term: '[calibre]' },
      { value: '', selected: false, termID: 2572, term: '[extensao]' },
    ];
    this.genericSubclavica = [
      { value: '', selected: false, termID: 2230, term: '[motivo]' },
      { value: '', selected: false, termID: 2233, term: '[localização]' },
      { value: '', selected: false, termID: 2234, term: '[ecogenicidade]' },
      { value: '', selected: false, termID: 2235, term: '[ecotextura]' },
      { value: '', selected: false, termID: 2236, term: '[superfície]' },
      { value: '', selected: false, termID: 2237, term: '[espessura]' },
      { value: '', selected: false, termID: 2238, term: '[estenose]' },
      { value: '', selected: false, termID: 2246, term: '[fluxo distal]' },
      { value: '', selected: false, termID: 2249, term: '[extensão]' },
      { value: '', selected: false, termID: 2252, term: '[estenose]' },
      { value: '', selected: false, termID: 2258, term: '[trombo]' },
      { value: '', selected: false, termID: 2259, term: '[calibre]' },
      { value: '', selected: false, termID: 2573, term: '[extensao]' },
    ];

    this.genericAchados = [
      { value: '', selected: false, termID: 2265, term: '[lâminas líquidas]' },
      { value: '', selected: false, termID: 2266, term: '[localização face]' },
      { value: '', selected: false, termID: 2267, term: '[localização]' },
    ];
  }

  public selecionar(itemSelecionado): any {
    const arrayAtualizado = this._selecteditememiter.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.ArteriaRadial = (itemSelecionado.id === this.ArteriaRadialId);
    this.ArteriaUlnar = (itemSelecionado.id === this.ArteriaUlnarId);
    this.ArteriaBraquial = (itemSelecionado.id === this.ArteriaBraquialId);
    this.ArteriaAxilar = (itemSelecionado.id === this.ArteriaAxilarId);
    this.ArteriaSubclavia = (itemSelecionado.id === this.ArteriaSubclaviaId);
    this.EdemaSuperior = (itemSelecionado.id === this.AchadosAdicionaisId);
    this.Ateromatose = (itemSelecionado.id === this.AteromatoseId);
    this.lateralidade = (itemSelecionado.id === 'lateralidade');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-lateralidade`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: center;"><b>ULTRASSONOGRAFIA DOPPLER ARTERIAL DO MEMBRO SUPERIOR ${this.Lado.toUpperCase()}</b></div></p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });
    this.descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[3]}</p>`,
      }
    });

    this.opiniao.forEach((opiniao) => {
      if (opiniao.value) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p><br/> ${opiniao.value} <br/></p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if(this.infoLogotipo !== '' && this.cabecalhoTexto !== ''){
      this.htmlContent = this.infoLogotipo;
      this.htmlContent += this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo !== '' && this.cabecalhoTexto == ''){
      this.htmlContent = this.infoLogotipo;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo == '' && this.cabecalhoTexto !== ''){
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo == '' && this.cabecalhoTexto == ''){
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.Lado.toUpperCase();
    this.htmlContent += maskSplit[1];
    this.htmlContent += this.indicacaoTexto;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContent += maskSplit[2];

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value && this.descricoes[i].visivel) {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
      }
    }

    this.htmlContent += maskSplit[3];
    for (i = 0; i < this.opiniao.length; i++) {
      if (this.opiniao[i].value) {
        this.htmlContent += '<br/>' + this.opiniao[i].value + '<br/>';
      }
    }

    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		indicacao,
    conclusao,
		conclusaoNull,
		achados,
    cabecalho,
    rodape,
    lateralidade
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.opiniao.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.opiniao.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }

        if(lateralidade !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);
          let lado = `<div style="text-align: center;"><b>ULTRASSONOGRAFIA DOPPLER ARTERIAL DO MEMBRO SUPERIOR ${lateralidade.toUpperCase()}</b></div>`

          if(footer) {
						footer.innerHTML = lado.toUpperCase();
          }
        }
			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  public enviaLados($event) {
    this.Lado = $event;

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'lateralidade',
        lateralidade: this.Lado
      });
    }
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.ArteriaRadial) {
        this.term.blockID = this.ArteriaRadialId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaRadialId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoRadial(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.ArteriaRadialId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              this.alteraDescricaoNormal();
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaRadialId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoRadial(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.opiniao.filter(x => x.value !== '');
          const conclusaoNull = this.opiniao.find(x => x.blockID === 0);
          const conclusao = this.opiniao.find(x => x.blockID === this.ArteriaRadialId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaRadialId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.ArteriaRadialId,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.ArteriaUlnar) {
        this.term.blockID = this.ArteriaUlnarId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaUlnarId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoUlnar(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });
              let index = this.descricoes.findIndex(x => x.blockID === this.ArteriaUlnarId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

              this.alteraDescricaoNormal();
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaUlnarId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoUlnar(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.opiniao.filter(x => x.value !== '');
          const conclusaoNull = this.opiniao.find(x => x.blockID === 0);
          const conclusao = this.opiniao.find(x => x.blockID === this.ArteriaUlnarId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaUlnarId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.ArteriaUlnarId,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.ArteriaBraquial) {
        this.term.blockID = this.ArteriaBraquialId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaBraquialId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoBraquial(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.ArteriaBraquialId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              this.alteraDescricaoNormal();
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaBraquialId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoBraquial(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.opiniao.filter(x => x.value !== '');
          const conclusaoNull = this.opiniao.find(x => x.blockID === 0);
          const conclusao = this.opiniao.find(x => x.blockID === this.ArteriaBraquialId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaBraquialId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.ArteriaBraquialId,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.ArteriaAxilar) {
        this.term.blockID = this.ArteriaAxilarId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaAxilarId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoAxilar(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.ArteriaAxilarId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              this.alteraDescricaoNormal();
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaAxilarId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoAxilar(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.opiniao.filter(x => x.value !== '');
          const conclusaoNull = this.opiniao.find(x => x.blockID === 0);
          const conclusao = this.opiniao.find(x => x.blockID === this.ArteriaAxilarId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaAxilarId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.ArteriaAxilarId,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.ArteriaSubclavia) {
        this.term.blockID = this.ArteriaSubclaviaId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaSubclaviaId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoSubclavica(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.ArteriaSubclaviaId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              this.alteraDescricaoNormal();
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaSubclaviaId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoSubclavica(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.opiniao.filter(x => x.value !== '');
          const conclusaoNull = this.opiniao.find(x => x.blockID === 0);
          const conclusao = this.opiniao.find(x => x.blockID === this.ArteriaSubclaviaId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaSubclaviaId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.ArteriaSubclaviaId,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.Ateromatose) {
        this.term.blockID = this.AteromatoseId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.AteromatoseId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.AteromatoseId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              this.alteraDescricaoNormal();
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.AteromatoseId,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.opiniao.filter(x => x.value !== '');
          const conclusaoNull = this.opiniao.find(x => x.blockID === 0);
          const conclusao = this.opiniao.find(x => x.blockID === this.AteromatoseId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.AteromatoseId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.AteromatoseId,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.EdemaSuperior) {
        this.term.blockID = this.AchadosAdicionaisId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.AchadosAdicionaisId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoAchados(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.AchadosAdicionaisId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.AchadosAdicionaisId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoAchados(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.opiniao.filter(x => x.value !== '');
          const conclusaoNull = this.opiniao.find(x => x.blockID === 0);
          const conclusao = this.opiniao.find(x => x.blockID === this.AchadosAdicionaisId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.AchadosAdicionaisId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.AchadosAdicionaisId,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  adicionaSelecaoRadial($event) {
    this.genericRadial.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoUlnar($event) {
    this.genericUlnar.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoBraquial($event) {
    this.genericBraquial.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }
  adicionaSelecaoAxilar($event) {
    this.genericAxilar.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }
  adicionaSelecaoSubclavica($event) {
    this.genericSubclavica.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoEdema($event) {
    this.genericAchados.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  alteraDescricaoNormal() {
    this.term.listTermID.forEach(term => {
      //Arteria Radial
      if (this.term.blockID === this.ArteriaRadialId) {
        if (+term === 2094 ) {
          this.genericRadial.forEach(generic => {
            if (generic.value === 'sem determinar estenose hemodinamicamente significativa (< 50%)') {
              this.descricoes[5].visivel = false;
              this.descricoes[6].visivel = true;
              this.descricoes[7].visivel = true;
            } else if (generic.value ==='determinando estenose hemodinamicamente significativa (≥ 50%)' ||
                      generic.value === 'determinando suboclusão') {
              // this.descricoes[5].visivel = true;
              this.descricoes[6].visivel = false;
              this.descricoes[7].visivel = false;
            }
          });
        } else if(+term === 2112) {
          // this.descricoes[5].visivel = true;
          this.descricoes[6].visivel = false;
          this.descricoes[7].visivel = true;
        } else if (+term === 2059 || +term === 2063 || +term === 2102 || +term === 2104 || +term === 2113) {
          this.descricoes[6].visivel = true;
        }

        if (+term === 2088 || +term === 2102 || +term === 2104 || +term === 2113) {
          this.descricoes[5].visivel = false;
          this.descricoes[7].visivel = false;
        } else if (+term === 2110) {
          this.descricoes[7].visivel = false;
        } else if (+term === 2059 || +term === 2063) {
          this.descricoes[5].visivel = true;
          this.descricoes[6].visivel = true;
          this.descricoes[7].visivel = true;
        }

        if (+term === 2110) {
          this.genericRadial.forEach(generic => {
            if (generic.value === 'com sinais de reestenose (> 50%)' || generic.value === 'ocluído') {
              this.descricoes[5].visivel = false;
            } else if (generic.value === 'sem sinais de estenose hemodinamicamente significativa') {
              this.descricoes[5].visivel = true;
            }
          });
        }
      }
      // Arteria Ulnar
      if (this.term.blockID === this.ArteriaUlnarId) {
        if (+term === 2132) {
          this.genericUlnar.forEach(generic => {
            if (generic.value === 'sem determinar estenose hemodinamicamente significativa (< 50%)') {
              this.descricoes[5].visivel = false;
              this.descricoes[6].visivel = true;
              this.descricoes[7].visivel = true;
            } else if (generic.value ==='determinando estenose hemodinamicamente significativa (≥ 50%)' ||
                      generic.value === 'determinando suboclusão') {
              // this.descricoes[5].visivel = true;
              this.descricoes[6].visivel = false;
              this.descricoes[7].visivel = false;
            }
          });
        } else if ( +term === 2149) {
          // this.descricoes[5].visivel = true;
          this.descricoes[6].visivel = false;
          this.descricoes[7].visivel = true;
        } else if (+term === 2122 || +term === 2123 || +term === 2139 || +term === 2141 || +term === 2150) {
          this.descricoes[6].visivel = true;
        }

        if (+term === 2125 || +term === 2139 || +term === 2141 || +term ===  2150) {
          this.descricoes[5].visivel = false;
          this.descricoes[7].visivel = false;
        } else if (+term === 2147) {
          this.descricoes[7].visivel = false;
        } else if (+term === 2122 || +term === 2123) {
          this.descricoes[5].visivel = true;
          this.descricoes[6].visivel = true;
          this.descricoes[7].visivel = true;
        }

        if (+term === 2147) {
          this.genericUlnar.forEach(generic => {
            if (generic.value === 'com sinais de reestenose (> 50%)' || generic.value === 'ocluído') {
              this.descricoes[5].visivel = false;
            } else if (generic.value === 'sem sinais de estenose hemodinamicamente significativa') {
              this.descricoes[5].visivel = true;
            }
          });
        }
      }
      //Arteria Braquial
      if (this.term.blockID === this.ArteriaBraquialId) {
        if (+term === 2169) {
          this.genericBraquial.forEach(generic => {
            if (generic.value === 'sem determinar estenose hemodinamicamente significativa (< 50%)') {
              this.descricoes[5].visivel = false;
              this.descricoes[6].visivel = true;
              this.descricoes[7].visivel = true;
            } else if (generic.value ==='determinando estenose hemodinamicamente significativa (≥ 50%)' ||
                      generic.value === 'determinando suboclusão') {
              // this.descricoes[5].visivel = true;
              this.descricoes[6].visivel = false;
              this.descricoes[7].visivel = false;
            }
          });

        } else if (+term === 2186) {
          this.descricoes[6].visivel = false;
        } else if (+term === 2159 || +term === 2160 || +term === 2176 || +term === 2178 || +term === 2187) {
          this.descricoes[6].visivel = true;
        }

        if (+term === 2162 || +term === 2176 || +term === 2178 || +term ===  2187) {
          this.descricoes[5].visivel = false;
          this.descricoes[7].visivel = false;
        } else if (+term === 2184) {
          this.descricoes[7].visivel = false;
        } else if (+term === 2159 || +term === 2160) {
          this.descricoes[5].visivel = true;
          this.descricoes[6].visivel = true;
          this.descricoes[7].visivel = true;
        }

        if (+term === 2184) {
          this.genericBraquial.forEach(generic => {
            if (generic.value === 'com sinais de reestenose (> 50%)' || generic.value === 'ocluído') {
              this.descricoes[5].visivel = false;
            } else if (generic.value === 'sem sinais de estenose hemodinamicamente significativa') {
              this.descricoes[5].visivel = true;
            }
          });
        }
      }
      //Arteria Axilar
      if (this.term.blockID === this.ArteriaAxilarId) {
        if (+term === 2206 ) {
          this.genericAxilar.forEach(generic => {
            if (generic.value === 'sem determinar estenose hemodinamicamente significativa (< 50%)') {
              this.descricoes[5].visivel = false;
              this.descricoes[6].visivel = true;
              this.descricoes[7].visivel = true;
            } else if (generic.value ==='determinando estenose hemodinamicamente significativa (≥ 50%)' ||
                      generic.value === 'determinando suboclusão') {
              this.descricoes[5].visivel = true;
              this.descricoes[6].visivel = false;
              this.descricoes[7].visivel = false;
            }
          });
        } else if (+term === 2220) {
          this.descricoes[6].visivel = false;
        } else if (+term === 2196 || +term === 2197 || +term === 2213 || +term === 2215 || +term === 2221) {
          this.descricoes[6].visivel = true;
        }

        if (+term === 2199 || +term === 2213 || +term === 2215 || +term ===  2221) {
          this.descricoes[5].visivel = false;
          this.descricoes[7].visivel = false;
        } else if (+term === 2219) {
          this.descricoes[7].visivel = false;
        } else if (+term === 2196 || +term === 2197) {
          this.descricoes[5].visivel = true;
          this.descricoes[6].visivel = true;
          this.descricoes[7].visivel = true;
        }

        if (+term === 2219) {
          this.genericAxilar.forEach(generic => {
            if (generic.value === 'com sinais de reestenose (> 50%)' || generic.value === 'ocluído') {
              this.descricoes[5].visivel = false;
            } else if (generic.value === 'sem sinais de estenose hemodinamicamente significativa') {
              this.descricoes[5].visivel = true;
            }
          });
        }
      }

      //Arteria Subclavica
      if (this.term.blockID === this.ArteriaSubclaviaId) {
        if (+term === 2238 ) {
          this.genericSubclavica.forEach(generic => {
            if (generic.value === 'sem determinar estenose hemodinamicamente significativa (< 50%)') {
              this.descricoes[5].visivel = false;
              this.descricoes[6].visivel = true;
              this.descricoes[7].visivel = true;
            } else if (generic.value ==='determinando estenose hemodinamicamente significativa (≥ 50%)' ||
                      generic.value === 'determinando suboclusão') {
              // this.descricoes[5].visivel = true;
              this.descricoes[6].visivel = false;
              this.descricoes[7].visivel = false;
            }
          });
        } else if (+term === 2252) {
          this.descricoes[6].visivel = false;
        } else if (+term === 2228 || +term === 2229 || +term === 2245 || +term === 2247 || +term === 2253) {
          this.descricoes[6].visivel = true;
        }

        if (+term === 2231 || +term === 2245 || +term === 2247 || +term ===  2253) {
          this.descricoes[5].visivel = false;
          this.descricoes[7].visivel = false;
        } else if (+term === 2251) {
          this.descricoes[7].visivel = false;
        } else if (+term === 2228 || +term === 2229) {
          this.descricoes[5].visivel = true;
          this.descricoes[6].visivel = true;
          this.descricoes[7].visivel = true;
        }

        if (+term === 2251) {
          this.genericSubclavica.forEach(generic => {
            if (generic.value === 'com sinais de reestenose (> 50%)' || generic.value === 'ocluído') {
              this.descricoes[5].visivel = false;
            } else if (generic.value === 'sem sinais de estenose hemodinamicamente significativa') {
              this.descricoes[5].visivel = true;
            }
          });
        }
      }
      //Ateromatose
      if (this.term.blockID === this.AteromatoseId) {
        if ( +term === 2261 || +term === 2262 || +term === 2263) {
          this.descricoes[7].visivel = false;
        } else {
          this.descricoes[5].visivel = true;
          this.descricoes[6].visivel = true;
          this.descricoes[7].visivel = true;
        }
      }
    });

  }


  MontaDescricaoRadial(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 2071 || +term === 2090 || +term === 2091 || +term === 2092 ||
        +term === 2093 || +term === 2094 || +term === 2095 || +term === 2103 ||
        +term === 2106 || +term === 2107 || +term === 2111 || +term === 2112 ||
        +term === 2115 || +term === 2116 || +term === 2117 || +term === 2569) {
        this.genericRadial.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }
  MontaConclusaoRadial(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2071 || +term === 2090 || +term === 2091 || +term === 2092 ||
        +term === 2093 || +term === 2094 || +term === 2095 ||
        +term === 2106 || +term === 2107 || +term === 2111 || +term === 2112 ||
        +term === 2113 || +term === 2117 || +term === 2116 || +term === 2115 ||
        +term === 2569) {
        this.genericRadial.forEach(generic => {
          if (+generic.termID === +term) {
            let valor = '';
            if (+term === 2115) {
              if (generic.value.includes('Ausência de sinais de trombose')) {
                valor = '';
              }
              if (generic.value.includes('Nota-se trombo mural no seu interior')) {
                valor = ', com trombo mural';
              }
              if (generic.value.includes('Ausência de fluxo vascular detectável')) {
                valor = ', com sinais de trombose';
              }
            }
            else {
              valor = generic.value;
            }
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, valor);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoUlnar(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {

      if (+term === 2124 || +term === 2127 || +term === 2128 || +term === 2129 ||
        +term === 2130 || +term === 2131 || +term === 2132 || +term === 2140 ||
        +term === 2143 || +term === 2144 || +term === 2148 || +term === 2149 ||
        +term === 2152 || +term === 2153 || +term === 2154 || +term === 2570) {
        this.genericUlnar.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }

    });

    return texto;
  }
  MontaConclusaoUlnar(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2124 || +term === 2127 || +term === 2128 || +term === 2129 ||
        +term === 2130 || +term === 2131 || +term === 2132 || +term === 2140 ||
        +term === 2143 || +term === 2144 || +term === 2148 || +term === 2149 ||
        +term === 2152 || +term === 2153 || +term === 2154 || +term === 2570) {
        this.genericUlnar.forEach(generic => {
          if (+generic.termID === +term) {
            let valor = '';
            if (+term === 2152) {
              if (generic.value.includes('Ausência de sinais de trombose')) {
                valor = '';
              }
              if (generic.value.includes('Nota-se trombo mural no seu interior')) {
                valor = ', com trombo mural';
              }
              if (generic.value.includes('Ausência de fluxo vascular detectável')) {
                valor = ', com sinais de trombose';
              }
            }
            else {
              valor = generic.value;
            }
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, valor);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoBraquial(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {

      if (+term === 2161 || +term === 2164 || +term === 2165 || +term === 2166 ||
        +term === 2167 || +term === 2168 || +term === 2169 || +term === 2177 ||
        +term === 2180 || +term === 2181 || +term === 2185 || +term === 2186 ||
        +term === 2189 || +term === 2190 || +term === 2191 || +term === 2571) {
        this.genericBraquial.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoBraquial(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2161 || +term === 2164 || +term === 2165 || +term === 2166 ||
        +term === 2167 || +term === 2168 || +term === 2169 || +term === 2177 ||
        +term === 2180 || +term === 2181 || +term === 2185 || +term === 2186 ||
        +term === 2189 || +term === 2190 || +term === 2191 || +term === 2571) {
        this.genericBraquial.forEach(generic => {
          if (+generic.termID === +term) {
            let valor = '';
            if (+term === 2189) {
              if (generic.value.includes('Ausência de sinais de trombose')) {
                texto = 'Aneurisma fusiforme da artéria braquial.';
                valor = '';
              }
              if (generic.value.includes('Nota-se trombo mural no seu interior')) {
                valor = ', com trombo mural.';
              }
              if (generic.value.includes('Ausência de fluxo vascular detectável')) {
                valor = ', com sinais de trombose.';
              }
            }
            else {
              valor = generic.value;
            }
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, valor);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoAxilar(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {

      if (+term === 2198 || +term === 2201 || +term === 2202 || +term === 2203 ||
        +term === 2204 || +term === 2205 || +term === 2206 || +term === 2214 ||
        +term === 2217 || +term === 2220 || +term === 2226 || +term === 2227 ||
        +term === 2572) {
        this.genericAxilar.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoAxilar(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2198 || +term === 2201 || +term === 2202 || +term === 2203 ||
        +term === 2204 || +term === 2205 || +term === 2206 || +term === 2214 ||
        +term === 2217 || +term === 2220 || +term === 2226 || +term === 2227 ||
        +term === 2572) {
        this.genericAxilar.forEach(generic => {
          if (+generic.termID === +term) {
            let valor = '';
            if (+term === 2226) {
              if (generic.value.includes('Ausência de sinais de trombose')) {
                valor = '';
              }
              if (generic.value.includes('Nota-se trombo mural no seu interior')) {
                valor = ', com trombo mural';
              }
              if (generic.value.includes('Ausência de fluxo vascular detectável')) {
                valor = ', com sinais de trombose';
              }
            }
            else {
              valor = generic.value;
            }
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, valor);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }
  MontaDescricaoSubclavica(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {

      if (+term === 2230 || +term === 2233 || +term === 2234 || +term === 2235 ||
        +term === 2236 || +term === 2237 || +term === 2238 || +term === 2246 ||
        +term === 2249 || +term === 2252 || +term === 2258 || +term === 2259 ||
        +term === 2573) {
        this.genericSubclavica.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }


  MontaConclusaoSubclavica(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2230 || +term === 2233 || +term === 2234 || +term === 2235 ||
        +term === 2236 || +term === 2237 || +term === 2238 || +term === 2246 ||
        +term === 2249 || +term === 2252 || +term === 2258 || +term === 2259 ||
        +term === 2573) {
        this.genericSubclavica.forEach(generic => {
          if (+generic.termID === +term) {
            let valor = '';
            if (+term === 2258) {
              if (generic.value.includes('Ausência de sinais de trombose')) {
                valor = '';
              }
              if (generic.value.includes('Nota-se trombo mural no seu interior')) {
                valor = ', com trombo mural';
              }
              if (generic.value.includes('Ausência de fluxo vascular detectável')) {
                valor = ', com sinais de trombose';
              }
            }
            else {
              valor = generic.value;
            }
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, valor);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoAchados(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {

      if (+term === 2265 || +term === 2266 || +term === 2267) {
        this.genericAchados.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoAchados(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2265 || +term === 2266 || +term === 2267) {
        this.genericAchados.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public RetornaConclusaoNull(Texto): any {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
