export class MedicalReportLog {
  UserID: number;
  MedicalReportTypeID: number;
  MedicalReportID: number;
  ContentReport: string;
  MedicalReportLogTypeID: MedicalReportLogType;
  CompanyID: number;
}

enum MedicalReportLogType {
  ResultadoLaudo = 1,
  TermosLaudo = 2,
}
