import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserService } from "../services/user.service";

@Injectable({
  providedIn: "root",
})
export class EmailConfirmadoGuard implements CanActivate {
  constructor(private userservice: UserService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    var userID = +localStorage.getItem("userID");
    return this.userservice.buscarUsuario(userID).pipe(
      map((usuario) => {
        if (usuario.emailConfirmed) {
          this.userservice.emailConfirmado.next(true);
          return true;
        } else {
          this.userservice.emailConfirmado.next(false);
          this.router.navigate(["/email-confirmacao"]);
          return false;
        }
      })
    );
  }
}
