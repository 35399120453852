import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatCheckbox, MatSelect } from '@angular/material';
import { Calculadora } from 'src/app/utils/calculadora';

@Component({
  selector: 'app-mst-crescimento-fetal',
  templateUrl: './mst-crescimento-fetal.component.html',
  styleUrls: ['./mst-crescimento-fetal.component.css']
})
export class MstCrescimentoFetalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() camposObrigatoriosPreenchidos = new EventEmitter();

  @ViewChild('inDBP') inDBP: ElementRef;
  @ViewChild('inDOF') inDOF: ElementRef;
  @ViewChild('inCC') inCC: ElementRef;
  @ViewChild('inCA') inCA: ElementRef;
  @ViewChild('inCUM') inCUM: ElementRef;
  @ViewChild('inCF') inCF: ElementRef;
  @ViewChild('inPeso') inPeso: ElementRef;
  @ViewChild('inPercentil') inPercentil: ElementRef;
  @ViewChild('ckUSG1TrimestreIndisponivel') ckUSG1TrimestreIndisponivel: MatCheckbox;

  showAndHideTerms: any;
  peso;
  ca;
  cf;
  dbp;
  cc;

  unidadeMedida: string;
  inCFInvalid: boolean = true;
  inDBPInvalid: boolean = true;
  inCAInvalid: boolean = true;
  inCCInvalid: boolean = true;
  percentilControl: boolean = true;

  constructor(private _calc: Calculadora) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahFormulario: true,
      sahPercentil: true,
    }
    this.unidadeMedida = 'mm';
  }

  clickUSG1TrimestreIndisponivel(){
    if (this.showAndHideTerms.sahPercentil === false) {
      this.showAndHideTerms.sahPercentil = true;
      this.percentilControl=true;
      this.catchIDs();
    } else {
      this.showAndHideTerms.sahPercentil = false;
      this.percentilControl=false;
      this.catchIDs();
    }
  }

  blurParametros() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    this.removeInvalidField();

    if ((this.inDBP && this.inDBP.nativeElement.value) || (this.inCC && this.inCC.nativeElement.value)
      || (this.inCF && this.inCF.nativeElement.value) || (this.inCA && this.inCA.nativeElement.value)) {
      if (this.inPeso) {
        this.inPeso.nativeElement.value = this.calculaPeso().toFixed(2);
      }

    }

    if(this.inDBP && this.inDBP.nativeElement.value){
      const objeto = { elemento: this.inDBP.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if(this.inDOF && this.inDOF.nativeElement.value){
      const objeto = { elemento: this.inDOF.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if(this.inCC && this.inCC.nativeElement.value){
      const objeto = { elemento: this.inCC.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if(this.inCA && this.inCA.nativeElement.value){
      const objeto = { elemento: this.inCA.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if(this.inCUM && this.inCUM.nativeElement.value){
      const objeto = { elemento: this.inCUM.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if(this.inCF && this.inCF.nativeElement.value){
      const objeto = { elemento: this.inCF.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if(this.inPercentil && this.inPercentil.nativeElement.value && this.percentilControl){
      const objetoElemento = { id: this.inPercentil.nativeElement.id , value: this.inPercentil.nativeElement.value + " %"};
      const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if(this.ckUSG1TrimestreIndisponivel && this.ckUSG1TrimestreIndisponivel.checked){
      const objeto = { elemento: this.ckUSG1TrimestreIndisponivel, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
    //Logica placeholder peso
    if (this.inPeso.nativeElement.value) {
      const objetoElemento = { id: this.inPeso.nativeElement.id , value: this.inPeso.nativeElement.value };
      const objetoCriado = { elemento: objetoElemento };
      this.termosGenericos.emit(objetoCriado);
    } else {
      const objetoElemento = { id: this.inPeso.nativeElement.id, value: this.peso };
      const objetoCriado = { elemento: objetoElemento };
      this.termosGenericos.emit(objetoCriado);
    }
    // Não possui cenario vigente para utilizar o 4777
    // let idadeBiometria = this._calc.calculadoraIdadeBiometria(this.cc, this.cf, this.dbp, this.ca);
    // const objeto = { elemento: { id: '4777', value: idadeBiometria }, isPrimeiraLesao: true };
    // this.termosGenericos.emit(objeto);

    if ((this.inDBP && this.inDBP.nativeElement.value)
    && (this.inCC && this.inCC.nativeElement.value)
    && (this.inCF && this.inCF.nativeElement.value)
    && (this.inCA && this.inCA.nativeElement.value)){
      this.camposObrigatoriosPreenchidos.emit({ativo: true, nome:'crescimento-fetal'});
    }else{
      this.camposObrigatoriosPreenchidos.emit({ativo: false, nome:'crescimento-fetal'});
    }

    this.listaCompleta.emit();
  }

  calculaPeso(): number {
    this.ca = this.inCA.nativeElement.value ? this.inCA.nativeElement.value : '0';
    this.cf = this.inCF.nativeElement.value ? this.inCF.nativeElement.value : '0';
    this.dbp = this.inDBP.nativeElement.value ? this.inDBP.nativeElement.value : '0';
    this.cc = this.inCC.nativeElement.value ? this.inCC.nativeElement.value : '0';
    return 10 ** (1.511 +
      (0.0436 * parseFloat(this.ca) / 10) +
      (0.1517 * parseFloat(this.cf) / 10) -
      (0.00321 * (parseFloat(this.ca) / 10 * parseFloat(this.cf) / 10)) +
      (0.0006923 * (parseFloat(this.dbp) / 10 * (parseFloat(this.cc) / 10))));
  }

  placeholderPeso(): string {
    if ((this.inDBP && this.inDBP.nativeElement.value) || (this.inCC && this.inCC.nativeElement.value)
      || (this.inCF && this.inCF.nativeElement.value) || (this.inCA && this.inCA.nativeElement.value)) {
      this.peso = this.calculaPeso().toFixed(2);
      return this.peso;
    } else
      this.peso = 'Peso Fetal';
    return this.peso;
  }

  removeInvalidField(){
    if(this.inCF && this.inCF.nativeElement.value){
      this.inCFInvalid = false;
    }else{
      this.inCFInvalid = true;
    }
    if(this.inDBP && this.inDBP.nativeElement.value){
      this.inDBPInvalid = false;
    }else{
      this.inDBPInvalid = true;
    }
    if(this.inCA && this.inCA.nativeElement.value){
      this.inCAInvalid = false;
    }else{
      this.inCAInvalid = true;
    }
    if(this.inCC && this.inCC.nativeElement.value){
      this.inCCInvalid = false;
    }else{
      this.inCCInvalid = true;
    }
  }
}
