import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Injuries, Term } from 'src/app/models/BO/Term';
import { TermResult } from 'src/app/models/BO/TermResult';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { TermosDinamicos } from 'src/app/models/Helper/TermosDinamicos';
import { TermosGenericos } from 'src/app/models/Helper/TermosGenericos';
import { CommonService } from 'src/app/services/common.service';
import { ConfigClinicaService } from 'src/app/services/config-clinica.service';
import { DataTermService } from 'src/app/services/dataTerm.service';
import { LaudosService } from 'src/app/services/laudos.service.spec';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { SelectedItemEmiter } from 'src/app/utils/SelectedItemEmiter';
import { CopyPaste } from 'src/app/utils/textEditor/CopyPaste';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-abdome-total-refatorado',
  templateUrl: './abdome-total-refatorado.component.html',
  styleUrls: ['./abdome-total-refatorado.component.css']
})
export class AbdomeTotalRefatoradoComponent implements OnInit {

  listTermDefault=[
    {id:'figado',listTerm:[1],blockID:1},
    {id:'veiaPorta',listTerm:[74],blockID:2},
    {id:'viasBiliares',listTerm:[107],blockID:4},
    {id:'vesicula',listTerm:[79],blockID:3},
    {id:'pancreas',listTerm:[162,159],blockID:6},
    {id:'baco',listTerm:[136],blockID:5},
    {id:'aorta',listTerm:[178],blockID:8},
    {id:'ascite',listTerm:[173],blockID:7},
    {id:'linfonodo',listTerm:[4109],blockID:264},
    {id:'limitacoes',listTerm:[1],blockID:274},
    {id:'rimDireito',listTerm:[236],blockID:10},
    {id:'rimEsquerdo',listTerm:[271],blockID:11},
    {id:'alcaIntestinal',listTerm:[4123],blockID:265},
    {id:'bexiga',listTerm:[185],blockID: 9},
  ];

  descricoes: DescricaoConclusao[] = [
    { value: '', blockID: 274, textTermID: 0 },
    { value: 'Fígado com forma, dimensões e contornos preservados. Parênquima hepático com ecotextura homogênea.', blockID: 1, textTermID: 4 },
    { value: 'Veia porta pérvia, de calibre preservado.', blockID: 2, textTermID: 1 },
    { value: 'Vias biliares intra e extra-hepáticas sem sinais de dilatação.', blockID: 4, textTermID: 56 },
    { value: 'Vesícula biliar normodistendida, com paredes finas e regulares, com conteúdo anecoico. Não há imagens sugestivas de cálculos no seu interior.', blockID: 3, textTermID: 30 },
    { value: 'Pâncreas com morfologia e ecotextura normais.', blockID: 6, textTermID: 83 },
    { value: 'Baço com forma, dimensões e contornos preservados. Ecotextura esplênica homogênea.', blockID: 5, textTermID: 70 },
    {
      value: 'Rins em topografia habitual, com forma e contornos preservados, de dimensões normais.</br>Parênquima renal com espessura e ecogenicidade preservadas. Relação córtico-medular preservada.' +
        '</br>Ausência de imagens sugestivas de cálculos renais. Não há sinais de hidronefrose.', blockID: 10, textTermID: 0
    },
    { value: '', blockID: 11, textTermID: 0 },
    { value: 'Aorta com trajeto, calibre e contornos preservados.', blockID: 8, textTermID: 185 },
    { value: 'Não foram caracterizadas linfonodomegalias nos segmentos avaliados.', blockID: 264, textTermID: 0 },
    { value: 'Ausência de líquido livre na cavidade abdominal nas janelas acústicas disponíveis.', blockID: 7, textTermID: 94 },
    { value: 'Bexiga com adequada repleção, paredes regulares e finas. O conteúdo vesical é anecoico e homogêneo.', blockID: 9, textTermID: 104 },
    { value: '', blockID: 265, textTermID: 0 },
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: 'Estudo ultrassonográfico sem alterações significativas.', blockID: 0, textTermID: 0 },
    { value: '', blockID: 274, textTermID: 0 },
    { value: '', blockID: 1, textTermID: 0 },
    { value: '', blockID: 2, textTermID: 0 },
    { value: '', blockID: 4, textTermID: 0 },
    { value: '', blockID: 3, textTermID: 0 },
    { value: '', blockID: 6, textTermID: 0 },
    { value: '', blockID: 5, textTermID: 0 },
    { value: '', blockID: 10, textTermID: 0 },
    { value: '', blockID: 11, textTermID: 0 },
    { value: '', blockID: 8, textTermID: 0 },
    { value: '', blockID: 264, textTermID: 0 },
    { value: '', blockID: 7, textTermID: 0 },
    { value: '', blockID: 9, textTermID: 0 },
    { value: '', blockID: 265, textTermID: 0 }
  ];

  genericTermsFigado = [
    { value: '', selected: false, termID: 209, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 198, term: '[Segmento]' },
    { value: '', selected: false, termID: 23, term: '[Medindo]' },
    { value: '', selected: false, termID: 210, term: '[Contorno]' },
    { value: '', selected: false, termID: 43, term: '[Medindo]' },
    { value: '', selected: false, termID: 207, term: '[Segmento]' },
    { value: '', selected: false, termID: 208, term: '[Segmento]' },
    { value: '', selected: false, termID: 59, term: '[Medindo]' },
    { value: '', selected: false, termID: 203, term: '[Segmento]' },
    { value: '', selected: false, termID: 202, term: '[Medindo]' },
    { value: '', selected: false, termID: 197, term: '[Segmento]' },
    { value: '', selected: false, termID: 195, term: '[Medindo]' }
  ];

  genericTermsVesicula = [
    { value: '', selected: false, termID: 215, term: '[espessura da parede]' },
    { value: '', selected: false, termID: 216, term: '[com/sem]' },
    { value: '', selected: false, termID: 88, term: '[Medindo]' },
    { value: '', selected: false, termID: 95, term: '[Medindo]' }
  ];

  genericTermsAorta = [
    { value: '', selected: false, termID: 184, term: '[Calibre]' }
  ];

  genericTermsLinfo = [
    { value: '', selected: false, termID: 4120, term: '[Medidas]' },
    { value: '', selected: false, termID: 4121, term: '[Medidas]' },
    { value: '', selected: false, termID: 4122, term: '[Medidas]' }
  ];

  genericTermsAscite = [
    { value: '', selected: false, termID: 175, term: '[Grau]' }
  ];

  genericTermsVeiaPorta = [
    { value: '', selected: false, termID: 75, term: '[Medindo]' }
  ];

  genericTermsPancreas = [
    { value: '', selected: false, termID: 167, term: '[Medindo]' },
    { value: '', selected: false, termID: 165, term: '[Localizacao]' }
  ];

  genericTermsBaco = [
    { value: '', selected: false, termID: 141, term: '[Indice Esplenico]' },
    { value: '', selected: false, termID: 145, term: '[Medindo]' },
    { value: '', selected: false, termID: 228, term: '[Maior mede]' },
    { value: '', selected: false, termID: 147, term: '[Medindo]' },
    { value: '', selected: false, termID: 148, term: '[Local]' },
    { value: '', selected: false, termID: 150, term: '[Medindo]' },
    { value: '', selected: false, termID: 151, term: '[Margens]' },
    { value: '', selected: false, termID: 152, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 153, term: '[Local]' },
    { value: '', selected: false, termID: 155, term: '[Medindo]' },
    { value: '', selected: false, termID: 156, term: '[Margens]' },
    { value: '', selected: false, termID: 158, term: '[Local]' }
  ];

  genericTermsViasBiliares = [
    { value: '', selected: false, termID: 230, term: '[Medida Colédoco]' },
    { value: '', selected: false, termID: 106, term: '[Graduação]' },
    { value: '', selected: false, termID: 105, term: '[Cálculo obstrutivo]' }
  ];

  genericTermsRimDireito = [
    { value: '', selected: false, termID: 237, term: '[Medida]' },
    { value: '', selected: false, termID: 238, term: '[Medida Parenquima]' },
    { value: '', selected: false, termID: 241, term: '[Medida]' },
    { value: '', selected: false, termID: 242, term: '[Medida Parenquima]' },
    { value: '', selected: false, termID: 245, term: '[Medida]' },
    { value: '', selected: false, termID: 246, term: '[Localização]' },
    { value: '', selected: false, termID: 248, term: '[Medida]' },
    { value: '', selected: false, termID: 249, term: '[Localização]' },
    { value: '', selected: false, termID: 252, term: '[Medida]' },
    { value: '', selected: false, termID: 253, term: '[Localização]' },
    { value: '', selected: false, termID: 254, term: '[Grau dilatação]' },
    { value: '', selected: false, termID: 259, term: '[Medida]' },
    { value: '', selected: false, termID: 260, term: '[Localização]' },
    { value: '', selected: false, termID: 265, term: '[Medida]' },
    { value: '', selected: false, termID: 266, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 267, term: '[Margens]' },
    { value: '', selected: false, termID: 268, term: '[Localização]' },
    { value: '', selected: false, termID: 270, term: '[Grau]' }
  ];

  genericTermsRimEsquerdo = [
    { value: '', selected: false, termID: 272, term: '[Medida]' },
    { value: '', selected: false, termID: 273, term: '[Medida Parenquima]' },
    { value: '', selected: false, termID: 276, term: '[Medida]' },
    { value: '', selected: false, termID: 277, term: '[Medida Parenquima]' },
    { value: '', selected: false, termID: 280, term: '[Medida]' },
    { value: '', selected: false, termID: 281, term: '[Localização]' },
    { value: '', selected: false, termID: 283, term: '[Medida]' },
    { value: '', selected: false, termID: 284, term: '[Localização]' },
    { value: '', selected: false, termID: 286, term: '[Medida]' },
    { value: '', selected: false, termID: 287, term: '[Localização]' },
    { value: '', selected: false, termID: 288, term: '[Grau dilatação]' },
    { value: '', selected: false, termID: 293, term: '[Medida]' },
    { value: '', selected: false, termID: 294, term: '[Localização]' },
    { value: '', selected: false, termID: 298, term: '[Medida]' },
    { value: '', selected: false, termID: 299, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 300, term: '[Margens]' },
    { value: '', selected: false, termID: 301, term: '[Localização]' },
    { value: '', selected: false, termID: 303, term: '[Grau]' }
  ];

  genericTermsAlcaIntes = [
    { value: '', selected: false, termID: 4135, term: '[Calibre]' },
    { value: '', selected: false, termID: 4136, term: '[Conteudo]' },
    { value: '', selected: false, termID: 4137, term: '[Medida]' },
    { value: '', selected: false, termID: 4138, term: '[Volume]' },
    { value: '', selected: false, termID: 4139, term: '[Localizacao]' },
    { value: '', selected: false, termID: 4140, term: '[Colon]' },
    { value: '', selected: false, termID: 4141, term: '[Conteudo]' },
    { value: '', selected: false, termID: 4142, term: '[Medida]' },
    { value: '', selected: false, termID: 4143, term: '[Volume]' }
  ];

  Subtitulo: string;
  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();
  objetoGrupoLesao: any;

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  dinamicLesions: TermosDinamicos[] = [];
  dinamicLesion: any;
  dinamicconclussion: TermosDinamicos[] = this.dinamicLesions;
  lesionRow = 1;
  cenarioCorrente = 0;
  indicacaoTexto = '';
  achadosAdTexto = '';
  medicaoNodulo = '';

  indicacao = false;
  veiaporta = false;
  vesiculabiliar = false;
  viasbiliares = false;
  baco = false;
  pancreas = false;
  figado = false;
  bexiga = false;
  aorta = false;
  ascite = false;
  rimdireito = false;
  rimesquerdo = false;
  linfonodo = false;
  alcaIntestinal = false;
  limitacoes = true;
  achadosTexto = false;
  cabecalho = false;

  posicaoRimDireito = 0;
  posicaoRimEsquerdo = 0;

  medicalReportTypeID = 1;
  figadoID = 1;
  veiaportaID = 2;
  vesiculabiliarID = 3;
  viasbiliaresID = 4;
  bacoID = 5;
  pancreasID = 6;
  asciteID = 7;
  aortaID = 8;
  bexigaID = 9;
  rimdireitoID = 10;
  rimesquerdoID = 11;
  linfonodoID = 264;
  alcaIntestinalID = 265;
  limitacoesID = 274;
  cabecalhoTexto = '';

  switchFormReport = true;
  showForm: Boolean = true;
  unidadeMedida: string;
  infoLogotipo:string|any= '';
  editLaudoMode: boolean = false;

  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _copyPaste: CopyPaste,
    private dataService:DataTermService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    public dialog: MatDialog,
    private _configClinica:ConfigClinicaService) { }

  ngOnInit() {
    this.posicaoRimDireito = this.descricoes.findIndex(x => x.blockID === this.rimdireitoID);
    this.posicaoRimEsquerdo = this.descricoes.findIndex(x => x.blockID === this.rimesquerdoID);

    this._pouchService.loadDatabase("abdome-totalAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Limitações';
    let configClinicaRule = JSON.parse(localStorage.getItem('configClinicaRule'));
    if(configClinicaRule && configClinicaRule.active){
      this._configClinica.handleSelectUserClinic();
      this._configClinica.maskClinica.subscribe(res=>{
        this.infoLogotipo=res;
        this.AdicionaTexto();
      })
    };

    const listBlocks = [
      this.figadoID,
      this.veiaportaID,
      this.vesiculabiliarID,
      this.viasbiliaresID,
      this.bacoID,
      this.pancreasID,
      this.asciteID,
      this.aortaID,
      this.bexigaID,
      this.rimdireitoID,
      this.rimesquerdoID,
      this.linfonodoID,
      this.alcaIntestinalID,
      this.limitacoesID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "abdome-totalMask").then((obj: any) => {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto();
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "abdomeTotal");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-abdome-total');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-abdome-total');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';
    this.AdicionaTexto();
  }

  resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.resetaLaudo();
        }
      });
    });
  }

  resetaLaudo() {
    this._pouchService.getAll("abdome-totalAnalise").then((obj) => {
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.achadosAdTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;

    setTimeout(() => {
      this.showForm = true;
      this.AdicionaTexto();
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      nome: 'cabecalho',
      titulo: 'Informações',
      selected: false
    },
    {
      id: 'indicacao',
      nome: 'indicacao',
      titulo: 'Indicação',
      selected: false
    },
    {
      id: 'limitacoes',
      nome: 'limitacoes',
      titulo: 'Limitações',
      selected: true
    },
    {
      id: 'figado',
      nome: 'figado',
      titulo: 'Fígado',
      selected: false
    },
    {
      id: 'veiaPorta',
      nome: 'veiaPorta',
      titulo: 'Veia Porta',
      selected: false
    },
    {
      id: 'viasBiliares',
      nome: 'viasBiliares',
      titulo: 'Vias Biliares',
      selected: false
    },
    {
      id: 'vesicula',
      nome: 'vesicula',
      titulo: 'Vesícula Biliar',
      selected: false
    },
    {
      id: 'pancreas',
      nome: 'pancreas',
      titulo: 'Pâncreas',
      selected: false
    },
    {
      id: 'baco',
      nome: 'baco',
      titulo: 'Baço',
      selected: false
    },
    {
      id: 'rimDireito',
      nome: 'rimDireito',
      titulo: 'Rim Direito',
      selected: false
    },
    {
      id: 'rimEsquerdo',
      nome: 'rimEsquerdo',
      titulo: 'Rim Esquerdo',
      selected: false
    },
    {
      id: 'aorta',
      nome: 'aorta',
      titulo: 'Aorta',
      selected: false
    },
    {
      id: 'linfonodo',
      nome: 'linfonodo',
      titulo: 'Linfonodo',
      selected: false
    },
    {
      id: 'ascite',
      nome: 'ascite',
      titulo: 'Ascite',
      selected: false
    },
    {
      id: 'bexiga',
      nome: 'bexiga',
      titulo: 'Bexiga',
      selected: false
    },
    {
      id: 'alcaIntestinal',
      nome: 'alcaIntestinal',
      titulo: 'Alça Intestinal',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados-adicionais',
      nome: 'achados',
      selected: false
    }
  ];


  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.nome, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(this.editLaudoMode){
      let seachTerm = this.listTermDefault.find(elem=> elem.id === itemSelecionado.nome);
      if(seachTerm !== undefined){
        this.dataService.setTermos(seachTerm.listTerm,seachTerm.blockID);
      }
    }

    this.cabecalho =  (itemSelecionado.nome === 'cabecalho');
    this.veiaporta = (itemSelecionado.nome === 'veiaPorta');
    this.vesiculabiliar = (itemSelecionado.nome === 'vesicula');
    this.viasbiliares = (itemSelecionado.nome === 'viasBiliares');
    this.baco = (itemSelecionado.nome === 'baco');
    this.pancreas = (itemSelecionado.nome === 'pancreas');
    this.figado = (itemSelecionado.nome === 'figado');
    this.bexiga = (itemSelecionado.nome === 'bexiga');
    this.aorta = (itemSelecionado.nome === 'aorta');
    this.ascite = (itemSelecionado.nome === 'ascite');
    this.rimdireito = (itemSelecionado.nome === 'rimDireito');
    this.rimesquerdo = (itemSelecionado.nome === 'rimEsquerdo');
    this.linfonodo = (itemSelecionado.nome === 'linfonodo');
    this.alcaIntestinal = (itemSelecionado.nome === 'alcaIntestinal');
    this.limitacoes = (itemSelecionado.nome === 'limitacoes');
    this.indicacao = (itemSelecionado.nome === 'indicacao');
    this.achadosTexto = (itemSelecionado.nome === 'achados');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;
    this.dinamicLesions = [];
    this.lesionRow = 1;
    this.medicaoNodulo = '';

    this.genericTermsFigado = [
      { value: '', selected: false, termID: 209, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 198, term: '[Segmento]' },
      { value: '', selected: false, termID: 23, term: '[Medindo]' },
      { value: '', selected: false, termID: 210, term: '[Contorno]' },
      { value: '', selected: false, termID: 43, term: '[Medindo]' },
      { value: '', selected: false, termID: 207, term: '[Segmento]' },
      { value: '', selected: false, termID: 208, term: '[Segmento]' },
      { value: '', selected: false, termID: 59, term: '[Medindo]' },
      { value: '', selected: false, termID: 203, term: '[Segmento]' },
      { value: '', selected: false, termID: 202, term: '[Medindo]' },
      { value: '', selected: false, termID: 197, term: '[Segmento]' },
      { value: '', selected: false, termID: 195, term: '[Medindo]' }
    ];

    this.genericTermsVesicula = [
      { value: '', selected: false, termID: 215, term: '[espessura da parede]' },
      { value: '', selected: false, termID: 216, term: '[com/sem]' },
      { value: '', selected: false, termID: 88, term: '[Medindo]' },
      { value: '', selected: false, termID: 95, term: '[Medindo]' }
    ];

    this.genericTermsAorta = [
      { value: '', selected: false, termID: 184, term: '[Calibre]' }
    ];

    this.genericTermsLinfo = [
      { value: '', selected: false, termID: 4120, term: '[Medidas]' },
      { value: '', selected: false, termID: 4121, term: '[Medidas]' },
      { value: '', selected: false, termID: 4122, term: '[Medidas]' }
    ];

    this.genericTermsAscite = [
      { value: '', selected: false, termID: 175, term: '[Grau]' }
    ];

    this.genericTermsVeiaPorta = [
      { value: '', selected: false, termID: 75, term: '[Medindo]' }
    ];

    this.genericTermsPancreas = [
      { value: '', selected: false, termID: 167, term: '[Medindo]' },
      { value: '', selected: false, termID: 165, term: '[Localizacao]' }
    ];

    this.genericTermsBaco = [
      { value: '', selected: false, termID: 141, term: '[Indice Esplenico]' },
      { value: '', selected: false, termID: 145, term: '[Medindo]' },
      { value: '', selected: false, termID: 228, term: '[Maior mede]' },
      { value: '', selected: false, termID: 147, term: '[Medindo]' },
      { value: '', selected: false, termID: 148, term: '[Local]' },
      { value: '', selected: false, termID: 150, term: '[Medindo]' },
      { value: '', selected: false, termID: 151, term: '[Margens]' },
      { value: '', selected: false, termID: 152, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 153, term: '[Local]' },
      { value: '', selected: false, termID: 155, term: '[Medindo]' },
      { value: '', selected: false, termID: 156, term: '[Margens]' },
      { value: '', selected: false, termID: 158, term: '[Local]' }
    ];

    this.genericTermsViasBiliares = [
      { value: '', selected: false, termID: 230, term: '[Medida Colédoco]' },
      { value: '', selected: false, termID: 106, term: '[Graduação]' },
      { value: '', selected: false, termID: 105, term: '[Cálculo obstrutivo]' }
    ];

    this.genericTermsRimDireito = [
      { value: '', selected: false, termID: 237, term: '[Medida]' },
      { value: '', selected: false, termID: 238, term: '[Medida Parenquima]' },
      { value: '', selected: false, termID: 241, term: '[Medida]' },
      { value: '', selected: false, termID: 242, term: '[Medida Parenquima]' },
      { value: '', selected: false, termID: 245, term: '[Medida]' },
      { value: '', selected: false, termID: 246, term: '[Localização]' },
      { value: '', selected: false, termID: 248, term: '[Medida]' },
      { value: '', selected: false, termID: 249, term: '[Localização]' },
      { value: '', selected: false, termID: 252, term: '[Medida]' },
      { value: '', selected: false, termID: 253, term: '[Localização]' },
      { value: '', selected: false, termID: 254, term: '[Grau dilatação]' },
      { value: '', selected: false, termID: 259, term: '[Medida]' },
      { value: '', selected: false, termID: 260, term: '[Localização]' },
      { value: '', selected: false, termID: 265, term: '[Medida]' },
      { value: '', selected: false, termID: 266, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 267, term: '[Margens]' },
      { value: '', selected: false, termID: 268, term: '[Localização]' },
      { value: '', selected: false, termID: 270, term: '[Grau]' }
    ];

    this.genericTermsRimEsquerdo = [
      { value: '', selected: false, termID: 272, term: '[Medida]' },
      { value: '', selected: false, termID: 273, term: '[Medida Parenquima]' },
      { value: '', selected: false, termID: 276, term: '[Medida]' },
      { value: '', selected: false, termID: 277, term: '[Medida Parenquima]' },
      { value: '', selected: false, termID: 280, term: '[Medida]' },
      { value: '', selected: false, termID: 281, term: '[Localização]' },
      { value: '', selected: false, termID: 283, term: '[Medida]' },
      { value: '', selected: false, termID: 284, term: '[Localização]' },
      { value: '', selected: false, termID: 286, term: '[Medida]' },
      { value: '', selected: false, termID: 287, term: '[Localização]' },
      { value: '', selected: false, termID: 288, term: '[Grau dilatação]' },
      { value: '', selected: false, termID: 293, term: '[Medida]' },
      { value: '', selected: false, termID: 294, term: '[Localização]' },
      { value: '', selected: false, termID: 298, term: '[Medida]' },
      { value: '', selected: false, termID: 299, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 300, term: '[Margens]' },
      { value: '', selected: false, termID: 301, term: '[Localização]' },
      { value: '', selected: false, termID: 303, term: '[Grau]' }
    ];

    this.genericTermsAlcaIntes = [
      { value: '', selected: false, termID: 4135, term: '[Calibre]' },
      { value: '', selected: false, termID: 4136, term: '[Conteudo]' },
      { value: '', selected: false, termID: 4137, term: '[Medida]' },
      { value: '', selected: false, termID: 4138, term: '[Volume]' },
      { value: '', selected: false, termID: 4139, term: '[Localizacao]' },
      { value: '', selected: false, termID: 4140, term: '[Colon]' },
      { value: '', selected: false, termID: 4141, term: '[Conteudo]' },
      { value: '', selected: false, termID: 4142, term: '[Medida]' },
      { value: '', selected: false, termID: 4143, term: '[Volume]' }
    ];

  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  enviaTermos($event) {
    if ($event === 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';
    this.AdicionaTexto();
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    this.AdicionaTexto();
  }

  adicionaSelecaoRimDireito($event) {
    this.genericTermsRimDireito.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoRimEsquerdo($event) {
    this.genericTermsRimEsquerdo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoFigado($event) {
    this.genericTermsFigado.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoViasBiliares($event) {
    this.genericTermsViasBiliares.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    }
  }

  adicionaSelecaoPancreas($event) {
    this.genericTermsPancreas.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoBaco($event) {
    this.genericTermsBaco.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoLinfonodo($event) {
    this.genericTermsLinfo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoAscite($event) {
    this.genericTermsAscite.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    }
  }

  adicionaSelecaoVeiaPorta($event) {
    this.genericTermsVeiaPorta.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    }
  }

  adicionaSelecaoVesicula($event) {
    this.genericTermsVesicula.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoAorta($event) {
    this.genericTermsAorta.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoAlcaInt($event) {
    this.genericTermsAlcaIntes.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  incluirLesoesDinamicas($event) {
    const lesoesValue = this._validacoes.formataDecimal($event.elemento.value);

    if ($event.elemento.id == 23 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 23,
          lesionID: 20,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medindo]',
          termView: 'inNoduloMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 23;
            obj.lesionID = 20;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medindo]';
          }
        });
      }
    }

    if ($event.linha == 1 && ($event.posicao == 0 || $event.posicao == 1)) {
      this.genericTermsFigado.forEach(obj => {
        if (obj.termID == $event.elemento.id) {
          obj.selected = true;
          obj.value = lesoesValue;
        }
      });
      if ($event.isPrimeiraLesao == true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaLesoes($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      case '209':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 209,
          lesionID: 20,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]',
          termView: 'slNoduloEcogenicidade'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '198':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 198,
          lesionID: 20,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento]',
          termView: 'slNoduloSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '210':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 210,
          lesionID: 20,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Contorno]',
          termView: 'slNoduloContorno'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;

      case '43':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 43,
          lesionID: 40,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medindo]',
          termView: 'inCistoMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '207':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 207,
          lesionID: 40,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento]',
          termView: 'slCistoSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;

      case '59':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 59,
          lesionID: 58,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medindo]',
          termView: 'inCalcificacaoMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '208':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 208,
          lesionID: 58,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento]',
          termView: 'slCalcificacaoSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;

      case '202':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 202,
          lesionID: 191,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medindo]',
          termView: 'inColecaoMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '203':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 203,
          lesionID: 191,
          linha: $event.linha,
          posicao: 0,
          term: '[Segmento]',
          termView: 'slColecaoSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '195':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 195,
          lesionID: 193,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medindo]',
          termView: 'inHemangiomasMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '197':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 197,
          lesionID: 193,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento]',
          termView: 'slHemangiomasSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '43':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 43,
          lesionID: 184,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medindo]',
          termView: 'inCistoMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '207':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 207,
          lesionID: 184,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento]',
          termView: 'slCistoSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '259':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 259,
          lesionID: 255,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]',
          termView: 'inDimensao1Cisto'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '260':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 260,
          lesionID: 255,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizaco1Cisto'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '293':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 293,
          lesionID: 289,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]',
          termView: 'inDimensao1Cisto'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '294':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 294,
          lesionID: 289,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizaco1Cisto'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '265':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 265,
          lesionID: 262,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]',
          termView: 'inDimensaoNodulo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '266':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 266,
          lesionID: 262,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]',
          termView: 'slEcogenicidadeNodulo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '267':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 267,
          lesionID: 262,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Margens]',
          termView: 'slMargensNodulo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '268':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 268,
          lesionID: 262,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizacaoNodulo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '293':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 293,
          lesionID: 289,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]',
          termView: 'inDimensao1Cisto'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '294':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 294,
          lesionID: 289,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizaco1Cisto'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '298':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 298,
          lesionID: 295,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]',
          termView: 'inDimensaoNodulo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '299':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 299,
          lesionID: 295,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]',
          termView: 'slEcogenicidadeNodulo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '300':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 300,
          lesionID: 295,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Margens]',
          termView: 'slMargensNodulo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '301':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 301,
          lesionID: 295,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizacaoNodulo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '245':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 245,
          lesionID: 244,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]',
          termView: 'inDimensaoCalcNaoObstrutivo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '246':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 246,
          lesionID: 244,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizacoCalcNaoObstrutivo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '280':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 280,
          lesionID: 279,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]',
          termView: 'inDimensaoCalcNaoObstrutivo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '281':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 281,
          lesionID: 279,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizacoCalcNaoObstrutivo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }

    this.lesionRow = $event.linha;
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.find(bloco=> bloco.selected===true)
      let seachTerm = this.listTermDefault.filter(item=>item.id === blocoSelecionado.nome);
      if(seachTerm[0].blockID != undefined)
        this.dataService.setTermos(this.term.listTermID, seachTerm[0].blockID);
    } else if(!this.editLaudoMode){
      if (this.veiaporta) {
        this.term.blockID = this.veiaportaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.veiaportaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaPorta(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.veiaportaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
              }
              this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.veiaportaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.veiaportaID;
              obj.value = obj.value + espaco + this.MontaConclusaoVeiaPorta(resultado.conclusion);
              espaco = '\n';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.veiaportaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.veiaportaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.AdicionaTexto();
        });

      } else if (this.figado) {
        this.term.blockID = this.figadoID;
        debugger;
        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.figadoID && termResults[0] !== undefined) {

              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {

                obj.value = obj.value + espaco + this.MontaDescricaoFigado(resultado.text, contadorTexto);
                espaco = '<br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.figadoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
              }
              this.AdicionaTexto();

            }
          });

          let obj = {
            value: '',
            blockID: this.figadoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.figadoID;
              obj.value = obj.value + espaco + this.MontarConclusaoFigado(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.figadoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.figadoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.AdicionaTexto();
        });

      } else if (this.limitacoes) {
        this.term.blockID = this.limitacoesID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          if (termResults[0].text !== undefined) {
            this.descricoes.forEach(obj => {
              if (obj.blockID === this.limitacoesID && termResults[0] !== undefined) {
                this.termResultsAPI = termResults;
                obj.textTermID = this.termResultsAPI[0].id;
                obj.value = '';
                this.termResultsAPI.forEach(resultado => {
                  obj.value = resultado.text;
                });

                const index = this.descricoes.findIndex(x => x.blockID === this.limitacoesID);
                if (index > -1) {
                  this.descricoes[index].value = obj.value;
                  this.descricoes[index].textTermID = obj.textTermID;
                }
                this.AdicionaTexto();
              }
            });

            let obj = {
              value: '',
              blockID: this.limitacoesID,
              textTermID: 0
            };
            let espaco = '';
            const indexConclusion = termResults.filter(x => x.conclusion !== null);
            this.termConclusionAPI = termResults;
            obj.value = '';
            this.termConclusionAPI.forEach(resultado => {
              if (resultado.conclusion) {
                obj.blockID = this.limitacoesID;
                obj.value = resultado.conclusion;
                espaco = '</br>';
              } else {
                if (indexConclusion.length <= 0) {
                  obj.blockID = 0;
                  obj.value = this.RetornaConclusaoNull(resultado.conclusion);
                } else {
                  if (!obj.value) {
                    obj.blockID = 0;
                    obj.value = '';
                  }
                }
              }
            });

            const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
            const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
            const conclusao = this.conclusoes.find(x => x.blockID === this.limitacoesID);

            if (obj.blockID === 0) {
              if (conclusaoAll.length <= 1) {
                if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.limitacoesID) {
                  conclusaoNull.value = obj.value;
                  conclusao.value = '';
                } else {
                  conclusaoNull.value = '';
                }
              } else {
                conclusao.value = '';
              }
            } else if (obj.value) {
              conclusaoNull.value = '';
              conclusao.value = obj.value;
            }

            this.AdicionaTexto();
          }
        });
      } else if (this.viasbiliares) {
        this.term.blockID = this.viasbiliaresID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.viasbiliaresID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoViasBiliares(resultado.text, contadorTexto);
                espaco = '<br/>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.viasbiliaresID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
              }
              this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.viasbiliaresID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.viasbiliaresID;
              obj.value = obj.value + espaco + this.MontarConclusaoViasBiliares(resultado.conclusion);
              espaco = '<br/>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.viasbiliaresID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.viasbiliaresID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.AdicionaTexto();
        });

      } else if (this.pancreas) {
        this.term.blockID = this.pancreasID;

        this.term.injuries.push(this.injuries);

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.pancreasID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoPancreas(resultado.text, contadorTexto);
                espaco = '<br/>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.pancreasID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
              }
              this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.pancreasID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          let espaco = '';
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.pancreasID;
              obj.value = obj.value + espaco + this.MontarConclusaoPancreas(resultado.conclusion);
              espaco = '<br/>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.pancreasID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.pancreasID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.AdicionaTexto();
        });

      } else if (this.baco) {
        this.term.blockID = this.bacoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.bacoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoBaco(resultado.text, contadorTexto);
                espaco = '<br/>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.bacoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
              }
              this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.bacoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.bacoID;
              obj.value = obj.value + espaco + this.MontarConclusaoBaco(resultado.conclusion);
              espaco = '<br/>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.bacoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.bacoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.AdicionaTexto();
        });
      } else if (this.linfonodo) {
        this.term.blockID = this.linfonodoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.linfonodoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoConclusaoLinfo(resultado.text);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.linfonodoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
              }
              this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.linfonodoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.linfonodoID;
              obj.value = obj.value + espaco + this.MontaDescricaoConclusaoLinfo(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.linfonodoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.linfonodoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.AdicionaTexto();
        });
      } else if (this.aorta) {
        this.term.blockID = this.aortaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.aortaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoAorta(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.aortaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
              }
              this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.aortaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.aortaID;
              obj.value = obj.value + espaco + this.MontarConclusaoAorta(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.aortaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.aortaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.AdicionaTexto();
        });
      } else if (this.ascite) {
        this.term.blockID = this.asciteID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.asciteID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoAscite(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.asciteID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
              }
              this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.asciteID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.asciteID;
              obj.value = obj.value + espaco + this.MontarConclusaoAscite(resultado.conclusion);
              espaco = '\n';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.asciteID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.asciteID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.AdicionaTexto();
        });
      } else if (this.bexiga) {
        this.term.blockID = this.bexigaID;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.bexigaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + resultado.text;
                espaco = '<br/>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.bexigaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
              }
              this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.bexigaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.bexigaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.bexigaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.AdicionaTexto();
        });
        //por que essa chamada esta aqui?
        //this._laudoService.buscaConclusaoPorTermo(this.term);
        this._pouchService.getLaudos(this.term);
      } else if (this.alcaIntestinal) {
        this.term.blockID = this.alcaIntestinalID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.alcaIntestinalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoAlca(resultado.text, contadorTexto);
                espaco = '<br/>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.alcaIntestinalID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
              }
              this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.alcaIntestinalID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.alcaIntestinalID;
              obj.value = obj.value + espaco + this.MontaConclusaoAlca(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.alcaIntestinalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.alcaIntestinalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.AdicionaTexto();
        });

      } else if (this.vesiculabiliar) {
        this.term.blockID = this.vesiculabiliarID;
        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.vesiculabiliarID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVesicula(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.vesiculabiliarID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
              }
              this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.vesiculabiliarID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.vesiculabiliarID;
              obj.value = obj.value + espaco + this.MontarConclusaoVesicula(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.vesiculabiliarID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.vesiculabiliarID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.AdicionaTexto();
        });
      } else if (this.rimdireito) {
        this.term.blockID = this.rimdireitoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.rimdireitoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoRimDireito(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.rimdireitoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
              }
              this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.rimdireitoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.rimdireitoID
              obj.value = obj.value + espaco + this.MontarConclusaoRimDireito(resultado.conclusion, contadorTexto);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
            contadorTexto++;
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.rimdireitoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.rimdireitoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.AdicionaTexto();
        });

      } else if (this.rimesquerdo) {
        this.term.blockID = this.rimesquerdoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.rimesquerdoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoRimEsquerdo(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.rimesquerdoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
              }
              this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.rimesquerdoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.rimesquerdoID;
              obj.value = obj.value + espaco + this.MontarConclusaoRimEsquerdo(resultado.conclusion, contadorTexto);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
            contadorTexto++;
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.rimesquerdoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.rimesquerdoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.AdicionaTexto();
        });
      }
    }
  }

  MontaDescricaoRimEsquerdo(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoRimEsquerdoDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoRimEsquerdoDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  TextoRimEsquerdoDescNormal(texto, contadorTexto) {
    // Descrição para lesões normais (1 ou múltiplas)
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';

    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (term >= 271 && term <= 303) {
          this.genericTermsRimEsquerdo.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)

      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (term >= 271 && term <= 303) {
          this.genericTermsRimEsquerdo.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    }
    return texto;
  }

  TextoRimEsquerdoDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    // Lista term
    this.term.listTermID.forEach(term => {
      if (+term === 272 || +term === 273 || +term === 276 || +term === 277 || +term === 303) {
        this.genericTermsRimEsquerdo.forEach(generic => {
          if (+generic.termID === +term) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
          }
        });
      }
    });

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID >= 271 && item.termID <= 303) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico + '\n';

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (item.termID >= 271 && item.termID <= 303) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    return texto;
  }

  MontarConclusaoRimEsquerdo(texto, contadorTexto) {
    let textoSplit = '';
    let textoRetorno = '';

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      textoSplit = texto.split('|', 2);
      if (textoSplit.length > 1) {
        texto = textoSplit[0] + '\n' + textoSplit[1];
      }
      this.term.listTermID.forEach(term => {
        if (term == 272 || term == 273 || term == 276 || term == 277
          || term == 280 || term == 281 || term == 283 || term == 284
          || term == 286 || term == 287 || term == 288 || term == 293
          || term == 294 || term == 298 || term == 299 || term == 300
          || term == 301 || term == 303) {
          this.genericTermsRimEsquerdo.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (term == 272 || term == 273 || term == 276 || term == 277
          || term == 280 || term == 281 || term == 283 || term == 284
          || term == 286 || term == 287 || term == 288 || term == 293
          || term == 294 || term == 298 || term == 299 || term == 300
          || term == 301 || term == 303) {
          this.genericTermsRimEsquerdo.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }


  MontaDescricaoRimDireito(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoRimDireitoDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoRimDireitoDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  TextoRimDireitoDescNormal(texto, contadorTexto) {
    // Descrição para lesões normais (1 ou múltiplas)
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';

    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (term == 237 || term == 238 || term == 241 || term == 242
          || term == 265 || term == 266 || term == 267 || term == 268
          || term == 245 || term == 246 || term == 248 || term == 249
          || term == 252 || term == 253 || term == 254 || term == 259
          || term == 260 || term == 270) {
          this.genericTermsRimDireito.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)

      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (term == 237 || term == 238 || term == 241 || term == 242
          || term == 265 || term == 266 || term == 267 || term == 268
          || term == 245 || term == 246 || term == 248 || term == 249
          || term == 252 || term == 253 || term == 254 || term == 259
          || term == 260 || term == 270) {
          this.genericTermsRimDireito.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    }
    return texto;
  }

  TextoRimDireitoDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;
    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    // Lista term
    this.term.listTermID.forEach(term => {
      if (+term === 237 || +term === 238 || +term === 241 || +term === 242 || +term === 270) {
        this.genericTermsRimDireito.forEach(generic => {
          if (+generic.termID === +term) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
          }
        });
      }
    });

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 237 || item.termID == 238 || item.termID == 241 || item.termID == 242
            || item.termID == 265 || item.termID == 266 || item.termID == 267 || item.termID == 268
            || item.termID == 245 || item.termID == 246 || item.termID == 248 || item.termID == 249
            || item.termID == 252 || item.termID == 253 || item.termID == 254 || item.termID == 259
            || item.termID == 260 || item.termID == 270) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico + '\n';

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (item.termID == 237 || item.termID == 238 || item.termID == 241 || item.termID == 242
            || item.termID == 265 || item.termID == 266 || item.termID == 267 || item.termID == 268
            || item.termID == 245 || item.termID == 246 || item.termID == 248 || item.termID == 249
            || item.termID == 252 || item.termID == 253 || item.termID == 254 || item.termID == 259
            || item.termID == 260 || item.termID == 270) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
    //   return lesaoID != din.lesionID;
    // });

    return texto;
  }

  MontarConclusaoRimDireito(texto, contadorTexto) {
    let textoSplit = '';
    let textoRetorno = '';
    if (contadorTexto === 0) { // Caso seja a primeira lesão
      textoSplit = texto.split('|', 2);
      if (textoSplit.length > 1) {
        texto = textoSplit[0] + '\n' + textoSplit[1];
      }
      this.term.listTermID.forEach(term => {
        if (term == 237 || term == 238 || term == 241 || term == 242
          || term == 265 || term == 266 || term == 267 || term == 268
          || term == 245 || term == 246 || term == 248 || term == 249
          || term == 252 || term == 253 || term == 254 || term == 259
          || term == 260 || term == 270) {
          this.genericTermsRimDireito.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (term == 237 || term == 238 || term == 241 || term == 242
          || term == 265 || term == 266 || term == 267 || term == 268
          || term == 245 || term == 246 || term == 248 || term == 249
          || term == 252 || term == 253 || term == 254 || term == 259
          || term == 260 || term == 270) {
          this.genericTermsRimDireito.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoFigado(texto, contadorTexto) {
    debugger
    const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return (din.linha - 1) == contadorTexto && (din.cenario - 1) == contadorTexto;
    });
    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;

      texto = this.MontarTextoDescricaoDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.MontarTextoDescricaoNormal(texto, contadorTexto);
    }
    return texto;
  }

  MontarTextoDescricaoNormal(texto, contadorTexto) {
    // Descrição para lesões normais (1 ou múltiplas)
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';

    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '<br/>' + textoSplit[1];
    }

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (term == 209 || term == 198 || term == 210 || term == 207 || term == 208 || term == 203 || term == 197 || term == 23
          || term == 43 || term == 59 || term == 202 || term == 195) {
          this.genericTermsFigado.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)

      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (term == 209 || term == 198 || term == 210 || term == 207 || term == 208 || term == 203 || term == 197 || term == 23
          || term == 43 || term == 59 || term == 202 || term == 195) {
          this.genericTermsFigado.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    }
    return texto;
  }

  MontarTextoDescricaoDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '<br/>';
      textoDinamico = textoSplit[1];
    }
    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 209 || item.termID == 198 || item.termID == 210 || item.termID == 207 || item.termID == 208
            || item.termID == 203 || item.termID == 197 || item.termID == 23 || item.termID == 43 || item.termID == 59
            || item.termID == 202 || item.termID == 195) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico + '<br/>';

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (item.termID == 209 || item.termID == 198 || item.termID == 210 || item.termID == 207 || item.termID == 208
            || item.termID == 203 || item.termID == 197 || item.termID == 23 || item.termID == 43 || item.termID == 59
            || item.termID == 202 || item.termID == 195) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    return texto;
  }

  MontarConclusaoFigado(texto) {
    let textoSplit = '';
    let textoRetorno = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '</br>' + textoSplit[1];
    }

    this.term.listTermID.forEach(term => {
      if (term == 209 || term == 198 || term == 210 || term == 207 || term == 208 || term == 203 || term == 197 || term == 23
        || term == 43 || term == 59 || term == 202 || term == 195) {
        this.genericTermsFigado.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoViasBiliares(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (term == 105 || term == 106 || term == 230) {
        this.genericTermsViasBiliares.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontarConclusaoViasBiliares(texto) {
    let textoSplit = '';
    let textoRetorno = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '<br/>' + textoSplit[1];
    }
    this.term.listTermID.forEach(term => {
      if (term == 105 || term == 106 || term == 230) {
        this.genericTermsViasBiliares.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoPancreas(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (term == 167 || term == 165) {
          this.genericTermsPancreas.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (term == 167 || term == 165) {
          this.genericTermsPancreas.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontarConclusaoPancreas(texto) {
    let textoSplit = '';
    let textoRetorno = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '<br/>' + textoSplit[1];
    }
    this.term.listTermID.forEach(term => {
      if (term == 167 || term == 165) {
        this.genericTermsPancreas.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoBaco(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (term == 141 || term == 145 || term == 228 || term == 147 || term == 148
          || term == 150 || term == 151 || term == 152 || term == 153 || term == 155
          || term == 156 || term == 158) {
          this.genericTermsBaco.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (term == 141 || term == 145 || term == 228 || term == 147 || term == 148
          || term == 150 || term == 151 || term == 152 || term == 153 || term == 155
          || term == 156 || term == 158) {
          this.genericTermsBaco.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontarConclusaoBaco(texto) {
    let textoSplit = '';
    let textoRetorno = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '<br/>' + textoSplit[1];
    }
    this.term.listTermID.forEach(term => {
      if (term == 141 || term == 145 || term == 228 || term == 147 || term == 148
        || term == 150 || term == 151 || term == 152 || term == 153 || term == 155
        || term == 156 || term == 158) {
        this.genericTermsBaco.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoConclusaoLinfo(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4120 || +term === 4121 || +term === 4122) {
        this.genericTermsLinfo.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoAorta(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (term == 184) {
        this.genericTermsAorta.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontarConclusaoAorta(texto) {
    let textoSplit = '';
    let textoRetorno = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }

    this.term.listTermID.forEach(term => {
      if (term == 184) {
        this.genericTermsAorta.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoVesicula(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return (din.linha - 1) == contadorTexto;
    });

    if (rowDinamic.length > 0) {
      let lesaoID = rowDinamic[0].lesionID;
      texto = this.MontarTextoDescricaoDinamicaVesicula(texto, contadorTexto, lesaoID);
    } else {
      texto = this.MontarTextoDescricaoNormalVesicula(texto, contadorTexto);
    }
    return texto;
  }

  MontarTextoDescricaoNormalVesicula(texto, contadorTexto) {
    // Descrição para lesões normais (1 ou múltiplas)
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';

    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '<br/>' + textoSplit[1];
    }

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (term == 215 || term == 216 || term == 88 || term == 95) {
          this.genericTermsVesicula.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)

      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (term == 215 || term == 216 || term == 88 || term == 95) {
          this.genericTermsVesicula.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    }
    return texto;
  }

  MontarTextoDescricaoDinamicaVesicula(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {

      if (item.linha == linha) {
        if (item.termID == 215 || item.termID == 216 || item.termID == 88 || item.termID == 95) {
          textoDinamico = textoDinamico.replace(item.term, item.value);
        }
      } else {
        linha++;
        textoRetorno = textoRetorno + textoDinamico + '\n';

        if (textoSplit.length == 1) {
          textoDinamico = textoSplit[0];
        } else {
          textoDinamico = textoSplit[1];
        }

        if (item.termID == 215 || item.termID == 216 || item.termID == 88 || item.termID == 95) {
          textoDinamico = textoDinamico.replace(item.term, item.value);
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  MontaDescricaoVeiaPorta(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (term == 75) {
        this.genericTermsVeiaPorta.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoVeiaPorta(texto) {
    let textoSplit = '';
    let textoRetorno = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }

    this.term.listTermID.forEach(term => {
      if (term == 75) {
        this.genericTermsVeiaPorta.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoAscite(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (term == 175) {
        this.genericTermsAscite.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontarConclusaoAscite(texto) {
    let textoSplit = '';
    let textoRetorno = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }

    this.term.listTermID.forEach(term => {
      if (term == 175) {
        this.genericTermsAscite.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontarConclusaoVesicula(texto) {
    let textoSplit = '';
    let textoRetorno = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }

    this.term.listTermID.forEach(term => {
      if (term == 209 || term == 198 || term == 210 || term == 207 || term == 208 || term == 203 || term == 197 || term == 23
        || term == 43 || term == 59 || term == 202 || term == 195) {
        this.genericTermsVesicula.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoAlca(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (+term === 4135 || +term === 4136 || +term === 4137 ||
          +term === 4138 || +term === 4139 || +term === 4140 ||
          +term === 4141 || +term === 4142 || +term === 4143) {
          this.genericTermsAlcaIntes.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4135 || +term === 4136 || +term === 4137 ||
          +term === 4138 || +term === 4139 || +term === 4140 ||
          +term === 4141 || +term === 4142 || +term === 4143) {
          this.genericTermsAlcaIntes.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaConclusaoAlca(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (+term === 4135 || +term === 4136 || +term === 4137 ||
          +term === 4138 || +term === 4139 || +term === 4140 ||
          +term === 4141 || +term === 4142 || +term === 4143) {
          this.genericTermsAlcaIntes.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4135 || +term === 4136 || +term === 4137 ||
          +term === 4138 || +term === 4139 || +term === 4140 ||
          +term === 4141 || +term === 4142 || +term === 4143) {
          this.genericTermsAlcaIntes.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;

  }


  AdicionaTexto() {
    let i;
    let maskSplit = this.mask.split('|');

    if(this.infoLogotipo !== '' && this.cabecalhoTexto !== ''){
      this.htmlContent = this.infoLogotipo;
      this.htmlContent += this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo !== '' && this.cabecalhoTexto == ''){
      this.htmlContent = this.infoLogotipo;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo == '' && this.cabecalhoTexto !== ''){
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo == '' && this.cabecalhoTexto == ''){
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.indicacaoTexto;
    this.htmlContent += maskSplit[1];

    if (this.posicaoRimDireito > 0 && this.posicaoRimEsquerdo > 0) {
      if (this.descricoes[this.posicaoRimDireito].textTermID != 0 && this.descricoes[this.posicaoRimEsquerdo].textTermID == 0) {
        this.descricoes[this.posicaoRimEsquerdo].value = 'Rim esquerdo em topografia habitual, com forma e contornos preservados, de dimensões normais.</br>Parênquima renal esquerdo com espessura e ecogenicidade preservadas. Relação córtico-medular preservada.</br>Ausência de imagens sugestivas de cálculos no rim esquerdo. Não há sinais de hidronefrose à esquerda.'.toString();
        this.descricoes[this.posicaoRimEsquerdo].textTermID = 187;
      } else if (this.descricoes[this.posicaoRimDireito].textTermID == 0 && this.descricoes[this.posicaoRimEsquerdo].textTermID != 0) {
        this.descricoes[this.posicaoRimDireito].value = 'Rim direito em topografia habitual, com forma e contornos preservados, de dimensões normais.</br>Parênquima renal direito com espessura e ecogenicidade preservadas. Relação córtico-medular preservada.</br>Ausência de imagens sugestivas de cálculos no rim direito. Não há sinais de hidronefrose à direita.'.toString();
        this.descricoes[this.posicaoRimDireito].textTermID = 186;
      }
    }

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
      }
    }

    this.htmlContent += maskSplit[2];

    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }

    this.htmlContent += this.achadosAdTexto;
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = 'Exame sem anormalidades demonstráveis pelo método.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if (formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

}
