import { Directive, HostListener } from '@angular/core';
import { ConnectionService } from '../services/connection.service';
import { MedicalReportLogService } from '../services/MedicalReportLog.service';
import { PouchDBService } from '../services/pouchdb.service';
import { getAnalytics, logEvent } from "firebase/analytics";


@Directive({
  selector: '[auditChange]'
})
export class AuditChangeDirective {

  @HostListener('change', ['$event']) auditChange(event: any) {
    let id = '';
    let value = '';
    const nomeUsuario = localStorage.getItem('nomeUsuario');
    const tipoImagem = localStorage.getItem('TipoImagem');
    if(event.target){
      id = event.target.id;
      if(event.target.value != undefined)
        value = event.target.innerText;
      else
        value = event.target.value;
    }
    else{
      id = event.source.id;
      if(event.source.value != undefined)
        value = event.source._elementRef.nativeElement.innerText;
      else
        value = event.source.value;
    }



    if (!value) {
      value = '';
    }
    // Aqui a gente pode pegar as informacoes do elemento que disparou o evento
    // console.log("id: "+id+" Valor: "+value+" nomeUsuario: "+nomeUsuario+" TipoImagem: "+tipoImagem);


    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition(position => {
        //TODO: enviar lat/long para a API quando estiver prota
        // console.log(position.coords);
      });
    }

    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: +id, //this.medicalReportTypeID,
      ContentReport: value,
      MedicalReportLogTypeID: 2
    };

    if (obj)
      this._pouch.saveLaudo(obj, "LogMedicalReport");
      this._medicalReportLogService.logEventFirebase(obj);
    }

  constructor(
    private _medicalReportLogService: MedicalReportLogService,
    private _pouch: PouchDBService) { }

}
