import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-cer-colo-uterino',
  templateUrl: './cer-colo-uterino.component.html',
  styleUrls: ['./cer-colo-uterino.component.css']
})
export class CerColoUterinoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() camposObrigatoriosPreenchidos = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdCurto') rdCurto: MatRadioButton;

  @ViewChild('slOrificio') slOrificio: MatSelect;
  @ViewChild('inMedida') inMedida: ElementRef;

  showAndHideTerms: any;
  unidadeMedida: string;
  inMedidaInvalid : boolean= true;

  orificios: ArrayDropDown[] = [
    { value: 'fechado', viewValue: 'Fechado' },
    { value: 'aberto', viewValue: 'Aberto' }
  ];

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMedida: true
    }
    this.unidadeMedida ='mm';
  }

  clckNormal() {
    this.showAndHideTerms.sahMedida = true;
    this.catchIDs();
  }

  blurMedindo() {
    this.checkNormalOuCurto(this.inMedida.nativeElement.value);
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    this.removeInvalidField();

    if (this.rdNormal && this.rdNormal.checked ) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdCurto && this.rdCurto.checked ){
      this.termoSelecionado.emit(this.rdCurto.id);
    }

    if (this.inMedida && this.inMedida.nativeElement.value !== '') {
      const Diam = this._validacoes.formataDecimal(this.inMedida.nativeElement.value);
      const objetoElemento = { id: this.inMedida.nativeElement.id, value: Diam, cenario: 0 };
      const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
    }

    if (this.slOrificio && this.slOrificio.value) {
      if (this.slOrificio.value === 'aberto') {
        const objetoElemento = { id: 4269, value: '', cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      } else if (this.slOrificio.value === 'fechado') {
        const objetoElemento = { id: 4270, value: '', cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if(this.rdNormal && this.rdNormal.checked || this.rdCurto && this.rdCurto.checked){
      if(this.inMedida && this.inMedida.nativeElement.value){
        this.camposObrigatoriosPreenchidos.emit({ativo: true, nome:'avaliacaoColoUterino'});
      }else{
        this.camposObrigatoriosPreenchidos.emit({ativo: false, nome:'avaliacaoColoUterino'});
      }
    }

    this.listaCompleta.emit();
  }

  checkNormalOuCurto(value){
    if(value<=25){
      this.rdCurto.checked=true;
      this.rdNormal.checked=false;
    }else{
      this.rdCurto.checked=false;
      this.rdNormal.checked=true;
    }
  }

  removeInvalidField(){
    if(this.inMedida && this.inMedida.nativeElement.value){
      this.inMedidaInvalid = false;
    }else{
      this.inMedidaInvalid = true;
    }
  }
}
