import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { MedicalReportLog } from '../models/BO/MedicalReportLog';
import { getAnalytics, logEvent } from "firebase/analytics";

@Injectable({
  providedIn: 'root'
})

export class MedicalReportLogService {
  constructor(private _http: HttpClient) { }

  // async saveLogMedicalReport(medicalReportLog: any) {
  //   const url = environment.nLaudoApi + 'MedicalReport/LogMedicalReport';
  //   const _medicalReportLog: MedicalReportLog = medicalReportLog;

  //   const obj = {
  //     'UserID': _medicalReportLog.UserID,
  //     'MedicalReportTypeID': _medicalReportLog.MedicalReportTypeID,
  //     'ContentReport': _medicalReportLog.ContentReport,
  //     'MedicalReportLogTypeID': _medicalReportLog.MedicalReportLogTypeID,
  //     'MedicalReportID': _medicalReportLog.MedicalReportID,
  //   };

  //   return this._http.post<MedicalReportLog>(url, obj).toPromise();
  // }

  async saveLogMedicalReport(medicalReportLog: any) {
    const _medicalReportLog: MedicalReportLog = medicalReportLog;

      const obj = {
      'UserID': _medicalReportLog.UserID,
      'MedicalReportTypeID': _medicalReportLog.MedicalReportTypeID,
      'ContentReport': _medicalReportLog.ContentReport,
      'MedicalReportLogTypeID': _medicalReportLog.MedicalReportLogTypeID,
      'MedicalReportID': _medicalReportLog.MedicalReportID,
    };

    this.logEventFirebase(_medicalReportLog);
  }

  logEventFirebase(medicalReportLog: any){
    let medicalReportID = localStorage.getItem('medicalReportID');
    let companyID = localStorage.getItem('companyID');

    if(companyID != '' && medicalReportID != ''){
      const analytics = getAnalytics();
      logEvent(analytics, 'medical_report_log', {
      UserID: [medicalReportLog.usuario],
      TermID: [medicalReportLog.MedicalReportTypeID],
      ContentReport: [medicalReportLog.ContentReport],
      Medical_Report_ID: medicalReportID, //procurar id
      CompanyID: companyID
      });
    }
    else{
      console.log('O log não foi salvo pois o Medical Report ID ou o Company ID não foram encontrados')
    }
  }
}
