import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { MatRadioButton, MatSelect } from '@angular/material';

@Component({
  selector: 'app-placenta',
  templateUrl: './placenta.component.html',
  styleUrls: ['./placenta.component.css']
})
export class PlacentaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() camposObrigatoriosPreenchidos = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdInsercaoBaixa') rdInsercaoBaixa: MatRadioButton;
  @ViewChild('inEspessura') inEspessura: ElementRef;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slGrau') slGrau: MatSelect;

  localizacoes: ArrayDropDown[] = [
    { value: 'anterior', viewValue: 'Anterior' },
    { value: 'posterior', viewValue: 'Posterior' },
    { value: 'fúndica', viewValue: 'Fúndica' },
    { value: 'lateral direita', viewValue: 'Lateral direita' },
    { value: 'lateral esquerda', viewValue: 'Lateral esquerda' }
  ];

  graus: ArrayDropDown[] = [
    { value: '0', viewValue: '0' },
    { value: 'I', viewValue: 'I' },
    { value: 'II', viewValue: 'II' },
    { value: 'III', viewValue: 'III' }
  ];

  showMedida = false;
  unidadeMedida: string;
  inGrauInvalid: boolean = true;
  inLocalizacaoInvalid: boolean = true;

  constructor() { }

  ngOnInit() {
    this.unidadeMedida = 'mm';
  }

  blurParametros(){
    this.catchIDs();
  }

  clickDefault(){
    this.catchIDs();

  }
  clickInsercao(){
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    this.removeInvalidField();

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdInsercaoBaixa && this.rdInsercaoBaixa.checked) {
      this.termoSelecionado.emit(this.rdInsercaoBaixa.id);
    }

    if (this.slLocalizacao && this.slLocalizacao.value){
      let objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.slGrau && this.slGrau.value) {
      let objeto = { elemento: this.slGrau, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.inEspessura && this.inEspessura.nativeElement.value) {
      let objeto = { elemento: this.inEspessura.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if((this.slLocalizacao && this.slLocalizacao.value) && (this.slGrau && this.slGrau.value)){
      this.camposObrigatoriosPreenchidos.emit({ativo: true, nome:'Placenta'});
    }else{
      this.camposObrigatoriosPreenchidos.emit({ativo: false, nome:'Placenta'});
    }

    this.listaCompleta.emit();
  }

  removeInvalidField(){
    if(this.slLocalizacao && this.slLocalizacao.value){
      this.inLocalizacaoInvalid = false;
    }else{
      this.inLocalizacaoInvalid = true;
    }
    if(this.slGrau && this.slGrau.value){
      this.inGrauInvalid = false;
    }else{
      this.inGrauInvalid = true;
    }
  }
}
