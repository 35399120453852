import {
  Component,
  OnInit,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ToastrManager } from "ng6-toastr-notifications";
import { Versao } from "../models/BO/Versao";
import { VersaoService } from "../services/versao.service";
import { MatDialog } from '@angular/material';
import { ReleaseDialogComponent } from "../utils/dialog/release-dialog/release-dialog.component";

@Component({
  selector: 'app-versao',
  templateUrl: './versao.component.html',
  styleUrls: ['./versao.component.css', '../app.component.css']
})
export class VersaoComponent implements OnInit {
  formConfig: FormGroup;
  $usuario: any;
  $config: any;
  gravar: string = "Salvar";
  versao: string;
  desc: string;
  release: string;
  bug: string;
  public loading: boolean = true;
  releaseType: string = 'Latest'
  data: string;
  releaseImg: string[];
  releaseTxt: string[];
  oldReleaseVersion: Versao[];
  oldReleaseNumber: string;
  oldReleaseDesc: Versao;
  oldRelease: string;
  oldReleaseTxt: string[];
  oldVersionsToggle: boolean = false;



  constructor(
    private _versaoService: VersaoService,
    private toastr: ToastrManager,
    public previewDialog: MatDialog
  ) { }

 ngOnInit() {
  this.carregaVersao();
  this.carregaOldVersion();
  window.scrollTo(0, 0);
  }
  ngAfterViewInit(){
   this.$usuario = new Object();

  }

  carregaVersao() {

   this._versaoService.getVersao().toPromise().then((version) => {
    if (version) {
      console.log(version);
      this.versao = version[0].version;
      this.desc = version[0].description;
      this.data = version[0].createdOn;

      //Separando o texto e as imagens
      this.release = version[0].releases;
      let rel = [];
      rel = this.release.split('|');
      this.releaseImg = rel.filter((a,i) => i%2);
      this.releaseTxt = rel.filter((a,i) => !(i%2));

      this.bug = version[0].bugs;
      console.log(this.versao+' - '+this.desc+' - '+this.release+' - '+this.bug);
    }

    if (version != null)
      this.loading = false;
  });
  }

  carregaOldVersion(){
    try{
     this._versaoService.getOldVersions().subscribe((rel) =>{
      this.oldReleaseVersion = rel;
     });
    }
    catch(err){
      console.log(err);
    }
  }

  carregaLatestVersionDesc(){
    this.releaseType = 'Latest';
    window.scrollTo(0, 0);
  }

  carregaOldVersionDesc(version: string){
    try{
      this._versaoService.getVersionByName(version).subscribe((rel)=>{
        this.oldReleaseDesc = rel;
        this.oldReleaseNumber = version;

        //Separando o texto e as imagens
        this.oldRelease = rel[0].releases;
        let old = [];
        old = this.oldRelease.split('|');
        this.oldReleaseTxt = old.filter((a,i) => !(i%2));

        //Acionando a div de releases antigos
        this.releaseType = 'Old';
        window.scrollTo(0, 0);
      });
    }
    catch(err){
      console.log(err);
    }
  }

  clickExpand(){
    this.oldVersionsToggle ? this.oldVersionsToggle = false : this.oldVersionsToggle = true;
  }

  openPreview(imgPath: string){
    this.previewDialog.open(ReleaseDialogComponent,{
      data: imgPath,
      width: '1300px',
      height:'800px',
    })
  }
}
