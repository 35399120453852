import { Component, OnInit, EventEmitter, ViewChild, Output, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-opt-utero',
  templateUrl: './opt-utero.component.html',
  styleUrls: ['./opt-utero.component.css']
})
export class OptUteroComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdNodulo') rdNodulo: MatRadioButton;

  @ViewChild('rdUmNodulo') rdUmNodulo: MatRadioButton;
  @ViewChild('rdDoisNodulos') rdDoisNodulos: MatRadioButton;
  @ViewChild('rdCaractSemelhantes') rdCaractSemelhantes: MatRadioButton;
  @ViewChild('rdCaractNaoSemelhantes') rdCaractNaoSemelhantes: MatRadioButton;

  @ViewChild('slEcogenicidade') slEcogenicidade: MatSelect;
  @ViewChild('slContornoNodulo') slContornoNodulo: MatSelect;
  @ViewChild('slSubtipo') slSubtipo: MatSelect;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('inMedidaUmNodulo1') inMedidaUmNodulo1: ElementRef;
  @ViewChild('inMedidaUmNodulo2') inMedidaUmNodulo2: ElementRef;
  @ViewChild('inMedidaUmNodulo3') inMedidaUmNodulo3: ElementRef;

  @ViewChild('slEcogenicidadeDoisNodSem') slEcogenicidadeDoisNodSem: MatSelect;
  @ViewChild('slContornoDoisNodSem') slContornoDoisNodSem: MatSelect;

  @ViewChildren('doisNodulosChildren') doisNodulosChildren: QueryList<any>;

  contornosNodulo: ArrayDropDown[] = [
    {value: 'regulares', viewValue: 'Regulares'},
    {value: 'lobulados', viewValue: 'Lobulados'},
    {value: 'indefinidos', viewValue: 'Indefinidos'},
    {value: 'irregulares', viewValue: 'Irregulares'}
  ];

  ecogenicidade: ArrayDropDown[] = [
    {value: 'hiperecogênico', viewValue: 'Hiperecogênico'},
    {value: 'hipoecogênico', viewValue: 'Hipoecogênico'},
    {value: 'isoecogênico', viewValue: 'Isoecogênico'}
  ];

  ecogenicidadeSem: ArrayDropDown[] = [
    {value: 'hiperecogênicos', viewValue: 'Hiperecogênicos'},
    {value: 'hipoecogênicos', viewValue: 'Hipoecogênicos'},
    {value: 'isoecogênicos', viewValue: 'Isoecogênicos'}
  ];

  subtipos: ArrayDropDown[] = [
    {value: 'intramural', viewValue: 'Intramural'},
    {value: 'subseroso', viewValue: 'Subseroso'},
    {value: 'submucoso', viewValue: 'Submucoso'},
    {value: 'intramural/subseroso', viewValue: 'Intramural/Subseroso'},
    {value: 'intramural/submucoso', viewValue: 'Intramural/Submucoso'},
    {value: 'transmural', viewValue: 'Transmural'}
  ];

  localizacoes: ArrayDropDown[] = [
    {value: 'região fúndica', viewValue: 'Região fúndica'},
    {value: 'parede corporal anterior', viewValue: 'Parede corporal anterior'},
    {value: 'parede corporal posterior', viewValue: 'Parede corporal posterior'},
    {value: 'parede lateral direita', viewValue: 'Parede lateral direita'},
    {value: 'parede lateral esquerda', viewValue: 'Parede lateral esquerda'}
  ];

  lesoesForm: FormGroup;
  linha = 0;
  objeto: any;
  obj: any;
  countLesoes = 0;
  objetofilho: any;
  phVolume = 'Volume';
  showAndHideTerms: any;

  constructor(private _validacoes: Validacoes, private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahNodulo: true,
      sahUmNodulo: true,
      sahDoisNodulos: false,
      sahDoisNodulosSem: false,
      sahDoisNodulosNaoSem: false
    }
    this.lesoesForm = this._fb.group({
      doisNodulos: this._fb.array([this.addDoisNodulosGrup()]),
    });
  }

  addDoisNodulos() {
    this.doisNodulosArray.push(this.addDoisNodulosGrup());
    this.catchIDs();
  }

  removeDoisNodulos(index) {
    this.doisNodulosArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get doisNodulosArray() {
    return <FormArray>this.lesoesForm.get('doisNodulos');
  }

  //Dinamico
  addDoisNodulosGrup() {
    return this._fb.group({
      ecogenicidade: [],
      contorno: [],
      doppler: [],
      subtipo: [],
      localizacao: [],
      medindo: []
    });
  }

  clickNormal() {
    this.showAndHideTerms.sahNodulo = false;
    this.showAndHideTerms.sahUmNodulo = false;
    this.showAndHideTerms.sahDoisNodulos = false;
    this.catchIDs();
  }

  clickNodulo() {
    this.showAndHideTerms.sahNodulo = true;
    this.showAndHideTerms.sahUmNodulo = true;
    this.showAndHideTerms.sahDoisNodulos = false;
  }

  clickUmNodulo() {
    this.verificaLesoes();
    this.showAndHideTerms.sahUmNodulo = true;
    this.showAndHideTerms.sahDoisNodulos = false;
    this.catchIDs();
    this.ajustaArrayDinamico();
  }

  clickDoisNodulos() {
    this.verificaLesoes();
    this.showAndHideTerms.sahUmNodulo = false;
    this.showAndHideTerms.sahDoisNodulos = true;
    this.showAndHideTerms.sahDoisNodulosSem = true;
    this.showAndHideTerms.sahDoisNodulosNaoSem = false;
    this.showAndHideTerms.sah = true;
    this.catchIDs();
    this.ajustaArrayDinamico();
  }
  verificaLesoes(){
    if((this.rdUmNodulo.checked || this.rdDoisNodulos.checked) && !this.rdNodulo.checked){
      this.rdNodulo.checked = true;
    }
  }

  clickCaractSemelhantes() {
    this.showAndHideTerms.sahDoisNodulosSem = true;
    this.showAndHideTerms.sahDoisNodulosNaoSem = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickCaractNaoSemelhantes() {
    this.showAndHideTerms.sahDoisNodulosNaoSem = true;
    this.showAndHideTerms.sahDoisNodulosSem = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  blurMedindo() {
    this.verificaLesoes();
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  catchIDs() {

    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');
    let posicao = 0;
    this.linha = 0;

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdNodulo && this.rdNodulo.checked) {
      this.countLesoes++;
      if (this.rdUmNodulo && this.rdUmNodulo.checked) {
        this.termoSelecionado.emit(this.rdUmNodulo.id);

        if (this.slEcogenicidade && this.slEcogenicidade.value) {
          const objeto = { elemento: this.slEcogenicidade, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slContornoNodulo && this.slContornoNodulo.value) {
          const objeto = { elemento: this.slContornoNodulo, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slSubtipo && this.slSubtipo.value) {
          const objeto = { elemento: this.slSubtipo, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLocalizacao && this.slLocalizacao.value) {
          const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoUmNodulo();

      }

      if (this.rdDoisNodulos && this.rdDoisNodulos.checked) {
         this.termoSelecionado.emit(this.rdDoisNodulos.id);
        // this.lesoesSelecionadas.emit(objeto);

        if (this.rdCaractSemelhantes && this.rdCaractSemelhantes.checked) {
          this.termoSelecionado.emit(this.rdCaractSemelhantes.id);
          // this.lesoesSelecionadas.emit(objeto);


          if (this.slEcogenicidadeDoisNodSem && this.slEcogenicidadeDoisNodSem.value) {
            const objeto = { elemento: this.slEcogenicidadeDoisNodSem, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }

          if (this.slContornoDoisNodSem && this.slContornoDoisNodSem.value) {
            const objeto = { elemento: this.slContornoDoisNodSem, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }


          this.doisNodulosChildren.forEach(obj => {
            if (obj.nativeElement) {
              if (posicao < 3) {
                posicao++;
              } else {
                posicao = 1;
              }

              this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
              this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
              this.lesoesDinamicas.emit(this.obj);
            } else {

              if (obj.id == 3968) {
                this.linha++;
              }

              this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
              this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
              this.lesoesDinamicas.emit(this.obj);
            }
          });
        }

        if (this.rdCaractNaoSemelhantes && this.rdCaractNaoSemelhantes.checked) {
          this.termoSelecionado.emit(this.rdCaractNaoSemelhantes.id);

          this.doisNodulosChildren.forEach(obj => {
            if (obj.nativeElement) {
              if (posicao < 3) {
                posicao++;
              } else {
                posicao = 1;
              }

              this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
              this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
              this.lesoesDinamicas.emit(this.obj);
            } else {

              if (obj.id == 3966) {
                this.linha++;
              }

              this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
              this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
              this.lesoesDinamicas.emit(this.obj);
            }
          });
        }
      }

    }

    this.listaCompleta.emit();
  }

  formataMedindoDoiNodulo() {
    const objetoElemento = {
      id: "3970", value: ' ' + this._validacoes.formataDecimal(this.inMedidaUmNodulo1.nativeElement.value) +
        ' x ' + this._validacoes.formataDecimal(this.inMedidaUmNodulo2.nativeElement.value) +
        ' x ' + this._validacoes.formataDecimal(this.inMedidaUmNodulo3.nativeElement.value), cenario: this.countLesoes
    };

    const objetoCriado = { elemento: objetoElemento };
    this.termosGenericos.emit(objetoCriado);
  }

  formataMedindoUmNodulo() {
    let medindoValor = '';
    let isPrimeiraLesao = false;

    if (this.inMedidaUmNodulo1 !== undefined &&
      this.inMedidaUmNodulo1.nativeElement.id !== undefined &&
      this.inMedidaUmNodulo1.nativeElement.value !== '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaUmNodulo1.nativeElement.value);
    }

    if (this.inMedidaUmNodulo2 !== undefined &&
      this.inMedidaUmNodulo2.nativeElement.id !== undefined &&
      this.inMedidaUmNodulo2.nativeElement.value !== '') {

      if (medindoValor !== '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaUmNodulo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaUmNodulo2.nativeElement.value);
      }
    }

    if (this.inMedidaUmNodulo3 !== undefined &&
      this.inMedidaUmNodulo3.nativeElement.id !== undefined &&
      this.inMedidaUmNodulo3.nativeElement.value !== '') {

      if (medindoValor !== '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaUmNodulo3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaUmNodulo3.nativeElement.value);
      }
    }

    if (medindoValor !== '') {
      // if (this.countLesoes < 1) {
      //   isPrimeiraLesao = true;
      // }

      const objetoElemento = { id: 3963, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  ajustaArrayDinamico() {

    if (this.rdNodulo && !this.rdNodulo.checked
      || this.rdDoisNodulos && !this.rdDoisNodulos.checked) {

      const arrayTotal = this.doisNodulosArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.doisNodulosArray.length - 1

        if (index !== 0) {
          this.doisNodulosArray.removeAt(index);
        }
      }
      this.doisNodulosArray.reset();
    }

  }

}
