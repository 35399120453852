import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { DescricaoConclusao } from '../../../models/Helper/DescricaoConclusao';
import { Term, Injuries } from 'src/app/models/BO/Term';
import { TermResult } from 'src/app/models/BO/TermResult';
import { SelectedItemEmiter } from 'src/app/utils/SelectedItemEmiter';
import { LaudosService } from 'src/app/services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validacoes } from 'src/app/utils/validacoes';
import { CopyPaste } from 'src/app/utils/textEditor/CopyPaste';
import { MatDialog } from '@angular/material';
import { TermosGenericos } from '../../../../app/models/Helper/TermosGenericos';
import { TermosDinamicos } from '../../../models/Helper/TermosDinamicos';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { CommonService } from 'src/app/services/common.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { ConfigClinicaService } from 'src/app/services/config-clinica.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

@Component({
  selector: 'app-torax-rsna',
  templateUrl: './torax-rsna.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./torax-rsna.component.css']
})
export class ToraxRsnaComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[1576],blockID:140},
    {listTerm:[1551],blockID:134},
    {listTerm:[1478],blockID:130},
    {listTerm:[1611],blockID:137},
    {listTerm:[1503],blockID:132},
    {listTerm:[1565],blockID:139},
    {listTerm:[1533],blockID:133},
    {listTerm:[1470],blockID:129},
    {listTerm:[1579],blockID:135},
    {listTerm:[1497],blockID:131},
    {listTerm:[1599],blockID:136},
    {listTerm:[1457],blockID:128},
    {listTerm:[1555],blockID:138},
    {listTerm:[4035],blockID:254},
    {listTerm:[1946],blockID:163},
  ]

  descricoes: DescricaoConclusao[] = [
    { value: '', blockID: 134, textTermID: 0, visivel: true },
    { value: '', blockID: 140, textTermID: 0, visivel: true },
    { value: '', blockID: 130, textTermID: 0, visivel: true },
    { value: 'Parênquima pulmonar com coeficientes de atenuação preservados.', blockID: 137, textTermID: 0, visivel: true },
    { value: 'Ausência de derrame pleural.', blockID: 132, textTermID: 0, visivel: true },
    { value: 'Traqueia, carina e brônquios fontes pérvios e com situação, calibre e contornos normais.', blockID: 139, textTermID: 0, visivel: true },
    { value: '', blockID: 139, textTermID: 1, visivel: true },
    { value: 'Mediastino centrado.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 133, textTermID: 1, visivel: true },
    { value: 'Estruturas vasculares com situação e calibre normais.', blockID: 133, textTermID: 0, visivel: true },
    { value: 'Coração com situação, morfologia, contornos e dimensões normais.', blockID: 129, textTermID: 0, visivel: true },
    { value: '', blockID: 129, textTermID: 1, visivel: true },
    { value: '', blockID: 135, textTermID: 0, visivel: true },
    { value: 'Não se observam linfonodomegalias mediastinais.', blockID: 135, textTermID: 1, visivel: true },
    { value: '', blockID: 131, textTermID: 0, visivel: true },
    { value: '', blockID: 136, textTermID: 0, visivel: true },
    { value: 'Arcabouço ósseo torácico preservado.', blockID: 128, textTermID: 0, visivel: true },
    { value: '', blockID: 254, textTermID: 0, visivel: true },
    { value: '', blockID: 138, textTermID: 0, visivel: true },
    { value: 'Exames anteriores indisponíveis para análise comparativa.', blockID: 163, textTermID: 0, visivel: true }
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: 'Não há sinais tomográficos de pneumonia. (Nota: a tomografia pode não apresentar achados nos estágios iniciais de COVID-19.)', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 134, textTermID: 0, visivel: true },
    { value: '', blockID: 130, textTermID: 0, visivel: true },
    { value: '', blockID: 137, textTermID: 0, visivel: true },
    { value: '', blockID: 132, textTermID: 0, visivel: true },
    { value: '', blockID: 139, textTermID: 0, visivel: true },
    { value: '', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 133, textTermID: 0, visivel: true },
    { value: '', blockID: 129, textTermID: 0, visivel: true },
    { value: '', blockID: 129, textTermID: 1, visivel: true },
    { value: '', blockID: 135, textTermID: 0, visivel: true },
    { value: '', blockID: 131, textTermID: 0, visivel: true },
    { value: '', blockID: 136, textTermID: 0, visivel: true },
    { value: '', blockID: 128, textTermID: 0, visivel: true },
    { value: '', blockID: 163, textTermID: 0, visivel: true }
  ];

  genericIndicacao: TermosGenericos[] = [
    { value: '', selected: false, termID: 1554, term: '[Outro]' }
  ];

  genericCoracao: TermosGenericos[] = [
    { value: '', selected: false, termID: 1472, term: '[Dimensoes]' },
    { value: '', selected: false, termID: 1473, term: '[VD]' },
    { value: '', selected: false, termID: 1474, term: '[VE]' },
    { value: '', selected: false, termID: 1476, term: '[Grau]' },
    { value: '', selected: false, termID: 1477, term: '[Espessura]' }
  ];

  genericEsofago: TermosGenericos[] = [
    { value: '', selected: false, termID: 1499, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1502, term: '[Grau]' }
  ];

  genericArcabouco: TermosGenericos[] = [
    { value: '', selected: false, termID: 1462, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1463, term: '[Lateralidade]' },
    { value: '', selected: false, termID: 1467, term: '[Localizacao Consolidada]' },
    { value: '', selected: false, termID: 1468, term: '[Lateralidade Consolidada]' }
  ];

  genericTuboCateter: TermosGenericos[] = [
    { value: '', selected: false, termID: 1480, term: '[Distancia Carina]' },
    { value: '', selected: false, termID: 1483, term: '[Lado]' },
    { value: '', selected: false, termID: 1484, term: '[Veia]' },
    { value: '', selected: false, termID: 4027, term: '[Extremidade]' },
    { value: '', selected: false, termID: 1486, term: '[Lado Disp.]' },
    { value: '', selected: false, termID: 1488, term: '[Lado Marca.]' },
    { value: '', selected: false, termID: 1489, term: '[Camaras]' },
    { value: '', selected: false, termID: 1492, term: '[Localizacao Dir.]' },
    { value: '', selected: false, termID: 1493, term: '[Espaco Dir.]' },
    { value: '', selected: false, termID: 1495, term: '[Localizacao Esq.]' },
    { value: '', selected: false, termID: 1496, term: '[Espaco Esq.]' }
  ];

  genericTraqueia: TermosGenericos[] = [
    { value: '', selected: false, termID: 1569, term: '[Lobo]' },
    { value: '', selected: false, termID: 1573, term: '[Localizacao]' }
  ];

  genericTransicao: TermosGenericos[] = [
    { value: '', selected: false, termID: 1559, term: '[Lado Nefro]' },
    { value: '', selected: false, termID: 1561, term: '[Lado Cisto]' },
    { value: '', selected: false, termID: 1563, term: '[Lado Cistos]' }
  ];

  genericEspacaPleural: TermosGenericos[] = [
    { value: '', selected: false, termID: 1506, term: '[Grau Dir.]' },
    { value: '', selected: false, termID: 1508, term: '[Grau Esq.]' },
    { value: '', selected: false, termID: 1513, term: '[Grau Loc Dir.]' },
    { value: '', selected: false, termID: 1516, term: '[Grau Loc Esq.]' },
    { value: '', selected: false, termID: 1521, term: '[Grau Emp Dir.]' },
    { value: '', selected: false, termID: 1523, term: '[Grau Emp Esq.]' },
    { value: '', selected: false, termID: 1528, term: '[Grau Pneu Dir.]' },
    { value: '', selected: false, termID: 1530, term: '[Grau Pneu Esq.]' },
    { value: '', selected: false, termID: 1532, term: '[Lateralidade]' }
  ];

  genericEstruturasVasc: TermosGenericos[] = [
    { value: '', selected: false, termID: 1539, term: '[Valva]' },
    { value: '', selected: false, termID: 1543, term: '[Calibre Ect]' },
    { value: '', selected: false, termID: 1545, term: '[Calibre Aor]' },
    { value: '', selected: false, termID: 1546, term: '[Segmento Aor]' },
    { value: '', selected: false, termID: 1548, term: '[Diametro]' },
    { value: '', selected: false, termID: 1549, term: '[Extensao]' },
    { value: '', selected: false, termID: 1550, term: '[Segmento Ane]' }
  ];

  genericMetodo: TermosGenericos[] = [
    { value: '', selected: false, termID: 1575, term: '[Tecnica]' },
    { value: '', selected: false, termID: 1578, term: '[Fase]' }
  ];

  genericLinfonodo: TermosGenericos[] = [
    { value: '', selected: false, termID: 1583, term: '[Cadeias]' },
    { value: '', selected: false, termID: 1587, term: '[Cadeias Proe]' },
    { value: '', selected: false, termID: 1590, term: '[Cadeias linfo]' },
    { value: '', selected: false, termID: 1593, term: '[Medida]' },
    { value: '', selected: false, termID: 1596, term: '[Cadeias linfo]' },
    { value: '', selected: false, termID: 1597, term: '[Maior cadeia]' },
    { value: '', selected: false, termID: 1598, term: '[Medida]' }
  ];

  genericParedeTor: TermosGenericos[] = [
    { value: '', selected: false, termID: 1602, term: '[Lado]' },
    { value: '', selected: false, termID: 1603, term: '[Medida]' },
    { value: '', selected: false, termID: 1606, term: '[Cadeias]' },
    { value: '', selected: false, termID: 1607, term: '[Medida linfo]' },
    { value: '', selected: false, termID: 4022, term: '[Lobo]' },
    { value: '', selected: false, termID: 4023, term: '[Medida]' }
  ];

  genericParenquimaPulm: TermosGenericos[] = [
    { value: '', selected: false, termID: 1864, term: '[Lobos]' },
    { value: '', selected: false, termID: 1866, term: '[Achados adicionais]' },
    { value: '', selected: false, termID: 1869, term: '[Lobos Mult]' },
    { value: '', selected: false, termID: 1871, term: '[Achados adicionais Mult]' },
    { value: '', selected: false, termID: 1874, term: '[Localizacao Halo]' },
    { value: '', selected: false, termID: 1876, term: '[Lobo Opac]' },
    { value: '', selected: false, termID: 1877, term: '[Segmento Opac]' },
    { value: '', selected: false, termID: 1878, term: '[Distribuicao Opac]' },
    // { value: '', selected: false, termID: 1879, term: '[Consolidacoes esparsas]' },
    { value: '', selected: false, termID: 1881, term: '[Localizacao consolidacao]' },
    { value: '', selected: false, termID: 1884, term: '[Lobo Loc]' },
    { value: '', selected: false, termID: 1885, term: '[Segmento Loc]' },
    { value: '', selected: false, termID: 1887, term: '[Lobo Ate]' },
    { value: '', selected: false, termID: 1888, term: '[Segmento Ate]' },
    { value: '', selected: false, termID: 1890, term: '[Lobo Micro Covid]' },
    { value: '', selected: false, termID: 1891, term: '[Segmento Micro Covid]' },
    { value: '', selected: false, termID: 1894, term: '[Lobo predominante Covid]' },
    { value: '', selected: false, termID: 1896, term: '[Lado derrame]' },
    { value: '', selected: false, termID: 1897, term: '[Grau derrame]' },
    { value: '', selected: false, termID: 1899, term: '[Extensao Aco]' },
    { value: '', selected: false, termID: 1641, term: '[Lobo Ate]' },
    { value: '', selected: false, termID: 1642, term: '[Segmento Ate]' },
    { value: '', selected: false, termID: 1644, term: '[Tipo Ate]' },
    { value: '', selected: false, termID: 1947, term: '[Lobo Ate Seg]' },
    { value: '', selected: false, termID: 1948, term: '[Lobo Ate Lam]' },
    { value: '', selected: false, termID: 1949, term: '[Lobo Ate Est]' },
    { value: '', selected: false, termID: 1650, term: '[Lobo Inf]' },
    { value: '', selected: false, termID: 1651, term: '[Segmento Inf]' },
    { value: '', selected: false, termID: 1654, term: '[Grau enf]' },
    { value: '', selected: false, termID: 1659, term: '[Tipo enf]' },
    { value: '', selected: false, termID: 1662, term: '[Lobo predominante Int]' },
    { value: '', selected: false, termID: 1671, term: '[Composicao Nod]' },
    { value: '', selected: false, termID: 1672, term: '[Contornos Nod]' },
    { value: '', selected: false, termID: 1673, term: '[Lobo Nod]' },
    { value: '', selected: false, termID: 1674, term: '[Segmento Nod]' },
    { value: '', selected: false, termID: 1675, term: '[Medida Nod]' },
    { value: '', selected: false, termID: 1683, term: '[Composicao Mult]' },
    { value: '', selected: false, termID: 1684, term: '[Contornos Mult]' },
    { value: '', selected: false, termID: 1689, term: '[Contornos Met]' },
    { value: '', selected: false, termID: 1698, term: '[Medida Dim]' },
    { value: '', selected: false, termID: 3832, term: '[Medida Dim Raros Inc]' },
    { value: '', selected: false, termID: 3833, term: '[Medida Dim Raros Res]' },
    { value: '', selected: false, termID: 3834, term: '[Medida Dim Res]' },
    { value: '', selected: false, termID: 1700, term: '[Composicao Mass]' },
    { value: '', selected: false, termID: 1701, term: '[Contornos Mass]' },
    { value: '', selected: false, termID: 1702, term: '[Lobo Mass]' },
    { value: '', selected: false, termID: 1703, term: '[Segmento Mass]' },
    { value: '', selected: false, termID: 1704, term: '[Medida Mass]' },
    { value: '', selected: false, termID: 1713, term: '[Lobo Micro]' },
    { value: '', selected: false, termID: 1714, term: '[Segmento Micro]' },
    { value: '', selected: false, termID: 1715, term: '[Lobo predominante Micro]' },
    { value: '', selected: false, termID: 1721, term: '[Tipo Bron]' },
    { value: '', selected: false, termID: 1722, term: '[Lobo Bron]' },
    { value: '', selected: false, termID: 4006, term: '[Lobo Cisto]' },
    { value: '', selected: false, termID: 4007, term: '[Segmento Cisto]' },
    { value: '', selected: false, termID: 4008, term: '[Medida Cisto]' },
    { value: '', selected: false, termID: 4009, term: '[Lobo Espar]' },
    { value: '', selected: false, termID: 4010, term: '[Medida Espar]' },
    { value: '', selected: false, termID: 4011, term: '[Lobo Sub]' },
    { value: '', selected: false, termID: 4012, term: '[Medida Sub]' },
    { value: '', selected: false, termID: 4026, term: '[Medida DimNcNc]' }
  ];

  genericExamesComparativosTorax: TermosGenericos[] = [
    { value: '', selected: false, termID: 1934, term: '[Opacidades vidro fosco]' },
    { value: '', selected: false, termID: 1936, term: '[Consolidacoes]' },
    { value: '', selected: false, termID: 1937, term: '[Espessamento septal]' },
    { value: '', selected: false, termID: 1935, term: '[Lobo]' },
    { value: '', selected: false, termID: 1938, term: '[Extensao]' },
    { value: '', selected: false, termID: 1939, term: '[Porcentagem]' },
    { value: '', selected: false, termID: 1940, term: '[Data]' },
    { value: '', selected: false, termID: 1945, term: '[Alteracao]' }
  ];

  genericArtefatosLimitacoes: TermosGenericos[] = [
    { value: '', selected: false, termID: 4016, term: '[Limitacao]' },
    { value: '', selected: false, termID: 4034, term: '[Limitacao Str]' }
  ];

  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  dinamicLesions: TermosDinamicos[] = [];
  dinamicLesion: any;
  lesionRow = 1;
  medicaoLinfo: string = '';
  isAusentOpacidade: boolean;
  CovidRef: boolean;
  cabecalhoTexto = '';
  achadosAdTexto = '';
  rodapeTexto = '';

  cabecalho = false;
  metodo = true;
  indicacao = false;
  tuboCateteres = false;
  parenquimaPulmonar = false;
  espacoPleural = false;
  traqueiaBronquios = false;
  estruturasVasculares = false;
  coracao = false;
  linfonodo = false;
  esofago = false;
  paredeToracica = false;
  arcabouco = false;
  trasicaAbdominal = false;
  artefatosLimitacoes = false;
  exameComparativoTorax = false;
  achadosTexto = false;
  rodape = false;

  medicalReportTypeID = 33;
  metodoID = 140;
  indicacaoID = 134;
  tuboCateteresID = 130;
  parenquimaPulmonarID = 137;
  espacoPleuralID = 132;
  traqueiaBronquiosID = 139;
  estruturasVascularesID = 133;
  coracaoID = 129;
  linfonodoID = 135;
  esofagoID = 131;
  paredeToracicaID = 136;
  arcaboucoID = 128;
  trasicaAbdominalID = 138;
  artefatosLimID = 254;
  exameComparativoToraxID = 163;
  public switchFormReport = true;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  unidadeMedida: string;
  infoLogotipo:string|any= '';
  editLaudoMode: boolean = false;

  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _copyPaste: CopyPaste,
    private _serviceMediocalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    public dialog: MatDialog,
    private _configClinica:ConfigClinicaService,
    public _addText: AddText,
    private _config: ConfigLaudoService,
    private dataService:DataTermService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("torax-rsnaAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Método'
    this.subtituloBloco = 'Método'
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });

    let configClinicaRule = JSON.parse(localStorage.getItem('configClinicaRule'));
    if(configClinicaRule && configClinicaRule.active){
      this._configClinica.handleSelectUserClinic();
      this._configClinica.maskClinica.subscribe(res=>{
        this.infoLogotipo=res;
        this.AdicionaTexto(this.newEditor);
      })
    };

    const listBlocks = [
      this.metodoID,
      this.indicacaoID,
      this.tuboCateteresID,
      this.parenquimaPulmonarID,
      this.espacoPleuralID,
      this.traqueiaBronquiosID,
      this.estruturasVascularesID,
      this.coracaoID,
      this.linfonodoID,
      this.esofagoID,
      this.paredeToracicaID,
      this.arcaboucoID,
      this.trasicaAbdominalID,
      this.artefatosLimID,
      this.exameComparativoToraxID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "torax-rsnaMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "toraxCovidRsna");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'tc-torax-rsna');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'tc-torax-rsna');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
      });
      dialogRef.afterClosed().subscribe(result =>{
        if(result){
          this.resetaLaudo();
        }
      });
    });
  }

  public resetaLaudo(){
    this._pouchService.getAll("torax-rsnaAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.achadosAdTexto = "";
    this.showForm = false;
    this.isAusentOpacidade = false;
    this.rodapeTexto = "";

    setTimeout(() => {
      this.showForm = true;
      this.AdicionaTexto(this.newEditor);
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: this.indicacaoID,
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      id: this.metodoID,
      name: 'metodo',
      titulo: 'Método',
      selected: true
    },
    {
      titulo: 'Artefatos/Limitações',
      id: this.artefatosLimID,
      name: 'artefatos-limitacoes',
      selected: false
    },
    {
      titulo: 'Dispositivos, tubos e cateteres',
      id: this.tuboCateteresID,
      name: 'tubos-cateteres',
      selected: false,
    },
    {
      titulo: 'Parênquima pulmonar',
      id: this.parenquimaPulmonarID,
      name: 'parenquima-pulmonar',
      selected: false
    },
    {
      titulo: 'Espaço pleural',
      id: this.espacoPleuralID,
      name: 'espaço-pleural',
      selected: false
    },
    {
      titulo: 'Traquéia e brônquios',
      id: this.traqueiaBronquiosID,
      name: 'traqueia-bronquios',
      selected: false
    },
    {
      titulo: 'Estruturas vasculares',
      id: this.estruturasVascularesID,
      name: 'estruturas-vasculares',
      selected: false
    },
    {
      titulo: 'Coração',
      id: this.coracaoID,
      name: 'coracao',
      selected: false
    },
    {
      titulo: 'Linfonodomegalias',
      id: this.linfonodoID,
      name: 'linfonodomegalias',
      selected: false
    },
    {
      titulo: 'Esôfago',
      id: this.esofagoID,
      name: 'esofago',
      selected: false
    },
    {
      titulo: 'Parede torácica',
      id: this.paredeToracicaID,
      name: 'parede-toracica',
      selected: false
    },
    {
      titulo: 'Arcabouço ósseo',
      id: this.arcaboucoID,
      name: 'arcabouco',
      selected: false
    },
    {
      titulo: 'Exame Comparativo',
      id: this.exameComparativoToraxID,
      name: 'exame-comparativo',
      selected: false
    },
    {
      titulo: 'Transição abdominal',
      id: this.trasicaAbdominalID,
      name: 'transicao-abdominal',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === this.indicacaoID);
    this.metodo = (itemSelecionado.id === this.metodoID);
    this.tuboCateteres = (itemSelecionado.id === this.tuboCateteresID);
    this.parenquimaPulmonar = (itemSelecionado.id === this.parenquimaPulmonarID);
    this.espacoPleural = (itemSelecionado.id === this.espacoPleuralID);
    this.traqueiaBronquios = (itemSelecionado.id === this.traqueiaBronquiosID);
    this.estruturasVasculares = (itemSelecionado.id === this.estruturasVascularesID);
    this.coracao = (itemSelecionado.id === this.coracaoID);
    this.linfonodo = (itemSelecionado.id === this.linfonodoID);
    this.esofago = (itemSelecionado.id === this.esofagoID);
    this.paredeToracica = (itemSelecionado.id === this.paredeToracicaID);
    this.arcabouco = (itemSelecionado.id === this.arcaboucoID);
    this.trasicaAbdominal = (itemSelecionado.id === this.trasicaAbdominalID);
    this.artefatosLimitacoes = (itemSelecionado.id === this.artefatosLimID);
    this.exameComparativoTorax = (itemSelecionado.id === this.exameComparativoToraxID);
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.dinamicLesions = [];
    this.lesionRow = 1;
    this.cenarioCorrente = 0;

    this.genericIndicacao = [
      { value: '', selected: false, termID: 1554, term: '[Outro]' }
    ];

    this.genericCoracao = [
      { value: '', selected: false, termID: 1472, term: '[Dimensoes]' },
      { value: '', selected: false, termID: 1473, term: '[VD]' },
      { value: '', selected: false, termID: 1474, term: '[VE]' },
      { value: '', selected: false, termID: 1476, term: '[Grau]' },
      { value: '', selected: false, termID: 1477, term: '[Espessura]' }
    ];

    this.genericEsofago = [
      { value: '', selected: false, termID: 1499, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1502, term: '[Grau]' }
    ];

    this.genericArcabouco = [
      { value: '', selected: false, termID: 1462, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1463, term: '[Lateralidade]' },
      { value: '', selected: false, termID: 1467, term: '[Localizacao Consolidada]' },
      { value: '', selected: false, termID: 1468, term: '[Lateralidade Consolidada]' }
    ];

    this.genericTuboCateter = [
      { value: '', selected: false, termID: 1480, term: '[Distancia Carina]' },
      { value: '', selected: false, termID: 1483, term: '[Lado]' },
      { value: '', selected: false, termID: 1484, term: '[Veia]' },
      { value: '', selected: false, termID: 4027, term: '[Extremidade]' },
      { value: '', selected: false, termID: 1486, term: '[Lado Disp.]' },
      { value: '', selected: false, termID: 1488, term: '[Lado Marca.]' },
      { value: '', selected: false, termID: 1489, term: '[Camaras]' },
      { value: '', selected: false, termID: 1492, term: '[Localizacao Dir.]' },
      { value: '', selected: false, termID: 1493, term: '[Espaco Dir.]' },
      { value: '', selected: false, termID: 1495, term: '[Localizacao Esq.]' },
      { value: '', selected: false, termID: 1496, term: '[Espaco Esq.]' }
    ];

    this.genericTraqueia = [
      { value: '', selected: false, termID: 1569, term: '[Lobo]' },
      { value: '', selected: false, termID: 1573, term: '[Localizacao]' }
    ];

    this.genericTransicao = [
      { value: '', selected: false, termID: 1559, term: '[Lado Nefro]' },
      { value: '', selected: false, termID: 1561, term: '[Lado Cisto]' },
      { value: '', selected: false, termID: 1563, term: '[Lado Cistos]' }
    ];

    this.genericEspacaPleural = [
      { value: '', selected: false, termID: 1506, term: '[Grau Dir.]' },
      { value: '', selected: false, termID: 1508, term: '[Grau Esq.]' },
      { value: '', selected: false, termID: 1513, term: '[Grau Loc Dir.]' },
      { value: '', selected: false, termID: 1516, term: '[Grau Loc Esq.]' },
      { value: '', selected: false, termID: 1521, term: '[Grau Emp Dir.]' },
      { value: '', selected: false, termID: 1523, term: '[Grau Emp Esq.]' },
      { value: '', selected: false, termID: 1528, term: '[Grau Pneu Dir.]' },
      { value: '', selected: false, termID: 1530, term: '[Grau Pneu Esq.]' },
      { value: '', selected: false, termID: 1532, term: '[Lateralidade]' }
    ];

    this.genericEstruturasVasc = [
      { value: '', selected: false, termID: 1539, term: '[Valva]' },
      { value: '', selected: false, termID: 1543, term: '[Calibre Ect]' },
      { value: '', selected: false, termID: 1545, term: '[Calibre Aor]' },
      { value: '', selected: false, termID: 1546, term: '[Segmento Aor]' },
      { value: '', selected: false, termID: 1548, term: '[Diametro]' },
      { value: '', selected: false, termID: 1549, term: '[Extensao]' },
      { value: '', selected: false, termID: 1550, term: '[Segmento Ane]' }
    ];

    this.genericMetodo = [
      { value: '', selected: false, termID: 1575, term: '[Tecnica]' },
      { value: '', selected: false, termID: 1578, term: '[Fase]' }
    ];

    this.genericLinfonodo = [
      { value: '', selected: false, termID: 1583, term: '[Cadeias]' },
      { value: '', selected: false, termID: 1587, term: '[Cadeias Proe]' },
      { value: '', selected: false, termID: 1590, term: '[Cadeias linfo]' },
      { value: '', selected: false, termID: 1593, term: '[Medida]' },
      { value: '', selected: false, termID: 1596, term: '[Cadeias linfo]' },
      { value: '', selected: false, termID: 1597, term: '[Maior cadeia]' },
      { value: '', selected: false, termID: 1598, term: '[Medida]' }
    ];

    this.genericParedeTor = [
      { value: '', selected: false, termID: 1602, term: '[Lado]' },
      { value: '', selected: false, termID: 1603, term: '[Medida]' },
      { value: '', selected: false, termID: 1606, term: '[Cadeias]' },
      { value: '', selected: false, termID: 1607, term: '[Medida linfo]' },
      { value: '', selected: false, termID: 4022, term: '[Lobo]' },
      { value: '', selected: false, termID: 4023, term: '[Medida]' }
    ];

    this.genericParenquimaPulm = [
      { value: '', selected: false, termID: 1864, term: '[Lobos]' },
      { value: '', selected: false, termID: 1866, term: '[Achados adicionais]' },
      { value: '', selected: false, termID: 1869, term: '[Lobos Mult]' },
      { value: '', selected: false, termID: 1871, term: '[Achados adicionais Mult]' },
      { value: '', selected: false, termID: 1874, term: '[Localizacao Halo]' },
      { value: '', selected: false, termID: 1876, term: '[Lobo Opac]' },
      { value: '', selected: false, termID: 1877, term: '[Segmento Opac]' },
      { value: '', selected: false, termID: 1878, term: '[Distribuicao Opac]' },
      // { value: '', selected: false, termID: 1879, term: '[Consolidacoes esparsas]' },
      { value: '', selected: false, termID: 1881, term: '[Localizacao consolidacao]' },
      { value: '', selected: false, termID: 1884, term: '[Lobo Loc]' },
      { value: '', selected: false, termID: 1885, term: '[Segmento Loc]' },
      { value: '', selected: false, termID: 1887, term: '[Lobo Ate]' },
      { value: '', selected: false, termID: 1888, term: '[Segmento Ate]' },
      { value: '', selected: false, termID: 1890, term: '[Lobo Micro Covid]' },
      { value: '', selected: false, termID: 1891, term: '[Segmento Micro Covid]' },
      { value: '', selected: false, termID: 1894, term: '[Lobo predominante Covid]' },
      { value: '', selected: false, termID: 1896, term: '[Lado derrame]' },
      { value: '', selected: false, termID: 1897, term: '[Grau derrame]' },
      { value: '', selected: false, termID: 1899, term: '[Extensao Aco]' },
      { value: '', selected: false, termID: 1641, term: '[Lobo Ate]' },
      { value: '', selected: false, termID: 1642, term: '[Segmento Ate]' },
      { value: '', selected: false, termID: 1644, term: '[Tipo Ate]' },
      { value: '', selected: false, termID: 1947, term: '[Lobo Ate Seg]' },
      { value: '', selected: false, termID: 1948, term: '[Lobo Ate Lam]' },
      { value: '', selected: false, termID: 1949, term: '[Lobo Ate Est]' },
      { value: '', selected: false, termID: 1650, term: '[Lobo Inf]' },
      { value: '', selected: false, termID: 1651, term: '[Segmento Inf]' },
      { value: '', selected: false, termID: 1654, term: '[Grau enf]' },
      { value: '', selected: false, termID: 1659, term: '[Tipo enf]' },
      { value: '', selected: false, termID: 1662, term: '[Lobo predominante Int]' },
      { value: '', selected: false, termID: 1671, term: '[Composicao Nod]' },
      { value: '', selected: false, termID: 1672, term: '[Contornos Nod]' },
      { value: '', selected: false, termID: 1673, term: '[Lobo Nod]' },
      { value: '', selected: false, termID: 1674, term: '[Segmento Nod]' },
      { value: '', selected: false, termID: 1675, term: '[Medida Nod]' },
      { value: '', selected: false, termID: 1683, term: '[Composicao Mult]' },
      { value: '', selected: false, termID: 1684, term: '[Contornos Mult]' },
      { value: '', selected: false, termID: 1689, term: '[Contornos Met]' },
      { value: '', selected: false, termID: 1698, term: '[Medida Dim]' },
      { value: '', selected: false, termID: 3832, term: '[Medida Dim Raros Inc]' },
      { value: '', selected: false, termID: 3833, term: '[Medida Dim Raros Res]' },
      { value: '', selected: false, termID: 3834, term: '[Medida Dim Res]' },
      { value: '', selected: false, termID: 1700, term: '[Composicao Mass]' },
      { value: '', selected: false, termID: 1701, term: '[Contornos Mass]' },
      { value: '', selected: false, termID: 1702, term: '[Lobo Mass]' },
      { value: '', selected: false, termID: 1703, term: '[Segmento Mass]' },
      { value: '', selected: false, termID: 1704, term: '[Medida Mass]' },
      { value: '', selected: false, termID: 1713, term: '[Lobo Micro]' },
      { value: '', selected: false, termID: 1714, term: '[Segmento Micro]' },
      { value: '', selected: false, termID: 1715, term: '[Lobo predominante Micro]' },
      { value: '', selected: false, termID: 1721, term: '[Tipo Bron]' },
      { value: '', selected: false, termID: 1722, term: '[Lobo Bron]' },
      { value: '', selected: false, termID: 4006, term: '[Lobo Cisto]' },
      { value: '', selected: false, termID: 4007, term: '[Segmento Cisto]' },
      { value: '', selected: false, termID: 4008, term: '[Medida Cisto]' },
      { value: '', selected: false, termID: 4009, term: '[Lobo Espar]' },
      { value: '', selected: false, termID: 4010, term: '[Medida Espar]' },
      { value: '', selected: false, termID: 4011, term: '[Lobo Sub]' },
      { value: '', selected: false, termID: 4012, term: '[Medida Sub]' },
      { value: '', selected: false, termID: 4026, term: '[Medida DimNcNc]' }
    ];

    this.genericExamesComparativosTorax = [
      { value: '', selected: false, termID: 1934, term: '[Opacidades vidro fosco]' },
      { value: '', selected: false, termID: 1936, term: '[Consolidacoes]' },
      { value: '', selected: false, termID: 1937, term: '[Espessamento septal]' },
      { value: '', selected: false, termID: 1935, term: '[Lobo]' },
      { value: '', selected: false, termID: 1938, term: '[Extensao]' },
      { value: '', selected: false, termID: 1939, term: '[Porcentagem]' },
      { value: '', selected: false, termID: 1940, term: '[Data]' },
      { value: '', selected: false, termID: 1945, term: '[Alteracao]' }
    ];

    this.genericArtefatosLimitacoes = [
      { value: '', selected: false, termID: 4016, term: '[Limitacao]' },
      { value: '', selected: false, termID: 4034, term: '[Limitacao Str]' }
    ];
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  incluirLesoesDinamicas($event) {
    let lesoesValue = '';
    if (Array.isArray($event.elemento.value)) {
      const arrLength = $event.elemento.value.length;
      $event.elemento.value.forEach(function (objArr, index) {
        if (lesoesValue) {
          if (arrLength == index + 1)
            lesoesValue = lesoesValue + ' e ' + objArr;
          else
            lesoesValue = lesoesValue + ', ' + objArr;
        } else {
          lesoesValue = objArr;
        }
      });
    } else {
      lesoesValue = $event.elemento.value;
    }

    if ($event.linha == 1 && ($event.posicao == 0 || $event.posicao == 1)) {
      if ($event.isPrimeiraLesao == true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaLesoes($event.elemento);
      }
    }

    if ($event.elemento.id == 1594 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoLinfo == '' || $event.posicao == 1) {
        this.medicaoLinfo = this._validacoes.formataDecimal(lesoesValue);
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 1594,
          lesionID: 1591,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida]',
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoLinfo = this.medicaoLinfo + ' x ' + this._validacoes.formataDecimal(lesoesValue);
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoLinfo;
            obj.selected = true;
            obj.termID = 1594;
            obj.lesionID = 1591;
            obj.cenario = $event.elemento.cenario;
            obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida]';
          }
        });
      }
    } else if ($event.elemento.id == 1610 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoLinfo == '' || $event.posicao == 1) {
        this.medicaoLinfo = this._validacoes.formataDecimal(lesoesValue);
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 1610,
          lesionID: 1608,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida linfo]',
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoLinfo = this.medicaoLinfo + ' x ' + this._validacoes.formataDecimal(lesoesValue);
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoLinfo;
            obj.selected = true;
            obj.termID = 1610;
            obj.lesionID = 1608;
            obj.cenario = $event.elemento.cenario;
            obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida linfo]';
          }
        });
      }
    } else if ($event.elemento.id == 1681 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoLinfo == '' || $event.posicao == 1) {
        this.medicaoLinfo = this._validacoes.formataDecimal(lesoesValue);
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 1681,
          lesionID: 1676,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida Nod]',
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoLinfo = this.medicaoLinfo + ' x ' + this._validacoes.formataDecimal(lesoesValue);
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoLinfo;
            obj.selected = true;
            obj.termID = 1681;
            obj.lesionID = 1676;
            obj.cenario = $event.elemento.cenario;
            obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida Nod]';
          }
        });
      }
    } else if ($event.elemento.id == 1692 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoLinfo == '' || $event.posicao == 1) {
        this.medicaoLinfo = this._validacoes.formataDecimal(lesoesValue);
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 1692,
          lesionID: 1688,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida Met]',
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoLinfo = this.medicaoLinfo + ' x ' + this._validacoes.formataDecimal(lesoesValue);
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoLinfo;
            obj.selected = true;
            obj.termID = 1692;
            obj.lesionID = 1688;
            obj.cenario = $event.elemento.cenario;
            obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida Met]';
          }
        });
      }
    } else if ($event.elemento.id == 1687 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoLinfo == '' || $event.posicao == 1) {
        this.medicaoLinfo = this._validacoes.formataDecimal(lesoesValue);
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 1687,
          lesionID: 1682,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida Mult]',
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoLinfo = this.medicaoLinfo + ' x ' + this._validacoes.formataDecimal(lesoesValue);
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoLinfo;
            obj.selected = true;
            obj.termID = 1687;
            obj.lesionID = 1682;
            obj.cenario = $event.elemento.cenario;
            obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida Mult]';
          }
        });
      }
    }

    switch ($event.elemento.id) {
      // Linfonodo
      case '1592':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1592,
          lesionID: 1591,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Cadeias linfo]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Parede Toracica
      case '1609':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1609,
          lesionID: 1608,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Cadeias]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Parenquima Pulmanar
      case '1677':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1677,
          lesionID: 1676,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Composicao Nod]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1678':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1678,
          lesionID: 1676,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Contornos Nod]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1679':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1679,
          lesionID: 1676,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lobo Nod]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1680':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1680,
          lesionID: 1676,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento Nod]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1683':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1683,
          lesionID: 1682,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Composicao Mult]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1684':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1684,
          lesionID: 1682,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Contornos Mult]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1685':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1685,
          lesionID: 1682,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lobo Mult]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1686':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1686,
          lesionID: 1682,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento Mult]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1689':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1689,
          lesionID: 1688,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Contornos Met]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1690':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1690,
          lesionID: 1688,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lobo Met]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1691':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1691,
          lesionID: 1688,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento Met]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1692':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1692,
          lesionID: 1688,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida Met]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1462':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1462,
          lesionID: 1,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1463':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1463,
          lesionID: 1,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lateralidade]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1467':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1467,
          lesionID: 2,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao Consolidada]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1468':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1468,
          lesionID: 2,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lateralidade Consolidada]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1483':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1483,
          lesionID: 1482,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lado]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1484':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1484,
          lesionID: 1482,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Veia]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4027':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 4027,
          lesionID: 1482,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Extremidade]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }
    this.lesionRow = $event.linha;
  }

  adicionaSelecaoIndicacao($event) {
    this.genericIndicacao.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoCoracao($event) {
    this.genericCoracao.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoEsofago($event) {
    this.genericEsofago.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoArcabouco($event) {
    this.genericArcabouco.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = $event.elemento.value;
        }
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoTuboCateter($event) {
    this.genericTuboCateter.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoTraqueia($event) {
    this.genericTraqueia.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = $event.elemento.value;/*this._validacoes.formataDecimal($event.elemento.value);*/
        }
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoTransicao($event) {
    this.genericTransicao.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoEspacoPleural($event) {
    this.genericEspacaPleural.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoEstruturasVasc($event) {
    this.genericEstruturasVasc.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoMetodo($event) {
    this.genericMetodo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoLinfonodo($event) {
    this.genericLinfonodo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoParedeTor($event) {
    this.genericParedeTor.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoArtefatosLimitacoes($event) {
    this.genericArtefatosLimitacoes.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }


  adicionaSelecaoParenquima($event) {
    this.genericParenquimaPulm.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoExameComparativo($event) {
    this.genericExamesComparativosTorax.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.indicacao) {
        this.term.blockID = this.indicacaoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.indicacaoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaIndicacao(resultado.text);
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.indicacaoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = "<b>INDICAÇÃO:</b></br>" + obj.value;

              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.indicacaoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.indicacaoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.indicacaoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.coracao) {
        this.term.blockID = this.coracaoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.coracaoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.value = '';
              let result = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                result = result + this.MontaCoracao(resultado.text, contadorTexto);
                contadorTexto++;
              });

              // Logica para adicionar em outro paragrafo
              //! REFATORAR LÓGICAS DE ESTR. VASC, CORACAO E LINFONODO
              //! ****************************************************
              const desc = result.split('<>')
              let objCoracao = this.descricoes.find(x => x.blockID === this.coracaoID && x.textTermID === 0);
              if (objCoracao) {
                if (desc[0] !== '')
                  objCoracao.value = desc[0];
                else
                  objCoracao.value = 'Coração com situação, morfologia, contornos e dimensões normais.';
                objCoracao.textTermID = 0;
              }
              // Logica para adicionar em outro paragrafo
              if (desc[1]) {
                let objCoracao = this.descricoes.find(x => x.blockID === this.coracaoID && x.textTermID === 1);
                if (objCoracao) {
                  objCoracao.value = desc[1];
                  objCoracao.textTermID = 1;
                }
              }
              //! ****************************************************
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.coracaoID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + this.MontaCoracao(resultado.conclusion, contadorTexto);
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.coracaoID);

          // Logica para adicionar em outro paragrafo
          //! REFATORAR LÓGICAS DE ESTR. VASC, CORACAO E LINFONODO
          //! ****************************************************
          const concl = obj.value.split('<>');
          if (concl[0] !== '' && concl[1])
            obj.value = concl[0] + '</br></br>' + concl[1];
          else
            if (concl[1] && concl[1] !== '')
              obj.value = concl[1]
            else
              obj.value = concl[0]
          //! ****************************************************

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.coracaoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          // this.AdicionaTexto();
        });

      } else if (this.esofago) {
        this.term.blockID = this.esofagoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.esofagoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaEsofago(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.esofagoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.esofagoID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaEsofago(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.esofagoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.esofagoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }
          ''

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.arcabouco) {
        this.term.blockID = this.arcaboucoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.arcaboucoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoArcabouco(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.arcaboucoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.arcaboucoID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoArcabouco(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.arcaboucoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.arcaboucoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tuboCateteres) {
        this.term.blockID = this.tuboCateteresID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.tuboCateteresID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoTuboCateter(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.tuboCateteresID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.tuboCateteresID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoTuboCateter(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.tuboCateteresID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tuboCateteresID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.traqueiaBronquios) {
        this.term.blockID = this.traqueiaBronquiosID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.traqueiaBronquiosID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.value = '';
              let result = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                if (resultado.text.split('<>').length > 1)
                  espaco = '';

                result = result + espaco + this.MontaTraqueia(resultado.text, contadorTexto);
                espaco = '</br>';

                contadorTexto++;
              });

              // Logica para adicionar em outro paragrafo
              //! REFATORAR LÓGICAS DE ESTR. VASC, CORACAO E LINFONODO
              //! ****************************************************
              const desc = result.split('<>')
              let objTraq = this.descricoes.find(x => x.blockID === this.traqueiaBronquiosID && x.textTermID === 0);
              if (objTraq) {
                if (desc[1] && desc[1] !== '')
                  objTraq.value = desc[1];
                else
                  objTraq.value = 'Traqueia, carina e brônquios fontes pérvios e com situação, calibre e contornos normais.';
                objTraq.textTermID = 0;
              }
              // Logica para adicionar em outro paragrafo

              if (desc[0]) {
                let objCoracao = this.descricoes.find(x => x.blockID === this.traqueiaBronquiosID && x.textTermID === 1);
                if (objCoracao) {
                  if (desc[0] !== 'Traqueia, carina e brônquios fontes pérvios e com situação, calibre e contornos normais.')
                    objCoracao.value = desc[0];
                  else
                    objCoracao.value = '';
                  objCoracao.textTermID = 1;
                }
              }
              //! ****************************************************
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.traqueiaBronquiosID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              if (resultado.conclusion.split('<>').length > 1)
                espaco = '';

              obj.value = obj.value + espaco + this.MontaTraqueia(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.traqueiaBronquiosID);

          // Logica para adicionar em outro paragrafo
          //! REFATORAR LÓGICAS DE ESTR. VASC, CORACAO E LINFONODO
          //! ****************************************************
          const concl = obj.value.split('<>');
          if (concl[0] !== '' && concl[1])
            obj.value = concl[1] + '</br></br>' + concl[0];
          else
            if (concl[1] && concl[1] !== '')
              obj.value = concl[1]
            else
              obj.value = concl[0]
          //! ****************************************************

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.traqueiaBronquiosID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.trasicaAbdominal) {
        this.term.blockID = this.trasicaAbdominalID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.trasicaAbdominalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaTransicao(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.trasicaAbdominalID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc
            });
          }
        });

      } else if (this.espacoPleural) {
        this.term.blockID = this.espacoPleuralID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.espacoPleuralID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaEspacoPleural(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.espacoPleuralID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.espacoPleuralID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaEspacoPleural(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.espacoPleuralID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.espacoPleuralID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.estruturasVasculares) {
        this.term.blockID = this.estruturasVascularesID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.estruturasVascularesID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.value = '';
              let result = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                result = result + espaco + this.MontaEstruturasVasc(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              // Logica para adicionar em outro paragrafo
              //! REFATORAR LÓGICAS DE ESTR. VASC, CORACAO E LINFONODO
              //! ****************************************************
              if (result !== 'Estruturas vasculares com situação e calibre normais.') {

                let desc = result.split('</br><>')
                let objVasc = this.descricoes.find(x => x.blockID === this.estruturasVascularesID && x.textTermID === 0);

                if (desc[0].split('<>').length > 1) {
                  const descVasc = desc[0].split('<>');
                  if (descVasc[1]) {
                    if (descVasc[1] && desc[1] && desc[2])
                      objVasc.value = descVasc[1] + '</br>' + desc[1] + '</br>' + desc[2];
                    else if (descVasc[1] && desc[1])
                      objVasc.value = descVasc[1] + '</br>' + desc[1];
                    else if (descVasc[1])
                      objVasc.value = descVasc[1];
                    objVasc.textTermID = 0;
                  }
                } else {
                  if (objVasc) {
                    if (desc[1]) {
                      if (desc[1] && desc[2] && desc[3])
                        objVasc.value = desc[1] + '</br>' + desc[2] + '</br>' + desc[3];
                      else if (desc[1] && desc[2])
                        objVasc.value = desc[1] + '</br>' + desc[2];
                      else if (desc[1])
                        objVasc.value = desc[1];
                      objVasc.textTermID = 0;
                    } else {
                      objVasc.value = 'Estruturas vasculares com situação e calibre normais.';
                      objVasc.textTermID = 0;
                    }
                  }
                  // Logica para adicionar em outro paragrafo
                  if (desc[0]) {
                    let objVasc = this.descricoes.find(x => x.blockID === this.estruturasVascularesID && x.textTermID === 1);
                    if (objVasc) {
                      objVasc.value = desc[0];
                      objVasc.textTermID = 1;
                    }
                  }
                }
              } else {
                let objVascEx = this.descricoes.find(x => x.blockID === this.estruturasVascularesID && x.textTermID === 0);
                objVascEx.value = 'Estruturas vasculares com situação e calibre normais.';
                objVascEx.textTermID = 0;

                let objVasc = this.descricoes.find(x => x.blockID === this.estruturasVascularesID && x.textTermID === 1);
                objVasc.value = '';
                objVasc.textTermID = 1;
              }

              //! ****************************************************
              // this.AdicionaTexto();
              desc = obj.value;

            }
          });

          let obj = {
            value: '',
            blockID: this.estruturasVascularesID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaEstruturasVasc(resultado.conclusion, contadorTexto);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
            contadorTexto++;
          });



          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.estruturasVascularesID);

          // Logica para adicionar em outro paragrafo
          //! REFATORAR LÓGICAS DE ESTR. VASC, CORACAO E LINFONODO
          //! ****************************************************
          const concl = obj.value.split('</br><>');
          if (concl[0].split('<>').length > 1) {
            const conclVasc = concl[0].split('<>');

            if (conclVasc[1] && concl[1] && concl[2])
              obj.value = conclVasc[1] + '</br>' + concl[1] + '</br>' + concl[2];
            else if (conclVasc[1] && concl[1])
              obj.value = conclVasc[1] + '</br>' + concl[1];
            else if (conclVasc[1])
              obj.value = conclVasc[1];
          } else {
            if (concl[0] !== '' && concl[1]) {
              if (concl[1] && concl[2] && concl[3])
                obj.value = concl[0] + '</br></br>' + concl[1] + '</br>' + concl[2] + '</br>' + concl[3];
              else if (concl[1] && concl[2])
                obj.value = concl[0] + '</br></br>' + concl[1] + '</br>' + concl[2];
              else if (concl[1])
                obj.value = concl[0] + '</br></br>' + concl[1];
            } else {
              obj.value = concl[0]
            }
          }
          //! ****************************************************

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.estruturasVascularesID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.metodo) {
        this.term.blockID = this.metodoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.metodoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaMetodo(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.metodoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc
            });
          }
        });

      } else if (this.linfonodo) {
        this.term.blockID = this.linfonodoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.linfonodoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              //obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let result = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                result = result + espaco + this.MontaDescricaoLinfonodo(resultado.text, contadorTexto);
                //espaco = '</br>';
                contadorTexto++;
              });

              // Logica para adicionar em outro paragrafo
              //! REFATORAR LÓGICAS DE ESTR. VASC, CORACAO E LINFONODO
              //! ****************************************************
              const desc = result.split('<>');
              let objLinfo = this.descricoes.find(x => x.blockID === this.linfonodoID && x.textTermID === 0);
              if (desc[1] && desc[1] !== '') {
                if (objLinfo) {
                  objLinfo.value = desc[1];
                  objLinfo.textTermID = 0;
                }
              }

              objLinfo = this.descricoes.find(x => x.blockID === this.linfonodoID && x.textTermID === 0);
              if (desc[2] && desc[2] !== '') {
                if (objLinfo) {
                  objLinfo.value = desc[1] + '</br>' + desc[2];
                  objLinfo.textTermID = 0;
                }
              }

              if (objLinfo) {
                let objLinfo = this.descricoes.find(x => x.blockID === this.linfonodoID && x.textTermID === 1);
                if (desc[0]) {
                  objLinfo.value = desc[0];
                } else {
                  objLinfo.value = 'Não se observam linfonodomegalias mediastinais.';
                  objLinfo.textTermID = 1;
                }
              }
              //! ****************************************************
              // this.AdicionaTexto();
            }
            desc = obj.value;
          });

          let obj = {
            value: '',
            blockID: this.linfonodoID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaLinfonodo(resultado.conclusion, contadorTexto);
              //espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.linfonodoID);

          // Logica para adicionar em outro paragrafo
          //! REFATORAR LÓGICAS DE ESTR. VASC, CORACAO E LINFONODO
          //! ****************************************************
          const concl = obj.value.split('<>');
          if (concl[2] && concl[2] !== '')
            obj.value = concl[1] + '</br>' + concl[2] + '</br></br>' + concl[0];
          else if (concl[0] !== '' && concl[1])
            obj.value = concl[1] + '</br></br>' + concl[0];
          else
            if (concl[1] && concl[1] !== '')
              obj.value = concl[1]
            else
              obj.value = concl[0]
          //! ****************************************************

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.linfonodoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.paredeToracica) {
        this.term.blockID = this.paredeToracicaID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.paredeToracicaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoParedeTor(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.paredeToracicaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.paredeToracicaID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.paredeToracicaID;
              obj.value = obj.value + espaco + this.MontaParedeTor(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              contadorTexto++;
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = this.paredeToracicaID;
                  obj.value = '';
                }
              }
            }
          });
          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.paredeToracicaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.paredeToracicaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.parenquimaPulmonar) {
        this.term.blockID = this.parenquimaPulmonarID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.parenquimaPulmonarID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                if (resultado.text) {
                  obj.value = obj.value + espaco + this.MontaDescricaoParenquima(resultado.text, contadorTexto);
                  espaco = '</br>';
                }
                contadorTexto++;
              });

              if (obj.value === '')
                obj.value = 'Parênquima pulmonar com coeficientes de atenuação preservados.'

              let index = this.descricoes.findIndex(x => x.blockID === this.parenquimaPulmonarID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

              if(!this.newEditor) {
                this.AdicionaTexto(this.newEditor);
              } else {
                this.atualizaLinha({
                  blockId: this.term.blockID,
                  descricao: desc,
                  conclusao: conclusao.value,
                  conclusaoNull: conclusaoNull.value
                });
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.parenquimaPulmonarID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          const conclTipica = 'Estão presentes achados de imagem comumente observados na pneumonia por COVID-19. Outras condições como pneumonia por influenza, pneumonia em organização, toxicidade por drogas e doença do tecido conjuntivo podem causar padrão de imagem semelhante.';
          const conclIndeterminada = 'Os achados de imagem podem ser observados em pneumonia por COVID-19, porém são inespecíficos, podendo ocorrer em uma variedade de processos infecciosos e não infecciosos.';
          const conclAtipica = 'Os achados de imagem são atípicos ou relatados raramente para pneumonia por COVID-19. Outros diagnósticos devem ser considerados.';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              if (resultado.conclusion === conclTipica)
                obj.value = resultado.conclusion;
              else if (resultado.conclusion === conclIndeterminada && obj.value !== conclTipica)
                obj.value = resultado.conclusion;
              else if (resultado.conclusion === conclAtipica && obj.value !== conclIndeterminada && obj.value !== conclTipica)
                obj.value = resultado.conclusion;
              else if (resultado.conclusion !== conclTipica && resultado.conclusion !== conclIndeterminada && resultado.conclusion !== conclAtipica)
                obj.value = obj.value + espaco + this.MontaParenquima(resultado.conclusion, contadorTexto);

              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });


          //Logica para adicionar frase de Obs em opacidades ausentes
          const opAusente = this.term.listTermID.find(x => x === 1613)
          if (opAusente)
            this.isAusentOpacidade = true;
          else
            this.isAusentOpacidade = false;

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.parenquimaPulmonarID);
          const covid = this.term.listTermID.find(x => x >= 1863);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.parenquimaPulmonarID) {
                this.CovidRef = false;
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            if (covid) {
              this.CovidRef = true;
              conclusao.value = obj.value;
            } else {
              this.CovidRef = false;
              conclusao.value = 'Não há sinais tomográficos de pneumonia. (Nota: a tomografia pode não apresentar achados nos estágios iniciais de COVID-19.)</br>' + obj.value;
            }

          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.exameComparativoTorax) {
        this.term.blockID = this.exameComparativoToraxID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.exameComparativoToraxID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoExameComparativoTorax(resultado.text);
              });

              const index = this.descricoes.findIndex(x => x.blockID === this.exameComparativoToraxID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

              if(!this.newEditor) {
                this.AdicionaTexto(this.newEditor);
              } else {
                this.atualizaLinha({
                  blockId: this.term.blockID,
                  descricao: desc
                });
              }
            }
          });
        });
      } else if (this.artefatosLimitacoes) {
        this.term.blockID = this.artefatosLimID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.artefatosLimID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoArtefatosLimitacoes(resultado.text);
              });

              const index = this.descricoes.findIndex(x => x.blockID === this.artefatosLimID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

              if(!this.newEditor) {
                this.AdicionaTexto(this.newEditor);
              } else {
                this.atualizaLinha({
                  blockId: this.term.blockID,
                  descricao: desc
                });
              }
            }
          });
        });
      }
    }
  }

  MontaIndicacao(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1554) {
        this.genericIndicacao.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaCoracao(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1472 || +term === 1473 || +term === 1474 || +term === 1476
          || +term === 1477) {
          this.genericCoracao.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1472 || +term === 1473 || +term === 1474 || +term === 1476
          || +term === 1477) {
          this.genericCoracao.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaEsofago(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1499 || +term === 1502) {
          this.genericEsofago.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1499 || +term === 1502) {
          this.genericEsofago.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaArcabouco(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1462 || +term === 1463 || +term === 1467 || +term === 1468) {
          this.genericArcabouco.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1462 || +term === 1463 || +term === 1467 || +term === 1468) {
          this.genericArcabouco.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaConclusaoTuboCateter(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1480 || +term === 1486 || +term === 1488
          || +term === 1489 || +term === 1492 || +term === 1493
          || +term === 1495 || +term === 1496) {
          this.genericTuboCateter.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    else {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1480 || +term === 1483 || +term === 1484 || +term === 1486
          || +term === 1488 || +term === 1489 || +term === 1492 || +term === 1493
          || +term === 1495 || +term === 1496 || +term === 4027) {
          this.genericTuboCateter.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoTuboCateter(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return (din.cenario - 1) == contadorTexto;
    });
    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoTuboCateterDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoTuboCateterDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  TextoTuboCateterDescNormal(texto, contadorTexto) {


    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1480 || +term === 1483 || +term === 1484 || +term === 1486
          || +term === 1488 || +term === 1489 || +term === 1492 || +term === 1493
          || +term === 1495 || +term === 1496 || +term === 4027) {
          this.genericTuboCateter.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1480 || +term === 1483 || +term === 1484 || +term === 1486
          || +term === 1488 || +term === 1489 || +term === 1492 || +term === 1493
          || +term === 1495 || +term === 1496 || +term === 4027) {
          this.genericTuboCateter.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  TextoTuboCateterDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0]+ '';;
    } else {
      textoRetorno = textoSplit[0]+ '</br>';
      textoDinamico = textoSplit[1]+ '</br>' ;
    }

    this.term.listTermID.forEach(term => {
      if (+term === 1483 || +term === 1484 || +term === 4027) {
        this.genericTuboCateter.forEach(generic => {
          if (+generic.termID === +term) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
          }
        });
      }
    });

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID === 1483 || item.termID === 1484 || item.termID == 4027) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1] + '</br>';
          }

          if (item.termID === 1483 || item.termID === 1484 || item.termID == 4027) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }


  MontaTuboCateter(texto, contadorTexto) {


    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1480 || +term === 1483 || +term === 1484 || +term === 1486
          || +term === 1488 || +term === 1489 || +term === 1492 || +term === 1493
          || +term === 1495 || +term === 1496) {
          this.genericTuboCateter.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1480 || +term === 1483 || +term === 1484 || +term === 1486
          || +term === 1488 || +term === 1489 || +term === 1492 || +term === 1493
          || +term === 1495 || +term === 1496) {
          this.genericTuboCateter.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaTraqueia(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1569 || +term === 1573) {
          this.genericTraqueia.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1569 || +term === 1573) {
          this.genericTraqueia.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaTransicao(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1559 || +term === 1561 || +term === 1563) {
          this.genericTransicao.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1559 || +term === 1561 || +term === 1563) {
          this.genericTransicao.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaEspacoPleural(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1506 || +term === 1508 || +term === 1513
          || +term === 1516 || +term === 1521 || +term === 1523
          || +term === 1528 || +term === 1530 || +term === 1532) {
          this.genericEspacaPleural.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1506 || +term === 1508 || +term === 1513
          || +term === 1516 || +term === 1521 || +term === 1523
          || +term === 1528 || +term === 1530 || +term === 1532) {
          this.genericEspacaPleural.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaEstruturasVasc(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1539 || +term === 1543 || +term === 1545
          || +term === 1546 || +term === 1548 || +term === 1549
          || +term === 1550) {
          this.genericEstruturasVasc.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1539 || +term === 1543 || +term === 1545
          || +term === 1546 || +term === 1548 || +term === 1549
          || +term === 1550) {
          this.genericEstruturasVasc.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaMetodo(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1575 || +term === 1578) {
          this.genericMetodo.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1575 || +term === 1578) {
          this.genericMetodo.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoArcabouco(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoArcaboucoDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.MontaArcabouco(texto, contadorTexto);
    }
    return texto;
  }

  TextoArcaboucoDinamica(texto, contadorTexto, lesaoID) {
    let textoRetorno = '';
    let textoDinamico = texto;
    let linha = 1;


    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (+item.termID == 1462 || +item.termID == 1467 || +item.termID == 1463 || +item.termID == 1468) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          if (textoRetorno != '')
            textoRetorno = textoRetorno + '</br>' + textoDinamico;
          else
            textoRetorno = textoRetorno + textoDinamico;

          textoDinamico = texto;

          if (+item.termID == 1462 || +item.termID == 1467 || +item.termID == 1463 || +item.termID == 1468) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    if (textoRetorno != '')
      textoRetorno = textoRetorno + '</br>' + textoDinamico;
    else
      textoRetorno = textoRetorno + textoDinamico;

    texto = textoRetorno;

    return texto;

  }


  MontaDescricaoLinfonodo(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoLinfonodoDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.MontaLinfonodo(texto, contadorTexto);
    }
    return texto;
  }

  TextoLinfonodoDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length > 1) {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (+item.termID == 1592 || +item.termID == 1594) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length > 1) {
            textoDinamico = textoSplit[1];
          }

          if (+item.termID == 1592 || +item.termID == 1594) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    return texto;
  }

  MontaLinfonodo(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1583 || +term === 1587 || +term === 1590 || +term === 1593
          || +term === 1596 || +term === 1597 || +term === 1598) {
          this.genericLinfonodo.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1583 || +term === 1587 || +term === 1590 || +term === 1593
          || +term === 1596 || +term === 1597 || +term === 1598) {
          this.genericLinfonodo.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoParedeTor(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;

      texto = this.TextoParedeTorDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.MontaParedeTor(texto, contadorTexto);
    }
    return texto;
  }

  TextoParedeTorDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length > 1) {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (+item.termID == 1609 || +item.termID == 1610) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length > 1) {
            textoDinamico = textoSplit[1];
          }

          if (+item.termID == 1609 || +item.termID == 1610) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    return texto;
  }

  MontaParedeTor(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1602 || +term === 1603 || +term === 1606 || +term === 1607
          || +term === 4022 || +term === 4023) {
          this.genericParedeTor.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1602 || +term === 1603 || +term === 1606 || +term === 1607
          || +term === 4022 || +term === 4023) {
          this.genericParedeTor.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoParenquima(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoParenquimaDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.MontaParenquima(texto, contadorTexto);
    }
    return texto;
  }

  TextoParenquimaDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length > 1) {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    // Lista term
    this.term.listTermID.forEach(term => {
      if (+term === 1683 || +term === 1684 || +term === 1689) {
        this.genericParenquimaPulm.forEach(generic => {
          if (+generic.termID === +term) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
          }
        });
      }
    });
    // Injuries
    if (this.term.injuries[0].listInjuries.length > 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1683 || +term === 1684 || +term === 1689) {
          this.genericParenquimaPulm.forEach(generic => {
            if (+generic.termID === +term) {
              textoRetorno = textoRetorno.replace(generic.term, generic.value);
            }
          });
        }
      });
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (+item.termID == 1677 || +item.termID == 1678 || +item.termID == 1679 || +item.termID == 1680
            || +item.termID == 1681 || +item.termID == 1683 || +item.termID == 1684 || +item.termID == 1685
            || +item.termID == 1686 || +item.termID == 1687 || +item.termID == 1689 || +item.termID == 1690
            || +item.termID == 1691 || +item.termID == 1692) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length > 1) {
            textoDinamico = textoSplit[1];
          }

          if (+item.termID == 1677 || +item.termID == 1678 || +item.termID == 1679 || +item.termID == 1680
            || +item.termID == 1681 || +item.termID == 1683 || +item.termID == 1684 || +item.termID == 1685
            || +item.termID == 1686 || +item.termID == 1687 || +item.termID == 1689 || +item.termID == 1690
            || +item.termID == 1691 || +item.termID == 1692) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    return texto;
  }

  MontaParenquima(texto, contadorTexto) {

    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1899
          || +term === 1864 || +term === 1866 || +term === 1869 || +term === 1871
          || +term === 1873 || +term === 1874 || +term === 1876 || +term === 1877
          || +term === 1878 || +term === 1881 || +term === 1884
          || +term === 1885 || +term === 1887 || +term === 1888 || +term === 1890
          || +term === 1891 || +term === 1894 || +term === 1896 || +term === 1897
          || +term === 1641 || +term === 1642 || +term === 1644 || +term === 1650
          || +term === 1651 || +term === 1654 || +term === 1659 || +term === 1662
          || +term === 1671 || +term === 1672 || +term === 1673 || +term === 1674
          || +term === 1675 || +term === 1698 || +term === 3832 || +term === 3833
          || +term === 3834 || +term === 1700 || +term === 1701
          || +term === 1602 || +term === 1603 || +term === 1606 || +term === 1607
          || +term === 1702 || +term === 1703 || +term === 1704 || +term === 1713
          || +term === 1714 || +term === 1715 || +term === 1721 || +term === 1722
          || +term === 1620 || +term === 1947 || +term === 1948 || +term === 1949
          || +term === 4006 || +term === 4007 || +term === 4008
          || +term === 4009 || +term === 4010 || +term === 4011 || +term === 4012
          || +term === 4026) {
          this.genericParenquimaPulm.forEach(generic => {

            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1899
          || +term === 1864 || +term === 1866 || +term === 1869 || +term === 1871
          || +term === 1873 || +term === 1874 || +term === 1876 || +term === 1877
          || +term === 1878 || +term === 1881 || +term === 1884
          || +term === 1885 || +term === 1887 || +term === 1888 || +term === 1890
          || +term === 1891 || +term === 1894 || +term === 1896 || +term === 1897
          || +term === 1641 || +term === 1642 || +term === 1644 || +term === 1650
          || +term === 1651 || +term === 1654 || +term === 1659 || +term === 1662
          || +term === 1671 || +term === 1672 || +term === 1673 || +term === 1674
          || +term === 1675 || +term === 1698 || +term === 3832 || +term === 3833
          || +term === 3834 || +term === 1700 || +term === 1701
          || +term === 1602 || +term === 1603 || +term === 1606 || +term === 1607
          || +term === 1702 || +term === 1703 || +term === 1704 || +term === 1713
          || +term === 1714 || +term === 1715 || +term === 1721 || +term === 1722
          || +term === 1620 || +term === 1947 || +term === 1948 || +term === 1949
          || +term === 4006 || +term === 4007 || +term === 4008
          || +term === 4009 || +term === 4010 || +term === 4011 || +term === 4012
          || +term === 4026) {
          this.genericParenquimaPulm.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }
  MontaDescricaoExameComparativoTorax(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1934 || +term === 1935 || +term === 1936 || +term === 1937 ||
        +term === 1938 || +term === 1939 || +term === 1940 || +term === 1945) {
        this.genericExamesComparativosTorax.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoArtefatosLimitacoes(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4016 || +term === 4034 ) {
        this.genericArtefatosLimitacoes.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });


    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-${this.descricoes[0].blockID}`,
        'name': ''
      },
      'data': {
        'text': `<p></p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-${this.descricoes[1].blockID}`,
        'name': ''
      },
      'data': {
        'text': `<p>Técnica: multislice </br>Meio de contraste: sem contraste<br/></p>`,
      }
    });

    if (this.descricoes[0].value) {
      this.htmlContent += maskSplit[1];
      this.htmlContent += '<br/>' + this.descricoes[0].value + '<br/><br/>';
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[3]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });
    this.descricoes.forEach(descricao => {
      if (descricao.blockID !== 138 && descricao.blockID !== 163) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[4]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao) => {
      if (conclusao.value !== '' ) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    //Exame Comparativo
    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-${this.descricoes[20].blockID}`,
        'name': ''
      },
      'data': {
        'text': `<p></p>`,
      }
    });


    // Transição abdominal
    if (this.descricoes[19].value) {
      this.htmlContent += maskSplit[5];
      this.htmlContent += '<br/>' + this.descricoes[19].value + '<br/>';
    } else {
      this.htmlContent += '';
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-${this.descricoes[19].blockID}`,
        'name': ''
      },
      'data': {
        'text': `<p></p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    // if (this.CovidRef)
    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p><i></br></br><b>Referência:</b></br>[1] Simpson S., Kay F.U.,Abbara S., Bhalla S., Chung J.H., Chung M., Henry T.S., Kanne J.P., Kligerman S., Ko J.P., Litt H. (2020). Radiological Society of North America Expert Consensus Statement on Reporting Chest CT Findings Related to COVID-19. Endorsed by the Society of Thoracic Radiology, the American College of Radiology, and RSNA. Radiology: Cardiothoracic Imaging 2020 2:2</i></p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if(this.infoLogotipo !== '' && this.cabecalhoTexto !== ''){
      this.htmlContent = this.infoLogotipo;
      this.htmlContent += this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo !== '' && this.cabecalhoTexto == ''){
      this.htmlContent = this.infoLogotipo;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo == '' && this.cabecalhoTexto !== ''){
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo == '' && this.cabecalhoTexto == ''){
      this.htmlContent = maskSplit[0];
    }

    if (this.descricoes[0].value) {
      this.htmlContent += maskSplit[1];
      this.htmlContent += '<br/>' + this.descricoes[0].value + '<br/><br/>';
    }
    this.htmlContent += maskSplit[2];
    if (this.descricoes[1].value) {
      this.htmlContent += '<br/>' + this.descricoes[1].value + '<br/><br/>';
    } else {
      this.htmlContent += '</br>Técnica: multislice';
      this.htmlContent += '</br>Meio de contraste: sem contraste<br/><br/>';
    }
    this.htmlContent += maskSplit[3];

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value && i > 1 && this.descricoes[i].visivel === true)
        if (this.descricoes[i].blockID !== 138 && this.descricoes[i].blockID !== 163)
          this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
    }
    this.htmlContent += maskSplit[4];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value && this.conclusoes[i].visivel === true)
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
    }

    //Exame Comparativo
    if (this.descricoes[20].value) {
      this.htmlContent += '</br><b>Exame Comparativo</b>';
      this.htmlContent += '<br/>' + this.descricoes[20].value + '<br/>';
    } else {
      this.htmlContent += '';
    }

    // Transição abdominal
    if (this.descricoes[19].value) {
      this.htmlContent += maskSplit[5];
      this.htmlContent += '<br/>' + this.descricoes[19].value + '<br/>';
    } else {
      this.htmlContent += '';
    }

    //if (this.achadosTexto === true) {
      //this.htmlContent += maskSplit[6];
      this.htmlContent += this.achadosAdTexto;
    //}

    // if (this.CovidRef)
    this.htmlContent += '<i></br></br><b>Referência:</b></br>[1] Simpson S., Kay F.U.,Abbara S., Bhalla S., Chung J.H., Chung M., Henry T.S., Kanne J.P., Kligerman S., Ko J.P., Litt H. (2020). Radiological Society of North America Expert Consensus Statement on Reporting Chest CT Findings Related to COVID-19. Endorsed by the Society of Thoracic Radiology, the American College of Radiology, and RSNA. Radiology: Cardiothoracic Imaging 2020 2:2</i>';

    if(this.rodapeTexto != '') {
      this.htmlContent += this.rodapeTexto;
    }
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }

			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = 'Não há sinais tomográficos de pneumonia. (Nota: a tomografia pode não apresentar achados nos estágios iniciais de COVID-19.)';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }


  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMediocalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
