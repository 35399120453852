import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { MedicalReport } from '../models/BO/MedicalReport';
import { MedicalReportMask } from '../models/BO/MedicalReportMask';
import { MedicalReportConfig } from '../models/BO/MedicalReportConfig';
import { User } from '../auth/user';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { ConnectionService } from './connection.service';
import { PouchDBService } from './pouchdb.service';
import { getAnalytics, logEvent } from "firebase/analytics";

@Injectable({
  providedIn: 'root'
})

export class MedicalReportService {

  private laudoObserve$ = new BehaviorSubject<any>({});
  ultimoLaudo : any;

  emitLaudo(laudo: any) {
    this.laudoObserve$.next(laudo);
  }

  getLaudo() {
    return this.laudoObserve$.asObservable();
  }

  constructor(private _http: HttpClient, private _connection: ConnectionService, private _pouch: PouchDBService) { }

  // oldsalvaLaudo(medicalReport: any): Observable<MedicalReport> {
  //   const url = environment.nLaudoApi + 'MedicalReport/SalvaLaudo';
  //   const _medicalReport: MedicalReport = medicalReport;

  //   const obj = {
  //     'Content': _medicalReport.Content,
  //     'ContentHtml': _medicalReport.ContentHtml,
  //     'ReportCode': _medicalReport.ReportCode,
  //     'UserID': _medicalReport.UserID,
  //     'MedicalReportTypeID': _medicalReport.MedicalReportTypeID
  //   };

  //   return this._http.post<MedicalReport>(url, obj);
  // }

  buscaLaudo(userID: string): Observable<Array<MedicalReport>> {
    const url = environment.nLaudoApi + 'MedicalReport/BuscaMedicalReportPoUsuario/';

    return this._http.get<Array<MedicalReport>>(url + userID);
  }

  buscaUltimoLaudo(userID: string): Observable<Array<MedicalReport>> {
    const url = environment.nLaudoApi + 'MedicalReport/BuscaUltimoMedicalReportPorUsuario/';

    return this._http.get<Array<MedicalReport>>(url + userID);
  }

  buscaMascara(medicalReportTypeID: number, companyID: number): Observable<MedicalReportMask> {
    const userId = +localStorage.getItem('userID')
    const url = environment.nLaudoApi + 'MedicalReport/BuscaMedicalReportMascara/';
    const res = this._http.get<MedicalReportMask>(url + medicalReportTypeID + '/' + companyID + '/' + userId);
    res.subscribe((laudo)=>{
      this.criaLaudo(laudo, false).then(() => {
        this.buscaUltimoLaudo(userId.toString()).subscribe((laudoPorUsuario: any)=>{
          this.ultimoLaudo = laudoPorUsuario;
          console.log(this.ultimoLaudo);
        })
      });
    })

    return res
  }

  async criaLaudo(medicalReport: any, isOffline: boolean) {
    if(this._pouch.getAll("LogMedicalReport")) {
      this._pouch.deleteDaabase("LogMedicalReport");
    }

    const url = environment.nLaudoApi + 'MedicalReport/SalvaLaudo';
    const _medicalReportMask: MedicalReportMask = medicalReport;
    const _medicalReport: MedicalReport = medicalReport;
    let obj;
    localStorage.setItem('medicalReportID', '');

    if(isOffline){
      obj = {
        'UserID': +localStorage.getItem('userID'),
        'Content': _medicalReport.Content,
        'ContentHtml': _medicalReport.ContentHtml,
        'ReportCode': _medicalReport.ReportCode,
        'MedicalReportTypeID': _medicalReport.MedicalReportTypeID
      };
    } else {
      obj = {
        'UserID': +localStorage.getItem('userID'),
        'Content': '',
        'ContentHtml': '',
        'ReportCode': '',
        'MedicalReportTypeID': _medicalReportMask.medicalReportTypeID
      };
    }
    return this._http.post<MedicalReport>(url, obj).toPromise()
    .then((medicalReportID)=>{
      localStorage.setItem('medicalReportID', medicalReportID.toString());
    })
    .catch(()=>{
      console.log('erro ao criar laudo');
    });
  }

  salvaLaudo(medicalReport: any): Observable<MedicalReport> {
    const url = environment.nLaudoApi + 'MedicalReport/AtualizaLaudo';
    const _medicalReport: MedicalReport = medicalReport;

    const obj = {
      'ID': _medicalReport.id,
      'Content': _medicalReport.Content,
      'ContentHtml': _medicalReport.ContentHtml,
      'ReportCode': _medicalReport.ReportCode,
      'ReportValue': _medicalReport.ReportValue
    };
    return this._http.post<MedicalReport>(url, obj);
  }

  salvaLaudoIntegracao(integrationObj: any, postbackurl: string): Observable<MedicalReport> {
    const url = postbackurl;

    const obj = {
      'report': integrationObj.report,
      'reportHtml': integrationObj.reportHtml,
      'partnerToken': integrationObj.partnerToken
    };
    return this._http.post<MedicalReport>(url, obj);
  }
}
