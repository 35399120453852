import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { TermResult } from '../../../../models/BO/TermResult';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { Term } from '../../../../models/BO/Term';
import { Validacoes } from '../../../../utils/validacoes';
import { TermosDinamicos } from 'src/app/models/Helper/TermosDinamicos';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { CommonService } from 'src/app/services/common.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { ConfigClinicaService } from 'src/app/services/config-clinica.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

@Component({
  selector: 'app-mao-bilateral',
  templateUrl: './mao-bilateral.component.html',
  styleUrls: ['./mao-bilateral.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
})
export class MaoBilateralComponent implements OnInit {

  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  public Subtitulo: string;
  public subtituloBloco:string;
  public DerrameArticular: Boolean = false;
  public FasciaPalmar: Boolean = false;
  public MusculaturaRegional: Boolean = false;
  public PeleTecido: Boolean = false;
  public SuperficiesOsseas: Boolean = false;
  public TendoesExtensores: Boolean = false;
  public TendoesFlexores: Boolean = false;
  public medicalReportTypeID: number = 60;
  public PeleTecidoID: Number = 149;
  public DerrameArticularID: Number = 150;
  public SuperficiesOsseasID: Number = 151;
  public TendoesFlexoresID: Number = 152;
  public TendoesExtensoresID: Number = 153;
  public MusculaturaRegionalID: Number = 154;
  public FasciaPalmarID: Number = 155;
  public htmlContent: string;
  public htmlContentDireita: string;
  public htmlContentEsquerda: string;
  public maskDireita: string;
  public maskEsquerda: string;
  public term: any = new Term();
  public cabecalhoTexto = '';
  public cabecalho = false;
  public indicacaoTextoDireita: any = '';
  public indicacaoTextoEsquerda: any = '';
  public achadosAdTextoDireita: any = "";
  public achadosAdTextoEsquerda: any = "";
  public rodape = false;
  public rodapeTexto: any = '';
  public indicacao: Boolean = true;
  public achadosTexto: Boolean = false;
  public lateralidade: Boolean = false;
  public LadoD: any = '';
  public LadoE: any = '';
  public termResultsAPI: Array<TermResult>;
  public termConclusionAPI: Array<TermResult>;
  public cenarioCorrente: any = 0;
  public dinamicLesions: TermosDinamicos[] = [];
  public dinamicLesion: any;
  public lesionRow = 1;
  public dinamicconclussion: TermosDinamicos[] = this.dinamicLesions;
  public generoLateralidade: string;
  public showForm: Boolean = true;
  public switchFormReport = true;
  public unidadeMedida: string;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];

  public blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo:'Preencha o campo indicação',
      selected: true
    },
    {
      titulo: 'Pele e tecido subcutâneo',
      id: this.PeleTecidoID,
      name: 'pele-tecido-subcutaneo',
      selected: false,
    },
    {
      titulo: 'Derrame articular',
      id: this.DerrameArticularID,
      name: 'derrame-articular',
      selected: false,
    },
    {
      titulo: 'Superfícies ósseas',
      id: this.SuperficiesOsseasID,
      name: 'superficies-osseas',
      selected: false,
    },
    {
      titulo: 'Tendões dos flexores',
      id: this.TendoesFlexoresID,
      name: 'tendoes-flexores',
      selected: false,
    },
    {
      titulo: 'Tendões extensores',
      id: this.TendoesExtensoresID,
      name: 'tendoes-extensores',
      selected: false,
    },
    {
      titulo: 'Musculatura regional',
      id: this.MusculaturaRegionalID,
      name: 'musculatura-regional',
      selected: false,
    },
    {
      titulo: 'Fáscia palmar',
      id: this.FasciaPalmarID,
      name: 'fascia-palmar',
      selected: false,
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados-adicionais',
      name: 'achados-adicionais',
      subtitulo:'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  listTermDefault=[
    {listTerm:[1731],blockID:149},
    {listTerm:[1734],blockID:150},
    {listTerm:[1739],blockID:151},
    {listTerm:[1743],blockID:152},
    {listTerm:[1746],blockID:153},
    {listTerm:[1749],blockID:154},
    {listTerm:[1750],blockID:155},
    {listTerm:[4656],blockID:322},
  ]

  public descricoesDireita: DescricaoConclusao[] = [
    { value: 'Pele e tecido subcutâneo preservados.', blockID: 149, textTermID: 0, visivel: true },
    { value: 'Articulações locais sem derrame significativo.', blockID: 150, textTermID: 0, visivel: true },
    { value: 'Superfícies ósseas sem alterações significativas ao método.', blockID: 151, textTermID: 0, visivel: true },
    { value: 'Tendões flexores dos dedos com ecogenicidade e espessura preservadas.', blockID: 152, textTermID: 0, visivel: true },
    { value: 'Tendões extensores dos dedos de aspecto anatômico.', blockID: 153, textTermID: 0, visivel: true },
    { value: 'Musculatura regional íntegra.', blockID: 154, textTermID: 0, visivel: true },
    { value: 'Fáscia palmar com espessura e ecogenicidades preservadas.', blockID: 155, textTermID: 0, visivel: true },
  ];

  public descricoesEsquerda: DescricaoConclusao[] = [
    { value: 'Pele e tecido subcutâneo preservados.', blockID: 149, textTermID: 0, visivel: true },
    { value: 'Articulações locais sem derrame significativo.', blockID: 150, textTermID: 0, visivel: true },
    { value: 'Superfícies ósseas sem alterações significativas ao método.', blockID: 151, textTermID: 0, visivel: true },
    { value: 'Tendões flexores dos dedos com ecogenicidade e espessura preservadas.', blockID: 152, textTermID: 0, visivel: true },
    { value: 'Tendões extensores dos dedos de aspecto anatômico.', blockID: 153, textTermID: 0, visivel: true },
    { value: 'Musculatura regional íntegra.', blockID: 154, textTermID: 0, visivel: true },
    { value: 'Fáscia palmar com espessura e ecogenicidades preservadas.', blockID: 155, textTermID: 0, visivel: true },
  ];

  public conclusoesDireita: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 149, textTermID: 0, visivel: true },
    { value: '', blockID: 150, textTermID: 0, visivel: true },
    { value: '', blockID: 151, textTermID: 0, visivel: true },
    { value: '', blockID: 152, textTermID: 0, visivel: true },
    { value: '', blockID: 153, textTermID: 0, visivel: true },
    { value: '', blockID: 154, textTermID: 0, visivel: true },
    { value: '', blockID: 155, textTermID: 0, visivel: true },
  ];

  public conclusoesEsquerda: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 149, textTermID: 0, visivel: true },
    { value: '', blockID: 150, textTermID: 0, visivel: true },
    { value: '', blockID: 151, textTermID: 0, visivel: true },
    { value: '', blockID: 152, textTermID: 0, visivel: true },
    { value: '', blockID: 153, textTermID: 0, visivel: true },
    { value: '', blockID: 154, textTermID: 0, visivel: true },
    { value: '', blockID: 155, textTermID: 0, visivel: true },
  ];

  public genecicPeleTecidoDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1733, term: '[Localizacao]' },
  ];

  public genecicPeleTecidoEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1733, term: '[Localizacao]' },
  ];

  public genericDerrameArticularDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1736, term: '[Articulacao]' },
    { value: '', selected: false, termID: 1737, term: '[Dedos]' },
    { value: '', selected: false, termID: 1738, term: '[Volume]' },
  ];

  public genericDerrameArticularEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1736, term: '[Articulacao]' },
    { value: '', selected: false, termID: 1737, term: '[Dedos]' },
    { value: '', selected: false, termID: 1738, term: '[Volume]' },
  ];

  public genericSuperficiesOsseasDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1741, term: '[Articulacao]' },
    { value: '', selected: false, termID: 1742, term: '[Dedos]' },
  ];

  public genericSuperficiesOsseasEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1741, term: '[Articulacao]' },
    { value: '', selected: false, termID: 1742, term: '[Dedos]' },
  ];

  public genericTendoesFlexoresDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1745, term: '[Dedos]' },
  ];

  public genericTendoesFlexoresEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1745, term: '[Dedos]' },
  ];

  public genericTendoesExtensoresDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1748, term: '[Dedos]' },
  ];

  public genericTendoesExtensoresEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1748, term: '[Dedos]' },
  ];

  public genericFasciaPalmarDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1754, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1755, term: '[Medida]' },
  ];

  public genericFasciaPalmarEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1754, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1755, term: '[Medida]' },
  ];
  editLaudoMode: boolean = false;



  infoLogotipo:string|any= '';


  constructor(private readonly _selecteditememiter: SelectedItemEmiter,
    private readonly _laudoservice: LaudosService,
    private readonly _copyPaste: CopyPaste,
    private readonly _toastr: ToastrManager,
    private readonly _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    public readonly dialog: MatDialog,
    private _configClinica:ConfigClinicaService,
    public _addText: AddText,
    private dataService:DataTermService,
    private _config: ConfigLaudoService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("maoAnalise", this.descricoesDireita, this.conclusoesDireita);
    this._pouchService.loadDatabase("maoAnalise", this.descricoesEsquerda, this.conclusoesEsquerda);
    this.resetaDialog();
    this.generoLateralidade = 'feminino';
    this.Subtitulo = 'Indicação';
    this.subtituloBloco ='Preencha o campo indicação'
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    let configClinicaRule = JSON.parse(localStorage.getItem('configClinicaRule'));
    if(configClinicaRule && configClinicaRule.active){
      this._configClinica.handleSelectUserClinic();
      this._configClinica.maskClinica.subscribe(res=>{
        this.infoLogotipo=res;
        this.AdicionaTextoDireita();
        this.AdicionaTextoEsquerda();
      })
    };

    const listBlocks = [
      this.PeleTecidoID,
      this.DerrameArticularID,
      this.SuperficiesOsseasID,
      this.TendoesFlexoresID,
      this.TendoesExtensoresID,
      this.MusculaturaRegionalID,
      this.FasciaPalmarID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "maoBilateralMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.maskDireita = obj.mask.replace('{0}', 'DIREITA');
      this.maskEsquerda = obj.mask.replace('{0}', 'ESQUERDA');
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "mao");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-mao');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-mao');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  }

  public resetaLaudo(){
    this._pouchService.getAll("maoAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoesDireita[i].value = obj[i].doc.value;
            this.descricoesEsquerda[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoesDireita[concCalc].value = obj[i].doc.value;
            this.conclusoesEsquerda[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.LadoD = "";
    this.LadoE = "";
    this.cabecalhoTexto = "";
    this.indicacaoTextoDireita = "";
    this.indicacaoTextoEsquerda = "";
    this.achadosAdTextoDireita = "";
    this.achadosAdTextoEsquerda = "";

    this.rodapeTexto = "";
    this.showForm = false;

    setTimeout(() => {
      this.showForm = true;
      this.AdicionaTexto(this.newEditor);
    }, 500);
  };

  public selecionar(itemSelecionado): any {
    const arrayAtualizado = this._selecteditememiter.Selected(itemSelecionado.name, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco=itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }


    this.cabecalho =  (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados-adicionais');
    this.rodape =  (itemSelecionado.id === 'rodape');
    this.PeleTecido = (itemSelecionado.id === this.PeleTecidoID);
    this.DerrameArticular = (itemSelecionado.id === this.DerrameArticularID);
    this.SuperficiesOsseas = (itemSelecionado.id === this.SuperficiesOsseasID);
    this.TendoesFlexores = (itemSelecionado.id === this.TendoesFlexoresID);
    this.TendoesExtensores = (itemSelecionado.id === this.TendoesExtensoresID);
    this.MusculaturaRegional = (itemSelecionado.id === this.MusculaturaRegionalID);
    this.FasciaPalmar = (itemSelecionado.id === this.FasciaPalmarID);

  }

  public limparTermos(): any {
    this.term.listTermID = new Array<number>();
    this.dinamicLesions = [];
    this.lesionRow = 1;

    this.genecicPeleTecidoDireita = [
      { value: '', selected: false, termID: 1733, term: '[Localizacao]' },
    ];

    this.genecicPeleTecidoEsquerda = [
      { value: '', selected: false, termID: 1733, term: '[Localizacao]' },
    ];

    this.genericDerrameArticularDireita = [
      { value: '', selected: false, termID: 1736, term: '[Articulacao]' },
      { value: '', selected: false, termID: 1737, term: '[Dedos]' },
      { value: '', selected: false, termID: 1738, term: '[Volume]' },
    ];

    this.genericDerrameArticularEsquerda = [
      { value: '', selected: false, termID: 1736, term: '[Articulacao]' },
      { value: '', selected: false, termID: 1737, term: '[Dedos]' },
      { value: '', selected: false, termID: 1738, term: '[Volume]' },
    ];

    this.genericSuperficiesOsseasDireita = [
      { value: '', selected: false, termID: 1741, term: '[Articulacao]' },
      { value: '', selected: false, termID: 1742, term: '[Dedos]' },
    ];

    this.genericSuperficiesOsseasEsquerda = [
      { value: '', selected: false, termID: 1741, term: '[Articulacao]' },
      { value: '', selected: false, termID: 1742, term: '[Dedos]' },
    ];

    this.genericTendoesFlexoresDireita = [
      { value: '', selected: false, termID: 1745, term: '[Dedos]' },
    ];

    this.genericTendoesFlexoresEsquerda = [
      { value: '', selected: false, termID: 1745, term: '[Dedos]' },
    ];

    this.genericTendoesExtensoresDireita = [
      { value: '', selected: false, termID: 1748, term: '[Dedos]' },
    ];

    this.genericTendoesExtensoresEsquerda = [
      { value: '', selected: false, termID: 1748, term: '[Dedos]' },
    ];

    this.genericFasciaPalmarDireita = [
      { value: '', selected: false, termID: 1754, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1755, term: '[Medida]' },
    ];

    this.genericFasciaPalmarEsquerda = [
      { value: '', selected: false, termID: 1754, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1755, term: '[Medida]' },
    ];
  }

  public MontaDescricaoPeleTecidoDireita(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1733) {
        this.genecicPeleTecidoDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoPeleTecidoEsquerda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1733) {
        this.genecicPeleTecidoEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  TextoDerrameArticularDinamica(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1736 || +item.termID === 1737 || +item.termID === 1738) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1736 || +item.termID === 1737 || +item.termID === 1738) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicLesions = this.dinamicLesions.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  MontaDescricaoDerrameArticularDireita(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });
    const RowDinamicConclussion = this.dinamicconclussion.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic !== undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoDerrameArticularDinamica(texto, contadorTexto, lesaoID);
    } else if (RowDinamicConclussion !== undefined && RowDinamicConclussion.length > 0) {
      const lesaoID = RowDinamicConclussion[0].lesionID;
      texto = this.MontaDerrameArticularConclusion(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoDerrameArticularNormalDireita(texto);
    }
    return texto;
  }

  MontaDescricaoDerrameArticularEsquerda(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });
    const RowDinamicConclussion = this.dinamicconclussion.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic !== undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoDerrameArticularDinamica(texto, contadorTexto, lesaoID);
    } else if (RowDinamicConclussion !== undefined && RowDinamicConclussion.length > 0) {
      const lesaoID = RowDinamicConclussion[0].lesionID;
      texto = this.MontaDerrameArticularConclusion(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoDerrameArticularNormalEsquerda(texto);
    }
    return texto;
  }

  public TextoDerrameArticularNormalDireita(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1736 || +term === 1737 || +term === 1738) {
        this.genericDerrameArticularDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public TextoDerrameArticularNormalEsquerda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1736 || +term === 1737 || +term === 1738) {
        this.genericDerrameArticularEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDerrameArticularConclusion(texto, contadorTexto, lesaoID): any {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicconclussion.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1736 || +item.termID === 1737 || +item.termID === 1738) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1736 || +item.termID === 1737 || +item.termID === 1738) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicconclussion = this.dinamicconclussion.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  TextoSuperficiesOsseasDinamica(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1741 || +item.termID === 1742) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1741 || +item.termID === 1742) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicLesions = this.dinamicLesions.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  MontaDescricaoSuperficiesOsseasDireita(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });
    const RowDinamicConclussion = this.dinamicconclussion.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic !== undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoSuperficiesOsseasDinamica(texto, contadorTexto, lesaoID);
    } else if (RowDinamicConclussion !== undefined && RowDinamicConclussion.length > 0) {
      const lesaoID = RowDinamicConclussion[0].lesionID;
      texto = this.MontaSuperficiesOsseasConclusion(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoSuperficiesOsseasNormalDireita(texto);
    }
    return texto;
  }

  MontaDescricaoSuperficiesOsseasEsquerda(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });
    const RowDinamicConclussion = this.dinamicconclussion.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic !== undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoSuperficiesOsseasDinamica(texto, contadorTexto, lesaoID);
    } else if (RowDinamicConclussion !== undefined && RowDinamicConclussion.length > 0) {
      const lesaoID = RowDinamicConclussion[0].lesionID;
      texto = this.MontaSuperficiesOsseasConclusion(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoSuperficiesOsseasNormalEsquerda(texto);
    }
    return texto;
  }

  public TextoSuperficiesOsseasNormalDireita(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1741 || +term === 1742) {
        this.genericSuperficiesOsseasDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public TextoSuperficiesOsseasNormalEsquerda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1741 || +term === 1742) {
        this.genericSuperficiesOsseasEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaSuperficiesOsseasConclusion(texto, contadorTexto, lesaoID): any {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicconclussion.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1741 || +item.termID === 1742) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1741 || +item.termID === 1742) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicconclussion = this.dinamicconclussion.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  TextoTendoesFlexoresDinamica(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1745) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1745) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicLesions = this.dinamicLesions.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  MontaDescricaoTendoesFlexoresDireita(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    const RowDinamicConclussion = this.dinamicconclussion.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic !== undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoTendoesFlexoresDinamica(texto, contadorTexto, lesaoID);
    } else if (RowDinamicConclussion !== undefined && RowDinamicConclussion.length > 0) {
      const lesaoID = RowDinamicConclussion[0].lesionID;
      texto = this.MontaTendoesFlexoresConclusion(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoTendoesFlexoresNormalDireita(texto);
    }
    return texto;
  }

  MontaDescricaoTendoesFlexoresEsquerda(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    const RowDinamicConclussion = this.dinamicconclussion.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic !== undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoTendoesFlexoresDinamica(texto, contadorTexto, lesaoID);
    } else if (RowDinamicConclussion !== undefined && RowDinamicConclussion.length > 0) {
      const lesaoID = RowDinamicConclussion[0].lesionID;
      texto = this.MontaTendoesFlexoresConclusion(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoTendoesFlexoresNormalEsquerda(texto);
    }
    return texto;
  }

  public TextoTendoesFlexoresNormalDireita(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1745) {
        this.genericTendoesFlexoresDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public TextoTendoesFlexoresNormalEsquerda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1745) {
        this.genericTendoesFlexoresEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaTendoesFlexoresConclusion(texto, contadorTexto, lesaoID): any {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicconclussion.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1745) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1745) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicconclussion = this.dinamicconclussion.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }


  TextoTendoesExtensoresDinamica(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1748) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1748) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicLesions = this.dinamicLesions.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  MontaDescricaoTendoesExtensoresDireita(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    const RowDinamicConclussion = this.dinamicconclussion.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic !== undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoTendoesExtensoresDinamica(texto, contadorTexto, lesaoID);
    } else if (RowDinamicConclussion !== undefined && RowDinamicConclussion.length > 0) {
      const lesaoID = RowDinamicConclussion[0].lesionID;
      texto = this.MontaTendoesExtensoresConclusion(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoTendoesExtensoresNormalDireita(texto);
    }
    return texto;
  }

  MontaDescricaoTendoesExtensoresEsquerda(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    const RowDinamicConclussion = this.dinamicconclussion.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic !== undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoTendoesExtensoresDinamica(texto, contadorTexto, lesaoID);
    } else if (RowDinamicConclussion !== undefined && RowDinamicConclussion.length > 0) {
      const lesaoID = RowDinamicConclussion[0].lesionID;
      texto = this.MontaTendoesExtensoresConclusion(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoTendoesExtensoresNormalEsquerda(texto);
    }
    return texto;
  }

  public TextoTendoesExtensoresNormalDireita(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1748) {
        this.genericTendoesExtensoresDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public TextoTendoesExtensoresNormalEsquerda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1748) {
        this.genericTendoesExtensoresEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaTendoesExtensoresConclusion(texto, contadorTexto, lesaoID): any {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicconclussion.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1748) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1748) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicconclussion = this.dinamicconclussion.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  TextoFasciaPalmarDinamica(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '</br>';
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1754 || +item.termID === 1755) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1754 || +item.termID === 1755) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicLesions = this.dinamicLesions.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  MontaDescricaoFasciaPalmarDireita(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic !== undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoFasciaPalmarDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoFasciaPalmarNormalDireita(texto);
    }
    return texto;
  }

  MontaDescricaoFasciaPalmarEsquerda(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic !== undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoFasciaPalmarDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoFasciaPalmarNormalEsquerda(texto);
    }
    return texto;
  }

  public TextoFasciaPalmarNormalDireita(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1754 || +term === 1755) {
        this.genericFasciaPalmarDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public TextoFasciaPalmarNormalEsquerda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1754 || +term === 1755) {
        this.genericFasciaPalmarEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoFasciaPalmarDireita(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1754 || +term === 1755) {
        this.genericFasciaPalmarDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoFasciaPalmarEsquerda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1754 || +term === 1755) {
        this.genericFasciaPalmarEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoPeleTecidoDireita(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1733) {
        this.genecicPeleTecidoDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoPeleTecidoEsquerda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1733) {
        this.genecicPeleTecidoEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public adicionaSelecaoPeleTecidoDireita($event): any {
    this.genecicPeleTecidoDireita.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoPeleTecidoEsquerda($event): any {
    this.genecicPeleTecidoEsquerda.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoDerrameArticularDireita($event): any {
    this.genericDerrameArticularDireita.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoDerrameArticularEsquerda($event): any {
    this.genericDerrameArticularEsquerda.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoSuperficiesOsseasDireita($event): any {
    this.genericSuperficiesOsseasDireita.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoSuperficiesOsseasEsquerda($event): any {
    this.genericSuperficiesOsseasEsquerda.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoTendoesFlexoresDireita($event): any {
    this.genericTendoesFlexoresDireita.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoTendoesFlexoresEsquerda($event): any {
    this.genericTendoesFlexoresEsquerda.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoTendoesExtensoresDireita($event): any {
    this.genericTendoesExtensoresDireita.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoTendoesExtensoresEsquerda($event): any {
    this.genericTendoesExtensoresEsquerda.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoFasciaPalmarDireita($event): any {
    this.genericFasciaPalmarDireita.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoFasciaPalmarEsquerda($event): any {
    this.genericFasciaPalmarEsquerda.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public consultarAPIDireita(): any {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.PeleTecido) {
        this.term.blockID = this.PeleTecidoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.PeleTecidoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoPeleTecidoDireita(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.PeleTecidoID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.PeleTecidoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoPeleTecidoDireita(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.PeleTecidoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.PeleTecidoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.PeleTecidoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }

        });

      } else if (this.DerrameArticular) {
        this.term.blockID = this.DerrameArticularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.DerrameArticularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoDerrameArticularDireita(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.DerrameArticularID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.DerrameArticularID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoDerrameArticularDireita(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.DerrameArticularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.DerrameArticularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.DerrameArticularID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }

        });

      } else if (this.SuperficiesOsseas) {
        this.term.blockID = this.SuperficiesOsseasID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.SuperficiesOsseasID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoSuperficiesOsseasDireita(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.SuperficiesOsseasID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.SuperficiesOsseasID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoSuperficiesOsseasDireita(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.SuperficiesOsseasID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.SuperficiesOsseasID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.SuperficiesOsseasID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.TendoesFlexores) {
        this.term.blockID = this.TendoesFlexoresID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.TendoesFlexoresID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoTendoesFlexoresDireita(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.TendoesFlexoresID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.TendoesFlexoresID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoTendoesFlexoresDireita(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.TendoesFlexoresID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.TendoesFlexoresID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.TendoesFlexoresID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.TendoesExtensores) {
        this.term.blockID = this.TendoesExtensoresID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.TendoesExtensoresID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoTendoesExtensoresDireita(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.TendoesExtensoresID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.TendoesExtensoresID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoTendoesExtensoresDireita(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.TendoesExtensoresID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.TendoesExtensoresID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.TendoesExtensoresID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.MusculaturaRegional) {
        this.term.blockID = this.MusculaturaRegionalID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.MusculaturaRegionalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });
              let index = this.descricoesDireita.findIndex(x => x.blockID === this.MusculaturaRegionalID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.MusculaturaRegionalID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.MusculaturaRegionalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.MusculaturaRegionalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.MusculaturaRegionalID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }

        });

      } else if (this.FasciaPalmar) {
        this.term.blockID = this.FasciaPalmarID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.FasciaPalmarID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoFasciaPalmarDireita(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.FasciaPalmarID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.FasciaPalmarID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoFasciaPalmarDireita(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.FasciaPalmarID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.FasciaPalmarID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.FasciaPalmarID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  public consultarAPIEsquerda(): any {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.PeleTecido) {
        this.term.blockID = this.PeleTecidoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.PeleTecidoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoPeleTecidoEsquerda(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.PeleTecidoID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.PeleTecidoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoPeleTecidoEsquerda(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.PeleTecidoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.PeleTecidoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.PeleTecidoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.DerrameArticular) {
        this.term.blockID = this.DerrameArticularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.DerrameArticularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoDerrameArticularEsquerda(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.DerrameArticularID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.DerrameArticularID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoDerrameArticularEsquerda(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.DerrameArticularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.DerrameArticularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.DerrameArticularID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.SuperficiesOsseas) {
        this.term.blockID = this.SuperficiesOsseasID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.SuperficiesOsseasID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoSuperficiesOsseasEsquerda(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.SuperficiesOsseasID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.SuperficiesOsseasID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoSuperficiesOsseasEsquerda(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.SuperficiesOsseasID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.SuperficiesOsseasID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.SuperficiesOsseasID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.TendoesFlexores) {
        this.term.blockID = this.TendoesFlexoresID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.TendoesFlexoresID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoTendoesFlexoresEsquerda(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.TendoesFlexoresID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.TendoesFlexoresID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoTendoesFlexoresEsquerda(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.TendoesFlexoresID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.TendoesFlexoresID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.TendoesFlexoresID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.TendoesExtensores) {
        this.term.blockID = this.TendoesExtensoresID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.TendoesExtensoresID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoTendoesExtensoresEsquerda(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.TendoesExtensoresID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.TendoesExtensoresID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoTendoesExtensoresEsquerda(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.TendoesExtensoresID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.TendoesExtensoresID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.TendoesExtensoresID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.MusculaturaRegional) {
        this.term.blockID = this.MusculaturaRegionalID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.MusculaturaRegionalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });
              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.MusculaturaRegionalID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.MusculaturaRegionalID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.MusculaturaRegionalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.MusculaturaRegionalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.MusculaturaRegionalID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.FasciaPalmar) {
        this.term.blockID = this.FasciaPalmarID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.FasciaPalmarID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoFasciaPalmarEsquerda(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.FasciaPalmarID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.FasciaPalmarID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoFasciaPalmarEsquerda(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.FasciaPalmarID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.FasciaPalmarID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.FasciaPalmarID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  public AdicionaTextoDireita(): any {
    let i;
    let maskSplit = this.maskDireita.split('|');
    let cabecalho = this.cabecalhoTexto;
    let rodape = this.rodapeTexto;
    let logo = this.infoLogotipo;

    this.htmlContentDireita = maskSplit[0];
    this.htmlContentDireita += this.LadoD.toUpperCase();
    this.htmlContentDireita += maskSplit[1];
    this.htmlContentDireita += this.indicacaoTextoDireita;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContentDireita += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContentDireita += maskSplit[2];

    for (i = 0; i < this.descricoesDireita.length; i++) {
      var newValue = this.descricoesDireita[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoesDireita[i].value = newValue2;
    }
    for (i = 0; i < this.descricoesDireita.length; i++) {
      if (this.descricoesDireita[i].value) {
        this.htmlContentDireita += '<br/>' + this.descricoesDireita[i].value + '<br/>';
      }
    }
    this.htmlContentDireita += maskSplit[3];
    for (i = 0; i < this.conclusoesDireita.length; i++) {
      if (this.conclusoesDireita[i].value) {
        this.htmlContentDireita += '<br/>' + this.conclusoesDireita[i].value + '<br/>';
      }
    }
    this.htmlContentDireita += this.achadosAdTextoDireita;

    if (this.cabecalhoTexto !== '' && this.rodapeTexto === ''){
      if(this.infoLogotipo)
      this.htmlContent = logo + cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
      else
      this.htmlContent =  cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    }

    if (this.cabecalhoTexto === '' && this.rodapeTexto !== ''){
      if(this.infoLogotipo)
      this.htmlContent = logo + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
      else
      this.htmlContent =  this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    }

    if (this.cabecalhoTexto !== '' && this.rodapeTexto !== ''){
      if(this.infoLogotipo)
        this.htmlContent =  logo + cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
      else
        this.htmlContent =  cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    }

    if (this.cabecalhoTexto === '' && this.rodapeTexto === ''){
      if(this.infoLogotipo)
        this.htmlContent = logo + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
      else
        this.htmlContent = this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    }
  }

  public AdicionaTextoEsquerda(): any {
    let i;
    let maskSplit = this.maskEsquerda.split('|');
    let cabecalho = this.cabecalhoTexto;
    let rodape = this.rodapeTexto;
    let logo = this.infoLogotipo;

    this.htmlContentEsquerda = maskSplit[0];
    this.htmlContentEsquerda += this.LadoE.toUpperCase();
    this.htmlContentEsquerda += maskSplit[1];
    this.htmlContentEsquerda += this.indicacaoTextoEsquerda;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContentEsquerda += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContentEsquerda += maskSplit[2];

    for (i = 0; i < this.descricoesEsquerda.length; i++) {
      var newValue = this.descricoesEsquerda[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoesEsquerda[i].value = newValue2;
    }
    for (i = 0; i < this.descricoesEsquerda.length; i++) {
      if (this.descricoesEsquerda[i].value) {
        this.htmlContentEsquerda += '<br/>' + this.descricoesEsquerda[i].value + '<br/>';
      }
    }
    this.htmlContentEsquerda += maskSplit[3];
    for (i = 0; i < this.conclusoesEsquerda.length; i++) {
      if (this.conclusoesEsquerda[i].value) {
        this.htmlContentEsquerda += '<br/>' + this.conclusoesEsquerda[i].value + '<br/>';
      }
    }
    this.htmlContentEsquerda += this.achadosAdTextoEsquerda;

    if (this.cabecalhoTexto !== '' && this.rodapeTexto === ''){
      if(this.infoLogotipo)
      this.htmlContent = logo + cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
      else
      this.htmlContent =  cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    }

    if (this.cabecalhoTexto === '' && this.rodapeTexto !== ''){
      if(this.infoLogotipo)
      this.htmlContent = logo + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
      else
      this.htmlContent =  this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    }

    if (this.cabecalhoTexto !== '' && this.rodapeTexto !== ''){
      if(this.infoLogotipo)
        this.htmlContent =  logo + cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
      else
        this.htmlContent =  cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    }

    if (this.cabecalhoTexto === '' && this.rodapeTexto === ''){
      if(this.infoLogotipo)
        this.htmlContent = logo + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
      else
        this.htmlContent = this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    }
  }

  public enviaTermosDireita($event): any {
    if ($event === 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  public enviaTermosEsquerda($event): any {
    if ($event === 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';
    this.AdicionaTexto(this.newEditor);
  }

  enviaIndicacaoDireita(texto) {
    if (texto)
      this.indicacaoTextoDireita = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTextoDireita = '';
      this.AdicionaTexto(this.newEditor);
  }

  enviaIndicacaoEsquerda(texto) {
    if (texto)
      this.indicacaoTextoEsquerda = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTextoEsquerda = '';
      this.AdicionaTexto(this.newEditor);
  }

  enviaAchadosDireita(texto) {
    if (texto)
      this.achadosAdTextoDireita = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTextoDireita = '';

      this.AdicionaTexto(this.newEditor);
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';
      this.AdicionaTexto(this.newEditor);
  }

  enviaAchadosEsquerda(texto) {
    if (texto)
      this.achadosAdTextoEsquerda = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTextoEsquerda = '';

      this.AdicionaTexto(this.newEditor);
  }

  public enviaLadosDireita($event) {
    this.LadoD = $event;
    this.AdicionaTexto(this.newEditor);
  }

  public enviaLadosEsquerda($event) {
    this.LadoE = $event;
    this.AdicionaTexto(this.newEditor);
  }

  public incluirLesoesDinamicas($event) {
    let lesoesValue = $event.elemento.value;

    if ($event.linha === 1 && ($event.posicao === 0 || $event.posicao === 1)) {
      if ($event.isPrimeiraLesao === true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaTermosDireita($event.elemento);
        this.enviaTermosEsquerda($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      // Derrame Articular
      case '1736':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1736,
          lesionID: 1735,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Articulacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1737':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1737,
          lesionID: 1735,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1738':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1738,
          lesionID: 1735,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Volume]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Superfícies ósseas
      case '1741':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1741,
          lesionID: 1740,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Articulacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1742':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1742,
          lesionID: 1740,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Tendões flexores
      case '1745':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1745,
          lesionID: 1744,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1745':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1745,
          lesionID: 1765,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1745':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1745,
          lesionID: 1766,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Tendões extensores
      case '1748':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1748,
          lesionID: 1747,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Fascia Palmar
      case '1754':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1754,
          lesionID: 1753,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1755':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1755,
          lesionID: 1753,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }

    this.dinamicconclussion = this.dinamicLesions;
    this.lesionRow = $event.linha;
  }

  public RetornaConclusaoNull(Texto): any {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  public adicionaTextoOldEditor(){
    this.AdicionaTextoDireita();
    this.AdicionaTextoEsquerda();
  }

  public adicionaTextoNewEditor(){
    let maskSplitD = this.maskDireita.split('|');
    let maskSplitE = this.maskEsquerda.split('|');

    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    //DIREITA
    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplitD[0]+this.LadoD.toUpperCase()+ maskSplitD[1]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTextoDireita}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplitD[2]}</p>`,
      }
    });

    this.descricoesDireita.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}-D`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplitD[3]}</p>`,
      }
    });

    this.conclusoesDireita.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoesDireita[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao-D`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTextoDireita}</p>`,
      }
    });

    //ESQUERDA
    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplitE[0]+this.LadoE.toUpperCase()+ maskSplitE[1]}</p>`,
      }
    });


    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTextoEsquerda}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplitE[2]}</p>`,
      }
    });

    this.descricoesEsquerda.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}-E`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplitE[3]}</p>`,
      }
    });

    this.conclusoesEsquerda.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoesEsquerda[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao-E`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTextoEsquerda}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });
  }


  atualizaLinhaDireita({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoesDireita.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId}-D p`);
        const conclusion = document.querySelector('#e-conclusao-D p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesDireita.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesDireita.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
			} else if(indicacao || achados) {
				if(indicacao === undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados === undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  atualizaLinhaEsquerda({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoesEsquerda.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId}-E p`);
        const conclusion = document.querySelector('#e-conclusao-E p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesEsquerda.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesEsquerda.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
			} else if(indicacao || achados) {
				if(indicacao === undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados === undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }
}
