import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Injuries, Term } from '../../../../models/BO/Term';
import { Validacoes } from '../../../../utils/validacoes';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { TermResult } from '../../../../models/BO/TermResult';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfigClinicaService } from 'src/app/services/config-clinica.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { identifierModuleUrl } from '@angular/compiler';
import { DataTermService } from 'src/app/services/dataTerm.service';

@Component({
  selector: 'app-venoso-membro',
  templateUrl: './venoso-membro.component.html',
  styleUrls: ['./venoso-membro.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
})
export class VenosoMembroComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  public htmlContent: string;
  public mask: string;
  public Subtitulo: string;
  public subtituloBloco: string;
  public Lado = '';
  public generoLateralidade: string;
  public lateralidade: Boolean = true;
  public VeiaFemoralComum: Boolean = false;
  public VeiaFemoral: Boolean = false;
  public VeiaFemoralProfunda: Boolean = false;
  public VeiaPolplitea: Boolean = false;
  public VeiaTibiaisAnteriores: Boolean = false;
  public VeiaTibiaisPosteriores: Boolean = false;
  public VeiasFibulares: Boolean = false;
  public VeiasMuscularesPanturrilha: Boolean = false;
  public Tromboflebite: Boolean = false;
  public achadosTexto: Boolean = false;
  public rodape: Boolean = false;
  public rodapeTexto: any = '';
  public indicacao: Boolean = false;
  public indicacaoTexto: any = '';
  public cabecalhoTexto = '';
  public cabecalho: Boolean = false;
  public achadosAdTexto: any = '';
  public achadosTextos: Boolean = false;
  public VeiaFemoralComumID: Number = 210;
  public VeiaFemoralID: Number = 211;
  public VeiaFemoralProfundaID: Number = 212;
  public VeiaPolpliteaID: Number = 213;
  public VeiaTibiaisAnterioresID: Number = 214;
  public VeiaTibiaisPosterioresID: Number = 215;
  public VeiasFibularesID: Number = 216;
  public VeiasMuscularesPanturrilhaID: Number = 217;
  public TromboflebiteID: Number = 218;
  public achadosTextoID: Number = 219;
  public medicalReportTypeID: number = 38;
  public term: any = new Term();
  public injuries: any = new Injuries();
  public cenarioCorrente: any = 0;
  public objetoGrupoLesao: any;
  public termResultsAPI: Array<TermResult>;
  public termConclusionAPI: Array<TermResult>;
  public showForm: Boolean = true;
  public unidadeMedida: string;
  infoLogotipo:string|any= '';
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  editLaudoMode: boolean = false;

  public blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Lateralidade',
      id: 'lateralidade',
      name: 'lateralidade',
      selected: true,
    },
    {
      id: this.VeiaFemoralComumID,
      name: 'Veia Femoral Comum',
      titulo: 'Veia Femoral Comum',
      selected: false,
    },
    {
      id: this.VeiaFemoralID,
      name: 'Veia Femoral',
      titulo: 'Veia Femoral',
      selected: false,
    },
    {
      id: this.VeiaFemoralProfundaID,
      name: 'Veia Femoral Profunda Proximal',
      titulo: 'Veia Femoral Profunda Proximal',
      selected: false,
    },
    {
      titulo: 'Veia Poplítea',
      id: this.VeiaPolpliteaID,
      name: 'Veia Poplítea',
      selected: false,
    },
    {
      titulo: 'Veias Tibiais Anteriores',
      id: this.VeiaTibiaisAnterioresID,
      name: 'Veias Tibias Anteriores',
      selected: false,
    },
    {
      titulo: 'Veias Tibiais Posteriores',
      id: this.VeiaTibiaisPosterioresID,
      name: 'Veias Tibiais Posteriores',
      selected: false,
    },
    {
      titulo: 'Veias Fibulares',
      id: this.VeiasFibularesID,
      name: 'Veias Fibulares',
      selected: false,
    },
    {
      titulo: 'Veias Musculares da Panturrilha',
      id: this.VeiasMuscularesPanturrilhaID,
      name: 'Veias Musculares da Panturrilha',
      selected: false,
    },
    {
      titulo: 'Tromboflebite',
      id: this.TromboflebiteID,
      name: 'Tromboflebite',
      selected: false,
    },
    {
      titulo: 'Achados adicionais',
      id: this.achadosTextoID,
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  listTermDefault=[
    {listTerm:[2424],blockID:210},
    {listTerm:[2496],blockID:211},
    {listTerm:[2432],blockID:212},
    {listTerm:[2440],blockID:213},
    {listTerm:[2448],blockID:214},
    {listTerm:[2456],blockID:215},
    {listTerm:[2464],blockID:216},
    {listTerm:[2472],blockID:217},
    {listTerm:[2476],blockID:218},
    {listTerm:[2483],blockID:219},
  ]

  public Descricoes: DescricaoConclusao[] = [
    {
      value: 'Foram avaliados os seguintes segmentos venosos.',
      blockID: 0, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veia femoral comum</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler. ',
      blockID: 210, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veia femoral</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler. ',
      blockID: 211, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veia femoral profunda proximal</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler.',
      blockID: 212, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veia poplítea</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler.',
      blockID: 213, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veias fibulares</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler.',
      blockID: 216, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veias tibiais anteriores</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler.',
      blockID: 214, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veias tibiais posteriores</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler.',
      blockID: 215, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veias musculares da panturrilha</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler.',
      blockID: 217, textTermID: 0, visivel: true
    },
    {
      value: '',
      blockID: 218, textTermID: 0, visivel: true
    },
    {
      value: 'As veias analisadas apresentam-se pérvias, com fluxo fásico, variante com a respiração, inferindo patência do sistema ilíaco e da veia cava inferior.',
      blockID: 0, textTermID: 0, visivel: true
    },
    {
      value: '<b>Junções safenofemoral e safenopoplítea pérvias</b>, com fluxo vascular habitual.',
      blockID: 0, textTermID: 0, visivel: true
    },
    {
      value: '',
      blockID: 219, textTermID: 0, visivel: true
    },

  ];

  public conclusoes: DescricaoConclusao[] = [
    {
      value: 'Não há sinais de trombose venosa.',
      blockID: 0, textTermID: 0, visivel: true
    },
    { value: '', blockID: 210, textTermID: 0, visivel: true },
    { value: '', blockID: 211, textTermID: 0, visivel: true },
    { value: '', blockID: 212, textTermID: 0, visivel: true },
    { value: '', blockID: 213, textTermID: 0, visivel: true },
    { value: '', blockID: 216, textTermID: 0, visivel: true },
    { value: '', blockID: 214, textTermID: 0, visivel: true },
    { value: '', blockID: 215, textTermID: 0, visivel: true },
    { value: '', blockID: 217, textTermID: 0, visivel: true },
    { value: '', blockID: 218, textTermID: 0, visivel: true },
    { value: '', blockID: 219, textTermID: 0, visivel: true },
  ];


  genericTermsVeiaFemoralComum: TermosGenericos[] = [
    { value: '', selected: false, termID: 2426, term: '[Motivo]' },
    { value: '', selected: false, termID: 2428, term: '[Localizacao]' },
    { value: '', selected: false, termID: 2431, term: '[Calibre]' },

  ];
  genericTermsVeiaFemoral: TermosGenericos[] = [
    { value: '', selected: false, termID: 2498, term: '[Motivo]' },
    { value: '', selected: false, termID: 2500, term: '[Localizacao]' },
    { value: '', selected: false, termID: 2502, term: '[Calibre]' },
  ];

  genericTermsVeiaFemoralProximal: TermosGenericos[] = [
    { value: '', selected: false, termID: 2434, term: '[Motivo]' },
    { value: '', selected: false, termID: 2439, term: '[Calibre]' },
  ];

  genericTermsVeiaPoplitea: TermosGenericos[] = [
    { value: '', selected: false, termID: 2442, term: '[Motivo]' },
    { value: '', selected: false, termID: 2444, term: '[Localizacao]' },
    { value: '', selected: false, termID: 2446, term: '[Calibre]' },

  ];

  genericTermsVeiaTibiasAnteriores: TermosGenericos[] = [
    { value: '', selected: false, termID: 2450, term: '[Motivo]' },
    { value: '', selected: false, termID: 2452, term: '[Localizacao]' },
    { value: '', selected: false, termID: 2454, term: '[Calibre]' },

  ];

  genericTermsVeiaTibiasPosteriores: TermosGenericos[] = [
    { value: '', selected: false, termID: 2458, term: '[Motivo]' },
    { value: '', selected: false, termID: 2460, term: '[Localizacao]' },
    { value: '', selected: false, termID: 2462, term: '[Calibre]' },

  ];

  genericTermsVeiaFibulares: TermosGenericos[] = [
    { value: '', selected: false, termID: 2466, term: '[Motivo]' },
    { value: '', selected: false, termID: 2468, term: '[Localizacao]' },
    { value: '', selected: false, termID: 2470, term: '[Calibre]' },

  ];

  genericTermsVeiaMusculares: TermosGenericos[] = [
    { value: '', selected: false, termID: 2474, term: '[Motivo]' },

  ];

  genericTermsTromboflebite: TermosGenericos[] = [
    { value: '', selected: false, termID: 2478, term: '[Safenas]' },
    { value: '', selected: false, termID: 2479, term: '[Terco]' },
    { value: '', selected: false, termID: 2480, term: '[Localizacao]' },
    { value: '', selected: false, termID: 2481, term: '[Veias Superficiais]' },
    { value: '', selected: false, termID: 2482, term: '[Localizacao face]' },
  ];

  genericAchadosAdicionais: TermosGenericos[] = [
    { value: '', selected: false, termID: 2484, term: '[Laminas Liquidas]' },
    { value: '', selected: false, termID: 2485, term: '[Localizacao Face]' },
    { value: '', selected: false, termID: 2486, term: '[Localizacao]' },
    { value: '', selected: false, termID: 2488, term: '[Paredes]' },
    { value: '', selected: false, termID: 2489, term: '[Conteudo]' },
    { value: '', selected: false, termID: 2490, term: '[Medida]' },
    { value: '', selected: false, termID: 2492, term: '[Extensao Na Perna]' },
    { value: '', selected: false, termID: 2495, term: '[Gastrocnemio]' },
    { value: '', selected: false, termID: 2495, term: '[Gastrocnemio]' },

  ];

  public switchFormReport = true;

  constructor(private readonly _selecteditememiter: SelectedItemEmiter,
    private readonly _toastr: ToastrManager,
    private readonly _copyPaste: CopyPaste,
    private _validacoes: Validacoes,
    private readonly _laudoservice: LaudosService,
    private readonly _serviceMedicalReportLog: MedicalReportLogService,
    private readonly _serviceMedicalReport: MedicalReportService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    public readonly dialog: MatDialog,
    private _configClinica:ConfigClinicaService,
    public _addText: AddText,
    private _config: ConfigLaudoService,
    private dataService:DataTermService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("venoso-membro-dopplerAnalise", this.Descricoes, this.conclusoes);
    this.resetaDialog();
    this.generoLateralidade = 'masculino';
    this.Subtitulo = 'Lateralidade';
    this.subtituloBloco = 'Lateralidade';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });

    let configClinicaRule = JSON.parse(localStorage.getItem('configClinicaRule'));
    if(configClinicaRule && configClinicaRule.active){
      this._configClinica.handleSelectUserClinic();
      this._configClinica.maskClinica.subscribe(res=>{
        this.infoLogotipo=res;
        this.AdicionaTexto(this.newEditor);
      })
    };

    const listBlocks = [
      this.VeiaFemoralComumID,
      this.VeiaFemoralID,
      this.VeiaFemoralProfundaID,
      this.VeiaPolpliteaID,
      this.VeiaTibiaisAnterioresID,
      this.VeiaTibiaisPosterioresID,
      this.VeiasFibularesID,
      this.VeiasMuscularesPanturrilhaID,
      this.TromboflebiteID,
      this.achadosTextoID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "venoso-membro-dopplerMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase( listBlocks, "tvpInferior");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-venoso-doppler');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-venoso-doppler');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo() {
    this._pouchService.getAll("venoso-membro-dopplerAnalise").then((obj) => {
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.Descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.Lado = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  public selecionar(itemSelecionado): any {
    const arrayAtualizado = this._selecteditememiter.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho =  (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === this.achadosTextoID);
    this.rodape =  (itemSelecionado.id === 'rodape');
    this.lateralidade = (itemSelecionado.id === 'lateralidade');
    this.VeiaFemoralComum = (itemSelecionado.id === this.VeiaFemoralComumID);
    this.VeiaFemoral = (itemSelecionado.id === this.VeiaFemoralID);
    this.VeiaFemoralProfunda = (itemSelecionado.id === this.VeiaFemoralProfundaID);
    this.VeiaPolplitea = (itemSelecionado.id === this.VeiaPolpliteaID);
    this.VeiaTibiaisAnteriores = (itemSelecionado.id === this.VeiaTibiaisAnterioresID);
    this.VeiaTibiaisPosteriores = (itemSelecionado.id === this.VeiaTibiaisPosterioresID);
    this.VeiasFibulares = (itemSelecionado.id === this.VeiasFibularesID);
    this.VeiasMuscularesPanturrilha = (itemSelecionado.id === this.VeiasMuscularesPanturrilhaID);
    this.Tromboflebite = (itemSelecionado.id === this.TromboflebiteID);


  }

  public limparTermos(): any {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;

    this.genericTermsVeiaFemoralComum = [
      { value: '', selected: false, termID: 2426, term: '[Motivo]' },
      { value: '', selected: false, termID: 2428, term: '[Localizacao]' },
      { value: '', selected: false, termID: 2431, term: '[Calibre]' },

    ];

    this.genericTermsVeiaFemoral = [
      { value: '', selected: false, termID: 2498, term: '[Motivo]' },
      { value: '', selected: false, termID: 2500, term: '[Localizacao]' },
      { value: '', selected: false, termID: 2502, term: '[Calibre]' },

    ];

    this.genericTermsVeiaFemoralProximal = [
      { value: '', selected: false, termID: 2434, term: '[Motivo]' },
      { value: '', selected: false, termID: 2439, term: '[Calibre]' },

    ];

    this.genericTermsVeiaPoplitea = [
      { value: '', selected: false, termID: 2442, term: '[Motivo]' },
      { value: '', selected: false, termID: 2444, term: '[Localizacao]' },
      { value: '', selected: false, termID: 2446, term: '[Calibre]' },

    ];

    this.genericTermsVeiaTibiasAnteriores = [
      { value: '', selected: false, termID: 2450, term: '[Motivo]' },
      { value: '', selected: false, termID: 2452, term: '[Localizacao]' },
      { value: '', selected: false, termID: 2454, term: '[Calibre]' },

    ];

    this.genericTermsVeiaTibiasPosteriores = [
      { value: '', selected: false, termID: 2458, term: '[Motivo]' },
      { value: '', selected: false, termID: 2460, term: '[Localizacao]' },
      { value: '', selected: false, termID: 2462, term: '[Calibre]' },

    ];

    this.genericTermsVeiaFibulares = [
      { value: '', selected: false, termID: 2466, term: '[Motivo]' },
      { value: '', selected: false, termID: 2468, term: '[Localizacao]' },
      { value: '', selected: false, termID: 2470, term: '[Calibre]' },

    ];

    this.genericTermsVeiaMusculares = [
      { value: '', selected: false, termID: 2474, term: '[Motivo]' },

    ];

    this.genericTermsTromboflebite = [
      { value: '', selected: false, termID: 2478, term: '[Safenas]' },
      { value: '', selected: false, termID: 2479, term: '[Terco]' },
      { value: '', selected: false, termID: 2480, term: '[Localizacao]' },
      { value: '', selected: false, termID: 2481, term: '[Veias Superficiais]' },
      { value: '', selected: false, termID: 2482, term: '[Localizacao face]' },
    ];

    this.genericAchadosAdicionais = [
      { value: '', selected: false, termID: 2484, term: '[Laminas Liquidas]' },
      { value: '', selected: false, termID: 2485, term: '[Localizacao Face]' },
      { value: '', selected: false, termID: 2486, term: '[Localizacao]' },
      { value: '', selected: false, termID: 2488, term: '[Paredes]' },
      { value: '', selected: false, termID: 2489, term: '[Conteudo]' },
      { value: '', selected: false, termID: 2490, term: '[Medida]' },
      { value: '', selected: false, termID: 2492, term: '[Extensao Na Perna]' },
      { value: '', selected: false, termID: 2495, term: '[Gastrocnemio]' },
      { value: '', selected: false, termID: 2495, term: '[Gastrocnemio]' },

    ];
  }


  public MontaDescricaoVeiaFemoralComum(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2426 || +term === 2428 || +term === 2431) {
        this.genericTermsVeiaFemoralComum.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 2426) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoVeiaFemoralComum(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2426 || +term === 2428 || +term === 2431) {
        this.genericTermsVeiaFemoralComum.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoVeiaFemoral(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2498 || +term === 2500 || +term === 2502) {
        this.genericTermsVeiaFemoral.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 2498) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }


  public MontaConclussaoVeiaFemoral(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2498 || +term === 2500 || +term === 2502) {
        this.genericTermsVeiaFemoral.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }


  public MontaDescricaoVeiaFemoralProximal(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2434 || +term === 2439) {
        this.genericTermsVeiaFemoralProximal.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 2434) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoVeiaFemoralProximal(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2434 || +term === 2439) {
        this.genericTermsVeiaFemoralProximal.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }


  public MontaDescricaoVeiaPoplitea(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2442 || +term === 2444 || +term === 2446) {
        this.genericTermsVeiaPoplitea.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 2442) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoVeiaPoplitea(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2442 || +term === 2444 || +term === 2446) {
        this.genericTermsVeiaPoplitea.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoVeiaTibiasAnteriores(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2450 || +term === 2452 || +term === 2454) {
        this.genericTermsVeiaTibiasAnteriores.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 2450) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoVeiaTibiasAnteriores(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2450 || +term === 2452 || +term === 2454) {
        this.genericTermsVeiaTibiasAnteriores.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoVeiaTibiasPosteriores(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2458 || +term === 2460 || +term === 2462) {
        this.genericTermsVeiaTibiasPosteriores.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 2458) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }


  public MontaConclussaoVeiaTibiasPosteriores(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2458 || +term === 2460 || +term === 2462) {
        this.genericTermsVeiaTibiasPosteriores.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoVeiaFibulares(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2466 || +term === 2468 || +term === 2470) {
        this.genericTermsVeiaFibulares.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 2466) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoVeiaFibulares(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2466 || +term === 2468 || +term === 2470) {
        this.genericTermsVeiaFibulares.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoVeiaMusculares(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2474) {
        this.genericTermsVeiaMusculares.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 2474) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoVeiaMusculares(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2474) {
        this.genericTermsVeiaMusculares.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoTromboflebite(texto, contadorTexto): any {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 2478 || +term === 2479 || +term === 2480 || + term === 2481 || + term === 2482) {
          this.genericTermsTromboflebite.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 2478 || +term === 2479 || +term === 2480 || + term === 2481 || + term === 2482) {
          this.genericTermsTromboflebite.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  public MontaConclussaoTromboflebite(texto, contadorTexto): any {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 2478 || +term === 2479 || +term === 2480 || + term === 2481 || + term === 2482) {
          this.genericTermsTromboflebite.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value.toLowerCase());
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 2478 || +term === 2479 || +term === 2480 || + term === 2481 || + term === 2482) {
          this.genericTermsTromboflebite.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value.toLowerCase());
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  public MontaDescricaoAchadosAdicionais(texto, contadorTexto): any {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 2484 || +term === 2485 || +term === 2486 || +term === 2488 || +term === 2489
          || + term === 2490 || + term === 2492 || + term === 2495 || + term === 2495) {
          this.genericAchadosAdicionais.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 2484 || +term === 2485 || +term === 2486 || +term === 2488 || +term === 2489
          || + term === 2490 || + term === 2492 || + term === 2495 || + term === 2495) {
          this.genericAchadosAdicionais.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  public MontaConclussaoAchadosAdicionais(texto, contadorTexto): any {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 2484 || +term === 2485 || +term === 2486 || +term === 2488 || +term === 2489
          || + term === 2490 || + term === 2492 || + term === 2495 || + term === 2495) {
          this.genericAchadosAdicionais.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 2484 || +term === 2485 || +term === 2486 || +term === 2488 || +term === 2489
          || + term === 2490 || + term === 2492 || + term === 2495 || + term === 2495) {
          this.genericAchadosAdicionais.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  public adicionaSelecaoVeiaFemoralComum($event): any {
    this.genericTermsVeiaFemoralComum.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoVeiaFemoral($event): any {
    this.genericTermsVeiaFemoral.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoVeiaFemoralProximal($event): any {
    this.genericTermsVeiaFemoralProximal.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoVeiaPoplitea($event): any {
    this.genericTermsVeiaPoplitea.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoVeiaTibiasAnteriores($event): any {
    this.genericTermsVeiaTibiasAnteriores.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoVeiaTibiasPosteriores($event): any {
    this.genericTermsVeiaTibiasPosteriores.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoVeiaFibulares($event): any {
    this.genericTermsVeiaFibulares.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoVeiaMusculares($event): any {
    this.genericTermsVeiaMusculares.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoTromboflebite($event): any {
    this.genericTermsTromboflebite.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });

    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  public adicionaSelecaoAchadosAdicionais($event): any {
    this.genericAchadosAdicionais.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao === true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  public enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  public consultarAPI(): any {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.VeiaFemoralComum) {
        this.term.blockID = this.VeiaFemoralComumID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.VeiaFemoralComumID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaFemoralComum(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.VeiaFemoralComumID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1729) {
                  this.Descricoes[10].visivel = false;
                } else {
                  this.Descricoes[10].visivel = true;
                }
              }
              //this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiaFemoralComumID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVeiaFemoralComum(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.VeiaFemoralComumID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiaFemoralComumID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          //this.AdicionaTexto();
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.VeiaFemoralComumID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.VeiaFemoral) {
        this.term.blockID = this.VeiaFemoralID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.VeiaFemoralID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaFemoral(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.VeiaFemoralID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1749) {
                  this.Descricoes[10].visivel = false;
                } else {
                  this.Descricoes[10].visivel = true;
                }
              }
              //this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiaFemoralID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVeiaFemoral(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.VeiaFemoralID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiaFemoralID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          //this.AdicionaTexto();
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.VeiaFemoralID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.VeiaFemoralProfunda) {
        this.term.blockID = this.VeiaFemoralProfundaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.VeiaFemoralProfundaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaFemoralProximal(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.VeiaFemoralProfundaID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1734) {
                  this.Descricoes[10].visivel = false;
                } else {
                  this.Descricoes[10].visivel = true;
                }
              }
              //this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiaFemoralProfundaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVeiaFemoralProximal(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.VeiaFemoralProfundaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiaFemoralProfundaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          //this.AdicionaTexto();
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.VeiaFemoralProfundaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.VeiaPolplitea) {
        this.term.blockID = this.VeiaPolpliteaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.VeiaPolpliteaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaPoplitea(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.VeiaPolpliteaID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1739) {
                  this.Descricoes[10].visivel = false;
                } else {
                  this.Descricoes[10].visivel = true;
                }
              }
              //this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiaPolpliteaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVeiaPoplitea(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.VeiaPolpliteaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiaPolpliteaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          //this.AdicionaTexto();
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.VeiaPolpliteaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.VeiaTibiaisAnteriores) {
        this.term.blockID = this.VeiaTibiaisAnterioresID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.VeiaTibiaisAnterioresID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {

                obj.value = obj.value + espaco + this.MontaDescricaoVeiaTibiasAnteriores(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.VeiaTibiaisAnterioresID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1744) {
                  this.Descricoes[10].visivel = false;
                } else {
                  this.Descricoes[10].visivel = true;
                }
              }
              //this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiaTibiaisAnterioresID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVeiaTibiasAnteriores(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.VeiaTibiaisAnterioresID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiaTibiaisAnterioresID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          //this.AdicionaTexto();
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.VeiaTibiaisAnterioresID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.VeiaTibiaisPosteriores) {
        this.term.blockID = this.VeiaTibiaisPosterioresID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.VeiaTibiaisPosterioresID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaTibiasPosteriores(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.VeiaTibiaisPosterioresID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1754) {
                  this.Descricoes[10].visivel = false;
                } else {
                  this.Descricoes[10].visivel = true;
                }
              }
              //this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiaTibiaisPosterioresID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVeiaTibiasPosteriores(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.VeiaTibiaisPosterioresID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiaTibiaisPosterioresID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          //this.AdicionaTexto();
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.VeiaTibiaisPosterioresID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.VeiasFibulares) {
        this.term.blockID = this.VeiasFibularesID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.VeiasFibularesID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaFibulares(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.VeiasFibularesID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1759) {
                  this.Descricoes[10].visivel = false;
                } else {
                  this.Descricoes[10].visivel = true;
                }
              }
              //this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiasFibularesID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVeiaFibulares(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.VeiasFibularesID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiasFibularesID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          //this.AdicionaTexto();
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.VeiasFibularesID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.VeiasMuscularesPanturrilha) {
        this.term.blockID = this.VeiasMuscularesPanturrilhaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.VeiasMuscularesPanturrilhaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaMusculares(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.VeiasMuscularesPanturrilhaID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1769) {
                  this.Descricoes[10].visivel = false;
                } else {
                  this.Descricoes[10].visivel = true;
                }
              }
              //this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiasMuscularesPanturrilhaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVeiaMusculares(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.VeiasMuscularesPanturrilhaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiasMuscularesPanturrilhaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          //this.AdicionaTexto();
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.VeiasMuscularesPanturrilhaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.Tromboflebite) {
        this.term.blockID = this.TromboflebiteID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.TromboflebiteID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoTromboflebite(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.TromboflebiteID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1860) {
                  this.Descricoes[10].visivel = false;
                } else {
                  this.Descricoes[10].visivel = true;
                }
              }
              //this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.TromboflebiteID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoTromboflebite(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.TromboflebiteID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.TromboflebiteID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          //this.AdicionaTexto();
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.TromboflebiteID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.achadosTexto) {
        this.term.blockID = this.achadosTextoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.achadosTextoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoAchadosAdicionais(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.achadosTextoID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1864) {
                  this.Descricoes[10].visivel = false;
                } else {
                  this.Descricoes[10].visivel = true;
                }
              }
              const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);

              if(!this.newEditor) {
                this.AdicionaTexto(this.newEditor);
              } else {
                this.atualizaLinha({
                  blockId: +this.achadosTextoID,
                  descricao: desc,
                  conclusao: '',
                  conclusaoNull: conclusaoNull.value
                });
              }
            }
          });
        });

      }
    }
  }

  public enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        indicacao: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  public enviaLados($event) {
    this.Lado = $event;
    this.AdicionaTexto(this.newEditor);
  }

  public RetornaConclusaoNull(Texto): any {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  public auditoriaUsuario() {

    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj) {
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
    }
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor(){
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-lateralidade`,
        'name': ''
      },
      'data': {
        'text': `<p>${maskSplit[0]} ${this.Lado.toUpperCase()}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.Descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao, index) => {
      if (conclusao.value && this.conclusoes[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

  }


  public adicionaTextoOldEditor(): any {
    let i;
    let maskSplit = this.mask.split('|');

    if(this.infoLogotipo !== '' && this.cabecalhoTexto !== ''){
      this.htmlContent = this.infoLogotipo;
      this.htmlContent += this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo !== '' && this.cabecalhoTexto == ''){
      this.htmlContent = this.infoLogotipo;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo == '' && this.cabecalhoTexto !== ''){
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo == '' && this.cabecalhoTexto == ''){
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.Lado.toUpperCase();
    this.htmlContent += maskSplit[1];
    this.htmlContent += this.indicacaoTexto;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContent += maskSplit[2];

    for (i = 0; i < this.Descricoes.length; i++) {
      var newValue = this.Descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.Descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.Descricoes.length; i++) {
      if (this.Descricoes[i].value && i <= 11 && this.Descricoes[i].visivel) {
        this.htmlContent += '<br/>' + this.Descricoes[i].value + '<br/>';
      }
    }

    this.htmlContent += maskSplit[3];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }

    if (this.Descricoes[12].value) {
      this.htmlContent += "</br><b>Achados adicionais:</b></br>";
      this.htmlContent += this.Descricoes[12].value;
    }

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.Descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
            header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {

          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
            footer.innerHTML = rodape;
          }
        }

			} else if (indicacao || achados) {
				if(indicacao === undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados === undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }
}
