import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validacoes } from '../../../../utils/validacoes';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { CommonService } from 'src/app/services/common.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { ConfigClinicaService } from 'src/app/services/config-clinica.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

@Component({
  selector: 'app-quadril-infantil-bilateral',
  templateUrl: './quadril-infantil-bilateral.component.html',
  styleUrls: ['./quadril-infantil-bilateral.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
})
export class QuadrilInfantilBilateralComponent implements OnInit {

  listTermDefault=[
    {listTerm:[4144],blockID:266},
    {listTerm:[4145],blockID:267},
    {listTerm:[4148],blockID:268},
    {listTerm:[4158],blockID:272},
    {listTerm:[4156],blockID:271},
    {listTerm:[4153],blockID:270},
    {listTerm:[4150],blockID:269},
    {listTerm:[4161],blockID:273},
  ]

  descricoesDireita: DescricaoConclusao[] = [
    { value: 'Pele e tecido subcutâneo preservados.', blockID: 266, textTermID: 0, visivel: true },
    { value: 'Cabeça femoral de morfologia preservada e encaixada ao repouso.', blockID: 267, textTermID: 0, visivel: true },
    { value: 'Acetábulo com morfologia preservada.', blockID: 268, textTermID: 0, visivel: true },
    { value: 'Lábio acetabular com morfologia e ecotextura preservadas.', blockID: 269, textTermID: 0, visivel: true },
    { value: 'Promontório de aspecto preservado.', blockID: 270, textTermID: 0, visivel: true },
    { value: 'Ausência de sinais de instabilidade durante a realização das manobras dinâmicas.', blockID: 271, textTermID: 0, visivel: true },
    { value: 'Ausência de derrame articular significativo no quadril.', blockID: 272, textTermID: 0, visivel: true },
    { value: '', blockID: 273, textTermID: 0, visivel: true }
  ];

  descricoesEsquerda: DescricaoConclusao[] = [
    { value: 'Pele e tecido subcutâneo preservados.', blockID: 266, textTermID: 0, visivel: true },
    { value: 'Cabeça femoral de morfologia preservada e encaixada ao repouso.', blockID: 267, textTermID: 0, visivel: true },
    { value: 'Acetábulo com morfologia preservada.', blockID: 268, textTermID: 0, visivel: true },
    { value: 'Lábio acetabular com morfologia e ecotextura preservadas.', blockID: 269, textTermID: 0, visivel: true },
    { value: 'Promontório de aspecto preservado.', blockID: 270, textTermID: 0, visivel: true },
    { value: 'Ausência de sinais de instabilidade durante a realização das manobras dinâmicas.', blockID: 271, textTermID: 0, visivel: true },
    { value: 'Ausência de derrame articular significativo no quadril.', blockID: 272, textTermID: 0, visivel: true },
    { value: '', blockID: 273, textTermID: 0, visivel: true }
  ];

  conclusoesDireita: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 266, textTermID: 0, visivel: true },
    { value: '', blockID: 267, textTermID: 0, visivel: true },
    { value: '', blockID: 268, textTermID: 0, visivel: true },
    { value: '', blockID: 269, textTermID: 0, visivel: true },
    { value: '', blockID: 270, textTermID: 0, visivel: true },
    { value: '', blockID: 271, textTermID: 0, visivel: true },
    { value: '', blockID: 272, textTermID: 0, visivel: true },
    { value: '', blockID: 273, textTermID: 0, visivel: true }
  ];

  conclusoesEsquerda: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 266, textTermID: 0, visivel: true },
    { value: '', blockID: 267, textTermID: 0, visivel: true },
    { value: '', blockID: 268, textTermID: 0, visivel: true },
    { value: '', blockID: 269, textTermID: 0, visivel: true },
    { value: '', blockID: 270, textTermID: 0, visivel: true },
    { value: '', blockID: 271, textTermID: 0, visivel: true },
    { value: '', blockID: 272, textTermID: 0, visivel: true },
    { value: '', blockID: 273, textTermID: 0, visivel: true }
  ];

  genericDerrameArticularDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 4160, term: '[Volume]' },
  ];

  genericDerrameArticularEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 4160, term: '[Volume]' },
  ];

  genericAnguloGrafDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 4167, term: '[Angulo]' },
  ];

  genericAnguloGrafEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 4167, term: '[Angulo]' },
  ];

  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  htmlContentDireita: string;
  htmlContentEsquerda: string;
  maskDireita: string;
  maskEsquerda: string;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  cabecalhoTexto = '';
  indicacaoTextoDireita = '';
  indicacaoTextoEsquerda = '';
  achadosAdTextoDireita = '';
  achadosAdTextoEsquerda = '';
  Lado = '';
  exibeTabela = false;
  tabelaValorDireita = new Array<string>();
  tabelaValorEsquerda = new Array<string>();
  rodapeTexto = '';
  tabelaDireita = '';
  tabelaEsquerda = '';

  cabecalho = false;
  showLateralidade = false;
  showIndicacao = true;
  showDerrameArticular = false;
  showInstabilidadeManobra = false;
  showPromontorio = false;
  showLabioAcetabular = false;
  showPeleTecido = false;
  showCabecaFemoral = false;
  showAcetabulo = false;
  showAngulosGraf = false;
  indicacao = true;
  achadosTexto = false;
  rodape = false;

  medicalReportTypeID = 65;
  peleTecidoID = 266;
  cabecaFemuralID = 267;
  acetabuloID = 268;
  derrameArticularID = 272;
  instabilidadeManobraID = 271;
  promontorioID = 270;
  labioAcetabularID = 269;
  angulosGrafID = 273;

  cabecaFemoralLuxada = false;

  public switchFormReport = true;
  public generoLateralidade: string;
  public showForm: Boolean = true;
  infoLogotipo:string|any= '';
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  editLaudoMode: boolean = false;

  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _copyPaste: CopyPaste,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _configClinica:ConfigClinicaService,
    private _config: ConfigLaudoService,
    public _addText: AddText,
    private dataService:DataTermService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("quadril-infantilAnalise", this.descricoesDireita, this.conclusoesDireita);
    this._pouchService.loadDatabase("quadril-infantilAnalise", this.descricoesEsquerda, this.conclusoesEsquerda);
    this.resetaDialog();
    this.generoLateralidade = 'masculino';
    this.Subtitulo = 'Indicação';
    this.subtituloBloco = 'Preencha o campo indicação';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.adicionaTexto();
    // });
    let configClinicaRule = JSON.parse(localStorage.getItem('configClinicaRule'));
    if(configClinicaRule && configClinicaRule.active){
      this._configClinica.handleSelectUserClinic();
      this._configClinica.maskClinica.subscribe(res=>{
        this.infoLogotipo=res;
        this.AdicionaTextoDireita();
        this.AdicionaTextoEsquerda();
      })
    };

    const listBlocks = [
      this.peleTecidoID,
      this.cabecaFemuralID,
      this.acetabuloID,
      this.derrameArticularID,
      this.instabilidadeManobraID,
      this.promontorioID,
      this.labioAcetabularID,
      this.angulosGrafID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "quadril-infantilBilateralMask").then((obj: any) => {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.maskDireita = obj.mask.replace('{0}', 'DIREITO');
      this.maskEsquerda = obj.mask.replace('{0}', 'ESQUERDO');
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase( listBlocks, "quadrilInfantil");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-quadril-infantil');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-quadril-infantil');
      }
    });
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }


  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetaLaudo();
          }
        });
      }
    });
  }

  public resetaLaudo() {
    this._pouchService.getAll("quadril-infantilAnalise").then((obj) => {
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoesDireita[i].value = obj[i].doc.value;
            this.descricoesEsquerda[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoesDireita[concCalc].value = obj[i].doc.value;
            this.conclusoesEsquerda[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.Lado = "";
    this.cabecalhoTexto = "";
    this.indicacaoTextoDireita = "";
    this.indicacaoTextoEsquerda = "";
    this.achadosAdTextoDireita = '';
    this.achadosAdTextoEsquerda = '';
    this.rodapeTexto = "";
    this.showForm = false;
    this.exibeTabela = false;
    this.tabelaValorDireita = [];
    this.tabelaValorEsquerda = [];

    setTimeout(() => {
      this.showForm = true;
      this.AdicionaTexto(this.newEditor);
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: true
    },
    {
      titulo: 'Pele e tecido subcutâneo',
      id: this.peleTecidoID,
      name: 'pele-tecido',
      selected: false
    },
    {
      titulo: 'Cabeça femoral',
      id: this.cabecaFemuralID,
      name: 'cabeca-femoral',
      selected: false
    },
    {
      titulo: 'Acetábulo',
      id: this.acetabuloID,
      name: 'acetabulo',
      selected: false
    },
    {
      titulo: 'Lábio acetabular',
      id: this.labioAcetabularID,
      name: 'labio-acetabular',
      selected: false
    },
    {
      titulo: 'Promontório',
      id: this.promontorioID,
      name: 'promontorio',
      selected: false
    },
    {
      titulo: 'Instabilidade durante manobras',
      id: this.instabilidadeManobraID,
      name: 'instabilidade-manobra',
      selected: false
    },
    {
      titulo: 'Derrame articular',
      id: this.derrameArticularID,
      name: 'derrame-articular',
      selected: false
    },
    {
      titulo: 'Ângulos de GRAF',
      id: this.angulosGrafID,
      name: 'angulos-graf',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ]

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.showLateralidade = (itemSelecionado.id === 'lateralidade');
    this.showPeleTecido = (itemSelecionado.id === this.peleTecidoID);
    this.showCabecaFemoral = (itemSelecionado.id === this.cabecaFemuralID);
    this.showAcetabulo = (itemSelecionado.id === this.acetabuloID);
    this.showDerrameArticular = (itemSelecionado.id === this.derrameArticularID);
    this.showInstabilidadeManobra = (itemSelecionado.id === this.instabilidadeManobraID);
    this.showPromontorio = (itemSelecionado.id === this.promontorioID);
    this.showLabioAcetabular = (itemSelecionado.id === this.labioAcetabularID);
    this.showAngulosGraf = (itemSelecionado.id === this.angulosGrafID);
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;

    this.genericDerrameArticularDireita = [
      { value: '', selected: false, termID: 4160, term: '[Volume]' },
    ];

    this.genericDerrameArticularEsquerda = [
      { value: '', selected: false, termID: 4160, term: '[Volume]' },
    ];

    this.genericAnguloGrafDireita = [
      { value: '', selected: false, termID: 4167, term: '[Angulo]' },
    ];

    this.genericAnguloGrafEsquerda = [
      { value: '', selected: false, termID: 4167, term: '[Angulo]' },
    ];
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaRodapeCabecalho({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaLadosDireita($event) {
    this.Lado = $event;
    this.AdicionaTexto(this.newEditor);
  }

  enviaLadosEsquerda($event) {
    this.Lado = $event;
    this.AdicionaTexto(this.newEditor);
  }

  enviaIndicacaoDireita(texto) {
    if (texto)
      this.indicacaoTextoDireita = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTextoDireita = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaDireita({
        blockId: 'indicacao',
        indicacao: this.indicacaoTextoDireita
      });
    }
  }

  enviaIndicacaoEsquerda(texto) {
    if (texto)
      this.indicacaoTextoEsquerda = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTextoEsquerda = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaEsquerda({
        blockId: 'indicacao',
        indicacao: this.indicacaoTextoEsquerda
      });
    }
  }

  enviaAchadosDireita(texto) {
    if (texto)
      this.achadosAdTextoDireita = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTextoDireita = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaDireita({
        blockId: 'achados',
        achados: this.achadosAdTextoDireita
      });
    }
  }

  enviaAchadosEsquerda(texto) {
    if (texto)
      this.achadosAdTextoEsquerda = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTextoEsquerda = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaEsquerda({
        blockId: 'achados',
        achados: this.achadosAdTextoEsquerda
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaRodapeCabecalho({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  atribuiValorTabelaDireita($element) {
    // Preenchendo as medidas
    let value;

    if ($element.value === '-') {
      value = $element.value
    } else {
      value = this._validacoes.formataDecimal($element.value);
    }
    this.exibeTabela = true;

    if ($element.id === '4162') {
      this.tabelaValorDireita[0] = value;
    } else if ($element.id === '4162') {
      this.tabelaValorDireita[0] = value;
    } else if ($element.id === '4163') {
      this.tabelaValorDireita[1] = value;
    } else if ($element.id === '4163') {
      this.tabelaValorDireita[1] = value;
    } else if ($element.id === '4167') {
      this.tabelaValorDireita[2] = value;
    } else if ($element.id === '4167') {
      this.tabelaValorDireita[2] = value;
    }

    this.tabelaDireita = this.TabelaDireita();

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaDireita({
        blockId: this.angulosGrafID,
        tabela: this.tabelaDireita,
      });
    }
  }

  atribuiValorTabelaEsquerda($element) {
    // Preenchendo as medidas
    let value;

    if ($element.value === '-') {
      value = $element.value
    } else {
      value = this._validacoes.formataDecimal($element.value);
    }
    this.exibeTabela = true;

    if ($element.id === '4162') {
      this.tabelaValorEsquerda[0] = value;
    } else if ($element.id === '4162') {
      this.tabelaValorEsquerda[0] = value;
    } else if ($element.id === '4163') {
      this.tabelaValorEsquerda[1] = value;
    } else if ($element.id === '4163') {
      this.tabelaValorEsquerda[1] = value;
    } else if ($element.id === '4167') {
      this.tabelaValorEsquerda[2] = value;
    } else if ($element.id === '4167') {
      this.tabelaValorEsquerda[2] = value;
    }

    this.tabelaEsquerda = this.TabelaEsquerda();

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaEsquerda({
        blockId: this.angulosGrafID,
        tabela: this.tabelaEsquerda,
      });
    }
  }

  adicionaSelecaoDerrameDireita($event) {
    this.genericDerrameArticularDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        if (+$event.elemento.id === 4160)
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        else
          obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoDerrameEsquerda($event) {
    this.genericDerrameArticularEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        if (+$event.elemento.id === 4160)
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        else
          obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoAnguloGrafDireita($event) {
    this.genericAnguloGrafDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        if (+$event.elemento.id === 4167)
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        else
          obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoAnguloGrafEsquerda($event) {
    this.genericAnguloGrafEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        if (+$event.elemento.id === 4167)
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        else
          obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  consultarAPIDireita() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){

      let desc;
      if (this.showPeleTecido) {
        this.term.blockID = this.peleTecidoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.peleTecidoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              const index = this.descricoesDireita.findIndex(x => x.blockID === this.peleTecidoID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.peleTecidoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.peleTecidoID;
              obj.value = resultado.conclusion;
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.peleTecidoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.peleTecidoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.peleTecidoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showCabecaFemoral) {
        this.term.blockID = this.cabecaFemuralID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.cabecaFemuralID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              const index = this.descricoesDireita.findIndex(x => x.blockID === this.cabecaFemuralID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.cabecaFemuralID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.cabecaFemuralID;
              obj.value = resultado.conclusion;
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.cabecaFemuralID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.cabecaFemuralID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.cabecaFemuralID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showAcetabulo) {
        this.term.blockID = this.acetabuloID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.acetabuloID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              const index = this.descricoesDireita.findIndex(x => x.blockID === this.acetabuloID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.acetabuloID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.acetabuloID;
              obj.value = resultado.conclusion;
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.acetabuloID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.acetabuloID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.acetabuloID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showDerrameArticular) {
        this.term.blockID = this.derrameArticularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.derrameArticularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaConclusaoDescricaoDerrameDireita(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.derrameArticularID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.derrameArticularID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.derrameArticularID;
              obj.value = obj.value + espaco + this.MontaConclusaoDescricaoDerrameDireita(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.derrameArticularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.derrameArticularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.derrameArticularID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showInstabilidadeManobra) {
        this.term.blockID = this.instabilidadeManobraID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.instabilidadeManobraID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              const index = this.descricoesDireita.findIndex(x => x.blockID === this.instabilidadeManobraID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.instabilidadeManobraID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.instabilidadeManobraID;
              obj.value = obj.value + espaco + this.MontaConclusaoDescricaoDerrameDireita(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.instabilidadeManobraID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.instabilidadeManobraID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.instabilidadeManobraID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showPromontorio) {
        this.term.blockID = this.promontorioID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.promontorioID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              const index = this.descricoesDireita.findIndex(x => x.blockID === this.promontorioID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.promontorioID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.promontorioID;
              obj.value = obj.value + espaco + this.MontaConclusaoDescricaoDerrameDireita(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.promontorioID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.promontorioID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.promontorioID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showLabioAcetabular) {
        this.term.blockID = this.labioAcetabularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.labioAcetabularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              const index = this.descricoesDireita.findIndex(x => x.blockID === this.labioAcetabularID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.labioAcetabularID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.labioAcetabularID;
              obj.value = obj.value + espaco + this.MontaConclusaoDescricaoDerrameDireita(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.promontorioID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.promontorioID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.labioAcetabularID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showAngulosGraf) {
        this.term.blockID = this.angulosGrafID;
        this.cabecaFemoralLuxada = false;
        //se for Cabeça femoral luxada Apague Cabeca femural
        if (this.term.listTermID.includes(4165)) {
          this.cabecaFemoralLuxada = true;
        }
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.angulosGrafID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaConclusaoAnguloGrafDireita(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.angulosGrafID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.angulosGrafID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.angulosGrafID;
              obj.value = obj.value + espaco + this.MontaConclusaoAnguloGrafDireita(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.angulosGrafID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.angulosGrafID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.angulosGrafID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  consultarAPIEsquerda() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){

      let desc;
      if (this.showPeleTecido) {
        this.term.blockID = this.peleTecidoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.peleTecidoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              const index = this.descricoesEsquerda.findIndex(x => x.blockID === this.peleTecidoID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.peleTecidoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.peleTecidoID;
              obj.value = resultado.conclusion;
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.peleTecidoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.peleTecidoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.peleTecidoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showCabecaFemoral) {
        this.term.blockID = this.cabecaFemuralID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.cabecaFemuralID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              const index = this.descricoesEsquerda.findIndex(x => x.blockID === this.cabecaFemuralID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.visivel;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.cabecaFemuralID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.cabecaFemuralID;
              obj.value = resultado.conclusion;
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.cabecaFemuralID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.cabecaFemuralID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.cabecaFemuralID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showAcetabulo) {
        this.term.blockID = this.acetabuloID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.acetabuloID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              const index = this.descricoesEsquerda.findIndex(x => x.blockID === this.acetabuloID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.acetabuloID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.acetabuloID;
              obj.value = resultado.conclusion;
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.acetabuloID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.acetabuloID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.acetabuloID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showDerrameArticular) {
        this.term.blockID = this.derrameArticularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.derrameArticularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaConclusaoDescricaoDerrameEsquerda(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.derrameArticularID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.derrameArticularID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.derrameArticularID;
              obj.value = obj.value + espaco + this.MontaConclusaoDescricaoDerrameEsquerda(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.derrameArticularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.derrameArticularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.derrameArticularID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showInstabilidadeManobra) {
        this.term.blockID = this.instabilidadeManobraID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.instabilidadeManobraID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              const index = this.descricoesEsquerda.findIndex(x => x.blockID === this.instabilidadeManobraID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.instabilidadeManobraID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.instabilidadeManobraID;
              obj.value = obj.value + espaco + this.MontaConclusaoDescricaoDerrameEsquerda(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.instabilidadeManobraID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.instabilidadeManobraID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.instabilidadeManobraID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showPromontorio) {
        this.term.blockID = this.promontorioID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.promontorioID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              const index = this.descricoesEsquerda.findIndex(x => x.blockID === this.promontorioID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.promontorioID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.promontorioID;
              obj.value = obj.value + espaco + this.MontaConclusaoDescricaoDerrameEsquerda(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.promontorioID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.promontorioID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.promontorioID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showLabioAcetabular) {
        this.term.blockID = this.labioAcetabularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.labioAcetabularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              const index = this.descricoesEsquerda.findIndex(x => x.blockID === this.labioAcetabularID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.labioAcetabularID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.labioAcetabularID;
              obj.value = obj.value + espaco + this.MontaConclusaoDescricaoDerrameEsquerda(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.promontorioID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.promontorioID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.labioAcetabularID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showAngulosGraf) {
        this.term.blockID = this.angulosGrafID;
        this.cabecaFemoralLuxada = false;
        //se for Cabeça femoral luxada Apague Cabeca femural
        if (this.term.listTermID.includes(4165)) {
          this.cabecaFemoralLuxada = true;
        }
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.angulosGrafID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaConclusaoAnguloGrafEsquerda(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.angulosGrafID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.angulosGrafID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.angulosGrafID;
              obj.value = obj.value + espaco + this.MontaConclusaoAnguloGrafEsquerda(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.angulosGrafID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.angulosGrafID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.angulosGrafID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  MontaConclusaoDescricaoDerrameDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4160) {
        this.genericDerrameArticularDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoDescricaoDerrameEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4160) {
        this.genericDerrameArticularEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoAnguloGrafDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4167) {
        this.genericAnguloGrafDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoAnguloGrafEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4167) {
        this.genericAnguloGrafEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  RetornaConclusaoNull(texto) {
    return 'Exame dentro dos padrões da normalidade.';
  }

  TabelaDireita(): any {
    let strTable = '<style>' +
      'table {' +
      'width: 80%;' +
      '}' +
      'table, th, td {' +
      'border: 1px solid #c2c0c2;' +
      'border-collapse: collapse;' +
      'th, td {' +
      '  padding: 5px;' +
      '  text-align: left;' +
      '}' +
      '</style>' +
      '<table>';
    if (this.tabelaValorDireita[0]) {
      strTable += '  <tr>';
      strTable += '    <th></th>';
      strTable += '    <th>QUADRIL ' + this.Lado.toUpperCase(); + '</th>';
      strTable += '  </tr>';

    }
    if (this.tabelaValorDireita[0] || this.tabelaValorDireita[0]) {

      strTable += '  <tr>';
      strTable += '    <td><b>Ângulo Alfa</b></td>';
      strTable += '    <td>' + this.tabelaValorDireita[0] + ' °</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValorDireita[1] || this.tabelaValorDireita[1]) {

      strTable += '  <tr>';
      strTable += '    <td><b>Ângulo Beta</b></td>';
      strTable += '    <td>' + this.tabelaValorDireita[1] + ' °</td>';
      strTable += '  </tr>';
    }
    strTable += '</tr>' +
      '</table>';

    return strTable;
  }

  TabelaEsquerda(): any {
    let strTable = '<style>' +
      'table {' +
      'width: 80%;' +
      '}' +
      'table, th, td {' +
      'border: 1px solid #c2c0c2;' +
      'border-collapse: collapse;' +
      'th, td {' +
      '  padding: 5px;' +
      '  text-align: left;' +
      '}' +
      '</style>' +
      '<table>';
    if (this.tabelaValorEsquerda[0]) {
      strTable += '  <tr>';
      strTable += '    <th></th>';
      strTable += '    <th>QUADRIL ' + this.Lado.toUpperCase(); + '</th>';
      strTable += '  </tr>';

    }
    if (this.tabelaValorEsquerda[0] || this.tabelaValorEsquerda[0]) {

      strTable += '  <tr>';
      strTable += '    <td><b>Ângulo Alfa</b></td>';
      strTable += '    <td>' + this.tabelaValorEsquerda[0] + ' °</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValorEsquerda[1] || this.tabelaValorEsquerda[1]) {

      strTable += '  <tr>';
      strTable += '    <td><b>Ângulo Beta</b></td>';
      strTable += '    <td>' + this.tabelaValorEsquerda[1] + ' °</td>';
      strTable += '  </tr>';
    }
    strTable += '</tr>' +
      '</table>';

    return strTable;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else {
      this.AdicionaTextoDireita();
      this.AdicionaTextoEsquerda();
    }

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor(){
    let maskDireita = this.maskDireita.split('|');
    let maskEsquerda = this.maskEsquerda.split('|');

    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    //lado direito -- lado direito -- lado direito

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskDireita[0]+this.Lado.toUpperCase()}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao-D`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTextoDireita}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskDireita[1]}</p>`,
      }
    });

    this.descricoesDireita.forEach(descricao => {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}-D`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-${this.angulosGrafID}-D`,//,
        'name': ''
      },
      'data': {
        'text': `<p>${this.tabelaDireita}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskDireita[2]}</p>`,
      }
    });

    this.conclusoesDireita.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoesDireita[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao-D`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados-D`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTextoDireita}</p>`,
      }
    });

    //lado esquedo -- lado esquedo -- lado esquedo

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskEsquerda[0]+this.Lado.toUpperCase()}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao-E`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTextoEsquerda}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskEsquerda[1]}</p>`,
      }
    });

    this.descricoesEsquerda.forEach(descricao => {
              this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}-E`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-${this.angulosGrafID}-E`,//,
        'name': ''
      },
      'data': {
        'text': `<p>${this.tabelaEsquerda}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskEsquerda[2]}</p>`,
      }
    });

    this.conclusoesEsquerda.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoesEsquerda[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao-E`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados-E`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTextoEsquerda}</p>`,
      }
    });

   //Rodape
    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });
  }

  AdicionaTextoDireita() {

    let i;
    let maskSplit = this.maskDireita.split('|');
    let cabecalho = this.cabecalhoTexto;
    let rodape = this.rodapeTexto;
    let logo = this.infoLogotipo;

    this.htmlContentDireita = maskSplit[0];
    this.htmlContentDireita += this.Lado.toUpperCase();
    this.htmlContentDireita += maskSplit[1];
    this.htmlContentDireita += this.indicacaoTextoDireita;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContentDireita += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContentDireita += maskSplit[2];

    for (i = 0; i < this.descricoesDireita.length; i++) {
      if (this.descricoesDireita[i].value) {
        if (this.descricoesDireita[i].blockID === this.cabecaFemuralID && this.cabecaFemoralLuxada)
          this.htmlContentDireita += '';
        else
          this.htmlContentDireita += '<br/>' + this.descricoesDireita[i].value + '<br/>';
      }
    }

    // Tabela
    if (this.exibeTabela) {
      this.htmlContentDireita += '</br><b>ÂNGULOS DE GRAF</b>'
      this.htmlContentDireita += '</br>' + this.TabelaDireita();
    }

    this.htmlContentDireita += maskSplit[3];
    for (i = 0; i < this.conclusoesDireita.length; i++) {
      if (this.conclusoesDireita[i].value) {
        this.htmlContentDireita += '<br/>' + this.conclusoesDireita[i].value + '<br/>';
      }
    }
    this.htmlContentDireita += this.achadosAdTextoDireita;

    if (this.cabecalhoTexto !== '' && this.rodapeTexto === ''){
      if(this.infoLogotipo)
      this.htmlContent = logo + cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
      else
      this.htmlContent =  cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    }

    if (this.cabecalhoTexto === '' && this.rodapeTexto !== ''){
      if(this.infoLogotipo)
      this.htmlContent = logo + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
      else
      this.htmlContent =  this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    }

    if (this.cabecalhoTexto !== '' && this.rodapeTexto !== ''){
      if(this.infoLogotipo)
        this.htmlContent =  logo + cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
      else
        this.htmlContent =  cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    }

    if (this.cabecalhoTexto === '' && this.rodapeTexto === ''){
      if(this.infoLogotipo)
        this.htmlContent = logo + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
      else
        this.htmlContent = this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    }
  }

  AdicionaTextoEsquerda() {

    let i;
    let maskSplit = this.maskEsquerda.split('|');
    let cabecalho = this.cabecalhoTexto;
    let rodape = this.rodapeTexto;
    let logo = this.infoLogotipo;

    this.htmlContentEsquerda = maskSplit[0];
    this.htmlContentEsquerda += this.Lado.toUpperCase();
    this.htmlContentEsquerda += maskSplit[1];
    this.htmlContentEsquerda += this.indicacaoTextoEsquerda;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContentEsquerda += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContentEsquerda += maskSplit[2];

    for (i = 0; i < this.descricoesEsquerda.length; i++) {
      if (this.descricoesEsquerda[i].value) {
        if (this.descricoesEsquerda[i].blockID === this.cabecaFemuralID && this.cabecaFemoralLuxada)
          this.htmlContentEsquerda += '';
        else
          this.htmlContentEsquerda += '<br/>' + this.descricoesEsquerda[i].value + '<br/>';
      }
    }

    // Tabela
    if (this.exibeTabela) {
      this.htmlContentEsquerda += '</br><b>ÂNGULOS DE GRAF</b>'
      this.htmlContentEsquerda += '</br>' + this.TabelaEsquerda();
    }

    this.htmlContentEsquerda += maskSplit[3];
    for (i = 0; i < this.conclusoesEsquerda.length; i++) {
      if (this.conclusoesEsquerda[i].value) {
        this.htmlContentEsquerda += '<br/>' + this.conclusoesEsquerda[i].value + '<br/>';
      }
    }
    this.htmlContentEsquerda += this.achadosAdTextoEsquerda;

    if (this.cabecalhoTexto !== '' && this.rodapeTexto === ''){
      if(this.infoLogotipo)
      this.htmlContent = logo + cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
      else
      this.htmlContent =  cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    }

    if (this.cabecalhoTexto === '' && this.rodapeTexto !== ''){
      if(this.infoLogotipo)
      this.htmlContent = logo + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
      else
      this.htmlContent =  this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    }

    if (this.cabecalhoTexto !== '' && this.rodapeTexto !== ''){
      if(this.infoLogotipo)
        this.htmlContent =  logo + cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
      else
        this.htmlContent =  cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    }

    if (this.cabecalhoTexto === '' && this.rodapeTexto === ''){
      if(this.infoLogotipo)
        this.htmlContent = logo + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
      else
        this.htmlContent = this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    }
  }


  atualizaLinhaDireita({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
    tabela,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoesDireita.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId}-D p`);
        const conclusion = document.querySelector('#e-conclusao-D p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesDireita.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesDireita.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
        //tabela
        if (tabela !== undefined) {
          const table = document.querySelector(`#e-${blockId}-D`);

          if (table) {
            table.innerHTML = `<b>ÂNGULOS DE GRAF</b><br/>${tabela}<br/>`;
          }
        }

			} else if(indicacao !== undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const indicacaoTxt = document.querySelector(`#e-${blockId}-D p`);

          if(indicacaoTxt) {
            indicacaoTxt.innerHTML = indicacao;
          }
				}

        if(achados !== undefined) {
          const achadosTxt = document.querySelector(`#e-${blockId}-D p`);

          if(achadosTxt) {
						achadosTxt.innerHTML = achados;
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  atualizaLinhaEsquerda({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
    tabela,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoesEsquerda.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId}-E p`);
        const conclusion = document.querySelector('#e-conclusao-E p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesEsquerda.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesEsquerda.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
        //tabela
        if (tabela !== undefined) {
          const table = document.querySelector(`#e-${blockId}-E`);

          if (table) {
            table.innerHTML = `<b>ÂNGULOS DE GRAF</b><br/>${tabela}<br/>`;
          }
        }
			} else if(indicacao !== undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const indicacaoTxt = document.querySelector(`#e-${blockId}-E`);

          if(indicacaoTxt) {
            indicacaoTxt.innerHTML = indicacao;
          }
				}

        if(achados !== undefined) {
          const achadosTxt = document.querySelector(`#e-${blockId}-E`);

          if(achadosTxt) {
						achadosTxt.innerHTML = achados;
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  atualizaRodapeCabecalho({
		blockId,
    cabecalho,
    rodape,
	}: IAtualizaLinha): void {
    if(cabecalho !== undefined) {
      const header = document.querySelector(`#e-${blockId}`);

      if(header) {
        header.innerHTML = cabecalho;
      }
    }

    if(rodape !== undefined) {
      const footer = document.querySelector(`#e-${blockId}`);

      if(footer) {
        footer.innerHTML = rodape;
      }
    }
    this._addText.lineHeight();
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  retornaConclusaoNull(Texto) {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if (formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
