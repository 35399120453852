import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { AuthService } from './../../auth/auth.service';
import { UserService } from 'src/app/services/user.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termos-confirmacao',
  templateUrl: './termos-confirmacao.component.html',
  styleUrls: ['./termos-confirmacao.component.css']
})
export class TermosConfirmacaoComponent implements OnInit {

  userID: number;
  Ip: any;
  termsAccepted: boolean = false;
  public loading: boolean = true;
  $usuario: any;
  userHasTermsAccepted: boolean = false;

  constructor(
    private _userService: UserService,
    private _authService: AuthService,
    private route: Router,
    private toastr: ToastrManager) { }

  ngOnInit() {
    this.userID = +localStorage.getItem('userID');
    this.getIP();
    this._userService.buscarUsuario(this.userID).subscribe((usuario) => {
      this.$usuario = usuario;
      this.userHasTermsAccepted = this.$usuario.acceptedTerms;
      this.loading = false;
    });
  }

   logout() {
    this._authService.logout();
  }

  sendTermsConfirmation(){
    let obj = {
      "UserId": this.userID,
      "Ip": this.Ip
    };
    this._userService.enviaTermosConfirmacao(obj).subscribe(
      res => {
          this._userService.termAccepted.next(true);
          this.route.navigate(['/laudos']);
      }, err =>{
        this._userService.termAccepted.next(false);
        this.toastr.errorToastr('Ocorreu um erro', 'Por favor, tente de novo.');
        console.log(err);
      }
    );
  }

  acceptTerms(){
    this.termsAccepted == false ? this.termsAccepted = true : this.termsAccepted = false;
  }

  async getIP(){
    let ip = await this._authService.getIPAddress();
    if(ip){
      this.Ip = ip
      }else{
        this.Ip = null;
      }
  }

  goToApp(){
    this.route.navigate(['/laudos']);
  }
}
