import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckbox } from '@angular/material';

@Component({
  selector: 'app-crescimento-fetal',
  templateUrl: './crescimento-fetal.component.html',
  styleUrls: ['./crescimento-fetal.component.css']
})
export class CrescimentoFetalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() camposObrigatoriosPreenchidos = new EventEmitter();


  @ViewChild('inDBP') inDBP: ElementRef;
  @ViewChild('inDOF') inDOF: ElementRef;
  @ViewChild('inCC') inCC: ElementRef;
  @ViewChild('inCA') inCA: ElementRef;
  @ViewChild('inCUM') inCUM: ElementRef;
  @ViewChild('inCF') inCF: ElementRef;
  @ViewChild('inPeso') inPeso: ElementRef;
  @ViewChild('ckSemPreencimento') ckSemPreencimento: MatCheckbox;

  showAndHideTerms: any;

  unidadeMedida: string;
  peso!:FormControl;
  inPesoInvalid: boolean = true;
  inCFInvalid: boolean = true;
  inDBPInvalid: boolean = true;
  inCAInvalid: boolean = true;
  inCCInvalid: boolean = true;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahFormulario: true
    }
    this.unidadeMedida = 'mm';
  }

  clickSemPreenchimento(){
    if (this.showAndHideTerms.sahFormulario === false) {
      this.showAndHideTerms.sahFormulario = true;
    } else {
      this.showAndHideTerms.sahFormulario = false;
    }
    this.catchIDs();
  }

  blurParametros() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    this.removeInvalidField();

    if(this.ckSemPreencimento && this.ckSemPreencimento.checked){
      this.termoSelecionado.emit(this.ckSemPreencimento.id);
    } else{
      if(this.inDBP && this.inDBP.nativeElement.value){
        const objeto = { elemento: this.inDBP.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if(this.inDOF && this.inDOF.nativeElement.value){
        const objeto = { elemento: this.inDOF.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if(this.inCC && this.inCC.nativeElement.value){
        const objeto = { elemento: this.inCC.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if(this.inCA && this.inCA.nativeElement.value){
        const objeto = { elemento: this.inCA.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if(this.inCUM && this.inCUM.nativeElement.value){
        const objeto = { elemento: this.inCUM.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if(this.inCF && this.inCF.nativeElement.value){
        const objeto = { elemento: this.inCF.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if(this.inPeso && this.inPeso.nativeElement.value){
        const objeto = { elemento: this.inPeso.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if((this.inCF && this.inCF.nativeElement.value)
    &&(this.inDBP && this.inDBP.nativeElement.value)
    &&(this.inCA && this.inCA.nativeElement.value)
    &&(this.inCC && this.inCC.nativeElement.value)
    &&(this.inPeso && this.inPeso.nativeElement.value)){
      this.camposObrigatoriosPreenchidos.emit({ativo: true, nome:'avaliacaoCrescimentoFetal'});
    }else{
      this.camposObrigatoriosPreenchidos.emit({ativo: false, nome:'avaliacaoCrescimentoFetal'});
    }

    this.listaCompleta.emit();
  }

  removeInvalidField(){
    if(this.inPeso && this.inPeso.nativeElement.value){
      this.inPesoInvalid = false;
    }else{
      this.inPesoInvalid = true;
    }
    if(this.inCF && this.inCF.nativeElement.value){
      this.inCFInvalid = false;
    }else{
      this.inCFInvalid = true;
    }
    if(this.inDBP && this.inDBP.nativeElement.value){
      this.inDBPInvalid = false;
    }else{
      this.inDBPInvalid = true;
    }
    if(this.inCA && this.inCA.nativeElement.value){
      this.inCAInvalid = false;
    }else{
      this.inCAInvalid = true;
    }
    if(this.inCC && this.inCC.nativeElement.value){
      this.inCCInvalid = false;
    }else{
      this.inCCInvalid = true;
    }
  }
}
