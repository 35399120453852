import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Injuries, Term } from 'src/app/models/BO/Term';
import { TermResult } from 'src/app/models/BO/TermResult';
import { CopyPaste } from 'src/app/utils/textEditor/CopyPaste';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { SelectedItemEmiter } from 'src/app/utils/SelectedItemEmiter';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { TermosGenericos } from 'src/app/models/Helper/TermosGenericos';
import { Validacoes } from 'src/app/utils/validacoes';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';
import { ControladorImprimirService } from 'src/app/services/controladorImprimir.service';
import { ConfigClinicaService } from 'src/app/services/config-clinica.service';

@Component({
  selector: 'app-usg-morfologica-primeiro-trimestre',
  templateUrl: './usg-morfologica-primeiro-trimestre.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./usg-morfologica-primeiro-trimestre.component.css']
})
export class UsgMorfologicaPrimeiroTrimestreComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[4776],blockID:301},
    {listTerm:[4693],blockID:302},
    {listTerm:[4702],blockID:303},
    {listTerm:[4366],blockID:304},
    {listTerm:[2010],blockID:181},
    {listTerm:[4703],blockID:306},
    {listTerm:[1973],blockID:307},
    {listTerm:[4393],blockID:308},
  ]

  descricoes: DescricaoConclusao[] = [
    {
      value: '<b>HISTÓRIA CLÍNICA</b></br>' +
        'Idade materna: ** anos.</br>' +
        'Peso materno: ** kg.</br>' +
        'Etnia: **.</br>' +
        'Método de concepção: **.</br>' +
        'Fumante: **.</br>' +
        'Histórico de trissomias em gestações anteriores: **.</br>' +
        'Diabetes tipo I: **.</br>' +
        'Diabetes tipo II: **.</br>' +
        'História obstétrica: **.', blockID: 301, textTermID: 0, visivel: true
    },
    {
      value:
        'Comprimento cabeça-nádegas (CCN): ** mm.</br>' +
        'Translucência nucal (NT): ** mm.</br>' +
        'Translucência intra craniana: **.</br>' +
        'Osso nasal: **.</br>' +
        'Ducto venoso com onda A ** e índice de pulsatilidade (IP) de **, no percentil **.</br>' +
        'Regurgitação tricúspide: .</br>' +
        'Batimentos cardíacos fetais (BCF): ** bpm.</br>' +
        'Líquido amniótico: **', blockID: 302, textTermID: 0, visivel: true
    },
    {
      value: '<b>AVALIAÇÃO DO CRESCIMENTO FETAL</b></br>' +
        'Diâmetro biparietal (DBP): ** mm.</br>' +
        'Diâmetro occiptofrontal (DOF): ** mm.</br>' +
        'Circunferência cefálica (CC): ** mm.</br>' +
        'Circunferência abdominal (CA): ** mm.</br>' +
        'Comprimento umeral (CUM): ** mm.</br>' +
        'Comprimento femoral (CF): ** mm.</br>' +
        'Peso fetal em torno de ** gramas (+/- 15%).', blockID: 303, textTermID: 0, visivel: true
    },
    {
      value: '<b>ANATOMIA FETAL</b></br>' +
        'Crânio / cérebro: **</br>' +
        'Coluna: **</br>' +
        'Coração: **</br>' +
        'Abdome: **</br>' +
        'Estômago: **</br>' +
        'Bexiga / rins: **</br>' +
        'Mãos: **</br>' +
        'Pés: **', blockID: 304, textTermID: 0, visivel: true
    },
    {
      value: '<b>PLACENTA</b></br>' +
        'Localização ***, com aspecto compatível com grau *** de Grannum.', blockID: 181, textTermID: 0, visivel: true
    },
    {
      value: '<b>CORDÃO UMBILICAL</b></br>' +
        'Inserção fetal e placentária, preservadas. Presença de 2 artérias e 1 veia.', blockID: 306, textTermID: 0, visivel: true
    },
    {
      value: '<b>CÁLCULO DE RISCO PARA ANEUPLOIDIAS</b></br>' +
        'O risco basal para aneuploidias é baseado na idade materna.' +
        ' O risco corrigido é o risco no momento do rastreio, calculado a partir do risco basal e fatores ultrassonográficos. Foi efetuado usando o algoritmo da ' +
        '<i>Fetal Medicine Foundation.</i>', blockID: 307, textTermID: 0, visivel: true
    },
    { value: '', blockID: 308, textTermID: 0, visivel: true }
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: 'Gestação única, tópica, de concepto vivo.</br>Biometria atual compatível com ** semanas e ** dia (variação de até +/- 5 dias) (Hadlock et al).', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 301, textTermID: 0, visivel: true },
    { value: '', blockID: 302, textTermID: 0, visivel: true },
    { value: '', blockID: 303, textTermID: 0, visivel: true },
    { value: '', blockID: 304, textTermID: 0, visivel: true },
    { value: '', blockID: 181, textTermID: 0, visivel: true },
    { value: '', blockID: 306, textTermID: 0, visivel: true },
    { value: '', blockID: 307, textTermID: 0, visivel: true },
    { value: '', blockID: 308, textTermID: 0, visivel: true }
  ];

  genericHistoriaClinica: TermosGenericos[] = [
    { value: '', selected: false, termID: 4356, term: '[Idade]' },
    { value: '', selected: false, termID: 4357, term: '[Peso]' },
    { value: '', selected: false, termID: 4358, term: '[Etnia]' },
    { value: '', selected: false, termID: 4359, term: '[Concepcao]' },
    { value: '', selected: false, termID: 4360, term: '[Fumante]' },
    { value: '', selected: false, termID: 4361, term: '[HistoricoTrissomia]' },
    { value: '', selected: false, termID: 4362, term: '[Trissomia]' },
    { value: '', selected: false, termID: 4363, term: '[Diabetes1]' },
    { value: '', selected: false, termID: 4364, term: '[Diabetes2]' },
    { value: '', selected: false, termID: 4365, term: '[Historia]' }
  ];

  genericMedidasPrimeiroTri: TermosGenericos[] = [
    { value: '', selected: false, termID: 4374, term: '[CCN]' },
    { value: '', selected: false, termID: 4375, term: '[NT]' },
    { value: '', selected: false, termID: 4376, term: '[NTPercentil]' },
    { value: '', selected: false, termID: 4377, term: '[TraIntraCraniana]' },
    { value: '', selected: false, termID: 4378, term: '[OssoNasal]' },
    { value: '', selected: false, termID: 4701, term: '[OssoNasalMedida]' },
    { value: '', selected: false, termID: 4379, term: '[DuctoVenoso]' },
    { value: '', selected: false, termID: 4380, term: '[IP]' },
    { value: '', selected: false, termID: 4381, term: '[IPPercentil]' },
    { value: '', selected: false, termID: 4382, term: '[Regurgitacao]' },
    { value: '', selected: false, termID: 4383, term: '[BCF]' },
    { value: '', selected: false, termID: 4384, term: '[Liquido]' },
    { value: '', selected: false, termID: 4397, term: '[CalculoCCN]' }
  ];

  genericCrescimentoFetal: TermosGenericos[] = [
    { value: '', selected: false, termID: 4385, term: '[DBP]' },
    { value: '', selected: false, termID: 4386, term: '[DOF]' },
    { value: '', selected: false, termID: 4387, term: '[CC]' },
    { value: '', selected: false, termID: 4388, term: '[CA]' },
    { value: '', selected: false, termID: 4389, term: '[CUM]' },
    { value: '', selected: false, termID: 4390, term: '[CF]' },
    { value: '', selected: false, termID: 4391, term: '[Peso]' }
  ];

  genericAnatomiaFetal: TermosGenericos[] = [
    { value: '', selected: false, termID: 4366, term: '[Cranio]' },
    { value: '', selected: false, termID: 4367, term: '[Coluna]' },
    { value: '', selected: false, termID: 4368, term: '[Coracao]' },
    { value: '', selected: false, termID: 4369, term: '[Abdome]' },
    { value: '', selected: false, termID: 4370, term: '[Estomago]' },
    { value: '', selected: false, termID: 4371, term: '[BexRins]' },
    { value: '', selected: false, termID: 4372, term: '[Maos]' },
    { value: '', selected: false, termID: 4373, term: '[Pes]' }
  ];

  genericPlacenta: TermosGenericos[] = [
    { value: '', selected: false, termID: 1996, term: '[localizacao]' },
    { value: '', selected: false, termID: 1997, term: '[grau]' },
    { value: '', selected: false, termID: 1998, term: '[espessura]' }
  ];

  genericRisco: TermosGenericos[] = [
    { value: '', selected: false, termID: 4392, term: '[RiscoBaixo]' },
    { value: '', selected: false, termID: 4420, term: '[RiscoIntermediario]' },
    { value: '', selected: false, termID: 4421, term: '[RiscoAlto]' }
  ];

  genericCordaoUmbilical: TermosGenericos[] = [
    { value: '', selected: false, termID: 4706, term: '[InsercaoCordao]' }
  ];

  tabelaValor: Array<string> = [
    "XXXX", "XXXX", "XXXX", "XXXX", "XXXX", "XXXX"
  ]

  htmlContent: string;
  Subtitulo: string;
  subtituloBloco:string;
  mask: string;
  term = new Term();
  injuries = new Injuries();
  objetoGrupoLesao: any;

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  cabecalhoTexto = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  calculadoraCcnTexto = '';
  calcFrase = '';
  calcConclusao = '';
  cenarioCorrente = 0;
  // tabelaValor = new Array<string>();
  rodapeTexto = '';

  cabecalho = false;
  indicacao = false;
  calculadora = true;
  historiaClinica = false;
  medidasPrimeiroTri = false;
  avaliacaoCrescimentoFetal = false;
  anatomiaFetal = false;
  placenta = false;
  cordaoUmbilical = false;
  calculoRiscoAneuploidias = false;
  orientacoesAdicionais = false;
  rodape = false;

  medicalReportTypeID = 53;
  historiaClinicaID = 301;
  medidasPrimeiroTriID = 302;
  avaliacaoCrescimentoFetalID = 303;
  anatomiaFetalID = 304;
  placentaID = 181;
  cordaoUmbilicalID = 306;
  calculoRiscoAneuploidiasID = 307;
  orientacoesAdicionaisID = 308;
  public showForm: Boolean = true;
  infoLogotipo:string|any= '';
  public switchFormReport = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  editLaudoMode: boolean = false;

  unidadeMedida: string;
  impressaoAtivaCrescimentoFetal:boolean = false;
  impressaoMedidas:boolean = false;
  impressaoAtivaPlacenta: boolean = false;

  constructor(
    public _selected: SelectedItemEmiter,
    private _validacoes: Validacoes,
    private _toastr: ToastrManager,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _copyPaste: CopyPaste,
    private _dialog: MatDialog,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _configClinica:ConfigClinicaService,
    public _addText: AddText,
    private dataService:DataTermService,
    private _config: ConfigLaudoService,
    private _controlImprimir:ControladorImprimirService
  ) { }

  ngOnInit() {
    this.utilizarEditor();
    this._toastr.warningToastr('Atenção esse exame ainda está em beta, caso venha a ocorrer algum problema reporte ao nosso suporte.', 'Atenção!');
    this._pouchService.loadDatabase("usgMorfologicaAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Calculadora IG';
    this.subtituloBloco = this.Subtitulo;
    let configClinicaRule = JSON.parse(localStorage.getItem('configClinicaRule'));
    if(configClinicaRule && configClinicaRule.active){
      this._configClinica.handleSelectUserClinic();
      this._configClinica.maskClinica.subscribe(res=>{
        this.infoLogotipo=res;
        this.AdicionaTexto(this.newEditor);
      })
    };

    const listBlocks = [
      this.historiaClinicaID,
      this.medidasPrimeiroTriID,
      this.avaliacaoCrescimentoFetalID,
      this.anatomiaFetalID,
      this.placentaID,
      this.cordaoUmbilicalID,
      this.calculoRiscoAneuploidiasID,
      this.orientacoesAdicionaisID,
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "usgMorfologicaMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "morfoPrim");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'usgMorfologicaPrimeiroTrimestre');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'usgMorfologicaPrimeiroTrimestre');
      }
    });
    this.unidadeMedida = 'mm'
  }
  ngAfterViewInit(): void {
    this._controlImprimir.desabilitarImprimir();
  }

  ngOnDestroy(): void {
    this._controlImprimir.habilitarImprimir();
  }

  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetaLaudo();
            this._controlImprimir.desabilitarImprimir();
          }
        });
      }
    });
  };

  public resetaLaudo() {
    this._pouchService.getAll("usgMorfologicaAnalise").then((obj) => {
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.calcFrase = "";
    this.rodapeTexto = "";
    this.calcConclusao = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  blocos = [
    {
      titulo: 'Informações',
      id: 'cabecalho',
      name: 'cabecalho',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Indicação',
      id: 'indicacao',
      name: 'indicacao',
      subitulo:'Preencha o campo indicação',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Calculadora IG',
      id: 'calculadora-idade-gestacional',
      name: 'calculadora-idade-gestacional',
      selected: true,
      blocked:false
    },
    {
      titulo: 'História Clínica',
      id: this.historiaClinicaID,
      name: 'historia-clinica',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Medidas do Primeiro Trimestre',
      id: this.medidasPrimeiroTriID,
      name: 'medidas-primeiro-trimestre',
      selected: false,
      blocked: true
    },
    {
      titulo: 'Avaliação do Crescimento Fetal',
      id: this.avaliacaoCrescimentoFetalID,
      name: 'avaliacao-crescimento-fetal',
      selected: false,
      blocked: true
    },
    {
      titulo: 'Anatomia Fetal',
      id: this.anatomiaFetalID,
      name: 'anatomia-fetal',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Placenta',
      id: this.placentaID,
      name: 'placenta',
      selected: false,
      blocked: true
    },
    {
      titulo: 'Cordão Umbilical',
      id: this.cordaoUmbilicalID,
      name: 'cordao-umbilical',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Calculo de Risco Para Aneuploidias',
      id: this.calculoRiscoAneuploidiasID,
      name: 'calculo-risco-aneuploidias',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Orientações Adicionais',
      id: this.orientacoesAdicionaisID,
      name: 'orientacoes-adicionais',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
      blocked:false
    }
  ]

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if (itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }

    this.cabecalho =  (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.calculadora = (itemSelecionado.id === 'calculadora-idade-gestacional');
    this.rodape =  (itemSelecionado.id === 'rodape');
    this.historiaClinica = (itemSelecionado.id === this.historiaClinicaID);
    this.medidasPrimeiroTri = (itemSelecionado.id === this.medidasPrimeiroTriID);
    this.avaliacaoCrescimentoFetal = (itemSelecionado.id === this.avaliacaoCrescimentoFetalID);
    this.anatomiaFetal = (itemSelecionado.id === this.anatomiaFetalID);
    this.placenta = (itemSelecionado.id === this.placentaID);
    this.cordaoUmbilical = (itemSelecionado.id === this.cordaoUmbilicalID);
    this.calculoRiscoAneuploidias = (itemSelecionado.id === this.calculoRiscoAneuploidiasID);
    this.orientacoesAdicionais = (itemSelecionado.id === this.orientacoesAdicionaisID);

    this.AdicionaTexto(this.newEditor);
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();

    this.genericHistoriaClinica = [
      { value: '', selected: false, termID: 4356, term: '[Idade]' },
      { value: '', selected: false, termID: 4357, term: '[Peso]' },
      { value: '', selected: false, termID: 4358, term: '[Etnia]' },
      { value: '', selected: false, termID: 4359, term: '[Concepcao]' },
      { value: '', selected: false, termID: 4360, term: '[Fumante]' },
      { value: '', selected: false, termID: 4361, term: '[HistoricoTrissomia]' },
      { value: '', selected: false, termID: 4362, term: '[Trissomia]' },
      { value: '', selected: false, termID: 4363, term: '[Diabetes1]' },
      { value: '', selected: false, termID: 4364, term: '[Diabetes2]' },
      { value: '', selected: false, termID: 4365, term: '[Historia]' }
    ];

    this.genericPlacenta = [
      { value: '', selected: false, termID: 1996, term: '[localizacao]' },
      { value: '', selected: false, termID: 1997, term: '[grau]' },
      { value: '', selected: false, termID: 1998, term: '[espessura]' }
    ];


    this.genericMedidasPrimeiroTri = [
      { value: '', selected: false, termID: 4374, term: '[CCN]' },
      { value: '', selected: false, termID: 4375, term: '[NT]' },
      { value: '', selected: false, termID: 4376, term: '[NTPercentil]' },
      { value: '', selected: false, termID: 4377, term: '[TraIntraCraniana]' },
      { value: '', selected: false, termID: 4378, term: '[OssoNasal]' },
      { value: '', selected: false, termID: 4701, term: '[OssoNasalMedida]' },
      { value: '', selected: false, termID: 4379, term: '[DuctoVenoso]' },
      { value: '', selected: false, termID: 4380, term: '[IP]' },
      { value: '', selected: false, termID: 4381, term: '[IPPercentil]' },
      { value: '', selected: false, termID: 4382, term: '[Regurgitacao]' },
      { value: '', selected: false, termID: 4383, term: '[BCF]' },
      { value: '', selected: false, termID: 4384, term: '[Liquido]' },
      { value: '', selected: false, termID: 4397, term: '[CalculoCCN]' }
    ];

    this.genericCrescimentoFetal = [
      { value: '', selected: false, termID: 4385, term: '[DBP]' },
      { value: '', selected: false, termID: 4386, term: '[DOF]' },
      { value: '', selected: false, termID: 4387, term: '[CC]' },
      { value: '', selected: false, termID: 4388, term: '[CA]' },
      { value: '', selected: false, termID: 4389, term: '[CUM]' },
      { value: '', selected: false, termID: 4390, term: '[CF]' },
      { value: '', selected: false, termID: 4391, term: '[Peso]' }
    ];

    this.genericAnatomiaFetal = [
      { value: '', selected: false, termID: 4366, term: '[Cranio]' },
      { value: '', selected: false, termID: 4367, term: '[Coluna]' },
      { value: '', selected: false, termID: 4368, term: '[Coracao]' },
      { value: '', selected: false, termID: 4369, term: '[Abdome]' },
      { value: '', selected: false, termID: 4370, term: '[Estomago]' },
      { value: '', selected: false, termID: 4371, term: '[BexRins]' },
      { value: '', selected: false, termID: 4372, term: '[Maos]' },
      { value: '', selected: false, termID: 4373, term: '[Pes]' }
    ];

    this.genericRisco = [
      { value: '', selected: false, termID: 4392, term: '[RiscoBaixo]' },
      { value: '', selected: false, termID: 4420, term: '[RiscoIntermediario]' },
      { value: '', selected: false, termID: 4421, term: '[RiscoAlto]' }
    ];

    this.genericCordaoUmbilical = [
      { value: '', selected: false, termID: 4706, term: '[InsercaoCordao]' }
    ];
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaCalculadora($event) {
    this.calcFrase = $event[0].toString();
    this.calcConclusao = $event[1].toString();
    this.AdicionaTexto(this.newEditor);
  }

  enviaCalculadoraCcn($event) {
    if ($event)
      this.calculadoraCcnTexto = $event;
    else
      this.calculadoraCcnTexto = '';
    this.AdicionaTexto(this.newEditor);
  }

  adicionaSelecaoHistoriaClinica($event) {
    this.genericHistoriaClinica.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoAnatomia($event) {
    this.genericAnatomiaFetal.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoRisco($event) {
    this.genericRisco.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoMedidasPrimeiroTri($event) {
    this.genericMedidasPrimeiroTri.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoCrescimentoFetal($event) {
    this.genericCrescimentoFetal.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }
  adicionaSelecaoPlacenta($event) {
    this.genericPlacenta.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoCordaoUmbilical($event) {
    this.genericCordaoUmbilical.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  RetornaConclusaoNull(Texto) {
    let texto = 'Gestação única, tópica, de concepto vivo.</br>Biometria atual compatível com ** semanas e ** dia (variação de até +/- 5 dias) (Hadlock et al).';
    return texto;
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente === 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario === 0 || $event.cenario === this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });
      console.log(injuryToAdd);

      if (injuryToAdd.listInjuries.length > 0)
        this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.historiaClinica) {
        this.term.blockID = this.historiaClinicaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.historiaClinicaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + this.MontaDescricaoHistoriaClinica(resultado.text);
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.historiaClinicaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.historiaClinicaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.historiaClinicaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.historiaClinicaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.historiaClinicaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.medidasPrimeiroTri) {
        this.term.blockID = this.medidasPrimeiroTriID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.medidasPrimeiroTriID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                if (resultado.text) {
                  obj.value = obj.value + espaco + this.MontaDescricaoMedidasPrimeiroTri(resultado.text, contadorTexto);
                  espaco = '<br/>';
                }
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.medidasPrimeiroTriID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.medidasPrimeiroTriID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoMedidasPrimeiroTri(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.medidasPrimeiroTriID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.medidasPrimeiroTriID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.medidasPrimeiroTriID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.avaliacaoCrescimentoFetal) {
        this.term.blockID = this.avaliacaoCrescimentoFetalID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.avaliacaoCrescimentoFetalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + this.MontaDescricaoCrescimentoFetal(resultado.text);;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.avaliacaoCrescimentoFetalID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.avaliacaoCrescimentoFetalID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.avaliacaoCrescimentoFetalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.avaliacaoCrescimentoFetalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.avaliacaoCrescimentoFetalID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.anatomiaFetal) {
        this.term.blockID = this.anatomiaFetalID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.anatomiaFetalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + this.MontaDescricaoAnatomia(resultado.text);
                contadorTexto++;
              });

              const index = this.descricoes.findIndex(x => x.blockID === this.anatomiaFetalID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });
          let obj = {
            value: '',
            blockID: this.anatomiaFetalID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoAnatomia(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.anatomiaFetalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.anatomiaFetalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.anatomiaFetalID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.placenta) {
        this.term.blockID = this.placentaID;

        this.term.injuries.push(this.injuries);

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.placentaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoPlacenta(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.placentaID);
              if (index > -1) {
                this.descricoes[index].value = '<b>PLACENTA</b></br>' + obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.placentaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoPlacenta(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.placentaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.placentaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.placentaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.cordaoUmbilical) {
        this.term.blockID = this.cordaoUmbilicalID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.cordaoUmbilicalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + this.MontaDescricaoCordaoUmbilical(resultado.text);
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.cordaoUmbilicalID);
              if (index > -1) {
                if (obj.value)
                  this.descricoes[index].value = '<b>CORDÃO UMBILICAL</b></br>' + obj.value;
                else
                  this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });
          let obj = {
            value: '',
            blockID: this.cordaoUmbilicalID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.cordaoUmbilicalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.cordaoUmbilicalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.cordaoUmbilicalID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.orientacoesAdicionais) {
        this.term.blockID = this.orientacoesAdicionaisID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.orientacoesAdicionaisID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + resultado.text;
                espaco = '</br>';
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.orientacoesAdicionaisID);
              if (index > -1) {
                  this.descricoes[index].value = obj.value;
                  this.descricoes[index].textTermID = obj.textTermID;
                  desc = obj.value;
              }
            }
          });
          let obj = {
            value: '',
            blockID: this.orientacoesAdicionaisID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.orientacoesAdicionaisID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.orientacoesAdicionaisID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.orientacoesAdicionaisID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.calculoRiscoAneuploidias) {

        this.term.blockID = this.calculoRiscoAneuploidiasID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.calculoRiscoAneuploidiasID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoRisco(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.calculoRiscoAneuploidiasID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.calculoRiscoAneuploidiasID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoRisco(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.calculoRiscoAneuploidiasID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.calculoRiscoAneuploidiasID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.calculoRiscoAneuploidiasID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }


  MontaDescricaoHistoriaClinica(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4356 || +term === 4357 || +term === 4358 || +term === 4359 || +term === 4360
        || +term === 4361 || +term === 4362 || +term === 4363 || +term === 4364 || +term === 4365) {
        this.genericHistoriaClinica.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoAnatomia(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4366 || +term === 4367 || +term === 4368 || +term === 4369
        || +term === 4370 || +term === 4371 || +term === 4372 || +term === 4373) {
        this.genericAnatomiaFetal.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoAnatomia(texto) {
    let textoRetorno = '';
    var termosGenericos = [];

    this.term.listTermID.forEach(term => {
      if (+term === 4366 || +term === 4367 || +term === 4368 || +term === 4369
        || +term === 4370 || +term === 4371 || +term === 4372 || +term === 4373) {
        this.genericAnatomiaFetal.forEach(generic => {
          if (+generic.termID === +term) {
            termosGenericos.push(generic.value);
          }
        });
      }
    });

    if (termosGenericos[0] === 'Normal' && termosGenericos[1] === 'Normal' && termosGenericos[2] === 'Corte de 4 câmaras aparentemente normal'
      && termosGenericos[3] === 'Normal' && termosGenericos[4] === 'Normal' && termosGenericos[5] === 'Visíveis'
      && termosGenericos[6] === 'Ambas visualizadas' && termosGenericos[7] === 'Ambos visualizados' ) {
      texto = 'Gestação única, tópica, de concepto vivo.<br/>Biometria atual compatível com ** semanas e ** dia (variação de até +/- 5 dias) (Hadlock et al).';
    } else {
      this.genericAnatomiaFetal.forEach(generic => {
        termosGenericos.push(generic.value);
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
        });
      }

    return texto;
  }

  MontaDescricaoMedidasPrimeiroTri(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (+term === 4374 || +term === 4375 || +term === 4376 || +term === 4377 || +term === 4378 || +term === 4379
          || +term === 4380 || +term === 4381 || +term === 4382 || +term === 4383 || +term === 4384 || +term === 4701) {
          this.genericMedidasPrimeiroTri.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4374 || +term === 4375 || +term === 4376 || +term === 4377 || +term === 4378 || +term === 4379
          || +term === 4380 || +term === 4381 || +term === 4382 || +term === 4383 || +term === 4384 || +term === 4701) {
          this.genericMedidasPrimeiroTri.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaConclusaoMedidasPrimeiroTri(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (+term === 4397) {
          this.genericMedidasPrimeiroTri.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4397) {
          this.genericMedidasPrimeiroTri.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoCrescimentoFetal(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4385 || +term === 4386 || +term === 4387 || +term === 4388
        || +term === 4389 || +term === 4390 || +term === 4391) {
        this.genericCrescimentoFetal.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoRisco(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4392 || +term === 4420 || +term === 4421) {
        this.genericRisco.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoPlacenta(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1996 || +term === 1997 || +term === 1998) {
        this.genericPlacenta.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoPlacenta(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1996 || +term === 1997 || +term === 1998) {
        this.genericPlacenta.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoCordaoUmbilical(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4703 || +term === 4704 || +term === 4705 || +term === 4706 || +term === 4778) {
        this.genericCordaoUmbilical.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  atribuiValorTabela($element) {
    const value = $element.value;

    if ($element.id === 'A') {
      this.tabelaValor[0] = value;
    } else if ($element.id === 'B') {
      this.tabelaValor[1] = value;
    } else if ($element.id === 'C') {
      this.tabelaValor[2] = value;
    } else if ($element.id === 'D') {
      this.tabelaValor[3] = value;
    } else if ($element.id === 'E') {
      this.tabelaValor[4] = value;
    } else if ($element.id === 'F') {
      this.tabelaValor[5] = value;
    }
    this.AdicionaTexto(this.newEditor);
  }

  TabelaAneuploidias(): any {
    let strTable = '<style>' +
      'table {' +
      '   width: 80%;' +
      '   text-align: center;' +
      '}' +
      'table, th, td {' +
      '   border: 1px solid #c2c0c2;' +
      '   border-collapse: collapse;' +
      'th, td {' +
      '   padding: 5px;' +
      '}' +
      '</style>' +
      '<table>';
    strTable += '  <tr>';
    strTable += '    <th><b>Risco</b></th>';
    strTable += '    <th><b>Trissomia do 21</b></th>';
    strTable += '    <th><b>Trissomia do 18</b></th>';
    strTable += '    <th><b>Trissomia do 13</b></th>';
    strTable += '  </tr>';
    strTable += '  <tr>';
    strTable += '    <td><b>Basal </b></td>';
    strTable += '    <td> 1:' + this.tabelaValor[0] + ' </td>';
    strTable += '    <td> 1:' + this.tabelaValor[1] + ' </td>';
    strTable += '    <td> 1:' + this.tabelaValor[2] + ' </td>';
    strTable += '  </tr>';
    strTable += '  <tr>';
    strTable += '    <td><b>Calculado </b></td>';
    strTable += '    <td> 1:' + this.tabelaValor[3] + ' </td>';
    strTable += '    <td> 1:' + this.tabelaValor[4] + ' </td>';
    strTable += '    <td> 1:' + this.tabelaValor[5] + ' </td>';
    strTable += '  </tr>';

    strTable += '</tr>' +
      '</table>';

    return strTable;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor(){
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {

      if (descricao.blockID !== 308 && descricao.blockID !== 302) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
      }
      if (descricao.blockID == 302) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p><b>MEDIDAS DO PRIMEIRO TRIMESTRE</b></br>${descricao.value}</p>`,
          }
        });
      }

      if(descricao.blockID == 307){
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p>${this.TabelaAneuploidias()}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoes[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    if (this.descricoes[7].blockID == 308 && this.descricoes[7].value !== '') {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'data': {
          'text': `<p>${maskSplit[3]}</p>`,
        }
      });
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-308`,
          'name': ''
        },
        'data': {
          'text': `<p>Sugiro, de forma complementar:<br/>${this.descricoes[7].value}</p>`,
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|')

    if(this.infoLogotipo !== '' && this.cabecalhoTexto !== ''){
      this.htmlContent = this.infoLogotipo;
      this.htmlContent += this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo !== '' && this.cabecalhoTexto == ''){
      this.htmlContent = this.infoLogotipo;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo == '' && this.cabecalhoTexto !== ''){
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo == '' && this.cabecalhoTexto == ''){
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.indicacaoTexto;
    if (this.calcFrase) {
      this.htmlContent += '<br/>';
      this.htmlContent += this.calcFrase;
      this.htmlContent += '<br/>';
    }

    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '</br><b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>';

    this.htmlContent += '</br></br>' + maskSplit[1];

    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value && this.descricoes[i].value !== '' && this.descricoes[i].blockID !== 308 && this.descricoes[i].blockID !== 302) {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
      }
      if (this.descricoes[i].blockID == 302) {
        this.htmlContent += '<br/>' + '<b>MEDIDAS DO PRIMEIRO TRIMESTRE</b></br>' + this.descricoes[i].value + '<br/>';
      }

      if (this.descricoes[i].blockID == 307) {
        this.htmlContent += '<br/>' + this.TabelaAneuploidias() + '<br/>';
      }
    }


    this.htmlContent += maskSplit[2];

    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        if (this.conclusoes[i].blockID == 302 && this.calcConclusao) {
          this.htmlContent += '<br/>' + this.calcConclusao;
          this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
        } else {
          this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
        }
      }
    }

    //Orientação Adcional
    if (this.descricoes[7].blockID == 308 && this.descricoes[7].value !== '') {
      this.htmlContent += maskSplit[3];
      this.htmlContent += '<br/>Sugiro, de forma complementar:<br/>' + this.descricoes[7].value + '<br/ > ';
    }

    this.htmlContent += `<p style="font-size: 0.8em"><br/>A paciente foi informada que nem todas as anormalidades que um bebê pode vir a apresentar após o nascimento podem ser visualizadas ao ultra-som.<br/>
      Alguns fatores como a estática fetal, a espessura do tecido subcutâneo, a idade gestacional e o aparelho utilizado podem limitar a acurácia do exame;
      <br/><br/>
      Na avaliação morfológica realiza-se o rastreio para síndromes cromossômicas, porém o diagnóstico de certeza somente é dado através de exame invasivo
      (biópsia de vilo coriônico ou amniocentese para a análise do cariótipo fetal);<br/>
      A avaliação morfológica do 2º Trimestre deverá ser realizada preferencialmente entre 20 e 24 semanas de gestação.<br/></p>
    `;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;

    //this.htmlContent += this.achadosAdTexto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
			} else if(indicacao || achados) {
				if(indicacao === undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados === undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }
  ativarImpressaoCrescimentoFetal($event){
    if($event.ativo){
      this.impressaoAtivaCrescimentoFetal=true;
      this.blocos[5].blocked=false;
    }else{
      this.impressaoAtivaCrescimentoFetal=false;
      this.blocos[5].blocked=true;
    }
    this.ativarImpressao();
  }

  ativarImpressaoPlacenta($event){
    if($event.ativo){
      this.impressaoAtivaPlacenta=true;
      this.blocos[7].blocked=false;
    }else{
      this.impressaoAtivaPlacenta=false;
      this.blocos[7].blocked=true;
    }
    this.ativarImpressao();
  }

  ativarImpressaoMedidas($event){
    if($event.ativo){
      this.impressaoMedidas=true;
      this.blocos[4].blocked=false;
    }else{
      this.impressaoMedidas=false;
      this.blocos[4].blocked=true;
    }
    this.ativarImpressao();
  }

  ativarImpressao(){
    if(this.impressaoAtivaCrescimentoFetal && this.impressaoAtivaPlacenta && this.impressaoMedidas)
      this._controlImprimir.habilitarImprimir();
    else
    this._controlImprimir.desabilitarImprimir();
  }
}
