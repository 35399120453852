import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlteraSenhaComponent } from './altera-senha.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule, MatCardModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatListModule, MatButtonModule, MatIconModule, MatSelectModule, MatBadgeModule, MatSidenavModule, MatExpansionModule } from '@angular/material';
import { MenuLateralModule } from '../menu-lateral/menu-lateral.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    MatCheckboxModule,
    FormsModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MenuLateralModule,
    MatBadgeModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatExpansionModule
  ],
  exports: [AlteraSenhaComponent],
  declarations: [AlteraSenhaComponent]
})
export class AlteraSenhaModule { }
