import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClinicConfig } from '../models/BO/ClinicConfig';
import { ConfigLaudoService } from './config-laudo.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigClinicaService {

public maskClinica = new Subject;
public objComConfiguracoesClinica = new Subject;

constructor(
    private http:HttpClient,
    private _configLaudo:ConfigLaudoService,
    private _toastr : ToastrManager,
    private route: Router
  ) { }

  async handleSelectUserClinic(){
    const userId = localStorage.getItem('userID');
    const url = environment.nLaudoApi + 'ConfigClinic/SlecionarLogoUser'+ `/${userId}`;
    this.http.get(url).toPromise().then((res:ClinicConfig)=>{
      if(res !== undefined){
        if(res[0].clinicLogo != undefined && res[0].clinicLogo){
          this.maskClinica.next(res[0].clinicLogo);
        }
      }else{
        this.maskClinica.next('');
      }
    })
  }

   async handleGetClinicConfig(){
    const userID = +localStorage.getItem('userID');
    const url = environment.nLaudoApi + 'ConfigClinic/SlecionarLogoUser'+ `/${userID}`;
    this.http.get(url).toPromise().then((res:ClinicConfig)=>{
      return this.objComConfiguracoesClinica.next(res[0])
    })
  }

  async handlePostClinicConfig(objToPost, listaDeRegras){
    const userID = +localStorage.getItem('userID');
    const url = environment.nLaudoApi + 'ConfigClinic/CadastraLogo';

    this.http.post(url, objToPost).toPromise().then(res=>{
      listaDeRegras[5].active=true;

      localStorage.setItem('configClinicaRule', JSON.stringify(listaDeRegras[5]));

      listaDeRegras.forEach((itens) => {
        localStorage.setItem('cmRule', JSON.stringify(listaDeRegras[1]));
        if (itens.userId === undefined || itens.userId === 0) {
          itens.userId = userID;
        }
      })

      this._configLaudo.salvaConfig(listaDeRegras).toPromise().then(()=>{
        this._toastr.successToastr('Logotipo e/ou Marca foram salvo com sucesso. Alterações ativas e serão aplicadas no próximo laudo!', 'Sucesso!',
        {
          position: 'top-right',
          toastTimeout: 4000
        })
        this.route.navigate(['/laudos']);
      });
    }).catch(
      err=>console.log(err)
    );
  }
}
