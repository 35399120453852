import { NgxMaskModule } from 'ngx-mask';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatButtonToggleModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatButtonModule,
  MatIconModule,
  MatDatepickerModule,
  MatToolbarModule,
  MatTableDataSource,
  MatTableModule,
  MatPaginatorModule,
  MatSidenavModule,
  MatSortModule,
  MatSelectModule,
  MatOptionModule,
  MatDialogModule,
  MatCheckboxModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CadastroComponent } from './cadastro.component';
import { EmailConfirmacaoComponent } from './email-confirmacao/email-confirmacao.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { TextMaskModule } from 'angular2-text-mask';
import { MatStepperModule } from '@angular/material/stepper';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatTableModule,
    FilterPipeModule,
    MatPaginatorModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatCardModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    FlexLayoutModule,
    FormsModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatSortModule,
    MatStepperModule,
    RecaptchaModule,
    TextMaskModule,
    MatSelectModule,
    MatOptionModule,
    MatAutocompleteModule,
    NgxMaskModule,
    MatCheckboxModule
  ],
  entryComponents:[
  ],
  declarations: [
    CadastroComponent,
    EmailConfirmacaoComponent
  ],
  exports: [CadastroComponent]
})
export class CadastroModule { }
