import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MedicalReportLogService } from '../../../../services/MedicalReportLog.service';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { Validacoes } from 'src/app/utils/validacoes';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { TermosDinamicos } from 'src/app/models/Helper/TermosDinamicos';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfigClinicaService } from 'src/app/services/config-clinica.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';
import { ControladorImprimirService } from 'src/app/services/controladorImprimir.service';

@Component({
  selector: 'app-transv-seg-terc-doppler',
  templateUrl: './transv-seg-terc-doppler.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./transv-seg-terc-doppler.component.css']
})
export class TransvSegTercDopplerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {id:'Utero',listTerm:[1977],blockID:178},
    {id:'Feto',listTerm:[1978],blockID:179},
    {id:'ParametrosBiometricos',listTerm:[1986],blockID:180},
    {id:'Placenta',listTerm:[1996,1997,1998],blockID:181},
    {id:'CordaoUmbilical',listTerm:[4703],blockID:306},
    {id:'LiquidoAmniotico',listTerm:[2002],blockID:183},
    {id:'ArteriasUterinas',listTerm:[1977],blockID:316},
    {id:'DopplerVelocimetria',listTerm:[2051],blockID:186},
  ]

  descricoes: DescricaoConclusao[] = [
    { value: 'Útero globoso e proeminente, apresentando no seu interior 1 feto.', blockID: 178, textTermID: 0, visivel: true },

    {
      value: 'Feto localizado em situação ***, apresentação ***, com posição de dorso à ***. <br>' +
        'Movimentos corpóreos e batimentos cardíacos presentes.<br>' +
        'Frequência cardíaca em torno de *** bpm.', blockID: 179, textTermID: 0, visivel: true
    },

    {
      value: '<b>Parâmetros biométricos:</b><br>' +
        'Diâmetro biparietal (DBP): *** mm.<br>' +
        'Diâmetro occiptofrontal (DOF): *** mm.<br>' +
        'Circunferência cefálica (CC): *** mm.<br>' +
        'Circunferência abdominal (CA): *** mm.<br>' +
        'Comprimento umeral (CUM): *** mm.<br>' +
        'Comprimento femoral (CF): *** mm.<br>' +
        'Peso fetal em torno de *** gramas (+/- 15%).', blockID: 180, textTermID: 0, visivel: true
    },

    {
      value: '<b>Placenta:</b><br>' +
        'Localização ***, com aspecto compatível com grau *** de Grannum. ' +
        'Espessura de *** mm.', blockID: 181, textTermID: 0, visivel: true
    },


    {
      value: '<b>Cordão umbilical:</b><br>' +
        'Inserção fetal e placentária preservadas. Presença de 2 artérias e 1 veia.', blockID: 306, textTermID: 0, visivel: true
    },

    {
      value: '<b>Líquido amniótico:</b><br>' +
        'Volume de líquido amniótico subjetivamente normal. ILA:', blockID: 183, textTermID: 0, visivel: true
    },

    {
      value: '<b>Estudo Dopplervelocimetria - Índice de Pulsatilidade:</b><br>'+
      'Artérias Uterinas: Direita: ___, Esquerda: ___, IP Médio: ___ (percentil ___)</br>'+
      'Artérias Umbilicais: ___ (percentil ___)</br>'+
      'Artéria Cerebral Média: ___ (percentil ___)</br>'+
      'Relação cerebro-placentária: ___ (percentil ___)</br>'+
      'Ducto venoso: ___ (normal < 1.0)', blockID: 437, textTermID: 0, visivel: true
    },
    {
      value: 'Artérias Umbilicais:<br>' +
        'Padrão espectral com morfologia normal.</br>' +
        'Índice de resistência (IR): *** .</br>' +
        'Índice de pulsatilidade (IP): *** .', blockID: 186, textTermID: 0, visivel: true
    },

    {
      value: 'Artéria Cerebral Média:<br>' +
        'Padrão espectral com morfologia normal.</br>' +
        'Índice de resistência (IR): *** .</br>' +
        'Índice de pulsatilidade (IP): *** .', blockID: 187, textTermID: 0, visivel: true
    },
    {
      value: '', blockID: 253, textTermID: 0, visivel: true
    }

  ];

  conclusoes: DescricaoConclusao[] = [
    { value: 'Gestação única, tópica e de concepto vivo.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 177, textTermID: 0, visivel: true },
    { value: '', blockID: 178, textTermID: 0, visivel: true },
    { value: '', blockID: 179, textTermID: 0, visivel: true },
    { value: '', blockID: 180, textTermID: 0, visivel: true },
    { value: '', blockID: 181, textTermID: 0, visivel: true },
    { value: '', blockID: 306, textTermID: 0, visivel: true },
    { value: '', blockID: 183, textTermID: 0, visivel: true },
    { value: 'Estudo Dopplerfluxométrico dentro dos limites da normalidade para a idade gestacional.', blockID: 437, textTermID: 0, visivel: true },
    { value: '', blockID: 253, textTermID: 0, visivel: true },
    { value: '', blockID: 188, textTermID: 0, visivel: true }
  ];

  genericFeto: TermosGenericos[] = [
    { value: '', selected: false, termID: 1980, term: '[situacao]' },
    { value: '', selected: false, termID: 1981, term: '[apresentacao]' },
    { value: '', selected: false, termID: 1982, term: '[dorso]' },
    { value: '', selected: false, termID: 1983, term: '[frequencia cardiaca]' },
    { value: '', selected: false, termID: 4168, term: '[polo cefalico]' },
    { value: '', selected: false, termID: 5570, term: '[SituacaoAusente]' },
    { value: '', selected: false, termID: 5571, term: '[ApresentacaoAusente]' },
    { value: '', selected: false, termID: 5572, term: '[DorsoAusente]' },
  ];
  genericPlacenta: TermosGenericos[] = [
    { value: '', selected: false, termID: 1996, term: '[localizacao]' },
    { value: '', selected: false, termID: 1997, term: '[grau]' },
    { value: '', selected: false, termID: 1998, term: '[espessura]' }
  ];

  genericCordaoUmbilical: TermosGenericos[] = [
    { value: '', selected: false, termID: 4706, term: '[InsercaoCordao]' }
  ];

  genericLiquido: TermosGenericos[] = [
    { value: '', selected: false, termID: 2005, term: '[ILA]' },
    { value: '', selected: false, termID: 4780, term: '[MBV]' }
  ];

  genericVelocimetria: TermosGenericos[] = [
    { value: '', selected: false, termID: 4419, term: '[IPDir]' },
    { value: '', selected: false, termID: 4707, term: '[Morfologia UTD Dir]' },
    { value: '', selected: false, termID: 4401, term: '[IPEsq]' },
    { value: '', selected: false, termID: 4708, term: '[Morfologia UTD Esq]' },
    { value: '', selected: false, termID: 4709, term: '[IP Medio]' },
    { value: '', selected: false, termID: 4786, term: '[Percentil IP Medio]' }
  ];

  genericParametrosBiometricos: TermosGenericos[] = [
    { value: '', selected: false, termID: 1986, term: '[DBP]' },
    { value: '', selected: false, termID: 1987, term: '[DOF]' },
    { value: '', selected: false, termID: 1988, term: '[CC]' },
    { value: '', selected: false, termID: 1989, term: '[CA]' },
    { value: '', selected: false, termID: 1990, term: '[CUM]' },
    { value: '', selected: false, termID: 1991, term: '[CF]' },
    { value: '', selected: false, termID: 1992, term: '[peso]' },
    { value: '', selected: false, termID: 2035, term: '[semanas]' },
    { value: '', selected: false, termID: 2036, term: '[dias]' },
    { value: '', selected: false, termID: 4787, term: '[Percentil]' }
  ];

  genericUtero: TermosGenericos[] = [
    { value: '', selected: false, termID: 3951, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 3952, term: '[Contornos]' },
    { value: '', selected: false, termID: 3953, term: '[Subtipo]' },
    { value: '', selected: false, termID: 3954, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3955, term: '[Medindo]' },
    { value: '', selected: false, termID: 3977, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 3978, term: '[Contorno]' },
    { value: '', selected: false, termID: 3979, term: '[Subtipo]' },
    { value: '', selected: false, termID: 3980, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3981, term: '[Medindo]' },
  ];

  genericDopplervelocimetria: TermosGenericos[] = [
    { value: '', selected: false, termID: 5575, term: '[ArteriaUterinaDireita]' },
    { value: '', selected: false, termID: 5576, term: '[ArteriaUterinaEsquerda]' },
    { value: '', selected: false, termID: 5577, term: '[ArteriasUterinasIPMedio]' },
    { value: '', selected: false, termID: 5578, term: '[PercentilArteriasUterinas]' },
    { value: '', selected: false, termID: 5579, term: '[ArteriasUmbilicais]' },
    { value: '', selected: false, termID: 5580, term: '[PercentilArteriasUmbilicais]' },
    { value: '', selected: false, termID: 5581, term: '[ArteriaCerebralMedia]' },
    { value: '', selected: false, termID: 5582, term: '[PercentilArteriaCerebralMedia]' },
    { value: '', selected: false, termID: 5583, term: '[RelacaoCerebroplacentaria]' },
    { value: '', selected: false, termID: 5584, term: '[PercentilRelacaoCerebroplacentaria]' },
    { value: '', selected: false, termID: 5585, term: '[DuctoVenoso]' },
    { value: '', selected: false, termID: 5586, term: '[ArteriasUmbilicaisAlteradas]' },
  ];

  public switchFormReport = true;
  infoLogotipo:string|any= '';
  editLaudoMode: boolean = false;
  unidadeMedida: string;
  impressaoAtivaParametrosBiometricos=false;
  impressaoAtivaPlacenta=false;
  percentil!:any;


  constructor(private _selected: SelectedItemEmiter,
    private _copyPaste: CopyPaste,
    public dialog: MatDialog,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _laudoService: LaudosService,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _serviceMedicalReport: MedicalReportService,
    private _configClinica:ConfigClinicaService,
    private _config: ConfigLaudoService,
    private dataService:DataTermService,
    public _addText: AddText,
    public _controlImprimir:ControladorImprimirService
  ) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("transv-seg-terc-dopplerAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Calculadora IG';
    this.subtituloBloco = this.Subtitulo;
    let configClinicaRule = JSON.parse(localStorage.getItem('configClinicaRule'));
    if(configClinicaRule && configClinicaRule.active){
      this._configClinica.handleSelectUserClinic();
      this._configClinica.maskClinica.subscribe(res=>{
        this.infoLogotipo=res;
        this.AdicionaTexto(this.newEditor, null, null);
      })
    };

    const listBlocks = [
      this.uteroId,
      this.fetoId,
      this.parametrosBiometricosId,
      this.placentaId,
      this.cordaoUmbilicalId,
      this.liquidoAmnioticoId,
      this.dopplervelocimetriaID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "transv-seg-terc-dopplerMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor, null, null);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase( listBlocks, "obsTransSegTercDoppler");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-obsteSegTercDoppler');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-obsteSegTercDoppler');
      }
    });
    this.unidadeMedida = 'mm'
  }

  ngAfterViewInit(): void {
    this._controlImprimir.desabilitarImprimir();
  }

  ngOnDestroy(): void {
    this._controlImprimir.habilitarImprimir();
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor, null, null);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
            this._controlImprimir.desabilitarImprimir();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("transv-seg-terc-dopplerAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.calcFrase = "";
    this.calcConclusao = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor, null, null);
      this.showForm = true;
    }, 500);
  };

  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  mask: string;
  medicalReportTypeID = 37;
  term = new Term();
  injuries = new Injuries();
  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  calcFrase = '';
  calcConclusao = '';
  cabecalhoTexto = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  rodapeTexto = '';

  cabecalho = false;
  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  dinamicLesions: TermosDinamicos[] = [];
  dinamicLesion: any;
  lesionRow = 1;
  medicaoNodulo = '';
  rodape = false;

  showCalculadora = true;
  showUtero = false;
  showFeto = false;
  showParametrosBiometricos = false;
  showPlacenta = false;
  showCordaoUmbilical = false;
  showLiquidoAmniotico = false;
  showDopplervelocimetria = false;
  achadosTexto = false;
  indicacao = false;

  uteroId = 178;
  fetoId = 179;
  parametrosBiometricosId = 180;
  placentaId = 181;
  cordaoUmbilicalId = 306;
  liquidoAmnioticoId = 183;
  dopplervelocimetriaID = 437;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Calculadora IG',
      id: 'calculadora',
      name: 'Calculadora',
      selected: true,
      blocked:false
    },
    {
      titulo: 'Útero',
      id: this.uteroId,
      name: 'Utero',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Feto',
      id: this.fetoId,
      name: 'Feto',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Parâmetros Biométricos',
      id: this.parametrosBiometricosId,
      name: 'ParametrosBiometricos',
      selected: false,
      blocked:true
    },
    {
      titulo: 'Placenta',
      id: this.placentaId,
      name: 'Placenta',
      selected: false,
      blocked:true
    },
    {
      titulo: 'Cordão Umbilical',
      id: this.cordaoUmbilicalId,
      name: 'CordaoUmbilical',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Líquido Amniótico',
      id: this.liquidoAmnioticoId,
      name: 'LiquidoAmniotico',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Dopplervelocimetria',
      id: this.dopplervelocimetriaID,
      name: 'dopplervelocimetria',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
      blocked:false
    }
  ];

  blocosTela = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
      blocked:false
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo:'Preencha o campo indicação',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Calculadora IG',
      id: 'Calculadora',
      name: 'Calculadora',
      selected: true,
      blocked:false
    },
    {
      titulo: 'Útero',
      id: 'Utero',
      name: 'Utero',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Feto',
      id: 'Feto',
      name: 'Feto',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Parâmetros Biométricos',
      id: 'ParametrosBiometricos',
      name: 'ParametrosBiometricos',
      selected: false,
      blocked:true
    },
    {
      titulo: 'Placenta',
      id: 'Placenta',
      name: 'Placenta',
      selected: false,
      blocked:true
    },
    {
      titulo: 'Cordão Umbilical',
      id: 'CordaoUmbilical',
      name: 'CordaoUmbilical',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Líquido Amniótico',
      id: 'LiquidoAmniotico',
      name: 'LiquidoAmniotico',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Dopplervelocimetria',
      id: this.dopplervelocimetriaID,
      name: 'Dopplervelocimetria',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados-adicionais',
      name: 'achados-adicionais',
      subtitulo:'Preencha o campo achados adicionais',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
      blocked:false
    }
  ];

  showBlock(event){
    this.selecionar(event);
  }

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.blocosTela = this._selected.Selected(itemSelecionado.id, this.blocosTela);
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;
      if(this.editLaudoMode){
        let seachTerm = this.listTermDefault.find(item=> item.id===itemSelecionado.id);
        if(seachTerm!= undefined)
        this.dataService.setTermos(seachTerm.listTerm,seachTerm.blockID);
      }

      switch (itemSelecionado.name) {
        case 'cabecalho':
          this.cabecalho = true;
          this.showCalculadora = false;
          this.showUtero = false;
          this.showFeto = false;
          this.showParametrosBiometricos = false;
          this.showPlacenta = false;
          this.showCordaoUmbilical = false;
          this.showLiquidoAmniotico = false;
          this.showDopplervelocimetria = false;
          this.indicacao = false;
          this.achadosTexto = false;
          this.rodape = false;
          break;
        case 'Calculadora':
          this.cabecalho = false;
          this.showCalculadora = true;
          this.showUtero = false;
          this.showFeto = false;
          this.showParametrosBiometricos = false;
          this.showPlacenta = false;
          this.showCordaoUmbilical = false;
          this.showLiquidoAmniotico = false;
          this.showDopplervelocimetria = false;
          this.indicacao = false;
          this.achadosTexto = false;
          this.rodape = false;
          break;
        case 'Utero':
          this.cabecalho = false;
          this.showCalculadora = false;
          this.showUtero = true;
          this.showFeto = false;
          this.showParametrosBiometricos = false;
          this.showPlacenta = false;
          this.showCordaoUmbilical = false;
          this.showLiquidoAmniotico = false;
          this.showDopplervelocimetria = false;
          this.indicacao = false;
          this.achadosTexto = false;
          this.rodape = false;
          break;
        case 'Feto':
          this.cabecalho = false;
          this.showCalculadora = false;
          this.showUtero = false;
          this.showFeto = true;
          this.showParametrosBiometricos = false;
          this.showPlacenta = false;
          this.showCordaoUmbilical = false;
          this.showDopplervelocimetria = false;
          this.indicacao = false;
          this.achadosTexto = false;
          this.rodape = false;
          break;
        case 'ParametrosBiometricos':
          this.cabecalho = false;
          this.showCalculadora = false;
          this.showUtero = false;
          this.showFeto = false;
          this.showParametrosBiometricos = true;
          this.showPlacenta = false;
          this.showCordaoUmbilical = false;
          this.showLiquidoAmniotico = false;
          this.showDopplervelocimetria = false;
          this.indicacao = false;
          this.achadosTexto = false;
          this.rodape = false;
          break;
        case 'Placenta':
          this.cabecalho = false;
          this.showCalculadora = false;
          this.showUtero = false;
          this.showFeto = false;
          this.showParametrosBiometricos = false;
          this.showPlacenta = true;
          this.showCordaoUmbilical = false;
          this.showLiquidoAmniotico = false;
          this.showDopplervelocimetria = false;
          this.indicacao = false;
          this.achadosTexto = false;
          this.rodape = false;
          break;
        case 'CordaoUmbilical':
          this.cabecalho = false;
          this.showCalculadora = false;
          this.showUtero = false;
          this.showFeto = false;
          this.showParametrosBiometricos = false;
          this.showPlacenta = false;
          this.showCordaoUmbilical = true;
          this.showLiquidoAmniotico = false;
          this.showDopplervelocimetria = false;
          this.indicacao = false;
          this.achadosTexto = false;
          this.rodape = false;
          break;
        case 'LiquidoAmniotico':
          this.cabecalho = false;
          this.showCalculadora = false;
          this.showUtero = false;
          this.showFeto = false;
          this.showParametrosBiometricos = false;
          this.showPlacenta = false;
          this.showCordaoUmbilical = false;
          this.showLiquidoAmniotico = true;
          this.showDopplervelocimetria = false;
          this.indicacao = false;
          this.achadosTexto = false;
          this.rodape = false;
          break;
        case 'Dopplervelocimetria':
          this.cabecalho = false;
          this.showCalculadora = false;
          this.showUtero = false;
          this.showFeto = false;
          this.showParametrosBiometricos = false;
          this.showPlacenta = false;
          this.showCordaoUmbilical = false;
          this.showLiquidoAmniotico = false;
          this.showDopplervelocimetria = true;
          this.indicacao = false;
          this.achadosTexto = false;
          this.rodape = false;
          break;
        case 'indicacao':
          this.Subtitulo = 'indicação';
          this.cabecalho = false;
          this.showCalculadora = false;
          this.showUtero = false;
          this.showFeto = false;
          this.showParametrosBiometricos = false;
          this.showPlacenta = false;
          this.showCordaoUmbilical = false;
          this.showLiquidoAmniotico = false;
          this.showDopplervelocimetria = false;
          this.indicacao = true;
          this.achadosTexto = false;
          this.rodape = false;
          break;
        case 'achados-adicionais':
          this.Subtitulo = 'achados adicionais';
          this.cabecalho = false;
          this.showCalculadora = false;
          this.showUtero = false;
          this.showFeto = false;
          this.showParametrosBiometricos = false;
          this.showPlacenta = false;
          this.showCordaoUmbilical = false;
          this.showLiquidoAmniotico = false;
          this.showDopplervelocimetria = false;
          this.indicacao = false;
          this.achadosTexto = true;
          this.rodape = false;
          break;
        case 'rodape':
          this.cabecalho = false;
          this.showCalculadora = false;
          this.showUtero = false;
          this.showFeto = false;
          this.showParametrosBiometricos = false;
          this.showPlacenta = false;
          this.showCordaoUmbilical = false;
          this.showLiquidoAmniotico = false;
          this.showDopplervelocimetria = false;
          this.indicacao = false;
          this.achadosTexto = false;
          this.rodape = true;
          break;
      }
    }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = 'Gestação única, tópica e de concepto vivo.';
    return texto;
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor, null, null);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor, null, null);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTexto = '';


    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor, null, null);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor, null, null);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();

    this.genericFeto = [
      { value: '', selected: false, termID: 1980, term: '[situacao]' },
      { value: '', selected: false, termID: 1981, term: '[apresentacao]' },
      { value: '', selected: false, termID: 1982, term: '[dorso]' },
      { value: '', selected: false, termID: 1983, term: '[frequencia cardiaca]' },
      { value: '', selected: false, termID: 4168, term: '[polo cefalico]' }
    ];

    this.genericPlacenta = [
      { value: '', selected: false, termID: 1996, term: '[localizacao]' },
      { value: '', selected: false, termID: 1997, term: '[grau]' },
      { value: '', selected: false, termID: 1998, term: '[espessura]' }

    ];

    this.genericCordaoUmbilical = [
      { value: '', selected: false, termID: 4706, term: '[InsercaoCordao]' }
    ];

    this.genericLiquido = [
      { value: '', selected: false, termID: 2005, term: '[ILA]' },
      { value: '', selected: false, termID: 4780, term: '[MBV]' }
    ];

    this.genericVelocimetria = [
      { value: '', selected: false, termID: 4419, term: '[IPDir]' },
      { value: '', selected: false, termID: 4707, term: '[Morfologia UTD Dir]' },
      { value: '', selected: false, termID: 4401, term: '[IPEsq]' },
      { value: '', selected: false, termID: 4708, term: '[Morfologia UTD Esq]' },
      { value: '', selected: false, termID: 4709, term: '[IP Medio]' },
      { value: '', selected: false, termID: 4786, term: '[Percentil IP Medio]' }
    ];

    this.genericParametrosBiometricos = [
      { value: '', selected: false, termID: 1986, term: '[DBP]' },
      { value: '', selected: false, termID: 1987, term: '[DOF]' },
      { value: '', selected: false, termID: 1988, term: '[CC]' },
      { value: '', selected: false, termID: 1989, term: '[CA]' },
      { value: '', selected: false, termID: 1990, term: '[CUM]' },
      { value: '', selected: false, termID: 1991, term: '[CF]' },
      { value: '', selected: false, termID: 1992, term: '[peso]' },
      { value: '', selected: false, termID: 2035, term: '[semanas]' },
      { value: '', selected: false, termID: 2036, term: '[dias]' }
    ];
    this.genericUtero = [
      { value: '', selected: false, termID: 3951, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 3952, term: '[Contornos]' },
      { value: '', selected: false, termID: 3953, term: '[Subtipo]' },
      { value: '', selected: false, termID: 3954, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3955, term: '[Medindo]' },
      { value: '', selected: false, termID: 3977, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 3978, term: '[Contorno]' },
      { value: '', selected: false, termID: 3979, term: '[Subtipo]' },
      { value: '', selected: false, termID: 3980, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3981, term: '[Medindo]' },
    ];
  }

  consultarAPI() {
    let desc;
    if (this.showUtero) {
      this.term.blockID = this.uteroId;
      if(this.editLaudoMode){
        this.dataService.setTermos(this.term.listTermID, this.term.blockID);
      }
      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.uteroId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoUtero(resultado.text, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.uteroId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto(this.term, obj.value);
          }
        });

        let obj = {
          value: '',
          blockID: this.uteroId,
          textTermID: 0
        };
        let espaco = '';
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.blockID = this.uteroId;
            obj.value = obj.value + espaco + this.MontaConclusaoUtero(resultado.conclusion);
            espaco = '</br>';
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.uteroId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.uteroId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor, this.term, obj.value);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    }else if (this.showFeto) {
      this.term.blockID = this.fetoId;
      if(this.editLaudoMode){
        this.dataService.setTermos(this.term.listTermID, this.term.blockID);
      }
      this.term.injuries.push(this.injuries);

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.fetoId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoFeto(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.fetoId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto(this.term, obj.value);
          }
        });

        let obj = {
          value: '',
          blockID: this.fetoId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclusaoFeto(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.fetoId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.fetoId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor, this.term, obj.value);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.showPlacenta) {
      this.term.blockID = this.placentaId;
      if(this.editLaudoMode){
        this.dataService.setTermos(this.term.listTermID, this.term.blockID);
      }
      this.term.injuries.push(this.injuries);

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.placentaId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoPlacenta(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.placentaId);
            if (index > -1) {
              this.descricoes[index].value = '<b>Placenta:</b><br>' + obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto(this.term, obj.value);
          }
        });

        let obj = {
          value: '',
          blockID: this.placentaId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclusaoPlacenta(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.placentaId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.placentaId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor, this.term, obj.value);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.showCordaoUmbilical) {
      this.term.blockID = this.cordaoUmbilicalId;
      if(this.editLaudoMode){
        this.dataService.setTermos(this.term.listTermID, this.term.blockID);
      }
      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.cordaoUmbilicalId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + this.MontaDescricaoCordaoUmbilical(resultado.text);
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.cordaoUmbilicalId);
            if (index > -1) {
              if (obj.value)
                this.descricoes[index].value = '<b>Cordão umbilical:</b></br>' + obj.value;
              else
                this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto(this.term, null);
          }
        });
        let obj = {
          value: '',
          blockID: this.cordaoUmbilicalId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = resultado.conclusion;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.cordaoUmbilicalId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.cordaoUmbilicalId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor, this.term, obj.value);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.showLiquidoAmniotico) {
      this.term.blockID = this.liquidoAmnioticoId;
      if(this.editLaudoMode){
        this.dataService.setTermos(this.term.listTermID, this.term.blockID);
      }
      this.term.injuries.push(this.injuries);

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.liquidoAmnioticoId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = '<b>Líquido amniótico:</b><br>' + obj.value + espaco + this.MontaDescricaoLiquido(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.liquidoAmnioticoId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto(this.term, obj.value);
          }
        });

        let obj = {
          value: '',
          blockID: this.liquidoAmnioticoId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclusaoLiquido(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.liquidoAmnioticoId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.liquidoAmnioticoId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor, this.term, obj.value);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.showParametrosBiometricos) {
      this.term.blockID = this.parametrosBiometricosId;
      if(this.editLaudoMode){
        this.dataService.setTermos(this.term.listTermID, this.term.blockID);
      }
      this.term.injuries.push(this.injuries);

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.parametrosBiometricosId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = '<b>Parâmetros biométricos: </b></br>' + obj.value + espaco + this.MontaDescricaoParametros(resultado.text);
              // espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.parametrosBiometricosId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto(this.term, obj.value);
          }
        });

        let obj = {
          value: '',
          blockID: this.parametrosBiometricosId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclusaoParametros(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.parametrosBiometricosId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.parametrosBiometricosId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor, this.term, obj.value);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.showDopplervelocimetria) {
      this.term.blockID = this.dopplervelocimetriaID;
      if(this.editLaudoMode){
        this.dataService.setTermos(this.term.listTermID, this.term.blockID);
      }
      this.term.injuries.push(this.injuries);

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.dopplervelocimetriaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = '<b>Estudo Dopplervelocimetria - Índice de Pulsatilidade:</b></br>' + obj.value + espaco + this.MontaDescricaoDopplervelocimetria(resultado.text);
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.dopplervelocimetriaID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto(this.term, obj.value);
          }
        });

        let obj = {
          value: '',
          blockID: this.dopplervelocimetriaID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclusaoDopplervelocimetria(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.dopplervelocimetriaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.dopplervelocimetriaID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor, this.term, obj.value);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    }
}

  incluirLesoesDinamicas($event) {
    const lesoesValue = this._validacoes.formataDecimal($event.elemento.value);

    if ($event.elemento.id == 3981 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 3981,
          lesionID: 3974,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medindo]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 3981;
            obj.lesionID = 3974;
            obj.cenario = $event.elemento.cenario;
            obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medindo]';

          }
        });
      }
    }

    if ($event.linha == 1 && ($event.posicao == 0 || $event.posicao == 1)) {
      this.genericUtero.forEach(obj => {
        if (obj.termID == $event.elemento.id) {
          obj.selected = true;
          obj.value = lesoesValue;
        }
      });
      if ($event.isPrimeiraLesao == true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaLesoes($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      //NODULO
      case '3977':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3977,
          lesionID: 3974,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '3978':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3978,
          lesionID: 3974,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Contorno]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '3979':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3979,
          lesionID: 3974,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Subtipo]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '3980':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3980,
          lesionID: 3974,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }

    this.lesionRow = $event.linha;
  }

  adicionaSelecaoUtero($event) {
    this.genericUtero.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      //Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      //adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }
  adicionaSelecaoDopplervelocimetria($event) {
    this.genericDopplervelocimetria.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoFeto($event) {
    this.genericFeto.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoPlacenta($event) {
    this.genericPlacenta.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoCordaoUmbilical($event) {
    this.genericCordaoUmbilical.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoLiquido($event) {
    this.genericLiquido.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoVelocimetria($event) {
    this.genericVelocimetria.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });

    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoParametros($event) {
    this.genericParametrosBiometricos.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        if (+$event.elemento.id === 2035 || +$event.elemento.id === 2036)
          obj.value = $event.elemento.value;
        else
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

   MontaDescricaoUtero(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoUteroDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoUteroDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  TextoUteroDescNormal(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 3951 || +term === 3952 || +term === 3953 || +term === 3954 || +term === 3955) {
          this.genericUtero.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    }
    return texto;
  }

  TextoUteroDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '</br>';
      textoDinamico = textoSplit[1];
    }

    this.term.listTermID.forEach(term => {
      if (+term === 3977 || +term === 3978) {
        this.genericUtero.forEach(generic => {
          if (+generic.termID === +term) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
          }
        });
      }
    });

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID === 3977 || item.termID === 3978 || item.termID == 3979
            || item.termID == 3980 || item.termID == 3981) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else if (item.linha >= 2) {
            textoDinamico = '</br>' + textoSplit[1];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (item.termID === 3977 || item.termID === 3978 || item.termID == 3979
            || item.termID == 3980 || item.termID == 3981) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  MontaConclusaoUtero(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3951 || +term === 3952 || +term === 3953 || +term === 3954 || +term === 3955 ||
        +term === 3977 || +term === 3978 || +term === 3979 || +term === 3980 || +term === 3981) {
        this.genericUtero.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoFeto(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1980 || +term === 1981 || +term === 1982 || +term === 1983 || +term === 4168 || +term === 5570 || +term === 5571 || +term === 5572) {
        this.genericFeto.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoFeto(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1980 || +term === 1981 || +term === 1982 || +term === 1983 || +term === 4168 || +term === 5570 || +term === 5571 || +term === 5572) {
        this.genericFeto.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            //Apresentacao
            if(+term === 1981){
              if(generic.value === 'pélvica') {
                textoRetorno = textoRetorno.slice(0, -1) + ' com apresentação pélvica.';
              } else if(generic.value === 'córmica') {
                textoRetorno = textoRetorno.slice(0, -1) + ' com apresentação córmica.';
              }
            }
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoPlacenta(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1996 || +term === 1997 || +term === 1998) {
        this.genericPlacenta.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoPlacenta(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1996 || +term === 1997 || +term === 1998) {
        this.genericPlacenta.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoCordaoUmbilical(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4703 || +term === 4704 || +term === 4705 || +term === 4706) {
        this.genericCordaoUmbilical.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoLiquido(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2002 || +term === 2003 || +term === 2004 || +term === 4780) {
        this.genericLiquido.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;

            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoLiquido(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2002 || +term === 2003 || +term === 2004 || +term === 4780) {
        this.genericLiquido.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoDopplervelocimetria(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if(+term === 5575 || +term === 5576 || +term === 5577
        || +term === 5578 || +term === 5579 || +term === 5580
        || +term === 5581 || +term === 5582 || +term === 5583
        || +term === 5584 || +term === 5585 || +term === 5586 ){
        this.genericDopplervelocimetria.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;

            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoDopplervelocimetria(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if(+term === 5586 ){
        this.genericDopplervelocimetria.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoParametros(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1986 || +term === 1987 || +term === 1988 || +term === 1989
        || +term === 1990 || +term === 1991 || +term === 1992 || +term === 4787) {
        this.genericParametrosBiometricos.forEach(generic => {
          if (+generic.termID === +term) {
            if(+term === 4787){
              this.handleGetPercentil(generic.value);
            }
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  handleGetPercentil(value){
    let number = value.replace(',','.');
    this.percentil =  Number(number);
  }

  MontaConclusaoParametros(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1986 || +term === 1987 || +term === 1988 || +term === 1989
        || +term === 1990 || +term === 1991 || +term === 1992 || +term === 2035 || +term === 2036 || +term === 4787) {
        this.genericParametrosBiometricos.forEach(generic => {
          if (+generic.termID === +term) {

            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    if(this.percentil > 9 && this.percentil < 90){
      if(!texto.includes('<br> Crescimento fetal adequado para a idade gestacional segundo Hadlock IV.')){
        texto = texto + "<br> Crescimento fetal adequado para a idade gestacional segundo Hadlock IV.";
      }
    }else{
      texto.replace('<br> Crescimento fetal adequado para a idade gestacional segundo Hadlock IV.', '');
    }

    return texto;
  }

  enviaCalculadora($event) {
    this.calcFrase = $event[0].toString();
    this.AdicionaTexto(this.newEditor, null, null);
  }

  enviaCalculadoraConclusao($event) {
    this.calcConclusao = $event[0].toString();
    this.AdicionaTexto(this.newEditor, null, null);
  }

  AdicionaTexto(isNewEditor: boolean, terms, texto) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor(terms, texto);

    if ((terms != null) && (texto != "") && (texto != null)) {
      terms.texto = texto;
      window.localStorage.setItem(terms.blockID, JSON.stringify(terms));
    }

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-calculadora`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.calcFrase}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });


    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      if (descricao.value && descricao.value !== '' && descricao.blockID !== 308) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[3]}</p>`,
      }
    });


    this.conclusoes.forEach((conclusao) => {
      if (conclusao.blockID == 177 && this.calcConclusao) {
        conclusao.value = this.calcConclusao;
      }

      if (conclusao.value !== '' ) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor(terms, texto) {
    let i;
    let maskSplit = this.mask.split('|');

    if(this.infoLogotipo !== '' && this.cabecalhoTexto !== ''){
      this.htmlContent = this.infoLogotipo;
      this.htmlContent += this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo !== '' && this.cabecalhoTexto == ''){
      this.htmlContent = this.infoLogotipo;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo == '' && this.cabecalhoTexto !== ''){
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo == '' && this.cabecalhoTexto == ''){
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.calcFrase;
    this.htmlContent += maskSplit[1];
    this.htmlContent += this.indicacaoTexto;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>';

    this.htmlContent += maskSplit[2];

    for (i = 0; i < this.descricoes.length; i++) {
      if(this.descricoes[i].blockID !== 183){
        var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
        var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
        this.descricoes[i].value = newValue2;
      }
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (i == 6)
        this.htmlContent += maskSplit[3];
      if (this.descricoes[i].value)
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
    }
    this.htmlContent += maskSplit[4];

    for (i = 0; i < this.conclusoes.length; i++) {
      if(this.conclusoes[i].blockID == 177 && this.calcConclusao){
        this.htmlContent += '<br/>' + this.calcConclusao + '<br/>';
      }
      if(this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }

    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;

    const obs = maskSplit[6];
    if (obs){
      this.htmlContent += maskSplit[6];
    }
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
    calculadora,
    tabela
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true, null, null);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true, null, null);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }

        if(calculadora !== undefined) {
          const calculo = document.querySelector(`#e-${blockId}`);

          if(calculo) {
						calculo.innerHTML = calculadora;
          }
        }

        if(tabela !== undefined) {
          const table = document.querySelector(`#e-${blockId}`);

          if(table) {
						table.innerHTML = tabela;
          }
        }
			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true, null, null);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true, null, null);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };
    let terms = [];
    let term = null;

    for (let i = 0; i < this.descricoes.length; i++) {
      term = localStorage.getItem(this.descricoes[i].blockID + "");
      if (term != null) {
        terms.push(term);
      }
    }

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  logaLaudo(eventEmited) {
    let terms = [];
    let term = null;
    for (let i = 0; i < this.descricoes.length; i++) {
      term = localStorage.getItem(this.descricoes[i].blockID + "");
      if (term != null) {
        terms.push(term);
      }
    }
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
  ativarImpressaoParametrosBiometricos(event){
    if(event.ativo){
      this.impressaoAtivaParametrosBiometricos=true;
      this.blocos[5].blocked=false;
      this.blocosTela[5].blocked=false;
    }else{
      this.impressaoAtivaParametrosBiometricos=false;
      this.blocos[5].blocked=true;
      this.blocosTela[5].blocked=true;
    }
    this.ativarImpressao();
  }

  ativarImpressaoPlacenta(event){
    if(event.ativo){
      this.impressaoAtivaPlacenta=true;
      this.blocos[6].blocked=false;
      this.blocosTela[6].blocked=false;
    }else{
      this.impressaoAtivaPlacenta=false;
      this.blocos[6].blocked=true;
      this.blocosTela[6].blocked=true;
    }
    this.ativarImpressao();
  }

  ativarImpressao(){
    if(this.impressaoAtivaParametrosBiometricos && this.impressaoAtivaPlacenta)
      this._controlImprimir.habilitarImprimir();
    else
    this._controlImprimir.desabilitarImprimir();
  }
}
