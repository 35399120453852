import { Injectable } from '@angular/core';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import { getApp } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor() { }
  private app: any;
  private userid;
  private analytics;

  initFirebase() {
    this.app = getApp();
    document.addEventListener("visibilitychange", this.visibilityChanged);
  }

  visibilityChanged() {
    if (document.visibilityState === 'visible' && +localStorage.getItem('userID') != 0) {
      this.analytics = getAnalytics(this.app);
      logEvent(this.analytics, 'manual_user_engagement', { client: 'Fidi', id_user: +localStorage.getItem('userID') });
    }
  }

  setUserProperties(): void {
    setUserProperties(this.analytics, { client: 'Fidi', user_id: this.userid });
  }

  verificaAcessoDiario() {
    let firstTime = new Date().setHours(0, 0, 0, 0);

    if (localStorage.getItem('firstTime') == null) {
      localStorage.setItem('firstTime', firstTime.toString());
      logEvent(this.analytics, 'manual_session_start', { client: 'Fidi', id_user: this.userid });
    } else {
      var secondTime = new Date().setHours(0, 0, 0, 0)
      if (secondTime != +firstTime) {
        logEvent(this.analytics, 'manual_session_start', { client: 'Fidi', id_user: this.userid });
      }
    }
  }

  logDefault(log: string) {
    debugger;
    logEvent(this.analytics, log, {
      client: 'Fidi',
      id_user: this.userid,
    });
  }

  logLogin() {
    this.userid = +localStorage.getItem('userID');
    this.analytics = getAnalytics(this.app);
    logEvent(this.analytics, 'manual_login', { client: 'Fidi', id_user: this.userid });
  }

  logLogoff() {
    logEvent(this.analytics, 'manual_logoff', { client: 'Fidi', id_user: this.userid });
  }

  logMedicalReport(medicalReport: any, isCopyHtml: boolean, clicks: number) {
    logEvent(this.analytics, 'register_medical_report', {
      medical_report_id: [medicalReport.id],
      medical_report_type_name: [medicalReport.title],
      client: 'Fidi',
      id_user: this.userid,
      clicks_qty: clicks,
      //editor_check: false,
      copy_html_check: isCopyHtml,
    });
  }

  logEnterMedicalReport(entrada: string) {
    logEvent(this.analytics, 'enter_medical_report', {
      which_enter: entrada,
      client: 'Fidi',
      id_user: this.userid,
    });
  }

  logExamType(examType: string) {
    logEvent(this.analytics, 'choose_exam_type', {
      exam_type: examType,
      client: 'Fidi',
      id_user: this.userid,
    });
  }

  logSpeciality(speciality: string) {
    logEvent(this.analytics, 'speciality', {
      speciality: speciality,
      client: 'Fidi',
      id_user: this.userid,
    });
  }

  logSearchForExam(search: string) {
    logEvent(this.analytics, 'search_for_exam', {
      search: search,
      client: 'Fidi',
      id_user: this.userid,
    });
  }

  logSendWhatsapp(whichSend: string) {
    logEvent(this.analytics, 'send_whatsapp', {
      which_send: whichSend,
      client: 'Fidi',
      id_user: this.userid,
    });
  }

  logSendEmail(subject: string) {
    logEvent(this.analytics, 'send_email', {
      subject: subject,
      client: 'Fidi',
      id_user: this.userid,
    });
  }

  logFeedback(feedback: string, rate: number) {
    logEvent(this.analytics, 'feedback', {
      stars: rate,
      subject: feedback,
      client: 'Fidi',
      id_user: this.userid,
    });
  }
}
