import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ControladorImprimirService {

  public imprimir = new Subject();

  constructor() {   }

  habilitarImprimir(){
    document.removeEventListener('copy',this.preventDefault);
    this.imprimir.next(false);
  }

  desabilitarImprimir(){
    document.addEventListener('copy',this.preventDefault);
    this.imprimir.next(true);
  }
  preventDefault(e){
    e.preventDefault();
  }
}
