import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-mg-linfonodomegalias',
  templateUrl: './mg-linfonodomegalias.component.html',
  styleUrls: ['./mg-linfonodomegalias.component.css']
})
export class MgLinfonodomegaliasComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdMamaLinfo') rdMamaLinfo: MatRadioButton;

  @ViewChild('slLado') slLado: MatSelect;

  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;

  lados: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  showAndHideTerms: any;
  slLadoId: string;
  inMedidaId: string;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes,) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickrdAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  clickDefault() {
    this.showAndHideTerms.sahPresente = true;

    if (this.rdMamaLinfo.checked) {
      this.slLadoId = '4064';
      this.inMedidaId = '4065';
    }

    if ((this.inMedida1 && this.inMedida1.nativeElement.value !== '') &&
      (this.inMedida2 && this.inMedida2.nativeElement.value !== '')) {
      this.inMedida1.nativeElement.value = '';
      this.inMedida2.nativeElement.value = '';
    }

    if (this.slLado)
      this.slLado.value = '';

    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente && this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdMamaLinfo && this.rdMamaLinfo.checked) {
      this.termoSelecionado.emit(this.rdMamaLinfo.id);

      if (this.slLado && this.slLado.value) {
        const objeto = { elemento: this.slLado, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoNodulo();
    }

    this.listaCompleta.emit();
  }

  formataMedindoNodulo() {
    let medindoValor = '';
    let isPrimeiraLesao = false;

    if (this.inMedida1 &&
      this.inMedida1.nativeElement.id &&
      this.inMedida1.nativeElement.value) {

      medindoValor = this._validacoes.formataDecimal(this.inMedida1.nativeElement.value);
    }

    if (this.inMedida2 &&
      this.inMedida2.nativeElement.id &&
      this.inMedida2.nativeElement.value) {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = {id: this.inMedidaId, value: medindoValor};
      const objeto = {elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao};
      this.termosGenericos.emit(objeto);
    }
  }

}
