import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatFormField } from '@angular/material';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service'
import { Router } from '@angular/router';
@Component({
  selector: 'app-altera-senha',
  templateUrl: './altera-senha.component.html',
  styleUrls: ['./altera-senha.component.css']
})
export class AlteraSenhaComponent implements OnInit {
  @ViewChild('inNovaSenha') inNovaSenha: ElementRef;
  @ViewChild('inConfirmaNovaSenha') inConfirmaNovaSenha: ElementRef;
  @ViewChild('matSenhaFF') matSenhaFF: MatFormField;
  @ViewChild('matConfirmeSenhaFF') matConfirmeSenhaFF: MatFormField;

  constructor(private fb:FormBuilder, private http:HttpClient, public user:UserService, private route:Router) { }

  senhaTamanhoMin: string;
  senhaTemAlfaENume: string;
  hide:boolean = true;
  hideCS:boolean = true;
  formSenha: FormGroup;
  senhaConfirmada: number = 1;
  errorMessage:string= '';
  senhasDiferentes:string= '';
  nivelSeguranca:string='';
  nivelSegunrancaIsValid:boolean=false;
  nsColor:string='';
  objUser={
    "ID":0,
    "PasswordMain":'',
    "Email":'',
    "Name":''
  }
  ngOnInit() {
    const userID = +localStorage.getItem('userID');
    this.formSenha = this.fb.group({
      senha: ['', Validators.required],
    });

    this.http.get<any>(environment.nLaudoApi + 'User/BuscaUsuario/' + userID).toPromise().then(res=>{
      this.objUser.ID = res.id;
      this.objUser.Email= res.email;
      this.objUser.Name = res.name;
    })
  }

  handleConfirmPassword(){
    this.handleRemoveInvalid();
    let isValid = this.handleCheckPasswordEqualit();
    if(isValid){
      this.objUser.PasswordMain = this.inConfirmaNovaSenha.nativeElement.value;
      this.http.post(environment.nLaudoApi + 'User/UserUpdatePassword', this.objUser).subscribe(res=>{
        this.senhaConfirmada=3;
        setTimeout(()=>{
          this.route.navigateByUrl('perfil');
        },3000);
      },(error)=>{ })
    }
  }

  handleCheckPassword(){
    const userID = +localStorage.getItem('userID');

    let objToPost={
      "ID": userID,
      "PasswordMain": this.formSenha.value.senha
    }

    if(this.formSenha.valid){
      this.http.post(environment.nLaudoApi + 'User/CheckPassword', objToPost).subscribe(res=>{
        if(res){
          this.errorMessage='';
          this.senhaConfirmada=2;
        }else{
          this.matSenhaFF._elementRef.nativeElement.classList.add('mat-form-field-invalid')
          this.errorMessage = 'Senha incorreta. Tente novamente.';
        }
      })
    }
  }

  handleRemoveInvalid(){
    if(this.senhaConfirmada==2){
      this.senhasDiferentes='';
      this.matConfirmeSenhaFF._elementRef.nativeElement.classList.remove('mat-form-field-invalid')
    }else{
      this.errorMessage='';
      this.matSenhaFF._elementRef.nativeElement.classList.remove('mat-form-field-invalid')
    }
  }

  handleCheckPasswordEqualit(){
    if(this.inNovaSenha.nativeElement.value === this.inConfirmaNovaSenha.nativeElement.value){
      this.senhasDiferentes= '';
      return true
    }else{
      this.matConfirmeSenhaFF._elementRef.nativeElement.classList.add('mat-form-field-invalid')
      this.senhasDiferentes= 'As senhas não correspondem.';
      return false
    }
  }

  handleCheckNewPassword(){
    let senha = this.inNovaSenha.nativeElement.value;
    this.nivelSegunrancaIsValid=false;

    var numeros = /([0-9])/;
	  var alfabeto = /([a-zA-Z])/;
    if(senha.length >= 8 && senha.length <= 10 && senha.match(alfabeto) && senha.match(numeros)){
      this.nivelSeguranca='Regular';
      this.nsColor='#E8B95D'
      this.nivelSegunrancaIsValid=true;
    }else if(senha.length > 10 && senha.match(alfabeto) && senha.match(numeros)){
      this.nivelSeguranca='Relevante';
      this.nsColor='green'
      this.nivelSegunrancaIsValid=true;
    }else{
      this.nivelSeguranca = 'Fraco';
      this.nsColor='red';
    }
    if(senha.length>=8){
      this.senhaTamanhoMin = 'green';
    }else{
      this.senhaTamanhoMin = 'black';
    }
    if(senha.match(alfabeto) && senha.match(numeros)){
      this.senhaTemAlfaENume= 'green'
    }else{
      this.senhaTemAlfaENume= 'black'
    }
  }
}
