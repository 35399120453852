import { Component, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { User } from '../../auth/user';
import { MediaChange, ObservableMedia } from '@angular/flex-layout';
import { UserService } from 'src/app/services/user.service';
import { FirebaseService } from 'src/app/services/firebase.service';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-login-email',
  templateUrl: './login-email.component.html',
  styleUrls: ['./login-email.component.css']
})
export class LoginEmailComponent implements OnInit {

  formEmail: FormGroup;
  watcher: Subscription;
  isPhone: boolean;
  private formSubmitAttempt: boolean;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private toastr: ToastrManager,
    private router: Router,
    private user: User,
    private obsMedia: ObservableMedia,
    private _firebase: FirebaseService
  ) {

    this.watcher = obsMedia
      .subscribe((change: MediaChange) => {
        (change.mqAlias === 'xs') ? this.isPhone = true : this.isPhone = false;
      });
  }

  ngOnInit() {
    this.formEmail = this.fb.group({
      Email: ['', Validators.email],
      Senha: ['', Validators.required],
      Coordinates: [''],
      Ip: [''],
      Browser: [''],
    })

    this.formEmail.patchValue({
      Email: null,
      Senha: null,
      Coordinates: null,
      Ip: null,
      Browser: null
    });

    this._firebase.initFirebase();

    this.getIPAndCoordinates();
    this.getBrowser();
  }

  cadastrarUsuario() {
    this.router.navigate(['/cadastro']);
  }

  recuperarSenha() {
    this.router.navigate(['/recuperarSenha']);
  }

  isFieldInvalidEml(field: string) {

    return (
      (!this.formEmail.get(field).valid && this.formEmail.get(field).touched) ||
      (this.formEmail.get(field).untouched && this.formSubmitAttempt)
    );

  }
  async getIPAndCoordinates() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.formEmail.patchValue({
          Coordinates: position.coords.latitude + ',' + position.coords.longitude
        });
      });
    }

    let ip = await this.authService.getIPAddress();
    if (ip) {
      this.formEmail.patchValue({
        Ip: ip
      });
    }
  }

  getBrowser(){
    this.formEmail.patchValue({
      Browser : this.whatsTheBrowser().name + ' ' + this.whatsTheBrowser().version
    });
  }

  whatsTheBrowser() {
    var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR\/(\d+)/)
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    tem = ua.match(/\bEdg\/(\d+)/)
    if (tem != null) {
      return { name: 'Edge', version: tem[1] };
    }

    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1]
    };
  }

  onSubmit() {
    if (this.formEmail.valid) {
      this.formEmail.disable();
      this.authService.loginEmail(this.formEmail.value)
        .subscribe(
          (usuario) => {
            if (usuario.authenticated) {
              this.authService.loggedIn.next(true);
              this.authService.integrationRoute.next(true);
              this.user.setDataLocalStorage(usuario);
              localStorage.setItem('nomeUsuario', usuario.userName);
              localStorage.setItem('token', usuario.accessToken);
              localStorage.setItem('userID', usuario.userID.toString());
              localStorage.setItem('companyID', usuario.companyID.toString());
              localStorage.setItem('payment', usuario.payment.toString());
              this.userService.buscarUsuario(usuario.userID).subscribe((userDetails) => {
                localStorage.setItem('crm', userDetails['crm']);
                this.userService.userData.next(userDetails);
                if(!userDetails.acceptedTerms){
                  this.userService.termAccepted.next(false);
                  this.router.navigate(['/termos-confirmacao']);
                } else {
                  if (userDetails.emailConfirmed) {
                    this.userService.emailConfirmado.next(true);
                    this.router.navigate(['/laudos']);
                  } else {
                    this.userService.emailConfirmado.next(false);
                    this.router.navigate(['/email-confirmacao']);
                  }
                }
              });
              this.formSubmitAttempt = true;
              this.formEmail.enable();
              this._firebase.logLogin();
            } else {
              this.toastr.errorToastr('Certifique-se que suas credenciais estão corretas!!!', 'Usuário ou Senha inválido');
              this.formEmail.enable();
            }
          },
          erro => {
            this.toastr.errorToastr('Certifique-se que suas credenciais estão corretas!!!', 'Usuário ou Senha inválido');
            console.log(erro);
            this.formEmail.enable();
          }
        );
    }
  }
}
