import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validacoes } from '../../../../utils/validacoes';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { CommonService } from 'src/app/services/common.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { ConfigClinicaService } from 'src/app/services/config-clinica.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

export interface TermosGenericos {
  value: string;
  selected: boolean;
  termID: number;
  term: string;
}

@Component({
  selector: 'app-ombro-bilateral',
  templateUrl: './ombro-bilateral.component.html',
  styleUrls: ['./ombro-bilateral.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ]
})
export class OmbroBilateralComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[1176],blockID:77},
    {listTerm:[1178],blockID:78},
    {listTerm:[1181],blockID:79},
    {listTerm:[1186],blockID:80},
    {listTerm:[1190],blockID:81},
    {listTerm:[1206],blockID:82},
    {listTerm:[1220],blockID:83},
    {listTerm:[1234],blockID:84},
    {listTerm:[1248],blockID:85},
    {listTerm:[1251],blockID:86},
    {listTerm:[1254],blockID:87},
  ]

  descricoesDireita: DescricaoConclusao[] = [
    { value: 'Pele e tecido subcutâneo preservados.', blockID: 77, textTermID: 0, visivel: true },
    { value: 'Superfícies ósseas regulares ao método.', blockID: 78, textTermID: 0, visivel: true },
    { value: 'Ventres musculares com ecogenicidade normal.', blockID: 79, textTermID: 0, visivel: true },
    { value: 'Tendão da cabeça longa do bíceps tópico, de ecogenicidade, contornos e espessura normais.', blockID: 80, textTermID: 0, visivel: true },
    { value: 'Tendão do supraespinal com espessura, ecogenicidade e contornos preservados.', blockID: 81, textTermID: 0, visivel: true },
    { value: 'Tendão do infraespinal com espessura, ecogenicidade e contornos preservados.', blockID: 82, textTermID: 0, visivel: true },
    { value: 'Tendão do subescapular com espessura, ecogenicidade e contornos preservados.', blockID: 83, textTermID: 0, visivel: true },
    { value: 'Tendão do redondo menor com espessura, ecogenicidade e contornos preservados.', blockID: 84, textTermID: 0, visivel: true },
    { value: 'Bursa subacromial-subdeltoidea sem distensão líquida ou espessamento parietal.', blockID: 85, textTermID: 0, visivel: true },
    { value: 'Articulação acromioclavicular sem alterações aparentes.', blockID: 86, textTermID: 0, visivel: true },
    { value: 'Articulação glenoumeral sem derrame articular.', blockID: 87, textTermID: 0, visivel: true }
  ];

  descricoesEsquerda: DescricaoConclusao[] = [
    { value: 'Pele e tecido subcutâneo preservados.', blockID: 77, textTermID: 0, visivel: true },
    { value: 'Superfícies ósseas regulares ao método.', blockID: 78, textTermID: 0, visivel: true },
    { value: 'Ventres musculares com ecogenicidade normal.', blockID: 79, textTermID: 0, visivel: true },
    { value: 'Tendão da cabeça longa do bíceps tópico, de ecogenicidade, contornos e espessura normais.', blockID: 80, textTermID: 0, visivel: true },
    { value: 'Tendão do supraespinal com espessura, ecogenicidade e contornos preservados.', blockID: 81, textTermID: 0, visivel: true },
    { value: 'Tendão do infraespinal com espessura, ecogenicidade e contornos preservados.', blockID: 82, textTermID: 0, visivel: true },
    { value: 'Tendão do subescapular com espessura, ecogenicidade e contornos preservados.', blockID: 83, textTermID: 0, visivel: true },
    { value: 'Tendão do redondo menor com espessura, ecogenicidade e contornos preservados.', blockID: 84, textTermID: 0, visivel: true },
    { value: 'Bursa subacromial-subdeltoidea sem distensão líquida ou espessamento parietal.', blockID: 85, textTermID: 0, visivel: true },
    { value: 'Articulação acromioclavicular sem alterações aparentes.', blockID: 86, textTermID: 0, visivel: true },
    { value: 'Articulação glenoumeral sem derrame articular.', blockID: 87, textTermID: 0, visivel: true }
  ];

  conclusoesDireita: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 77, textTermID: 0, visivel: true },
    { value: '', blockID: 78, textTermID: 0, visivel: true },
    { value: '', blockID: 79, textTermID: 0, visivel: true },
    { value: '', blockID: 80, textTermID: 0, visivel: true },
    { value: '', blockID: 81, textTermID: 0, visivel: true },
    { value: '', blockID: 82, textTermID: 0, visivel: true },
    { value: '', blockID: 83, textTermID: 0, visivel: true },
    { value: '', blockID: 84, textTermID: 0, visivel: true },
    { value: '', blockID: 85, textTermID: 0, visivel: true },
    { value: '', blockID: 86, textTermID: 0, visivel: true },
    { value: '', blockID: 87, textTermID: 0, visivel: true }
  ];

  conclusoesEsquerda: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 77, textTermID: 0, visivel: true },
    { value: '', blockID: 78, textTermID: 0, visivel: true },
    { value: '', blockID: 79, textTermID: 0, visivel: true },
    { value: '', blockID: 80, textTermID: 0, visivel: true },
    { value: '', blockID: 81, textTermID: 0, visivel: true },
    { value: '', blockID: 82, textTermID: 0, visivel: true },
    { value: '', blockID: 83, textTermID: 0, visivel: true },
    { value: '', blockID: 84, textTermID: 0, visivel: true },
    { value: '', blockID: 85, textTermID: 0, visivel: true },
    { value: '', blockID: 86, textTermID: 0, visivel: true },
    { value: '', blockID: 87, textTermID: 0, visivel: true }
  ];

  genericSuperficieOssea: TermosGenericos[] = [
    { value: '', selected: false, termID: 1180, term: '[Localizacao]' }
  ];

  genericVentresMusculares: TermosGenericos[] = [
    { value: '', selected: false, termID: 1185, term: '[Musculatura]' },
    { value: '', selected: false, termID: 4265, term: '[MusculaturaHipo]' },
    { value: '', selected: false, termID: 4266, term: '[MusculaturaLipo]' }
  ];

  genericTendoes: TermosGenericos[] = [
    //Supraespinal
    { value: '', selected: false, termID: 1196, term: '[Rotura]' },
    { value: '', selected: false, termID: 1198, term: '[Tipo]' },
    { value: '', selected: false, termID: 1199, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1200, term: '[Extensao]' },
    { value: '', selected: false, termID: 1201, term: '[Retracao]' },
    { value: '', selected: false, termID: 1257, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1204, term: '[Extensao]' },
    { value: '', selected: false, termID: 1205, term: '[Retracao]' },
    { value: '', selected: false, termID: 1294, term: '[Medida]' },
    //Infraespinal
    { value: '', selected: false, termID: 1211, term: '[Rotura]' },
    { value: '', selected: false, termID: 1212, term: '[Tipo]' },
    { value: '', selected: false, termID: 1213, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1214, term: '[Extensao]' },
    { value: '', selected: false, termID: 1215, term: '[Retracao]' },
    { value: '', selected: false, termID: 1217, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1218, term: '[Extensao]' },
    { value: '', selected: false, termID: 1219, term: '[Retracao]' },
    { value: '', selected: false, termID: 1296, term: '[Medida]' },
    //Subescapular
    { value: '', selected: false, termID: 1225, term: '[Rotura]' },
    { value: '', selected: false, termID: 1226, term: '[Tipo]' },
    { value: '', selected: false, termID: 1227, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1228, term: '[Extensao]' },
    { value: '', selected: false, termID: 1229, term: '[Retracao]' },
    { value: '', selected: false, termID: 1231, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1232, term: '[Extensao]' },
    { value: '', selected: false, termID: 1233, term: '[Retracao]' },
    { value: '', selected: false, termID: 1298, term: '[Medida]' },
    //Redondo
    { value: '', selected: false, termID: 1239, term: '[Rotura]' },
    { value: '', selected: false, termID: 1240, term: '[Tipo]' },
    { value: '', selected: false, termID: 1241, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1242, term: '[Extensao]' },
    { value: '', selected: false, termID: 1243, term: '[Retracao]' },
    { value: '', selected: false, termID: 1245, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1246, term: '[Extensao]' },
    { value: '', selected: false, termID: 1247, term: '[Retracao]' },
    { value: '', selected: false, termID: 1300, term: '[Medida]' }

  ];

  genericArticulacaoGlenoDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1256, term: '[Volume]' }
  ];
  genericArticulacaoGlenoEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1256, term: '[Volume]' }
  ];

  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  htmlContentDireita: string;
  htmlContentEsquerda: string;
  maskEsquerda: string;
  maskDireita: string;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  objetoGrupoLesao: any;
  cabecalhoTexto = '';
  indicacaoTextoDireita = '';
  indicacaoTextoEsquerda = '';
  achadosAdTextoDireita = '';
  achadosAdTextoEsquerda = '';
  Lado = '';
  cenarioCorrente = 0;
  rodapeTexto = '';

  cabecalho = false;
  lateralidade = false;
  peleTecido = false;
  superficieOssea = false;
  ventresMusculares = false;
  tendaoCabeca = false;
  tendaoSupraespinal = false;
  tendaoInfraespinal = false;
  tendaoSubescapular = false;
  tendaoRedondo = false;
  bursa = false;
  articulacaoAcro = false;
  articulacaoGleno = false;
  indicacao = true;
  achadosTexto = false;
  rodape = false;

  medicalReportTypeID = 56;
  peleTecidoID = 77;
  superficieOsseaID = 78;
  ventresMuscularesID = 79;
  tendaoCabecaID = 80;
  tendaoSupraespinalID = 81;
  tendaoInfraespinalID = 82;
  tendaoSubescapularID = 83;
  tendaoRedondoID = 84;
  bursaID = 85;
  articulacaoAcroID = 86;
  articulacaoGlenoID = 87;
  public switchFormReport = true;
  public generoLateralidade: string;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  unidadeMedida: string;
  infoLogotipo:string|any= '';
  editLaudoMode: boolean = false;

  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _copyPaste: CopyPaste,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _configClinica:ConfigClinicaService,
    private _config: ConfigLaudoService,
    public _addText: AddText,
    private dataService:DataTermService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("ombroAnalise", this.descricoesDireita, this.conclusoesDireita);
    this._pouchService.loadDatabase("ombroAnalise", this.descricoesEsquerda, this.conclusoesEsquerda);

    this.resetaDialog();
    this.generoLateralidade = 'masculino';
    this.Subtitulo = 'Indicação';
    this.subtituloBloco = 'Preencha o campo indicação';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    let configClinicaRule = JSON.parse(localStorage.getItem('configClinicaRule'));
    if(configClinicaRule && configClinicaRule.active){
          this._configClinica.handleSelectUserClinic();
          this._configClinica.maskClinica.subscribe(res=>{
            this.infoLogotipo=res;
            this.AdicionaTextoDireita();
            this.AdicionaTextoEsquerda();
          })
        };

    const listBlocks = [
      this.peleTecidoID,
      this.superficieOsseaID,
      this.ventresMuscularesID,
      this.tendaoCabecaID,
      this.tendaoSupraespinalID,
      this.tendaoInfraespinalID,
      this.tendaoSubescapularID,
      this.tendaoRedondoID,
      this.bursaID,
      this.articulacaoAcroID,
      this.articulacaoGlenoID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "ombroBilateralMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.maskDireita = obj.mask.replace('{0}', 'DIREITO');
      this.maskEsquerda = obj.mask.replace('{0}', 'ESQUERDO');
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "ombro");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-ombro');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-ombro');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetaLaudo();
          }
        });
      }
    });
  }
  public resetaLaudo() {
    this._pouchService.getAll("ombroAnalise").then((obj) => {
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoesDireita[i].value = obj[i].doc.value;
            this.descricoesEsquerda[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoesDireita[concCalc].value = obj[i].doc.value;
            this.conclusoesEsquerda[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.Lado = "";
    this.cabecalhoTexto = "";
    this.indicacaoTextoDireita = "";
    this.indicacaoTextoEsquerda = "";
    this.achadosAdTextoDireita = "";
    this.achadosAdTextoEsquerda = "";
    this.rodapeTexto = "";
    this.showForm = false;

    setTimeout(() => {
      this.showForm = true;
      this.AdicionaTexto(this.newEditor);
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: true
    },
    {
      titulo: 'Pele e tecido subcutâneo',
      id: this.peleTecidoID,
      name: 'pele-tecido',
      selected: false,
    },
    {
      titulo: 'Superfície óssea',
      id: this.superficieOsseaID,
      name: 'superficie-ossea',
      selected: false
    },
    {
      titulo: 'Ventres musculares',
      id: this.ventresMuscularesID,
      name: 'ventres-musculares',
      selected: false
    },
    {
      titulo: 'Tendão da cabeça longa',
      id: this.tendaoCabecaID,
      name: 'tendao-cabeca',
      selected: false
    },
    {
      titulo: 'Tendão do supraespinal',
      id: this.tendaoSupraespinalID,
      name: 'tendao-supraespinal',
      selected: false
    },
    {
      titulo: 'Tendão do infraespinal',
      id: this.tendaoInfraespinalID,
      name: 'tendao-infraespinal',
      selected: false
    },
    {
      titulo: 'Tendão do subescapular',
      id: this.tendaoSubescapularID,
      name: 'tendao-subescupular',
      selected: false
    },
    {
      titulo: 'Tendão do redondo menor',
      id: this.tendaoRedondoID,
      name: 'tendao-redondo',
      selected: false
    },
    {
      titulo: 'Bursa subacromial-subdeltoidea',
      id: this.bursaID,
      name: 'bursa',
      selected: false
    },
    {
      titulo: 'Articulação acromioclavicular',
      id: this.articulacaoAcroID,
      name: 'articulacao-acro',
      selected: false
    },
    {
      titulo: 'Articulação glenoumeral',
      id: this.articulacaoGlenoID,
      name: 'articulacao-gleno',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;


      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.lateralidade = (itemSelecionado.id === 'lateralidade');
    this.peleTecido = (itemSelecionado.id === this.peleTecidoID);
    this.superficieOssea = (itemSelecionado.id === this.superficieOsseaID);
    this.ventresMusculares = (itemSelecionado.id === this.ventresMuscularesID);
    this.tendaoCabeca = (itemSelecionado.id === this.tendaoCabecaID);
    this.tendaoSupraespinal = (itemSelecionado.id === this.tendaoSupraespinalID);
    this.tendaoInfraespinal = (itemSelecionado.id === this.tendaoInfraespinalID);
    this.tendaoSubescapular = (itemSelecionado.id === this.tendaoSubescapularID);
    this.tendaoRedondo = (itemSelecionado.id === this.tendaoRedondoID);
    this.bursa = (itemSelecionado.id === this.bursaID);
    this.articulacaoAcro = (itemSelecionado.id === this.articulacaoAcroID);
    this.articulacaoGleno = (itemSelecionado.id === this.articulacaoGlenoID);
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();

    this.genericSuperficieOssea = [
      { value: '', selected: false, termID: 1180, term: '[Localizacao]' }
    ];

    this.genericVentresMusculares = [
      { value: '', selected: false, termID: 1185, term: '[Musculatura]' },
      { value: '', selected: false, termID: 4265, term: '[MusculaturaHipo]' },
      { value: '', selected: false, termID: 4266, term: '[MusculaturaLipo]' }
    ];

    this.genericTendoes = [
      //Supraespinal
      { value: '', selected: false, termID: 1196, term: '[Rotura]' },
      { value: '', selected: false, termID: 1198, term: '[Tipo]' },
      { value: '', selected: false, termID: 1199, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1200, term: '[Extensao]' },
      { value: '', selected: false, termID: 1201, term: '[Retracao]' },
      { value: '', selected: false, termID: 1257, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1204, term: '[Extensao]' },
      { value: '', selected: false, termID: 1205, term: '[Retracao]' },
      { value: '', selected: false, termID: 1294, term: '[Medida]' },
      //Infraespinal
      { value: '', selected: false, termID: 1211, term: '[Rotura]' },
      { value: '', selected: false, termID: 1212, term: '[Tipo]' },
      { value: '', selected: false, termID: 1213, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1214, term: '[Extensao]' },
      { value: '', selected: false, termID: 1215, term: '[Retracao]' },
      { value: '', selected: false, termID: 1217, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1218, term: '[Extensao]' },
      { value: '', selected: false, termID: 1219, term: '[Retracao]' },
      { value: '', selected: false, termID: 1296, term: '[Medida]' },
      //Subescapular
      { value: '', selected: false, termID: 1225, term: '[Rotura]' },
      { value: '', selected: false, termID: 1226, term: '[Tipo]' },
      { value: '', selected: false, termID: 1227, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1228, term: '[Extensao]' },
      { value: '', selected: false, termID: 1229, term: '[Retracao]' },
      { value: '', selected: false, termID: 1231, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1232, term: '[Extensao]' },
      { value: '', selected: false, termID: 1233, term: '[Retracao]' },
      { value: '', selected: false, termID: 1298, term: '[Medida]' },
      //Redondo
      { value: '', selected: false, termID: 1239, term: '[Rotura]' },
      { value: '', selected: false, termID: 1240, term: '[Tipo]' },
      { value: '', selected: false, termID: 1241, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1242, term: '[Extensao]' },
      { value: '', selected: false, termID: 1243, term: '[Retracao]' },
      { value: '', selected: false, termID: 1245, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1246, term: '[Extensao]' },
      { value: '', selected: false, termID: 1247, term: '[Retracao]' },
      { value: '', selected: false, termID: 1300, term: '[Medida]' }
    ];

    this.genericArticulacaoGlenoDireita = [
      { value: '', selected: false, termID: 1256, term: '[Volume]' }
    ];
    this.genericArticulacaoGlenoEsquerda = [
      { value: '', selected: false, termID: 1256, term: '[Volume]' }
    ];

  }

  enviaTermosDireita($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }
  enviaTermosEsquerda($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaLados($event) {
    this.Lado = $event;
    //this.AdicionaTexto();
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaRodapeCabecalho({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacaoDireita(texto) {
    if (texto)
      this.indicacaoTextoDireita = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTextoDireita = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaDireita({
        blockId: 'indicacao',
        indicacao: this.indicacaoTextoDireita
      });
    }
  }

  enviaIndicacaoEsquerda(texto) {
    if (texto)
      this.indicacaoTextoEsquerda = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTextoEsquerda = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaEsquerda({
        blockId: 'indicacao',
        indicacao: this.indicacaoTextoEsquerda
      });
    }
  }

  enviaAchadosDireita(texto) {
    if (texto)
      this.achadosAdTextoDireita = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTextoDireita = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaDireita({
        blockId: 'achados',
        achados: this.achadosAdTextoDireita
      });
    }
  }

  enviaAchadosEsquerda(texto) {
    if (texto)
      this.achadosAdTextoEsquerda = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTextoEsquerda = '';


    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaEsquerda({
        blockId: 'achados',
        achados: this.achadosAdTextoEsquerda
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaRodapeCabecalho({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  adicionaSelecaoSuperficieOsseaDireita($event) {
    this.genericSuperficieOssea.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }
  adicionaSelecaoSuperficieOsseaEsquerda($event) {
    this.genericSuperficieOssea.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoVentresMuscularesDireita($event) {
    this.genericVentresMusculares.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });

    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }

  }

  adicionaSelecaoVentresMuscularesEsquerda($event) {
    this.genericVentresMusculares.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });

    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }

  }

  adicionaSelecaoTendoesDireita($event) {
    this.genericTendoes.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = $event.elemento.value;
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoTendoesEsquerda($event) {
    this.genericTendoes.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = $event.elemento.value;
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoArticulacaoGlenoDireita($event) {
    this.genericArticulacaoGlenoDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }
  adicionaSelecaoArticulacaoGlenoEsquerda($event) {
    this.genericArticulacaoGlenoEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente === 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario === 0 || $event.cenario === this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });
      console.log(injuryToAdd);

      if (injuryToAdd.listInjuries.length > 0)
        this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  consultarAPIDireita() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.peleTecido) {
        this.term.blockID = this.peleTecidoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.peleTecidoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.peleTecidoID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.peleTecidoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.peleTecidoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.peleTecidoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.superficieOssea) {
        this.term.blockID = this.superficieOsseaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.superficieOsseaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoSuperficieOssea(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.superficieOsseaID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.superficieOsseaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoSuperficieOssea(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.superficieOsseaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.superficieOsseaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ventresMusculares) {
        this.term.blockID = this.ventresMuscularesID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.ventresMuscularesID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                if (obj.value)
                  obj.value += espaco + this.MontaDescricaoVentresMusculares(resultado.text, contadorTexto);
                else
                  obj.value += this.MontaDescricaoVentresMusculares(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.ventresMuscularesID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.ventresMuscularesID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value += espaco + this.MontaConclusaoVentresMusculares(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.ventresMuscularesID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ventresMuscularesID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendaoCabeca) {
        this.term.blockID = this.tendaoCabecaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.tendaoCabecaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.tendaoCabecaID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendaoCabecaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.tendaoCabecaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendaoCabecaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendaoSupraespinal) {
        this.term.blockID = this.tendaoSupraespinalID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.tendaoSupraespinalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoTendaoSupraespinal(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.tendaoSupraespinalID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendaoSupraespinalID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoTendaoSupraespinal(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.tendaoSupraespinalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendaoSupraespinalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendaoInfraespinal) {
        this.term.blockID = this.tendaoInfraespinalID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.tendaoInfraespinalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoTendaoInfraespinal(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.tendaoInfraespinalID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendaoInfraespinalID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoTendaoInfraespinal(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.tendaoInfraespinalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendaoInfraespinalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendaoSubescapular) {
        this.term.blockID = this.tendaoSubescapularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.tendaoSubescapularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoTendaoSubescapular(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.tendaoSubescapularID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendaoSubescapularID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoTendaoSubescapular(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.tendaoSubescapularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendaoSubescapularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendaoRedondo) {
        this.term.blockID = this.tendaoRedondoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.tendaoRedondoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoTendaoRedondo(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.tendaoRedondoID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendaoRedondoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoTendaoRedondo(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.tendaoRedondoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendaoRedondoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.bursa) {
        this.term.blockID = this.bursaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.bursaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.bursaID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.bursaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.bursaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.bursaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.articulacaoAcro) {
        this.term.blockID = this.articulacaoAcroID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.articulacaoAcroID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.articulacaoAcroID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.articulacaoAcroID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.articulacaoAcroID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.articulacaoAcroID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.articulacaoGleno) {
        this.term.blockID = this.articulacaoGlenoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.articulacaoGlenoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoArticulacaoGlenoDireita(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.articulacaoGlenoID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.articulacaoGlenoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoArticulacaoGlenoDireita(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.articulacaoGlenoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.articulacaoGlenoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      }
    }
  }

  consultarAPIEsquerda() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.peleTecido) {
        this.term.blockID = this.peleTecidoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.peleTecidoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.peleTecidoID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.peleTecidoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.peleTecidoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.peleTecidoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.superficieOssea) {
        this.term.blockID = this.superficieOsseaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.superficieOsseaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoSuperficieOssea(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.superficieOsseaID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.superficieOsseaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoSuperficieOssea(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.superficieOsseaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.superficieOsseaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ventresMusculares) {
        this.term.blockID = this.ventresMuscularesID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.ventresMuscularesID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                if (obj.value)
                  obj.value += espaco + this.MontaDescricaoVentresMusculares(resultado.text, contadorTexto);
                else
                  obj.value += this.MontaDescricaoVentresMusculares(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.ventresMuscularesID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.ventresMuscularesID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value += espaco + this.MontaConclusaoVentresMusculares(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.ventresMuscularesID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ventresMuscularesID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendaoCabeca) {
        this.term.blockID = this.tendaoCabecaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.tendaoCabecaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.tendaoCabecaID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendaoCabecaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.tendaoCabecaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendaoCabecaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendaoSupraespinal) {
        this.term.blockID = this.tendaoSupraespinalID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.tendaoSupraespinalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoTendaoSupraespinal(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.tendaoSupraespinalID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendaoSupraespinalID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoTendaoSupraespinal(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.tendaoSupraespinalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendaoSupraespinalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendaoInfraespinal) {
        this.term.blockID = this.tendaoInfraespinalID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.tendaoInfraespinalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoTendaoInfraespinal(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.tendaoInfraespinalID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendaoInfraespinalID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoTendaoInfraespinal(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.tendaoInfraespinalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendaoInfraespinalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendaoSubescapular) {
        this.term.blockID = this.tendaoSubescapularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.tendaoSubescapularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoTendaoSubescapular(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.tendaoSubescapularID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendaoSubescapularID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoTendaoSubescapular(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.tendaoSubescapularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendaoSubescapularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendaoRedondo) {
        this.term.blockID = this.tendaoRedondoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.tendaoRedondoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoTendaoRedondo(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.tendaoRedondoID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendaoRedondoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoTendaoRedondo(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.tendaoRedondoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendaoRedondoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.bursa) {
        this.term.blockID = this.bursaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.bursaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.bursaID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.bursaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.bursaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.bursaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.articulacaoAcro) {
        this.term.blockID = this.articulacaoAcroID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.articulacaoAcroID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.articulacaoAcroID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.articulacaoAcroID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.articulacaoAcroID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.articulacaoAcroID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.articulacaoGleno) {
        this.term.blockID = this.articulacaoGlenoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.articulacaoGlenoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoArticulacaoGlenoEsquerda(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.articulacaoGlenoID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.articulacaoGlenoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoArticulacaoGlenoEsquerda(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.articulacaoGlenoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.articulacaoGlenoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      }
    }
  }

  MontaDescricaoSuperficieOssea(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1180) {
        this.genericSuperficieOssea.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoSuperficieOssea(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1180) {
        this.genericSuperficieOssea.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoVentresMusculares(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1185 || +term === 4265 || +term === 4266) {
        this.genericVentresMusculares.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (generic.value.length > 1) {
              let musculaturas = '';
              for (let i = 0; i < generic.value.length; i++) {
                if (i != generic.value.length && i != generic.value.length-1 && i != generic.value.length-2) {
                  musculaturas += generic.value[i] + ', ';
                }
                if (i == generic.value.length-2) {
                  musculaturas += generic.value[i];
                }
                if (i == generic.value.length-1) {
                  musculaturas += ' e ' + generic.value[i];
                }
              }
              textoRetorno = texto.replace(generic.term, musculaturas);
            }else{
              textoRetorno = texto.replace(generic.term, generic.value);
            }

            texto = textoRetorno;
          }
        });
      }
    });

    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1185 || +term === 4265 || +term === 4266) {
          this.genericVentresMusculares.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              if (generic.value.length > 1) {
                let musculaturas = '';
                for (let i = 0; i < generic.value.length; i++) {
                  if (i != generic.value.length && i != generic.value.length-1 && i != generic.value.length-2) {
                    musculaturas += generic.value[i] + ', ';
                  }
                  if (i == generic.value.length-2) {
                    musculaturas += generic.value[i];
                  }
                  if (i == generic.value.length-1) {
                    musculaturas += ' e ' + generic.value[i];
                  }
                }
                textoRetorno = texto.replace(generic.term, musculaturas);
              }else{
                textoRetorno = texto.replace(generic.term, generic.value);
              }
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaConclusaoVentresMusculares(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1185 || +term === 4265 || +term === 4266) {
        this.genericVentresMusculares.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
              if (generic.value.length > 1) {
                let musculaturas = '';
                for (let i = 0; i < generic.value.length; i++) {
                  if (i != generic.value.length && i != generic.value.length-1 && i != generic.value.length-2) {
                    musculaturas += generic.value[i] + ', ';
                  }
                  if (i == generic.value.length-2) {
                    musculaturas += generic.value[i];
                  }
                  if (i == generic.value.length-1) {
                    musculaturas += ' e ' + generic.value[i];
                  }
                }
                textoRetorno = texto.replace(generic.term, musculaturas);
              }else{
                textoRetorno = texto.replace(generic.term, generic.value);
              }
            texto = textoRetorno;
          }
        });
      }
    });

    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1185 || +term === 4265 || +term === 4266) {
          this.genericVentresMusculares.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              if (generic.value.length > 1) {
                let musculaturas = '';
                for (let i = 0; i < generic.value.length; i++) {
                  if (i != generic.value.length && i != generic.value.length-1 && i != generic.value.length-2) {
                    musculaturas += generic.value[i] + ', ';
                  }
                  if (i == generic.value.length-2) {
                    musculaturas += generic.value[i];
                  }
                  if (i == generic.value.length-1) {
                    musculaturas += ' e ' + generic.value[i];
                  }
                }
                textoRetorno = texto.replace(generic.term, musculaturas);
              }else{
                textoRetorno = texto.replace(generic.term, generic.value);
              }
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoTendaoCabecaLonga(texto){
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1185 || +term === 4265 || +term === 4266) {
        this.genericTendoes.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
              if (generic.value.length > 1) {
                let musculaturas = '';
                for (let i = 0; i < generic.value.length; i++) {
                  if (i != generic.value.length && i != generic.value.length-1 && i != generic.value.length-2) {
                    musculaturas += generic.value[i] + ', ';
                  }
                  if (i == generic.value.length-2) {
                    musculaturas += generic.value[i];
                  }
                  if (i == generic.value.length-1) {
                    musculaturas += ' e ' + generic.value[i];
                  }
                }
                textoRetorno = texto.replace(generic.term, musculaturas);
              }else{
                textoRetorno = texto.replace(generic.term, generic.value);
              }
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }
  MontaConclusaoTendaoCabecaLonga(){

  }

  MontaDescricaoTendaoSupraespinal(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1196 || +term === 1198 || +term === 1199 || +term === 1200
        || +term === 1201 || +term === 1204 || +term === 1205 || +term === 1257
        || +term === 1294) {
        this.genericTendoes.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoTendaoSupraespinal(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1196 || +term === 1198 || +term === 1199 || +term === 1200
        || +term === 1201 || +term === 1204 || +term === 1205 || +term === 1257) {
        this.genericTendoes.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoTendaoInfraespinal(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1211 || +term === 1212 || +term === 1213 || +term === 1214
        || +term === 1215 || +term === 1217 || +term === 1218 || +term === 1219
        || +term === 1296) {
        this.genericTendoes.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoTendaoInfraespinal(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1211 || +term === 1212 || +term === 1213 || +term === 1214
        || +term === 1215 || +term === 1217 || +term === 1218 || +term === 1219) {
        this.genericTendoes.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoTendaoSubescapular(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1225 || +term === 1226 || +term === 1227 || +term === 1228
        || +term === 1229 || +term === 1231 || +term === 1232 || +term === 1233
        || +term === 1298) {
        this.genericTendoes.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoTendaoSubescapular(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1225 || +term === 1226 || +term === 1227 || +term === 1228
        || +term === 1229 || +term === 1231 || +term === 1232 || +term === 1233) {
        this.genericTendoes.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoTendaoRedondo(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1239 || +term === 1240 || +term === 1241 || +term === 1242
        || +term === 1243 || +term === 1245 || +term === 1246 || +term === 1247
        || +term === 1300) {
        this.genericTendoes.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoTendaoRedondo(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1239 || +term === 1240 || +term === 1241 || +term === 1242
        || +term === 1243 || +term === 1245 || +term === 1246 || +term === 1247) {
        this.genericTendoes.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoArticulacaoGlenoDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1256) {
        this.genericArticulacaoGlenoDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoArticulacaoGlenoDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1256) {
        this.genericArticulacaoGlenoDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoArticulacaoGlenoEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1256) {
        this.genericArticulacaoGlenoEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoArticulacaoGlenoEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1256) {
        this.genericArticulacaoGlenoEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else {
      this.AdicionaTextoDireita();
      this.AdicionaTextoEsquerda();
    }

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskDireita = this.maskDireita.split('|');
    let maskEsquerda = this.maskEsquerda.split('|');

    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    //lado direito -- lado direito -- lado direito

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskDireita[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskDireita[1]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao-D`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTextoDireita}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskDireita[2]}</p>`,
      }
    });

    this.descricoesDireita.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });
    this.descricoesDireita.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}-D`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskDireita[3]}</p>`,
      }
    });

    this.conclusoesDireita.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao-D`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados-D`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTextoDireita}</p>`,
      }
    });

    //lado esquedo -- lado esquedo -- lado esquedo

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskEsquerda[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskEsquerda[1]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao-E`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTextoEsquerda}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskEsquerda[2]}</p>`,
      }
    });

    this.descricoesEsquerda.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });
    this.descricoesEsquerda.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}-E`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskEsquerda[3]}</p>`,
      }
    });

    this.conclusoesEsquerda.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao-E`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados-E`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTextoEsquerda}</p>`,
      }
    });

    //Rodape
    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  AdicionaTextoDireita() {
    let i;
    let maskSplit = this.maskDireita.split('|');
    let cabecalho = this.cabecalhoTexto;
    let rodape = this.rodapeTexto;
    let logo = this.infoLogotipo;

    this.htmlContentDireita = maskSplit[0];
    this.htmlContentDireita += this.Lado.toUpperCase();
    this.htmlContentDireita += maskSplit[1];
    this.htmlContentDireita += this.indicacaoTextoDireita;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContentDireita += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContentDireita += maskSplit[2];

    for (i = 0; i < this.descricoesDireita.length; i++) {
      var newValue = this.descricoesDireita[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoesDireita[i].value = newValue2;
    }
    for (i = 0; i < this.descricoesDireita.length; i++) {
      if (this.descricoesDireita[i].value) {
        this.htmlContentDireita += '<br/>' + this.descricoesDireita[i].value + '<br/>';
      }
    }
    this.htmlContentDireita += maskSplit[3];
    for (i = 0; i < this.conclusoesDireita.length; i++) {
      if (this.conclusoesDireita[i].value) {
        this.htmlContentDireita += '<br/>' + this.conclusoesDireita[i].value + '<br/>';
      }
    }
    this.htmlContentDireita += this.achadosAdTextoDireita;

    if (this.cabecalhoTexto !== '' && this.rodapeTexto === ''){
      if(this.infoLogotipo)
      this.htmlContent = logo + cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
      else
      this.htmlContent =  cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    }

    if (this.cabecalhoTexto === '' && this.rodapeTexto !== ''){
      if(this.infoLogotipo)
      this.htmlContent = logo + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
      else
      this.htmlContent =  this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    }

    if (this.cabecalhoTexto !== '' && this.rodapeTexto !== ''){
      if(this.infoLogotipo)
        this.htmlContent =  logo + cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
      else
        this.htmlContent =  cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    }

    if (this.cabecalhoTexto === '' && this.rodapeTexto === ''){
      if(this.infoLogotipo)
        this.htmlContent = logo + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
      else
        this.htmlContent = this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    }
  }

  AdicionaTextoEsquerda() {
    let i;
    let maskSplit = this.maskEsquerda.split('|');
    let cabecalho = this.cabecalhoTexto;
    let rodape = this.rodapeTexto;
    let logo = this.infoLogotipo;

    this.htmlContentEsquerda = maskSplit[0];
    this.htmlContentEsquerda += this.Lado.toUpperCase();
    this.htmlContentEsquerda += maskSplit[1];
    this.htmlContentEsquerda += this.indicacaoTextoEsquerda;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContentEsquerda += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContentEsquerda += maskSplit[2];

    for (i = 0; i < this.descricoesEsquerda.length; i++) {
      var newValue = this.descricoesEsquerda[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoesEsquerda[i].value = newValue2;
    }
    for (i = 0; i < this.descricoesEsquerda.length; i++) {
      if (this.descricoesEsquerda[i].value) {
        this.htmlContentEsquerda += '<br/>' + this.descricoesEsquerda[i].value + '<br/>';
      }
    }
    this.htmlContentEsquerda += maskSplit[3];
    for (i = 0; i < this.conclusoesEsquerda.length; i++) {
      if (this.conclusoesEsquerda[i].value) {
        this.htmlContentEsquerda += '<br/>' + this.conclusoesEsquerda[i].value + '<br/>';
      }
    }
    this.htmlContentEsquerda += this.achadosAdTextoEsquerda;

    if (this.cabecalhoTexto !== '' && this.rodapeTexto === ''){
      if(this.infoLogotipo)
      this.htmlContent = logo + cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
      else
      this.htmlContent =  cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    }

    if (this.cabecalhoTexto === '' && this.rodapeTexto !== ''){
      if(this.infoLogotipo)
      this.htmlContent = logo + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
      else
      this.htmlContent =  this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    }

    if (this.cabecalhoTexto !== '' && this.rodapeTexto !== ''){
      if(this.infoLogotipo)
        this.htmlContent =  logo + cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
      else
        this.htmlContent =  cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    }

    if (this.cabecalhoTexto === '' && this.rodapeTexto === ''){
      if(this.infoLogotipo)
        this.htmlContent = logo + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
      else
        this.htmlContent = this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    }
  }

  atualizaLinhaDireita({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoesDireita.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId}-D p`);
        const conclusion = document.querySelector('#e-conclusao-D p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesDireita.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesDireita.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

			} else if(indicacao !== undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const indicacaoTxt = document.querySelector(`#e-${blockId}-D p`);

          if(indicacaoTxt) {
            indicacaoTxt.innerHTML = indicacao;
          }
				}

        if(achados !== undefined) {
          const achadosTxt = document.querySelector(`#e-${blockId}-D p`);

          if(achadosTxt) {
						achadosTxt.innerHTML = achados;
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  atualizaLinhaEsquerda({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoesEsquerda.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId}-E p`);
        const conclusion = document.querySelector('#e-conclusao-E p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesEsquerda.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesEsquerda.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
			} else if(indicacao !== undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const indicacaoTxt = document.querySelector(`#e-${blockId}-E`);

          if(indicacaoTxt) {
            indicacaoTxt.innerHTML = indicacao;
          }
				}

        if(achados !== undefined) {
          const achadosTxt = document.querySelector(`#e-${blockId}-E`);

          if(achadosTxt) {
						achadosTxt.innerHTML = achados;
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  atualizaRodapeCabecalho({
		blockId,
    cabecalho,
    rodape,
	}: IAtualizaLinha): void {
    if(cabecalho !== undefined) {
      const header = document.querySelector(`#e-${blockId}`);

      if(header) {
        header.innerHTML = cabecalho;
      }
    }

    if(rodape !== undefined) {
      const footer = document.querySelector(`#e-${blockId}`);

      if(footer) {
        footer.innerHTML = rodape;
      }
    }
    this._addText.lineHeight();
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
