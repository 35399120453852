// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  nLaudoApi: 'https://app-fidi-nreport-04.azurewebsites.net/api/',
  integrationAPI: 'https://homolog-integration.nreport.ness.com.br/api/',
  viaCepApi: 'https://viacep.com.br/ws/',

  firebaseConfig: {
    apiKey: "AIzaSyAPEolqQh-XGC9HhgE9Sr-KJS4xsb3fqss",
    authDomain: "n-report-apps.firebaseapp.com",
    databaseURL: "",
    projectId: "n-report-apps",
    storageBucket: "n-report-apps.appspot.com",
    messagingSenderId: "118378609373",
    appId: "1:118378609373:web:7affd4a3fd17c614a9b934",
    measurementId: "G-G95474XH72"
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
