import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ViaCepServiceService } from '../services/via-cep-service.service';
import { Endereco } from '../models/BO/Endereco';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../auth/user';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { ConfirmDialogComponent } from '../utils/dialog/confirm-dialog/confirm-dialog.component';
import { UserService } from '../services/user.service';
import { ProtocoloResult } from '../models/BO/ProtocoloResult';
import { Validacoes } from '../utils/validacoes';
import { AuthService } from '../auth/auth.service';
import { IbgeServiceService } from '../services/ibge-service.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from '../services/common.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.css']
})

export class CadastroComponent implements OnInit {
  formCadastro: FormGroup;
  $endereco: Endereco;
  $usuario: any;
  isPayment: boolean;
  gravar: string = 'Salvar Usuário';
  startDate = new Date(1990, 0, 1);
  hide = true;
  public MASKS = MASKS;
  minDate = new Date(1900, 0, 1);
  maxDate = new Date();
  dataNascimentoControl: FormControl;
  private formSubmitAttempt: boolean;
  showAndHideInputs: any;
  chaveConvite: string;
  isSubmitting: boolean = false;
  origin: string = null;
  termsAccepted: boolean = false;

  comoConheceu: Array<any> = [
    { value: 'Indicação', viewValue: 'Indicação' },
    { value: 'Google', viewValue: 'Google' },
    { value: 'Facebook', viewValue: 'Facebook' },
    { value: 'Instagram', viewValue: 'Instagram' },
    { value: 'LinkedIn', viewValue: 'LinkedIn' },
    { value: 'Outros', viewValue: 'Outros' }
  ];
  profissao: Array<any> = [
    { value: 'Medico', viewValue: 'Médico' },
    { value: 'Auxiliar de sala', viewValue: 'Auxiliar de sala' },
    { value: 'Outro', viewValue: 'Outro' }
  ];

  estados: Array<any> = [];
  cidades: Array<any> = [];

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  constructor(private fb: FormBuilder,
    private _viaCEPService: ViaCepServiceService,
    private auth: AuthService,
    private actvRoute: ActivatedRoute,
    private route: Router,
    private _cadastroService: UserService,
    private user: User,
    private toastr: ToastrManager,
    private utils: Validacoes,
    public readonly dialog: MatDialog,
    private _ibge: IbgeServiceService
  ) { }

  ngOnInit() {
    if (this.actvRoute.snapshot.queryParams['isPayment']) {
      if (this.actvRoute.snapshot.queryParams['isPayment'] === 'true') {
        this.isPayment = true
      } else if (this.actvRoute.snapshot.queryParams['isPayment'] === 'false') {
        this.isPayment = false
      }
    }

    this._ibge.getEstados().subscribe(
      (data: any) => {
        this.estados = data;
      },
      (error: any) => {
        console.log(error);
      }
    );

    this.chaveConvite = this.actvRoute.snapshot.queryParams['token'] == null ? '' : this.actvRoute.snapshot.queryParams['token']

    this.dataNascimentoControl = this.fb.control({ value: null, disabled: true })
    this.formCadastro = this.fb.group({
      Name: ['', Validators.required],
      Email: ['', [Validators.email, this.utils.validateEmail.bind(this)]],
      Celular: ['', [
        Validators.required,
        Validators.minLength(11)]
      ],
      Senha: ['', Validators.required],
      ConfirmaSenha: [],
      CPF: ['', [
        <any>Validators.required,
        Validators.maxLength(14),
        Validators.minLength(11)]
      ],
      CRM: [''],
      Cidade: ['', Validators.required],
      Estado: ['', Validators.required],
      ComoConheceu: ['', Validators.required],
      Profissao: ['', Validators.required],
      ProfissaoText: [''],
      Clinica: [''],
      Token: this.chaveConvite,
      Origin: [''],
      Ip: ['']
    });
    this.showAndHideInputs = {
      sahMedico: false,
      sahAuxSala: false,
      sahOutro: false
    }
    this.getIP();
    // final on init()
    console.log('Aqui chave capturada: ' + this.chaveConvite);
  }

  isFieldInvalid(field: string) {
    return (
      (!this.formCadastro.get(field).valid && this.formCadastro.get(field).touched) ||
      (this.formCadastro.get(field).untouched && this.formSubmitAttempt)
    );
  }

  buscaCidades($event) {
    this._ibge.getCidades($event.value).subscribe(
      (data: any) => {
        this.cidades = data;
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  _filter(value: string): string[] {
    console.log(value);
    console.log(this.cidades);
    debugger
    if (value == '') {
      return this.cidades;
    } else {
      const filterValue = value.toLowerCase();
      this.cidades.filter(option => console.log(option));
      this.formCadastro.controls.Cidade.setValue(value);
      return this.cidades.filter(option => option.nome.toLowerCase().includes(filterValue));
    }
  }

  changeProfissao(event) {
    if (event.value === 'Outro') {
      this.formCadastro.get('CRM').setValue('');
      this.showAndHideInputs.sahOutro = true;
      this.showAndHideInputs.sahMedico = false;
      this.showAndHideInputs.sahAuxSala = false;
    }
    if (event.value === 'Medico') {
      this.formCadastro.get('ProfissaoText').setValue('');
      this.showAndHideInputs.sahMedico = true;
      this.showAndHideInputs.sahOutro = false;
      this.showAndHideInputs.sahAuxSala = false;
    }
    if (event.value === 'Auxiliar de sala') {
      this.formCadastro.get('CRM').setValue('');
      this.showAndHideInputs.sahAuxSala = true;
      this.showAndHideInputs.sahMedico = false;
      this.showAndHideInputs.sahOutro = false;
    }
  }

  populaDadosFormCEP(dados: Endereco) {
    this.formCadastro.patchValue({
      Logradouro: dados.logradouro,
      Bairro: dados.bairro,
      Cidade: dados.localidade,
      Estado: dados.uf
    });
  }

  checkSenha() {
    let s1 = this.formCadastro.get('Senha').value;
    let s2 = this.formCadastro.get('ConfirmaSenha').value;

    if (s1 !== s2) {
      this.toastr.errorToastr('As senhas precisam ser iguais', 'Falhou!!',
        {
          position: 'top-right',
          toastTimeout: 4000
        }
      );
    }
  }

  checkCPF(): boolean {
    let CPF = this.formCadastro.get('CPF').value;
    if (!this.utils.validarCPF(CPF)) {
      this.toastr.errorToastr('CPF inválido', 'Falhou!!',
        {
          position: 'top-right',
          toastTimeout: 4000
        }
      );
      this.formCadastro.patchValue({
        CPF: null
      });
      return false;
    } else {
      return true;
    }
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  onSubmit() {
    debugger;
    this.isSubmitting = true;
    this.checkOrigin();
    this._cadastroService.gravarUsuario(this.formCadastro.value)
      .subscribe(
        res => {
          this.formCadastro.enable();
          this.toastr.successToastr('Cadastro Efetuado com sucesso!', 'Tudo certo!',
            {
              position: 'top-right',
              toastTimeout: 4000
            }
          );
          this.auth.loggedIn.next(true);

          this.auth.loginEmail(this.formCadastro.value)
            .subscribe(
              (usuario) => {
                if (usuario.authenticated) {
                  this.auth.loggedIn.next(true);
                  this.auth.integrationRoute.next(true);
                  this.user.setDataLocalStorage(usuario);
                  localStorage.setItem('nomeUsuario', usuario.userName);
                  localStorage.setItem('token', usuario.accessToken);
                  localStorage.setItem('userID', usuario.userID.toString());
                  localStorage.setItem('companyID', usuario.companyID.toString());
                  this._cadastroService.buscarUsuario(usuario.userID).subscribe((user) => {
                    if (user.emailConfirmed) {
                      this.route.navigate(['/laudos']);
                    } else {
                      this.route.navigate(['/email-confirmacao']);
                    }
                  });
                  this.formSubmitAttempt = true;
                  this.formCadastro.enable();
                } else {
                  this.toastr.errorToastr('Certifique-se que suas credenciais estão corretas!!!', 'Usuário ou Senha inválido');
                  this.formCadastro.enable();
                }
              })
          this.route.navigate(['/laudo']);
        },
        err => {
          console.log(err)
          this.formCadastro.enable();
          this.isSubmitting = false;
          this.toastr.errorToastr(err.error, 'Falha ao efetuar cadastro!',
            {
              position: 'top-right',
              toastTimeout: 4000
            }
          );
        },
      );
    this.formSubmitAttempt = true;
  }
  backToLogin() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: 'info|Deseja realmente voltar?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.route.navigate(['/login'])
      }
    });
  }
  checkOrigin() {
    const urlParams = new URLSearchParams(window.location.search);
    let originParam = urlParams.get('origin');
    if (originParam == 'LP') {
      this.formCadastro.patchValue({
        Origin: 'LP'
      });
    } else {
      this.formCadastro.patchValue({
        Origin: null
      });
    }
  }
  acceptTerms() {
    this.termsAccepted == false ? this.termsAccepted = true : this.termsAccepted = false;
  }
  async getIP() {
    let ip = await this.auth.getIPAddress();
    if (ip) {
      this.formCadastro.patchValue({
        Ip: ip
      });
    }
  }
}
