import { environment } from "../../environments/environment";
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { MedicalReport } from "../models/BO/MedicalReport";
import { MedicalReportMask } from "../models/BO/MedicalReportMask";
import { MedicalReportConfig } from "../models/BO/MedicalReportConfig";
import { User } from "../auth/user";
import { analyzeAndValidateNgModules } from "@angular/compiler";
import { ConnectionService } from "./connection.service";
import { PouchDBService } from "./pouchdb.service";
import { UserHasMaskModification } from "../models/BO/UserHasMaskModification";

@Injectable({
  providedIn: "root",
})
export class UserHasMaskModificationService {
  constructor(
    private _http: HttpClient
  ) {}

  buscaUserHasMaskModification(userID: string): Observable<UserHasMaskModification> {
    const url = environment.nLaudoApi + "UserHasMaskModification/GetMaskModification/";
    return this._http.get<UserHasMaskModification>(url + userID);
  }

  salvaUserHasMaskModification(maskModification: any): Observable<UserHasMaskModification> {
    const url = environment.nLaudoApi + "UserHasMaskModification/AddMaskModification";
    const _maskModification: UserHasMaskModification = maskModification;

    const obj = {
      UserID: _maskModification.userID,
      Description: _maskModification.description,
      Conclusion: _maskModification.conclusion,
    };

    return this._http.post<UserHasMaskModification>(url, obj);
  }

  updateUserHasMaskModification(userID: string, maskModification: any, active: number): Observable<UserHasMaskModification> {

    const url = environment.nLaudoApi + "UserHasMaskModification/UpdateMaskModification/" + userID;
    const _maskModification: UserHasMaskModification = maskModification;
    const obj = {
      ID: _maskModification.ID,
      Description: _maskModification.description,
      Conclusion: _maskModification.conclusion,
      Active: active,
    };

    return this._http.put<UserHasMaskModification>(url, obj);
  }
}
