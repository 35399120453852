import { PowerBIToken } from './../models/BO/PowerBIToken';
import { PowerBIService } from './../services/powerBI.service';
import { UserService } from './../services/user.service';
import { Component, OnInit } from '@angular/core';
import { models, service } from 'powerbi-client';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-indicadores',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./indicadores.component.css']
})
export class IndicadoresComponent implements OnInit {

  adminUser: boolean;
  embedToken: string;
  isEmbedded = false;
  displayMessage = '';
  reportClass = 'reportClass hidden';
  safeURLPower: SafeResourceUrl;

  embedConfig =
    {
    type: "report",
    id: "71e1fea4-5025-4af1-bac5-a94d5b83c00c",
    embedUrl: "https://app.powerbi.com/reportEmbed?reportId=71e1fea4-5025-4af1-bac5-a94d5b83c00c&groupId=d08a4f10-6d44-479d-8ed3-22a740ff9517&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1CLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFF1ZXJ5RGF0YVNhYVNFbWJlZCI6dHJ1ZSwic2tpcFF1ZXJ5RGF0YVBhYVNFbWJlZCI6dHJ1ZSwic2tpcFF1ZXJ5RGF0YUV4cG9ydFRvIjp0cnVlfX0%3d",
    accessToken: undefined,
    tokenType: models.TokenType.Embed,
    settings: {
        panes: {
            filters: {
                expanded: false,
                visible: false
            }
        },
        background: models.BackgroundType.Transparent,
    }
}

eventHandlersMap = new Map<string, (event?: service.ICustomEvent<any>) => void>([
  ['loaded', () => console.log('Report has loaded')],
  ['rendered', () => console.log('Report rendered')],
  ['error', (event?: service.ICustomEvent<any>) => console.error(event ? event.detail : "no event obj")],
  ['visualClicked', () => console.log('visual clicked')],
  ['pageChanged', (event) => console.log(event)],
]);

  constructor(
    private userService: UserService,
    public sanitizer: DomSanitizer,
    private powerBI: PowerBIService
  ) { }

  ngOnInit() {
    this.verificarAdmin();
    this.embedReport();
    // this.openPowerBIHapvida();
  }

  verificarAdmin(){
    this.userService.buscarUsuario(parseInt(localStorage.getItem('userID')))
    .subscribe((user) => {
      if(user.profileID === 3){
        this.adminUser = true;
      } else{
        this.adminUser = false;
      }
    })
  }

  async embedReport(){
    let reportConfigResponse: PowerBIToken;
    reportConfigResponse = await this.powerBI.getAccessToken().toPromise();
    this.embedConfig = {
      ...this.embedConfig,
      accessToken: reportConfigResponse.token,
    };
    console.log(this.embedConfig.accessToken)
  }

  openPowerBIHapvida(){
    const embedUrl = "https://app.powerbi.com/view?r=eyJrIjoiNjVkYTYwYzktNjZmNy00MWE3LThhNDUtZjJkZTI5YWI2ZGQyIiwidCI6IjUzMjMzZGQ1LTQzM2MtNDM3YS05ZjJhLWZiMzE5NmEwOGZlMCJ9&pageName=ReportSection10c151d5552a9318b2a9";
    this.safeURLPower = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }

}
