import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter, AfterViewInit, HostListener, OnDestroy, Renderer2 } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { CopyPaste } from '../textEditor/CopyPaste';
import { MatCheckbox, MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../dialog/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { CopyPasteEditor } from 'src/app/utils/text-editor-js/text-editor/CopyPaste/CopyPaste';
import { ConnectionService } from 'src/app/services/connection.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { SpeechToTextService } from 'src/app/services/speech-to-text.service';
import interpretador from '../interpretador-de-voz/interpretador';
import { ControladorImprimirService } from 'src/app/services/controladorImprimir.service';
var jsRTF = require('jsrtf');

@Component({
  selector: 'app-laudo-footer',
  templateUrl: './laudo-footer.component.html',
  styleUrls: ['./laudo-footer.component.css']
})
export class LaudoFooterComponent implements OnInit, OnDestroy {
  // @ViewChild('micButtonFooter') micButtonFooter: ElementRef;
  // @ViewChild('pulseRingFooter') pulseRingFooter: ElementRef;
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;
  @ViewChild('inValorLaudo') inValorLaudo: ElementRef;
  @ViewChild('ckcopiarHtml') ckcopiarHtml: MatCheckbox;
  @Input() medicalReportTypeID: number;
  @Output() laudoReset = new EventEmitter();
  @Output() laudoReturn = new EventEmitter();
  @Output() laudoCopy = new EventEmitter();

  contentCopy: string;
  contentCopyHtml: string;
  postbackURL: string;
  isCopy = false;
  showForm;
  userID: any;
  databaseDeleted: any;
  clickPage = 0;
  countClickFn: any;
  laudoSaved: boolean = false;
  parentNode = document.createElement("a");
  textoAlterado = '';
  transcription = '';
  ultimoLaudoID;
  impressao=false;

  constructor(
    private _toastr: ToastrManager,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _copyPaste: CopyPaste,
    private _dialog: MatDialog,
    private _common: CommonService,
    private _connection: ConnectionService,
    private _copyPasteEditor: CopyPasteEditor,
    private _pouch: PouchDBService,
    private _firebase: FirebaseService,
    private renderer: Renderer2,
    public speech: SpeechToTextService,
    private _controlImprimir:ControladorImprimirService) { }

  ngOnInit() {
    // Verifica as Regras do usuario
    this.userID = localStorage.getItem('userID');
    this.postbackURL = localStorage.getItem('postbackURL');
    localStorage.removeItem('postbackURL');
    this._controlImprimir.imprimir.subscribe((res:boolean)=>{
      this.impressao=res;
    });

    this.countClickFn = this.renderer.listen('document', 'click', () => {
      this.clickPage++;
    });
  }

  ngOnDestroy(): void {
    this.countClickFn();
    if (!this.laudoSaved) {
      this._firebase.logDefault("leave_without_saving");
    }
  }

  podeResetarLaudo() {
    return +this.medicalReportTypeID === 4;
  }

  LaudoReset() {
    if (this._common.invokeEvent.observers.length > 1) {
      while (this._common.invokeEvent.observers.length !== 1) {
        this._common.invokeEvent.observers.shift();
      }
    }

    if (this._common.invokeEvent.observers.length == 1) {
      this.laudoReset.emit('');
      this.waitaSecond().then((value) => this.laudoReturn.emit(value));
      this._common.callCommonMethod();
    }
    this._firebase.logDefault("clean_medical_report");
  }

  waitaSecond() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve("espera de 0.5s!");
      }, 500);
    });
  }

  LaudoCopy(id: string) {
    if (this.medicalReportTypeID === 1 || this.medicalReportTypeID === 12 || this.medicalReportTypeID === 25
      || this.medicalReportTypeID === 27 || this.medicalReportTypeID === 32) {
      this.LaudoCopyVesicula(id);
    } else if (this.medicalReportTypeID === 13 || this.medicalReportTypeID === 14
      || this.medicalReportTypeID === 16 || this.medicalReportTypeID === 17 || this.medicalReportTypeID === 18
      || this.medicalReportTypeID === 19 || this.medicalReportTypeID === 21 || this.medicalReportTypeID === 22
      || this.medicalReportTypeID === 38 || this.medicalReportTypeID === 41 || this.medicalReportTypeID === 15
      || this.medicalReportTypeID === 42 || this.medicalReportTypeID === 43 || this.medicalReportTypeID === 44
      || this.medicalReportTypeID === 51) {
      this.LaudoCopyLateralidade(id);
    } else {
      this.LaudoCopyGeral(id);
    }
  }

  //Todos os outros
  LaudoCopyGeral(id: string) {
    if(!this.impressao){
      this.auditoriaUsuario();
      this.salvaLaudo();
      if (this.postbackURL) //só chama se tiver a URL de postback
        this.salvaLaudoIntegracao();
    }
  }

  //medicalReportID = 1, 12, 25, 27, 32,
  LaudoCopyVesicula(id: string) {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: 'Paciente tem vesícula?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.auditoriaUsuario();
        this.salvaLaudo();

        if (this.postbackURL) //só chama se tiver a URL de postback
          this.salvaLaudoIntegracao();
      }
    });
  }

  //medicalReportID = 13, 14, 15, 16, 17, 18, 19, 21, 22, 34, 38, 41, 42, 43, 44, 51
  LaudoCopyLateralidade(id: string): any {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: 'Lateralidade do exame está correta?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.auditoriaUsuario();
        this.salvaLaudo();

        if (this.postbackURL) //só chama se tiver a URL de postback
          this.salvaLaudoIntegracao();
      }
    });
  }

  convertHtmlToRtf() {
    let codigoLaudoHtml;
    let codigoLaudo;
    let tituloExame;

    let htmlText = this._copyPasteEditor.getHtmlFromId('editorjs');
    let crm = localStorage.getItem('crm');
    let crmHtml = `<br><br> CRM: ${crm}`;

    if (document.querySelector('.laudoHeader h1')) {
      let nomeExame = document.querySelector('.laudoHeader h1').textContent;
      let nomeExameSplit = nomeExame.split("-", 1);
      tituloExame = nomeExameSplit.toString()
    } else
      tituloExame = document.getElementById('laudoHeader').innerText;


    if(!this.postbackURL) {
      if ((<HTMLInputElement>document.getElementById('codigoLaudo')))
        codigoLaudo = (<HTMLInputElement>document.getElementById('codigoLaudo')).value;
      else
        codigoLaudo = document.getElementById('codigoLaudoLista').innerText;

      if (codigoLaudo !== undefined && codigoLaudo !== '' && codigoLaudo !== '-') {
        codigoLaudoHtml = `<br><br> Código laudo: ${codigoLaudo}`;
      }
    }

    var myDoc = new jsRTF();

    // Formatter object
    var textFormat = new jsRTF.Format({
      spaceBefore: 300,
      spaceAfter: 300,
      paragraph: true,
    });

    // Adding text styled with formatter
    if(!this.postbackURL) {
      if (codigoLaudo !== undefined && codigoLaudo !== '' && codigoLaudo !== '-') {
        myDoc.writeText(htmlText + codigoLaudoHtml + crmHtml, textFormat);
      }
    } else {
      myDoc.writeText(htmlText + crmHtml, textFormat);
    }

    // Make content...
    var content = myDoc.createDocument();

    this.saveAFile(content, `${tituloExame}.rtf`);
  }

  saveAFile(file, name): void {
    const dlink: HTMLAnchorElement = document.createElement('a');
    dlink.download = name; // the file name
    const myFileContent: string = file;
    dlink.href = 'data:text/rtf;charset=utf-8,' + myFileContent;
    //show save dialog and save the file
    //https://stackoverflow.com/questions/53914772/how-to-save-a-file-to-my-desktop-folder-angular-6
    dlink.click(); // this will trigger the dialog window
    dlink.remove();
  }

  salvaLaudo() {
    const [text, htmlText] = this.pegaTextoTela();
    this._copyPasteEditor.copyFromId('editorjs', this.ckcopiarHtml.checked);

    if(!this.postbackURL) {
      var valorLaudo = this.inValorLaudo.nativeElement.value.replace('R$ ', '');
    } else {
      valorLaudo = "";
    }

    if(!this.postbackURL) {
      var CodLaudo = this.inCodLaudo.nativeElement.value;
    } else {
      CodLaudo = "";
    }

    const obj = {
      UserID: +localStorage.getItem('userID'),
      Content: text,
      ContentHtml: htmlText,
      ReportCode: CodLaudo,
      ReportValue: valorLaudo.replace('.', '').replace(',', '.'),
      MedicalReportTypeID: this.medicalReportTypeID,
      id: ''
    };

    if (obj)
      if (!this._connection.isOnline) {
        this._pouch.saveLaudo(obj, "tempLaudo");
        this._toastr.warningToastr('Sem conexão. Laudo copiado. Será salvo ao conectar novamente.');
      } else {
        this._serviceMedicalReport.buscaUltimoLaudo(this.userID.toString()).subscribe((laudoPorUsuario: any) => {
          obj.id = laudoPorUsuario[0].id;
        }).add(() => {
          this._firebase.logMedicalReport(obj, this.ckcopiarHtml.checked, this.clickPage);
          this._serviceMedicalReport.salvaLaudo(obj).subscribe(() => {
            this.laudoSaved = true;
            this._toastr.successToastr('Laudo salvo e copiado.');
          });
        });
      }
  }

  salvaLaudoIntegracao() {
    const url = this.postbackURL;
    const [text, htmlText] = this.pegaTextoTela();

    const obj = {
      partnerToken: +localStorage.getItem('userID'),
      report: text,
      reportHtml: htmlText
    };

    if (obj)
      if (this._connection.isOnline) {
        this._serviceMedicalReport.salvaLaudoIntegracao(obj, url).subscribe(() => { });
      }
  }

  auditoriaUsuario() {
    const userId = +localStorage.getItem('userID')
    this._serviceMedicalReport.buscaUltimoLaudo(userId.toString()).subscribe((laudoPorUsuario: any) => {
      this.ultimoLaudoID = laudoPorUsuario[0].id;
    })

    const [text,] = this.pegaTextoTela();

    const obj = {
      UserID: this.userID,
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: text,
      MedicalReportLogTypeID: 1
    };

    if (obj)
      if (!this._connection.isOnline) {
        this._pouch.saveLaudo(obj, "LogMedicalReport");
      }
  }

  pegaTextoTela() {
    let text = this._copyPasteEditor.getTextFromId('editorjs');
    let htmlText = this._copyPasteEditor.getHtmlFromId('editorjs');

    if (document.getElementById('NomePacienteLaudo')) {
      let nomePaciente = document.getElementById('NomePacienteLaudo').innerText;
      let nomePacienteHtml = document.getElementById('NomePacienteLaudo').innerHTML;

      text = text.replace(nomePaciente, "");
      htmlText = htmlText.replace(nomePacienteHtml, "");
    }

    if (document.getElementById('DataNascimentoLaudo')) {
      let DataNascimento = document.getElementById('DataNascimentoLaudo').innerText;
      let DataNascimentoHtml = document.getElementById('DataNascimentoLaudo').innerHTML;

      text = text.replace(DataNascimento, "")
      htmlText = htmlText.replace(DataNascimentoHtml, "")
    }

    return [text, htmlText];
  }

  @HostListener('window:keyup', ['$event']) keyEvent(e: KeyboardEvent) {
    if (e.keyCode == 67 && e.ctrlKey) {
      if(!this.impressao){
        this.LaudoCopy('copyGeral');
      }
      else
        e.preventDefault();
    }
  }


  // async gravadorDeVoz() {
  //   let selection = window.getSelection && window.getSelection();

  //   if (selection && selection.rangeCount > 0) {
  //     this.verificaGravando().then(() => {
  //       this.speech.gravadorDeVoz(
  //         this.speech.gravando === false
  //           ? false
  //           : true
  //       ).then(() => {
  //         this.resetSpeech();
  //       });
  //     });
  //   } else {
  //     if (this.speech.gravando === false) {
  //       this._toastr.errorToastr('Selecione o texto para inserir a transcrição.');
  //     }
  //     this.speech.gravadorDeVoz(false);
  //   }
  // }

  // alteraTexto(texto) {
  //   let selection = window.getSelection && window.getSelection();
  //   if (selection && selection.rangeCount > 0) {
  //     let range = selection.getRangeAt(0);
  //     let node = range.startContainer;
  //     node.textContent += texto;
  //   }
  // }


  // async verificaGravando() {
  //   this.speech.verificaGravando().then((res) => {
  //     if (res) {
  //       // this.pulseRingFooter.nativeElement.style.display = 'block';
  //       // this.micButtonFooter.nativeElement.classList.add('mic-button-gravando-footer');
  //       // this.micButtonFooter.nativeElement.classList.remove('mic-button-footer');
  //     } else {
  //       // this.pulseRingFooter.nativeElement.style.display = 'none';
  //       // this.micButtonFooter.nativeElement.classList.remove('mic-button-gravando-footer');
  //       // this.micButtonFooter.nativeElement.classList.add('mic-button-footer');
  //     }
  //   });
  // }

  // resetSpeech() {
  //   this.textoAlterado = '';
  //   this.transcription = '';
  //   this.speech.finalTranscription = '';
  // }
}
