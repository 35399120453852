import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Term, Injuries } from 'src/app/models/BO/Term';
import { SelectedItemEmiter } from 'src/app/utils/SelectedItemEmiter';
import { LaudosService } from 'src/app/services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { Validacoes } from 'src/app/utils/validacoes';
import { CopyPaste } from 'src/app/utils/textEditor/CopyPaste';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { TermosGenericos } from 'src/app/models/Helper/TermosGenericos';
import { TermResult } from 'src/app/models/BO/TermResult';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { ConfigClinicaService } from 'src/app/services/config-clinica.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

@Component({
  selector: 'app-varizes',
  templateUrl: './varizes.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./varizes.component.css']
})
export class VarizesComponent implements OnInit {

  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[2424],blockID:210},
    {listTerm:[2496],blockID:211},
    {listTerm:[2432],blockID:212},
    {listTerm:[2440],blockID:213},
    {listTerm:[2448],blockID:214},
    {listTerm:[2456],blockID:215},
    {listTerm:[2464],blockID:216},
    {listTerm:[2472],blockID:217},
    {listTerm:[2476],blockID:218},
    {listTerm:[2483],blockID:219},
    {listTerm:[3570],blockID:237},
    {listTerm:[3580],blockID:238},
    {listTerm:[3590],blockID:239},
    {listTerm:[2483],blockID:240},
    {listTerm:[2483],blockID:241},
  ]

  Descricoes: DescricaoConclusao[] = [
    { value: '<b>Sistema Profundo</b>', blockID: 0, textTermID: 0, visivel: true },
    {
      value: '<b>Veia femoral comum</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler',
      blockID: 210, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veia femoral</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler. ',
      blockID: 211, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veia femoral profunda proximal</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler. ',
      blockID: 212, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veia poplítea</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler. ',
      blockID: 213, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veias fibulares</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler.',
      blockID: 216, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veias tibiais anteriores</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler.',
      blockID: 214, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veias tibiais posteriores</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler.',
      blockID: 215, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veias musculares da panturrilha</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler.',
      blockID: 217, textTermID: 0, visivel: true
    },
    {
      value: 'As veias analisadas apresentam-se pérvias, com fluxo fásico, variante com a respiração, inferindo patência do sistema ilíaco e da veia cava inferior.',
      blockID: 0, textTermID: 0, visivel: true
    },
    { value: '<b>Sistema Superficial</b>', blockID: 0, textTermID: 0, visivel: true },
    {
      value: '<b>Veia safena magna</b> de calibre preservado, sem sinais de insuficiência ou trombos intraluminais.',
      blockID: 237, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veia safena parva</b> de calibre preservado, sem sinais de insuficiência ou trombos intraluminais.',
      blockID: 238, textTermID: 0, visivel: true
    },
    {
      value: '<b>Junção safeno-femoral</b> de calibre preservado, sem sinais de insuficiência ou trombos intraluminais.',
      blockID: 240, textTermID: 0, visivel: true
    },
    {
      value: '<b>Junção safeno-poplítea</b> de calibre preservado, localizada ___ cm acima do nível da interlinha articular do joelho, sem sinais de insuficiência ou trombos intraluminais.',
      blockID: 241, textTermID: 0, visivel: true
    },
    { value: '<b>Veias perfurantes</b> sem sinais de insuficiência.', blockID: 239, textTermID: 0, visivel: true },

  ];

  conclusoes: DescricaoConclusao[] = [
    {
      value: 'Ultrassonografia com Doppler colorido das veias analisadas dentro dos limites da normalidade.',
      blockID: 0, textTermID: 0, visivel: true
    },
    { value: '', blockID: 210, textTermID: 0, visivel: true },
    { value: '', blockID: 211, textTermID: 0, visivel: true },
    { value: '', blockID: 212, textTermID: 0, visivel: true },
    { value: '', blockID: 213, textTermID: 0, visivel: true },
    { value: '', blockID: 216, textTermID: 0, visivel: true },
    { value: '', blockID: 214, textTermID: 0, visivel: true },
    { value: '', blockID: 215, textTermID: 0, visivel: true },
    { value: '', blockID: 217, textTermID: 0, visivel: true },
    { value: '', blockID: 218, textTermID: 0, visivel: true },
    { value: '', blockID: 219, textTermID: 0, visivel: true },
    { value: '', blockID: 237, textTermID: 0, visivel: true },
    { value: '', blockID: 238, textTermID: 0, visivel: true },
    { value: '', blockID: 239, textTermID: 0, visivel: true },
    { value: '', blockID: 240, textTermID: 0, visivel: true },
  ];

  genericTermsVeiaFemoralComum: TermosGenericos[] = [
    { value: '', selected: false, termID: 2426, term: '[Motivo]' },
    { value: '', selected: false, termID: 2428, term: '[Localizacao]' },
    { value: '', selected: false, termID: 2431, term: '[Calibre]' },

  ];
  genericTermsVeiaFemoral: TermosGenericos[] = [
    { value: '', selected: false, termID: 2498, term: '[Motivo]' },
    { value: '', selected: false, termID: 2500, term: '[Localizacao]' },
    { value: '', selected: false, termID: 2502, term: '[Calibre]' },
  ];

  genericTermsVeiaFemoralProximal: TermosGenericos[] = [
    { value: '', selected: false, termID: 2434, term: '[Motivo]' },
    { value: '', selected: false, termID: 2439, term: '[Calibre]' },
  ];

  genericTermsVeiaPoplitea: TermosGenericos[] = [
    { value: '', selected: false, termID: 2442, term: '[Motivo]' },
    { value: '', selected: false, termID: 2444, term: '[Localizacao]' },
    { value: '', selected: false, termID: 2446, term: '[Calibre]' },
  ];

  genericTermsVeiaTibiasAnteriores: TermosGenericos[] = [
    { value: '', selected: false, termID: 2450, term: '[Motivo]' },
    { value: '', selected: false, termID: 2452, term: '[Localizacao]' },
    { value: '', selected: false, termID: 2454, term: '[Calibre]' },
  ];

  genericTermsVeiaTibiasPosteriores: TermosGenericos[] = [
    { value: '', selected: false, termID: 2458, term: '[Motivo]' },
    { value: '', selected: false, termID: 2460, term: '[Localizacao]' },
    { value: '', selected: false, termID: 2462, term: '[Calibre]' },
  ];

  genericTermsVeiaFibulares: TermosGenericos[] = [
    { value: '', selected: false, termID: 2466, term: '[Motivo]' },
    { value: '', selected: false, termID: 2468, term: '[Localizacao]' },
    { value: '', selected: false, termID: 2470, term: '[Calibre]' },
  ];

  genericTermsVeiaMusculares: TermosGenericos[] = [
    { value: '', selected: false, termID: 2474, term: '[Motivo]' },
  ];

  genericMagna: TermosGenericos[] = [
    { value: '', selected: false, termID: 3573, term: '[Calibre]' },
    { value: '', selected: false, termID: 3574, term: '[Terco]' },
    { value: '', selected: false, termID: 3576, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3578, term: '[Terco Tro]' },
    { value: '', selected: false, termID: 3579, term: '[Localizacao Tro]' }
  ];

  genericParva: TermosGenericos[] = [
    { value: '', selected: false, termID: 3583, term: '[Calibre]' },
    { value: '', selected: false, termID: 3584, term: '[Terco]' },
    { value: '', selected: false, termID: 3586, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3588, term: '[Terco Tro]' }
  ];

  genericPerfurantes: TermosGenericos[] = [
    { value: '', selected: false, termID: 3593, term: '[Calibre]' },
    { value: '', selected: false, termID: 3594, term: '[Face]' },
    { value: '', selected: false, termID: 3595, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3596, term: '[Distancia Plantar]' },
    { value: '', selected: false, termID: 3598, term: '[Face Tro]' },
    { value: '', selected: false, termID: 3599, term: '[Localizacao Tro]' }
  ];

  Subtitulo: string;
  subtituloBloco: string;
  mask: string;
  htmlContent: string;
  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  term: any = new Term();
  injuries = new Injuries();
  cabecalhoTexto = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  lesionRow = 1;
  exibeTabela = false;
  exibeTabelaParva = false;
  tabelaValor = new Array<string>();
  generoLateralidade: string;
  Lado = '';
  tabelaMagna = '';
  tabelaParva = '';
  cabecalho = false;
  lateralidade = true;
  indicacao = false;
  public VeiaFemoralComum: Boolean = false;
  public VeiaFemoral: Boolean = false;
  public VeiaFemoralProfunda: Boolean = false;
  public VeiaPolplitea: Boolean = false;
  public VeiaTibiaisAnteriores: Boolean = false;
  public VeiaTibiaisPosteriores: Boolean = false;
  public VeiasFibulares: Boolean = false;
  public VeiasMuscularesPanturrilha: Boolean = false;
  public: Boolean = false;
  veiaSafenaMagna = false;
  veiaSafenaParva = false;
  veiaPerfurantes = false;
  juncaoSafenoFemoral = false;
  juncaoSafenoPoplitea = false;
  tabelaVarizes = false;
  achadosTexto = false;
  public rodape: Boolean = false;
  public rodapeTexto: any = '';

  medicalReportTypeID = 44;
  public VeiaFemoralComumID: Number = 210;
  public VeiaFemoralID: Number = 211;
  public VeiaFemoralProfundaID: Number = 212;
  public VeiaPolpliteaID: Number = 213;
  public VeiaTibiaisAnterioresID: Number = 214;
  public VeiaTibiaisPosterioresID: Number = 215;
  public VeiasFibularesID: Number = 216;
  public VeiasMuscularesPanturrilhaID: Number = 217;
  public ID: Number = 218;
  public achadosTextoID: Number = 219;
  veiaSafenaMagnaID = 237;
  veiaSafenaParvaID = 238;
  veiaPerfurantesID = 239;
  juncaoSafenoFemoralID = 240;
  juncaoSafenoPopliteaID = 241;

  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  unidadeMedida: string;
  infoLogotipo:string|any= '';
  editLaudoMode: boolean = false;

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Lateralidade',
      id: 'lateralidade',
      name: 'lateralidade',
      selected: true,
    },
    {
      id: this.VeiaFemoralComumID,
      name: 'Veia Femoral Comum',
      titulo: 'Veia Femoral Comum',
      selected: false,
    },
    {
      id: this.VeiaFemoralID,
      name: 'Veia Femoral',
      titulo: 'Veia Femoral',
      selected: false,
    },
    {
      id: this.VeiaFemoralProfundaID,
      name: 'Veia Femoral Profunda Proximal',
      titulo: 'Veia Femoral Profunda Proximal',
      selected: false,
    },
    {
      titulo: 'Veia Poplítea',
      id: this.VeiaPolpliteaID,
      name: 'Veia Poplítea',
      selected: false,
    },
    {
      titulo: 'Veias Tibiais Anteriores',
      id: this.VeiaTibiaisAnterioresID,
      name: 'Veias Tibias Anteriores',
      selected: false,
    },
    {
      titulo: 'Veias Tibiais Posteriores',
      id: this.VeiaTibiaisPosterioresID,
      name: 'Veias Tibiais Posteriores',
      selected: false,
    },
    {
      titulo: 'Veias Fibulares',
      id: this.VeiasFibularesID,
      name: 'Veias Fibulares',
      selected: false,
    },
    {
      titulo: 'Veias Musculares da Panturrilha',
      id: this.VeiasMuscularesPanturrilhaID,
      name: 'Veias Musculares da Panturrilha',
      selected: false,
    },
    {
      id: this.veiaSafenaMagnaID,
      name: 'veia-magna',
      titulo: 'Veia Safena Magna',
      selected: false
    },
    {
      id: this.veiaSafenaParvaID,
      name: 'veia-parva',
      titulo: 'Veia Safena Parva',
      selected: false
    },
    {
      id: this.veiaPerfurantesID,
      name: 'veias-perfurante',
      titulo: 'Veias Perfurantes',
      selected: false
    },
    {
      id: 'tabela',
      name: 'tabela',
      titulo: 'Tabela',
      selected: false
    },
    {
      id: this.achadosTextoID,
      name: 'achados-adicionais',
      titulo: 'Achados adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      id: 'rodape',
      name: 'rodape',
      titulo: 'Assinatura',
      selected: false,
    }
  ];

  public switchFormReport = true;

  constructor(private _selected: SelectedItemEmiter,
    private _laudoservice: LaudosService,
    private _toastr: ToastrManager,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _validacoes: Validacoes,
    public dialog: MatDialog,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _configClinica:ConfigClinicaService,
    private _config: ConfigLaudoService,
    public _addText: AddText,
    private _copyPaste: CopyPaste,
    private dataService:DataTermService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("varizes-dopplerAnalise", this.Descricoes, this.conclusoes);
    this.resetaDialog();
    this.generoLateralidade = 'masculino';
    this.Subtitulo = 'Lateralidade';
    this.subtituloBloco = 'Lateralidade';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    let configClinicaRule = JSON.parse(localStorage.getItem('configClinicaRule'));
    if(configClinicaRule && configClinicaRule.active){
      this._configClinica.handleSelectUserClinic();
      this._configClinica.maskClinica.subscribe(res=>{
        this.infoLogotipo=res;
        this.AdicionaTexto(this.newEditor);
      })
    };

    const listBlocks = [
      this.VeiaFemoralComumID,
      this.VeiaFemoralID,
      this.VeiaFemoralProfundaID,
      this.VeiaPolpliteaID,
      this.VeiaTibiaisAnterioresID,
      this.VeiaTibiaisPosterioresID,
      this.VeiasFibularesID,
      this.VeiasMuscularesPanturrilhaID,
      this.ID,
      this.achadosTextoID,
      this.veiaSafenaMagnaID,
      this.veiaSafenaParvaID,
      this.veiaPerfurantesID,
      this.juncaoSafenoFemoralID,
      this.juncaoSafenoPopliteaID
    ];
    this._pouchService.loadMask(this.medicalReportTypeID, "varizes-dopplerMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "varizes");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-varizes-doppler');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-varizes-doppler');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("varizes-dopplerAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.Descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.Lado = "";
    this.exibeTabela = false;
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  selecionar(itemSelecionado): any {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.lateralidade = (itemSelecionado.id === 'lateralidade');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.tabelaVarizes = (itemSelecionado.id === 'tabela');
    this.veiaSafenaParva = (itemSelecionado.id === this.veiaSafenaParvaID);
    this.veiaPerfurantes = (itemSelecionado.id === this.veiaPerfurantesID);
    this.veiaSafenaMagna = (itemSelecionado.id === this.veiaSafenaMagnaID);
    this.VeiaFemoralComum = (itemSelecionado.id === this.VeiaFemoralComumID);
    this.VeiaFemoral = (itemSelecionado.id === this.VeiaFemoralID);
    this.VeiaFemoralProfunda = (itemSelecionado.id === this.VeiaFemoralProfundaID);
    this.VeiaPolplitea = (itemSelecionado.id === this.VeiaPolpliteaID);
    this.VeiaTibiaisAnteriores = (itemSelecionado.id === this.VeiaTibiaisAnterioresID);
    this.VeiaTibiaisPosteriores = (itemSelecionado.id === this.VeiaTibiaisPosterioresID);
    this.VeiasFibulares = (itemSelecionado.id === this.VeiasFibularesID);
    this.VeiasMuscularesPanturrilha = (itemSelecionado.id === this.VeiasMuscularesPanturrilhaID);
    this.achadosTexto = (itemSelecionado.id === this.achadosTextoID);
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;

    this.genericTermsVeiaFemoralComum = [
      { value: '', selected: false, termID: 2426, term: '[Motivo]' },
      { value: '', selected: false, termID: 2428, term: '[Localizacao]' },
      { value: '', selected: false, termID: 2431, term: '[Calibre]' },

    ];
    this.genericTermsVeiaFemoral = [
      { value: '', selected: false, termID: 2498, term: '[Motivo]' },
      { value: '', selected: false, termID: 2500, term: '[Localizacao]' },
      { value: '', selected: false, termID: 2502, term: '[Calibre]' },
    ];

    this.genericTermsVeiaFemoralProximal = [
      { value: '', selected: false, termID: 2434, term: '[Motivo]' },
      { value: '', selected: false, termID: 2439, term: '[Calibre]' },
    ];

    this.genericTermsVeiaPoplitea = [
      { value: '', selected: false, termID: 2442, term: '[Motivo]' },
      { value: '', selected: false, termID: 2444, term: '[Localizacao]' },
      { value: '', selected: false, termID: 2446, term: '[Calibre]' },
    ];

    this.genericTermsVeiaTibiasAnteriores = [
      { value: '', selected: false, termID: 2450, term: '[Motivo]' },
      { value: '', selected: false, termID: 2452, term: '[Localizacao]' },
      { value: '', selected: false, termID: 2454, term: '[Calibre]' },
    ];

    this.genericTermsVeiaTibiasPosteriores = [
      { value: '', selected: false, termID: 2458, term: '[Motivo]' },
      { value: '', selected: false, termID: 2460, term: '[Localizacao]' },
      { value: '', selected: false, termID: 2462, term: '[Calibre]' },
    ];

    this.genericTermsVeiaFibulares = [
      { value: '', selected: false, termID: 2466, term: '[Motivo]' },
      { value: '', selected: false, termID: 2468, term: '[Localizacao]' },
      { value: '', selected: false, termID: 2470, term: '[Calibre]' },
    ];

    this.genericTermsVeiaMusculares = [
      { value: '', selected: false, termID: 2474, term: '[Motivo]' },
    ];

    this.genericMagna = [
      { value: '', selected: false, termID: 3573, term: '[Calibre]' },
      { value: '', selected: false, termID: 3574, term: '[Terco]' },
      { value: '', selected: false, termID: 3576, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3578, term: '[Terco Tro]' },
      { value: '', selected: false, termID: 3579, term: '[Localizacao Tro]' }
    ];

    this.genericParva = [
      { value: '', selected: false, termID: 3583, term: '[Calibre]' },
      { value: '', selected: false, termID: 3584, term: '[Terco]' },
      { value: '', selected: false, termID: 3586, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3588, term: '[Terco Tro]' }
    ];

    this.genericPerfurantes = [
      { value: '', selected: false, termID: 3593, term: '[Calibre]' },
      { value: '', selected: false, termID: 3594, term: '[Face]' },
      { value: '', selected: false, termID: 3595, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3596, term: '[Distancia Plantar]' },
      { value: '', selected: false, termID: 3598, term: '[Face Tro]' },
      { value: '', selected: false, termID: 3599, term: '[Localizacao Tro]' }
    ];

  }

  enviaTermos($event) {
    if ($event === 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  public enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: '219',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLados($event) {
    this.Lado = $event;

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'lateralidade',
        lateralidade: this.Lado
      });
    }
  }

  public MontaDescricaoVeiaFemoralComum(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2426 || +term === 2428 || +term === 2431) {
        this.genericTermsVeiaFemoralComum.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 2426) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoVeiaFemoralComum(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2426 || +term === 2428 || +term === 2431) {
        this.genericTermsVeiaFemoralComum.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoVeiaFemoral(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2498 || +term === 2500 || +term === 2502) {
        this.genericTermsVeiaFemoral.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 2498) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoVeiaFemoral(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2498 || +term === 2500 || +term === 2502) {
        this.genericTermsVeiaFemoral.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoVeiaFemoralProximal(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2434 || +term === 2439) {
        this.genericTermsVeiaFemoralProximal.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 2434) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoVeiaFemoralProximal(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2434 || +term === 2439) {
        this.genericTermsVeiaFemoralProximal.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoVeiaPoplitea(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2442 || +term === 2444 || +term === 2446) {
        this.genericTermsVeiaPoplitea.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 2442) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoVeiaPoplitea(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2442 || +term === 2444 || +term === 2446) {
        this.genericTermsVeiaPoplitea.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoVeiaTibiasAnteriores(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2450 || +term === 2452 || +term === 2454) {
        this.genericTermsVeiaTibiasAnteriores.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 2450) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoVeiaTibiasAnteriores(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2450 || +term === 2452 || +term === 2454) {
        this.genericTermsVeiaTibiasAnteriores.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoVeiaTibiasPosteriores(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2458 || +term === 2460 || +term === 2462) {
        this.genericTermsVeiaTibiasPosteriores.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 2458) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }


  public MontaConclussaoVeiaTibiasPosteriores(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2458 || +term === 2460 || +term === 2462) {
        this.genericTermsVeiaTibiasPosteriores.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoVeiaFibulares(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2466 || +term === 2468 || +term === 2470) {
        this.genericTermsVeiaFibulares.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 2466) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoVeiaFibulares(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2466 || +term === 2468 || +term === 2470) {
        this.genericTermsVeiaFibulares.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoVeiaMusculares(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2474) {
        this.genericTermsVeiaMusculares.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 2474) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoVeiaMusculares(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2474) {
        this.genericTermsVeiaMusculares.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public adicionaSelecaoVeiaFemoralComum($event): any {
    this.genericTermsVeiaFemoralComum.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }


  public adicionaSelecaoVeiaFemoral($event): any {
    this.genericTermsVeiaFemoral.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }


  public adicionaSelecaoVeiaFemoralProximal($event): any {
    this.genericTermsVeiaFemoralProximal.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }


  public adicionaSelecaoVeiaPoplitea($event): any {
    this.genericTermsVeiaPoplitea.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }


  public adicionaSelecaoVeiaTibiasAnteriores($event): any {
    this.genericTermsVeiaTibiasAnteriores.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }


  public adicionaSelecaoVeiaTibiasPosteriores($event): any {
    this.genericTermsVeiaTibiasPosteriores.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }


  public adicionaSelecaoVeiaFibulares($event): any {
    this.genericTermsVeiaFibulares.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoVeiaMusculares($event): any {
    this.genericTermsVeiaMusculares.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoMagna($event) {
    this.genericMagna.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        }
        else {
          obj.selected = true;
          obj.value = $event.elemento.value;
        }
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoParva($event) {
    this.genericParva.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        }
        else {
          obj.selected = true;
          obj.value = $event.elemento.value;
        }
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoPerfurantes($event) {
    this.genericPerfurantes.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  atribuiValorTabela($element) {
    // Preenchendo as medidas
    // let accDir, aciDir, accEsq, aciEsq: number;
    const value = this._validacoes.formataDecimal($element.value);
    this.exibeTabela = true;

    if ($element.id === 'Magna') {
      this.tabelaValor[0] = value;
    } else if ($element.id === 'Magna') {
      this.tabelaValor[0] = value;
    } else if ($element.id === 'TercoProxCoxaMagna') {
      this.tabelaValor[1] = value;
    } else if ($element.id === 'TercoProxCoxaMagna') {
      this.tabelaValor[1] = value;
    } else if ($element.id === 'TercoMediCoxaMagna') {
      this.tabelaValor[2] = value;
    } else if ($element.id === 'TercoMediCoxaMagna') {
      this.tabelaValor[2] = value;
    } else if ($element.id === 'TercoDistCoxaMagna') {
      this.tabelaValor[3] = value;
    } else if ($element.id === 'TercoDistCoxaMagna') {
      this.tabelaValor[3] = value;
    } else if ($element.id === 'TercoProxPernaMagna') {
      this.tabelaValor[4] = value;
    } else if ($element.id === 'TercoProxPernaMagna') {
      this.tabelaValor[4] = value;
    } else if ($element.id === 'TercoMediPernaMagna') {
      this.tabelaValor[5] = value;
    } else if ($element.id === 'TercoMediPernaMagna') {
      this.tabelaValor[5] = value;
    } else if ($element.id === 'TercoDistPernaMagna') {
      this.tabelaValor[6] = value;
    } else if ($element.id === 'TercoDistPernaMagna') {
      this.tabelaValor[6] = value;
    } else if ($element.id === 'NaoCitar') {
      this.exibeTabela = false;
      this.tabelaValor = [];
    }

    if ($element.id === 'TercoProxPernaParva') {
      this.exibeTabelaParva = true;
      this.tabelaValor[7] = value;
    } else if ($element.id === 'TercoProxPernaParva') {
      this.tabelaValor[7] = value;
    } else if ($element.id === 'TercoMedPernaParva') {
      this.tabelaValor[8] = value;
    } else if ($element.id === 'TercoMedPernaParva') {
      this.tabelaValor[8] = value;
    } else if ($element.id === 'TercoDistPernaParva') {
      this.tabelaValor[9] = value;
    } else if ($element.id === 'TercoDistPernaParva') {
      this.tabelaValor[9] = value;
    } else if ($element.id === 'NaoCitar') {
      this.exibeTabela = false;
      this.exibeTabelaParva = false;
      this.tabelaValor = [];
    }

    this.tabelaMagna = this.Tabela1();
    if (this.tabelaMagna !== '') {
      if(!this.newEditor) {
        this.AdicionaTexto(this.newEditor);
      } else {
        this.atualizaLinha({
          blockId: 'tabelaMagna',
          tabelaMagna: this.tabelaMagna,
        });
      }
    }

    this.tabelaParva = this.Tabela2();
    if (this.tabelaParva !== '') {
      if(!this.newEditor) {
        this.AdicionaTexto(this.newEditor);
      } else {
        this.atualizaLinha({
          blockId: 'tabelaParva',
          tabelaParva: this.tabelaParva
        });
      }
    }
  }

  Tabela1(): any {
    let strTable = '<style>' +
      'table {' +
      'width: 80%;' +
      '}' +
      'table, th, td {' +
      'border: 1px solid #c2c0c2;' +
      'border-collapse: collapse;' +
      'th, td {' +
      '  padding: 5px;' +
      '  text-align: left;' +
      '}' +
      '</style>' +
      '<table>';
    if (this.tabelaValor[0]) {
      strTable += '  <tr>';
      strTable += '    <th><b>Segmentos</b></th>';
      strTable += '    <th><b>Calibres (' + this.unidadeMedida + ')</b></th>';
      strTable += '  </tr>';

    }
    if (this.tabelaValor[0] || this.tabelaValor[0]) {

      strTable += '  <tr>';
      strTable += '    <td><b>Crossa </b></td>';
      strTable += '    <td>' + this.tabelaValor[0] + ' ' + this.unidadeMedida + '</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[1] || this.tabelaValor[1]) {

      strTable += '  <tr>';
      strTable += '    <td><b>Terço proximal da coxa  </b></td>';
      strTable += '    <td>' + this.tabelaValor[1] + ' ' + this.unidadeMedida + '</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[2] || this.tabelaValor[2]) {

      strTable += '  <tr>' +
        '    <td><b>Terço médio da coxa </b></b></td>';
      strTable += '    <td>' + this.tabelaValor[2] + ' ' + this.unidadeMedida + '</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[3] || this.tabelaValor[3]) {

      strTable += '  <tr>' +
        '    <td><b>Terço distal da coxa </b></td>';
      strTable += '    <td>' + this.tabelaValor[3] + ' ' + this.unidadeMedida + '</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[4] || this.tabelaValor[4]) {

      strTable += '  <tr>' +
        '    <td><b>Terço proximal da perna </b></td>';
      strTable += '    <td>' + this.tabelaValor[4] + ' ' + this.unidadeMedida + '</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[5] || this.tabelaValor[5]) {

      strTable += '  <tr>' +
        '    <td><b>Terço médio da perna </b></td>';
      strTable += '    <td>' + this.tabelaValor[5] + ' ' + this.unidadeMedida + '</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[6] || this.tabelaValor[6]) {

      strTable += '  <tr>' +
        '    <td><b>Terço distal da perna </b></td>';
      strTable += '    <td>' + this.tabelaValor[6] + ' ' + this.unidadeMedida + '</td>';
      strTable += '  </tr>';
    }

    strTable += '</tr>' +
      '</table>';

    return strTable;
  }

  Tabela2(): any {
    let strTable = '<style>' +
      'table {' +
      'width: 80%;' +
      '}' +
      'table, th, td {' +
      'border: 1px solid #c2c0c2;' +
      'border-collapse: collapse;' +
      'th, td {' +
      '  padding: 5px;' +
      '  text-align: left;' +
      '}' +
      '</style>' +
      '<table>';
    if (this.tabelaValor[7]) {
      strTable += '  <tr>';
      strTable += '    <th><b>Segmentos</b></th>';
      strTable += '    <th><b>Calibres (' + this.unidadeMedida + ')</b></th>';
      strTable += '  </tr>';

    }
    if (this.tabelaValor[7] || this.tabelaValor[7]) {

      strTable += '  <tr>' +
        '    <td><b>Terço proximal da perna </b></td>';
      strTable += '    <td>' + this.tabelaValor[7] + ' ' + this.unidadeMedida + '</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[8] || this.tabelaValor[8]) {

      strTable += '  <tr>' +
        '    <td><b>Terço médio da perna </b></td>';
      strTable += '    <td>' + this.tabelaValor[8] + ' ' + this.unidadeMedida + '</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[9] || this.tabelaValor[9]) {

      strTable += '  <tr>' +
        '    <td><b>Terço distal da perna </b></td>';
      strTable += '    <td>' + this.tabelaValor[9] + ' ' + this.unidadeMedida + '</td>';
      strTable += '  </tr>';
    }
    strTable += '</tr>' +
      '</table>';

    return strTable;
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.VeiaFemoralComum) {
        this.term.blockID = this.VeiaFemoralComumID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.VeiaFemoralComumID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaFemoralComum(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.VeiaFemoralComumID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1729) {
                  this.Descricoes[9].visivel = false;
                } else {
                  this.Descricoes[9].visivel = true;
                }
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiaFemoralComumID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVeiaFemoralComum(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const Descricoes = this.conclusoes.find(x => x.blockID === this.VeiaFemoralComumID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiaFemoralComumID) {
                conclusaoNull.value = obj.value;
                Descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              Descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            Descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusaoAll,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.VeiaFemoral) {
        this.term.blockID = this.VeiaFemoralID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.VeiaFemoralID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaFemoral(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.VeiaFemoralID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1749) {
                  this.Descricoes[9].visivel = false;
                } else {
                  this.Descricoes[9].visivel = true;
                }
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiaFemoralID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVeiaFemoral(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const Descricoes = this.conclusoes.find(x => x.blockID === this.VeiaFemoralID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiaFemoralID) {
                conclusaoNull.value = obj.value;
                Descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              Descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            Descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusaoAll,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.VeiaFemoralProfunda) {
        this.term.blockID = this.VeiaFemoralProfundaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.VeiaFemoralProfundaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaFemoralProximal(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.VeiaFemoralProfundaID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1734) {
                  this.Descricoes[9].visivel = false;
                } else {
                  this.Descricoes[9].visivel = true;
                }
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiaFemoralProfundaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVeiaFemoralProximal(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const Descricoes = this.conclusoes.find(x => x.blockID === this.VeiaFemoralProfundaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiaFemoralProfundaID) {
                conclusaoNull.value = obj.value;
                Descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              Descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            Descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusaoAll,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.VeiaPolplitea) {
        this.term.blockID = this.VeiaPolpliteaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.VeiaPolpliteaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaPoplitea(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.VeiaPolpliteaID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1739) {
                  this.Descricoes[9].visivel = false;
                } else {
                  this.Descricoes[9].visivel = true;
                }
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiaPolpliteaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVeiaPoplitea(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const Descricoes = this.conclusoes.find(x => x.blockID === this.VeiaPolpliteaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiaPolpliteaID) {
                conclusaoNull.value = obj.value;
                Descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              Descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            Descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusaoAll,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.VeiaTibiaisAnteriores) {
        this.term.blockID = this.VeiaTibiaisAnterioresID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.VeiaTibiaisAnterioresID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaTibiasAnteriores(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.VeiaTibiaisAnterioresID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1744) {
                  this.Descricoes[9].visivel = false;
                } else {
                  this.Descricoes[9].visivel = true;
                }
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiaTibiaisAnterioresID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVeiaTibiasAnteriores(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const Descricoes = this.conclusoes.find(x => x.blockID === this.VeiaTibiaisAnterioresID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiaTibiaisAnterioresID) {
                conclusaoNull.value = obj.value;
                Descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              Descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            Descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusaoAll,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.VeiaTibiaisPosteriores) {
        this.term.blockID = this.VeiaTibiaisPosterioresID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.VeiaTibiaisPosterioresID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaTibiasPosteriores(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.VeiaTibiaisPosterioresID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1754) {
                  this.Descricoes[9].visivel = false;
                } else {
                  this.Descricoes[9].visivel = true;
                }
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiaTibiaisPosterioresID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVeiaTibiasPosteriores(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const Descricoes = this.conclusoes.find(x => x.blockID === this.VeiaTibiaisPosterioresID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiaTibiaisPosterioresID) {
                conclusaoNull.value = obj.value;
                Descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              Descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            Descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusaoAll,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.VeiasFibulares) {
        this.term.blockID = this.VeiasFibularesID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.VeiasFibularesID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaFibulares(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.VeiasFibularesID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1759) {
                  this.Descricoes[9].visivel = false;
                } else {
                  this.Descricoes[9].visivel = true;
                }
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiasFibularesID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVeiaFibulares(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const Descricoes = this.conclusoes.find(x => x.blockID === this.VeiasFibularesID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiasFibularesID) {
                conclusaoNull.value = obj.value;
                Descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              Descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            Descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusaoAll,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.VeiasMuscularesPanturrilha) {
        this.term.blockID = this.VeiasMuscularesPanturrilhaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.VeiasMuscularesPanturrilhaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaMusculares(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.VeiasMuscularesPanturrilhaID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                // indicação de sumiço da frase de 'veias normais'
                if (this.Descricoes[index].textTermID !== 1769) {
                  this.Descricoes[9].visivel = false;
                } else {
                  this.Descricoes[9].visivel = true;
                }
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiasMuscularesPanturrilhaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVeiaMusculares(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const Descricoes = this.conclusoes.find(x => x.blockID === this.VeiasMuscularesPanturrilhaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiasMuscularesPanturrilhaID) {
                conclusaoNull.value = obj.value;
                Descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              Descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            Descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusaoAll,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.veiaSafenaMagna) {
        this.term.blockID = this.veiaSafenaMagnaID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.veiaSafenaMagnaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaVeiaSafenaMagna(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.veiaSafenaMagnaID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.veiaSafenaMagnaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaVeiaSafenaMagna(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.veiaSafenaMagnaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.veiaSafenaMagnaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.veiaSafenaParva) {
        this.term.blockID = this.veiaSafenaParvaID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.veiaSafenaParvaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaVeiaSafenaParva(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.veiaSafenaParvaID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.veiaSafenaParvaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaVeiaSafenaParva(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.veiaSafenaParvaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.veiaSafenaParvaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.veiaPerfurantes) {
        this.term.blockID = this.veiaPerfurantesID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.veiaPerfurantesID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaVeiaPerfurantes(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.veiaPerfurantesID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.veiaPerfurantesID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaVeiaPerfurantes(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.veiaPerfurantesID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.veiaPerfurantesID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  MontaVeiaSafenaMagna(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 3573 || +term === 3574 || +term === 3576 || +term === 3578 ||
          +term === 3579) {
          this.genericMagna.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 3573 || +term === 3574 || +term === 3576 || +term === 3578 ||
          +term === 3579) {
          this.genericMagna.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaVeiaSafenaParva(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 3583 || +term === 3584 || +term === 3586 || +term === 3588) {
          this.genericParva.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 3583 || +term === 3584 || +term === 3586 || +term === 3588) {
          this.genericParva.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }


  MontaVeiaPerfurantes(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 3593 || +term === 3594 || +term === 3595 || +term === 3596 ||
          +term === 3598 || +term === 3599) {
          this.genericPerfurantes.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 3593 || +term === 3594 || +term === 3595 || +term === 3596 ||
          +term === 3598 || +term === 3599) {
          this.genericPerfurantes.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-lateralidade`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: center;"><b>ULTRASSONOGRAFIA DOPPLER DO SISTEMA VENOSO PROFUNDO E SUPERFICIAL ${this.Lado.toUpperCase()}</b></div></p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p></br>${this.indicacaoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.Descricoes.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });
    this.Descricoes.forEach(descricao => {
      if(descricao.value && descricao.visivel) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-tabelaMagna`,
        'name': ''
      },
      'data': {
        'text': `<p></br>${this.tabelaMagna}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-tabelaParva`,
        'name': ''
      },
      'data': {
        'text': `<p></br>${this.tabelaParva}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[5]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-${this.achadosTextoID}`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);

  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if(this.infoLogotipo !== '' && this.cabecalhoTexto !== ''){
      this.htmlContent = this.infoLogotipo;
      this.htmlContent += this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo !== '' && this.cabecalhoTexto == ''){
      this.htmlContent = this.infoLogotipo;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo == '' && this.cabecalhoTexto !== ''){
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    }else if(this.infoLogotipo == '' && this.cabecalhoTexto == ''){
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.Lado.toUpperCase();
    this.htmlContent += maskSplit[1];
    this.htmlContent += this.indicacaoTexto;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContent += maskSplit[2];

    for (i = 0; i < this.Descricoes.length; i++) {
      var newValue = this.Descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.Descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.Descricoes.length; i++) {
      if (this.Descricoes[i].value && this.Descricoes[i].visivel) {
        // if (i > 1 && i < 8) {
        //   this.htmlContent += this.Descricoes[i].value + '<br/>';
        // } else {
        this.htmlContent += '<br/>' + this.Descricoes[i].value + '<br/>';
        // }
      }
    }

    // Tabela
    if (this.exibeTabela) {
      this.htmlContent += maskSplit[3];
      this.htmlContent += '</br>' + this.Tabela1() + '</br>';
      if (this.exibeTabelaParva) {
        this.htmlContent += maskSplit[4];
        this.htmlContent += '</br>' + this.Tabela2();
      }
    }

    this.htmlContent += maskSplit[5];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }

    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		indicacao,
		achados,
    cabecalho,
    rodape,
    lateralidade,
    tabelaMagna,
    tabelaParva
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.Descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }

        if(lateralidade !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);
          let lado = `<div style="text-align: center;"><b>ULTRASSONOGRAFIA DOPPLER DO SISTEMA VENOSO PROFUNDO E SUPERFICIAL ${lateralidade.toUpperCase()}</b></div>`

          if(footer) {
						footer.innerHTML = lado.toUpperCase();
          }
        }

        if(tabelaMagna !== undefined) {
          const footer = document.querySelector(`#e-${blockId} p`);

          if(footer) {
						footer.innerHTML = `<b>SAFENA MAGNA:</b></br> ${tabelaMagna}`;
          }
        }

        if(tabelaParva !== undefined) {
          const footer = document.querySelector(`#e-${blockId} p`);

          if(footer) {
						footer.innerHTML = `<b>SAFENA PARVA:</b></br> ${tabelaParva}`;
          }
        }
			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    // if (this.conclusoes.length <= 1) {
    texto = 'Ultrassonografia com Doppler colorido das veias analisadas dentro dos limites da normalidade.';
    // }
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
