import { UserService } from 'src/app/services/user.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TermosConfirmadosGuard implements CanActivate {
  constructor(private userservice: UserService, private router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      let userID = +localStorage.getItem("userID");
    return this.userservice.buscarUsuario(userID).pipe(
      map((usuario) => {
        if (usuario.acceptedTerms) {
          this.userservice.termAccepted.next(true);
          return true;
        } else {
          this.userservice.termAccepted.next(false);
          this.router.navigate(["/termos-confirmacao"]);
          return false;
        }
      })
    );
  }
}
