import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatRadioButton } from '@angular/material';
import { User } from 'src/app/auth/user';
import { Validacoes } from 'src/app/utils/validacoes';
import { Calculadora } from '../../../../../../utils/calculadora';

@Component({
  selector: 'app-opt-saco-gestacional',
  templateUrl: './opt-saco-gestacional.component.html',
  styleUrls: ['./opt-saco-gestacional.component.css']
})
export class OptSacoGestacionalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() camposObrigatoriosPreenchidos = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdIrregular') rdIrregular: MatRadioButton;

  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;
  @ViewChild('inMedida3') inMedida3: ElementRef;
  @ViewChild('inDmsg') inDmsg: ElementRef;

  showAndHideTerms: any;
  dmsg = 'DSMG';

  inMedida1Invalid: boolean = true;
  inMedida2Invalid: boolean = true;
  inMedida3Invalid: boolean = true;

  constructor(private _validacoes: Validacoes, private _calc: Calculadora, private _user: User) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahIrregular: true,
    }
  }

  clickDefault() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    this.removeInvalidField();

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
      this.formataMedindo();
    }

    if (this.rdIrregular && this.rdIrregular.checked) {
      this.termoSelecionado.emit(this.rdIrregular.id);
      this.formataMedindo();
    }

    if((this.inMedida1 && this.inMedida1.nativeElement.value)
    && (this.inMedida2 && this.inMedida2.nativeElement.value)
    && (this.inMedida3 && this.inMedida3.nativeElement.value)){
      this.camposObrigatoriosPreenchidos.emit({ativo: true, nome:'medidasSacoGestacional'});
    }else{
      this.camposObrigatoriosPreenchidos.emit({ativo: false, nome:'medidasSacoGestacional'});
    }

    this.listaCompleta.emit();

  }

  formataMedindo() {
    let dmsgFrase = 'Diâmetro médio do saco gestacional: ';
    let sgFrase = '';
    let resultado = 0;
    let sg1 = 0;
    let sg2 = 0;
    let sg3 = 0;
    let m1 = 0;
    let m2 = 0;
    let m3 = 0;

    if (this.inMedida1 !== undefined &&
      this.inMedida1.nativeElement.id !== undefined &&
      this.inMedida1.nativeElement.value !== '') {
      m1 = this.inMedida1.nativeElement.value;
      sg1 = this._validacoes.formataDecimal(this.inMedida1.nativeElement.value);
    }

    if (this.inMedida2 != undefined &&
      this.inMedida2.nativeElement.id != undefined &&
      this.inMedida2.nativeElement.value != '') {
      m2 = this.inMedida2.nativeElement.value;
      sg2 = this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
    }

    if (this.inMedida3 != undefined &&
      this.inMedida3.nativeElement.id != undefined &&
      this.inMedida3.nativeElement.value != '') {
      m3 = this.inMedida3.nativeElement.value
      sg3 = this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);
    }

    resultado = this._calc.calculaDMSG(m1, m2, m3);
    this.dmsg = this._validacoes.formataDecimal(resultado.toFixed(1)).toString();

    if (resultado)
      localStorage.setItem('dmsgValue', resultado.toString());

    if (this.inDmsg.nativeElement.value) {
      dmsgFrase += this._validacoes.formataDecimal(this.inDmsg.nativeElement.value) + ' mm';
    } else {
      dmsgFrase += this._validacoes.formataDecimal(resultado.toFixed(1)).toString() + ' mm';
    }

    sgFrase = sg1 + ' x ' + sg2 + ' x ' + sg3 + ' mm. ' + dmsgFrase;

    const objetoElemento = { id: this.inDmsg.nativeElement.id, value: sgFrase, cenario: 0 };
    const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
    this.termosGenericos.emit(objeto);

  }

  removeInvalidField(){
    if(this.inMedida1 && this.inMedida1.nativeElement.value){
      this.inMedida1Invalid= false;
    }else{
      this.inMedida1Invalid = true;
    }
    if(this.inMedida2 && this.inMedida2.nativeElement.value){
      this.inMedida2Invalid = false;
    }else{
      this.inMedida2Invalid = true;
    }
    if(this.inMedida3 && this.inMedida3.nativeElement.value){
      this.inMedida3Invalid = false;
    }else{
      this.inMedida3Invalid = true;
    }
  }
}
