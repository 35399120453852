import { Component, OnInit, ElementRef, ViewChild, Output } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validacoes } from '../../../../utils/validacoes';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { AbdomeTotalComponent } from '../abdome-total/abdome-total.component';
import { MatDialog } from '@angular/material';
import { User } from 'src/app/auth/user';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { PelvicoComponent } from '../pelvico/pelvico.component';
import { EventEmitter } from 'events';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { ConfigClinicaService } from 'src/app/services/config-clinica.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

export interface DescricaoConclusao {
  value: string;
  textTermID: number;
  blockID: number;
}

export interface TermosGenericos {
  value: string;
  selected: boolean;
  term: string;
  termView: string;
  termID: number;
  linha: number;
  cenario: number;
  posicao: number;
  lesionID: number;
}

@Component({
  selector: 'app-rins-pelvico',
  templateUrl: './rins-pelvico.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./rins-pelvico.component.css']
})
export class RinsPelvicoComponent implements OnInit {

  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;
  @Output() paragrafoSelecionado = new EventEmitter();
  medicaoNodulo = '';
  public switchFormReport = true;

  listTermDefault=[
    {listTerm:[451],blockID:24},
    {listTerm:[400],blockID:20},
    {listTerm:[532],blockID:22},
    {listTerm:[547],blockID:26},
    {listTerm:[417],blockID:21},
    {listTerm:[424],blockID:23},
    {listTerm:[185],blockID:9},
    {listTerm:[236],blockID:10},
    {listTerm:[271],blockID:11},
  ]

  descricoes: DescricaoConclusao[] = [];

  conclusoes: DescricaoConclusao[] = [];

  htmlContent: string;
  mask: string;
  Subtitulo: string;
  subtituloBloco: string;
  blockID: number;
  term = new Term();
  injuries = new Injuries();
  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  companyID = 0;
  cabecalhoTexto = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  blocoSelecionado = 'indicacao'
  rodapeTexto = '';

  cabecalho = false;
  indicacao = true;
  veiaporta = false;
  vesiculabiliar = false;
  viasbiliares = false;
  baco = false;
  pancreas = false;
  figado = false;
  bexiga = false;
  aorta = false;
  ascite = false;
  rimdireito = false;
  rimesquerdo = false;
  linfonodo = false;
  alcaIntestinal = false;
  limitacoes = false;
  achadosTexto = false;
  rodape = false;

  utero = false;
  endometrio = false;
  ovarioDireito = false;
  ovarioEsquerdo = false;
  liquidoLivre = false;
  regiaoAnexial = false;

  medicalReportTypeID = 69;
  bexigaID = 9;
  rimdireitoID = 10;
  rimesquerdoID = 11;

  uteroID = 24;
  endometrioID = 20;
  ovarioDireitoID = 22;
  ovarioEsquerdoID = 26;
  liquidoLivreID = 21;
  regiaoAnexialID = 23;

  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  editLaudoMode: boolean = false;
  unidadeMedida: string;

  constructor(
    private _selected: SelectedItemEmiter,
    private _serviceMedicalReport: MedicalReportService,
    private _dialog: MatDialog,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _user: User,
    private dataService:DataTermService,
    public abdome: AbdomeTotalComponent,
    public _addText: AddText,
    private _config: ConfigLaudoService,
    public pelvico: PelvicoComponent,
    private _configClinica:ConfigClinicaService
  ) { }

  ngOnInit() {
    this.utilizarEditor();
    this.resetaLaudo();
    this.descricoes = this.abdome.descricoes.filter(desc => ([0, 9, 10, 11].indexOf(desc.blockID) > 0)).concat(this.pelvico.descricoes.filter(c => c.blockID != 9));
    this.conclusoes = this.abdome.conclusoes.filter(desc => ([0, 9, 10, 11].indexOf(desc.blockID) > 0)).concat(this.pelvico.conclusoes.filter(c => c.blockID > 0 && c.blockID != 9));

    this._pouchService.loadDatabase("rins-pelvicoAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Indicação';
    this.subtituloBloco = 'Preencha o campo indicação';

    //Recupera o companyID
    this.companyID = this._user.getUserCompanyID();
    if (this.companyID === 2) {
      this.conclusoes[0].value = 'Estudo ultrassonográfico do aparelho urinário sem evidências de anormalidades.';
    }

    const listBlocks = [
      this.bexigaID = 9,
      this.rimdireitoID = 10,
      this.rimesquerdoID = 11,
      this.uteroID = 24,
      this.endometrioID = 20,
      this.ovarioDireitoID = 22,
      this.ovarioEsquerdoID = 26,
      this.liquidoLivreID = 21,
      this.regiaoAnexialID = 23
    ];

    this.abdome.carregaDescricaoConclusao();
    this.abdome.resetaLaudo();
    this.pelvico.carregaDescricaoConclusao();
    this.pelvico.resetaLaudo();
    

    this._pouchService.loadMask(this.medicalReportTypeID, "rins-pelvicoMask").then((obj: any) => {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "rinsPelvico");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-rins-pelvico');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-rins-pelvico');
      }
    });
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if (config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.abdome.resetaLaudo();
            this.pelvico.resetaLaudo();
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo() {
    this._pouchService.getAll("rins-pelvicoAnalise").then((obj) => {
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      nome: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      nome: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: true
    },
    {
      id: this.rimdireitoID,
      nome: 'rim-direito',
      titulo: 'Rim Direito',
      selected: false
    },
    {
      id: this.rimesquerdoID,
      nome: 'rim-esquerdo',
      titulo: 'Rim Esquerdo',
      selected: false
    },
    {
      id: this.bexigaID,
      nome: 'bexiga',
      titulo: 'Bexiga',
      selected: false
    },
    //pelvico
    {
      titulo: 'Útero',
      id: this.uteroID,
      nome: 'utero',
      selected: false,
    },
    {
      titulo: 'Endométrio',
      id: this.endometrioID,
      nome: 'endometrio',
      selected: false
    },
    {
      titulo: 'Ovário direito',
      id: this.ovarioDireitoID,
      nome: 'ovario-direito',
      selected: false
    },
    {
      titulo: 'Ovário esquerdo',
      id: this.ovarioEsquerdoID,
      nome: 'ovario-esquerdo',
      selected: false
    },
    {
      titulo: 'Líquido Livre',
      id: this.liquidoLivreID,
      nome: 'liquido-livre',
      selected: false
    },
    {
      titulo: 'Região Anexial',
      id: this.regiaoAnexialID,
      nome: 'regiao-anexial',
      selected: false
    },
    {
      id: 'achados',
      nome: 'achados',
      titulo: 'Achados Adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      id: 'rodape',
      nome: 'rodape',
      titulo: 'Assinatura',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if (itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.bexiga = (itemSelecionado.id === this.bexigaID);
    this.rimdireito = (itemSelecionado.id === this.rimdireitoID);
    this.rimesquerdo = (itemSelecionado.id === this.rimesquerdoID);
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
    this.utero = (itemSelecionado.id === this.uteroID);
    this.endometrio = (itemSelecionado.id === this.endometrioID);
    this.ovarioDireito = (itemSelecionado.id === this.ovarioDireitoID);
    this.ovarioEsquerdo = (itemSelecionado.id === this.ovarioEsquerdoID);
    this.liquidoLivre = (itemSelecionado.id === this.liquidoLivreID);
    this.regiaoAnexial = (itemSelecionado.id === this.regiaoAnexialID);
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    this.abdome.enviaLesoes($event);
    this.pelvico.enviaLesoes($event);
    this.AdicionaTexto(this.newEditor);
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      if (this.rimdireito) {
        this.abdome.blockID = this.abdome.rimdireitoID;
        this.abdome.term.injuries.push(this.abdome.injuries);
        this.abdome.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.abdome.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.abdome.rimdireitoID && termResults[0] !== undefined) {
              this.abdome.termResultsAPI = termResults;
              obj.textTermID = this.abdome.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.abdome.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.abdome.MontaDescricaoRimDireito(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.abdome.descricoes.findIndex(x => x.blockID == this.abdome.rimdireitoID);
              if (index > -1) {
                this.abdome.descricoes[index].value = obj.value;
                this.abdome.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.abdome.rimdireitoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.abdome.termConclusionAPI = termResults;
          let espaco = '';
          let contadorTexto = 0;
          this.abdome.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.abdome.rimdireitoID;
              obj.value = obj.value + espaco + this.abdome.MontarConclusaoRimDireito(resultado.conclusion, contadorTexto);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.abdome.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }

            contadorTexto++;
          });

          const conclusaoAll = this.abdome.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.abdome.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.abdome.conclusoes.find(x => x.blockID === this.abdome.rimdireitoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.abdome.rimdireitoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.abdome.rimdireitoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.rimesquerdo) {
        this.abdome.term.blockID = this.rimesquerdoID;

        this.abdome.term.injuries.push(this.abdome.injuries);
        this.abdome.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.abdome.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.rimesquerdoID && termResults[0] !== undefined) {
              this.abdome.termResultsAPI = termResults;
              obj.textTermID = this.abdome.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.abdome.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.abdome.MontaDescricaoRimEsquerdo(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.abdome.descricoes.findIndex(x => x.blockID == this.rimesquerdoID);
              if (index > -1) {
                this.abdome.descricoes[index].value = obj.value;
                this.abdome.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.rimesquerdoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.abdome.termConclusionAPI = termResults;
          let espaco = '';
          let contadorTexto = 0;
          this.abdome.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.rimesquerdoID;
              obj.value = obj.value + espaco + this.abdome.MontarConclusaoRimEsquerdo(resultado.conclusion, contadorTexto);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }

            contadorTexto++;

          });

          const conclusaoAll = this.abdome.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.abdome.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.abdome.conclusoes.find(x => x.blockID === this.rimesquerdoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.rimesquerdoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.rimesquerdoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.bexiga) {
        this.abdome.term.blockID = this.bexigaID;

        let desc;
        this._pouchService.getLaudos(this.abdome.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.bexigaID && termResults[0] !== undefined) {
              this.abdome.termResultsAPI = termResults;
              obj.textTermID = this.abdome.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.abdome.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.abdome.MontaDescricaoBexiga(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.abdome.descricoes.findIndex(x => x.blockID == this.bexigaID);
              if (index > -1) {
                this.abdome.descricoes[index].value = obj.value;
                this.abdome.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.bexigaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.abdome.termConclusionAPI = termResults;
          this.abdome.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.abdome.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.abdome.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.abdome.conclusoes.find(x => x.blockID === this.bexigaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.bexigaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.bexigaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
        //Por que essa chamada esta aqui?
        //this._laudoService.buscaConclusaoPorTermo(this.term);
        this._pouchService.getLaudos(this.abdome.term);
        ///Pelvico
      } else if (this.liquidoLivre) {
        this.pelvico.term.blockID = this.liquidoLivreID;

        let desc;
        this._pouchService.getLaudos(this.pelvico.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.liquidoLivreID && termResults[0] !== undefined) {
              this.pelvico.termResultsAPI = termResults;
              obj.textTermID = this.pelvico.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.pelvico.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.pelvico.MontaDescricaoLiquidoLivre(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.pelvico.descricoes.findIndex(x => x.blockID === this.liquidoLivreID);
              if (index > -1) {
                this.pelvico.descricoes[index].value = obj.value;
                this.pelvico.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.liquidoLivreID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.pelvico.termConclusionAPI = termResults;
          this.pelvico.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion && this.pelvico.liqLivreSemConclusao === false) {
              obj.blockID = this.liquidoLivreID;
              obj.value = obj.value + espaco + this.pelvico.MontaConclusaoLiquidoLivre(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 1) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.pelvico.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.pelvico.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.pelvico.conclusoes.find(x => x.blockID === this.liquidoLivreID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.liquidoLivreID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.liquidoLivreID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.endometrio) {

        this.pelvico.term.blockID = this.endometrioID;
        this.pelvico.term.injuries.push(this.pelvico.injuries);
        this.pelvico.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.pelvico.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.endometrioID && termResults[0] !== undefined) {
              this.pelvico.termResultsAPI = termResults;
              obj.textTermID = this.pelvico.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.pelvico.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.pelvico.MontaDescricaoEndometrio(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.pelvico.descricoes.findIndex(x => x.blockID === this.endometrioID);
              if (index > -1) {
                this.pelvico.descricoes[index].value = obj.value;
                this.pelvico.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.endometrioID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.pelvico.termConclusionAPI = termResults;
          this.pelvico.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.endometrioID;
              obj.value = obj.value + espaco + this.pelvico.MontaConclusaoEndometrio(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.pelvico.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.pelvico.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.pelvico.conclusoes.find(x => x.blockID === this.endometrioID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.endometrioID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.endometrioID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.regiaoAnexial) {

        this.pelvico.term.blockID = this.regiaoAnexialID;
        this.pelvico.term.injuries.push(this.pelvico.injuries);
        this.pelvico.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.pelvico.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.regiaoAnexialID && termResults[0] !== undefined) {
              this.pelvico.termResultsAPI = termResults;
              obj.textTermID = this.pelvico.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.pelvico.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.pelvico.MontaDescricaoRegiaoAnexial(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.pelvico.descricoes.findIndex(x => x.blockID === this.regiaoAnexialID);
              if (index > -1) {
                this.pelvico.descricoes[index].value = obj.value;
                this.pelvico.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.regiaoAnexialID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.pelvico.termConclusionAPI = termResults;
          this.pelvico.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.regiaoAnexialID;
              obj.value = obj.value + espaco + this.pelvico.MontaConclusaoRegiaoAnexial(resultado.conclusion);
              espaco = '</br>';
            } else if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              obj.blockID = 0;
              obj.value = '';
            }
          });

          const conclusaoAll = this.pelvico.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.pelvico.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.pelvico.conclusoes.find(x => x.blockID === this.regiaoAnexialID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.regiaoAnexialID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.regiaoAnexialID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.utero) {

        this.pelvico.term.blockID = this.uteroID;
        this.pelvico.term.injuries.push(this.pelvico.injuries);
        this.pelvico.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.pelvico.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.uteroID && termResults[0] !== undefined) {
              this.pelvico.termResultsAPI = termResults;
              obj.textTermID = this.pelvico.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.pelvico.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.pelvico.MontaDescricaoUtero(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.pelvico.descricoes.findIndex(x => x.blockID === this.uteroID);
              if (index > -1) {
                this.pelvico.descricoes[index].value = obj.value;
                this.pelvico.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.uteroID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.pelvico.termConclusionAPI = termResults;
          this.pelvico.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.uteroID;
              obj.value = obj.value + espaco + this.pelvico.MontaConclusaoUtero(resultado.conclusion);
              espaco = '</br>';
            } else if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const consclusaoAll = this.pelvico.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.pelvico.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.pelvico.conclusoes.find(x => x.blockID === this.uteroID);

          if (obj.blockID === 0) {
            if (consclusaoAll.length <= 1) {
              if (consclusaoAll[0].blockID === 0 || consclusaoAll[0].blockID === this.uteroID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.uteroID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ovarioDireito) {

        this.pelvico.term.blockID = this.ovarioDireitoID;
        this.pelvico.term.injuries.push(this.pelvico.injuries);
        this.pelvico.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.pelvico.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.ovarioDireitoID && termResults[0] !== undefined) {
              this.pelvico.termResultsAPI = termResults;
              obj.textTermID = this.pelvico.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.pelvico.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.pelvico.MontaDescricaoOvarioDireito(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.pelvico.descricoes.findIndex(x => x.blockID === this.ovarioDireitoID);
              if (index > -1) {
                this.pelvico.descricoes[index].value = obj.value;
                this.pelvico.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.ovarioDireitoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.pelvico.termConclusionAPI = termResults;
          this.pelvico.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.ovarioDireitoID;
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
            } else if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              obj.blockID = 0;
              obj.value = '';
            }
          });

          const conclusaoAll = this.pelvico.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.pelvico.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.pelvico.conclusoes.find(x => x.blockID === this.ovarioDireitoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ovarioDireitoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.ovarioDireitoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ovarioEsquerdo) {

        this.pelvico.term.blockID = this.ovarioEsquerdoID;
        this.pelvico.term.injuries.push(this.pelvico.injuries);
        this.pelvico.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.pelvico.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.ovarioEsquerdoID && termResults[0] !== undefined) {
              this.pelvico.termResultsAPI = termResults;
              obj.textTermID = this.pelvico.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.pelvico.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.pelvico.MontaDescricaoOvarioEsquerdo(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.pelvico.descricoes.findIndex(x => x.blockID === this.ovarioEsquerdoID);
              if (index > -1) {
                this.pelvico.descricoes[index].value = obj.value;
                this.pelvico.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.ovarioEsquerdoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.pelvico.termConclusionAPI = termResults;
          this.pelvico.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.ovarioEsquerdoID;
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
            } else if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              obj.blockID = 0;
              obj.value = '';
            }
          });

          const conclusaoAll = this.pelvico.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.pelvico.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.pelvico.conclusoes.find(x => x.blockID === this.ovarioEsquerdoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ovarioEsquerdoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.ovarioEsquerdoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }


  AdicionaTexto(isNewEditor: boolean) {
    if (isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    this.descricoes = this.abdome.descricoes.filter(desc => ([0, 9, 10, 11].indexOf(desc.blockID) > 0)).concat(this.pelvico.descricoes.filter(c => c.blockID != 9));
    this.conclusoes = this.abdome.conclusoes.filter(desc => ([0, 9, 10, 11].indexOf(desc.blockID) > 0)).concat(this.pelvico.conclusoes.filter(c => c.blockID > 0 && c.blockID != 9));
    this.conclusoes.push(this.abdome.conclusoes[0]); //Adicionando frase de normal

    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p></br>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'data': {
          'text': '<b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    if (this.descricoes[0].textTermID != 0 && this.descricoes[1].textTermID == 0) {
      this.descricoes[1].value = 'Rim esquerdo em topografia habitual, com forma e contornos preservados, de dimensões normais.</br>Parênquima renal esquerdo com espessura e ecogenicidade preservadas. Relação córtico-medular preservada.</br>Ausência de imagens sugestivas de cálculos no rim esquerdo. Não há sinais de hidronefrose à esquerda.'.toString();
      this.descricoes[1].textTermID = 187;
    } else if (this.descricoes[0].textTermID == 0 && this.descricoes[1].textTermID != 0) {
      this.descricoes[0].value = 'Rim direito em topografia habitual, com forma e contornos preservados, de dimensões normais.</br>Parênquima renal direito com espessura e ecogenicidade preservadas. Relação córtico-medular preservada.</br>Ausência de imagens sugestivas de cálculos no rim direito. Não há sinais de hidronefrose à direita.'.toString();
      this.descricoes[0].textTermID = 186;
    }

    this.descricoes.forEach(descricao => {
      if (descricao.value) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;

    if (this.mask !== undefined) {
      let maskSplit = this.mask.split('|');

      debugger
      this.descricoes = this.abdome.descricoes.filter(desc => ([0, 9, 10, 11].indexOf(desc.blockID) > 0)).concat(this.pelvico.descricoes.filter(c => c.blockID != 9));
      this.conclusoes = this.abdome.conclusoes.filter(desc => ([0, 9, 10, 11].indexOf(desc.blockID) > 0)).concat(this.pelvico.conclusoes.filter(c => c.blockID > 0 && c.blockID != 9));
      this.conclusoes.push(this.abdome.conclusoes[0]); //Adicionando frase de normal

      //Regra para verficar conclusão normal
      const index = this.conclusoes.findIndex(c => c.blockID == 0);
      if (this.conclusoes.filter(c => c.value && c.blockID != 0).length > 0){
        this.conclusoes[index].value = '';
      } else{
        if (this.companyID === 2) {
          this.conclusoes[index].value = 'Estudo ultrassonográfico do aparelho urinário sem evidências de anormalidades.';
        } else {
          this.conclusoes[index].value = 'Estudo ultrassonográfico sem alterações significativas.';
        }
      }

      if (this.cabecalhoTexto !== '') {
        this.htmlContent = this.cabecalhoTexto;
        this.htmlContent += maskSplit[0];
      } else {
        this.htmlContent = maskSplit[0];
      }

      this.htmlContent += this.indicacaoTexto;
      //Lógica Rules
      if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
        this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>';

      this.htmlContent += maskSplit[1];

      // POG - AJUSTAR ISSO ass.:Gabs
      if (this.descricoes[0].textTermID != 0 && this.descricoes[1].textTermID == 0) {
        this.descricoes[1].value = 'Rim esquerdo em topografia habitual, com forma e contornos preservados, de dimensões normais.</br>Parênquima renal esquerdo com espessura e ecogenicidade preservadas. Relação córtico-medular preservada.</br>Ausência de imagens sugestivas de cálculos no rim esquerdo. Não há sinais de hidronefrose à esquerda.'.toString();
        this.descricoes[1].textTermID = 187;
      } else if (this.descricoes[0].textTermID == 0 && this.descricoes[1].textTermID != 0) {
        this.descricoes[0].value = 'Rim direito em topografia habitual, com forma e contornos preservados, de dimensões normais.</br>Parênquima renal direito com espessura e ecogenicidade preservadas. Relação córtico-medular preservada.</br>Ausência de imagens sugestivas de cálculos no rim direito. Não há sinais de hidronefrose à direita.'.toString();
        this.descricoes[0].textTermID = 186;
      }

      for (i = 0; i < this.descricoes.length; i++) {
        var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
        var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
        this.descricoes[i].value = newValue2;
      }

      for (i = 0; i < this.descricoes.length; i++) {
        if (this.descricoes[i].value) {
          this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
        }
      }
      this.htmlContent += maskSplit[2];
      for (i = 0; i < this.conclusoes.length; i++) {
        if (this.conclusoes[i].value) {
          this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
        }
      }
      this.htmlContent += this.achadosAdTexto;

      if (this.rodapeTexto != '')
        this.htmlContent += this.rodapeTexto;
    } else {
      console.log('Adiciona texto undefined');
    }
  }

  atualizaLinha({
    blockId,
    descricao,
    conclusao,
    conclusaoNull,
    indicacao,
    achados,
    cabecalho,
    rodape,
  }: IAtualizaLinha): void {
    if (blockId !== undefined) {
      if (indicacao === undefined && achados === undefined) {
        if (descricao === undefined) {
          this.descricoes.forEach(desc => {
            if (desc.blockID === blockId) {
              descricao = desc.value;
            }
          });
        }

        const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

        if (conclusao !== '' || conclusaoNull !== '') {
          if (conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                  ? conclusaoNull !== ''
                    ? conclusaoNull
                    : ''
                  : conclusao;
              }
            });
          }
        } else {
          if (conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                  ? conclusaoNull !== ''
                    ? conclusaoNull
                    : ''
                  : conclusao;
              }
            });
          }
        }

        if (description) {
          description.innerHTML = descricao;
        } else if (!description) {
          this.AdicionaTexto(true);
        } else if (!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if (cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId} p`);

          if (header) {
            header.innerHTML = cabecalho;
          }
        }

        if (rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId} p`);

          if (footer) {
            footer.innerHTML = rodape;
          }
        }
      } else if (indicacao !== undefined || achados !== undefined) {
        if (indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if (recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
        } else if (achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if (findings) {
            findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
        }
      }
      this._addText.lineHeight();
    }
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    if (this.companyID === 2) {
      texto = 'Estudo ultrassonográfico do aparelho urinário sem evidências de anormalidades.';
    } else {
      texto = 'Estudo ultrassonográfico sem alterações significativas.';
    }
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    this.abdome.medicalReportTypeID = this.medicalReportTypeID;
    this.abdome.auditoriaUsuario();
    // const obj = {
    //   UserID: +localStorage.getItem('userID'),
    //   MedicalReportTypeID: this.medicalReportTypeID,
    //   ContentReport: this._copyPaste.getTextFromId('editor'),
    //   MedicalReportLogTypeID: 1
    // };

    // if (obj)
    //   this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if (formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

}
