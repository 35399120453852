import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MASKS } from 'ng-brazil';
import { UserService } from '../services/user.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Plano } from '../models/BO/Plano';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../utils/dialog/confirm-dialog/confirm-dialog.component';
import { Pagamento } from '../models/BO/Pagamento';
import { PagamentoService } from '../services/pagamento.service';
import { ViaCepServiceService } from '../services/via-cep-service.service';
import { Endereco } from '../models/BO/Endereco';
import { Validacoes } from '../utils/validacoes';
import { Router } from '@angular/router';
import { DISABLED } from '@angular/forms/src/model';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css', '../app.component.css']
})
export class PerfilComponent implements OnInit {
  planos: Plano[];
  formCadastro: FormGroup;
  $usuario: any;
  gravar: string = 'Salvar';
  hide = true;
  public MASKS = MASKS;
  private formSubmitAttempt: boolean;
  endereco: Endereco;
  private updated: boolean = false;
  public updatedUserClick: boolean = false;
  public loading: boolean = true;

  constructor(private fb: FormBuilder,
    private _userService: UserService,
    private _pagamentoService: PagamentoService,
    private toastr: ToastrManager,
    private _dialog: MatDialog,
    private _viaCEPService: ViaCepServiceService,
    private route:Router
  ) { }

  ngOnInit() {
    this.formCadastro = this.fb.group({
      id: ['', [Validators.required]],
      Name: ['', [Validators.required, Validacoes.verificaSeExisteSobrenome]],
      Email: [{value: '', disabled: true}, [Validators.email]],
      Celular: ['', [
        Validators.required,
        Validators.minLength(11),
        Validators.maxLength(15),
      ]],
      Senha: [''],
      CEP: ['', [Validators.required]],
      Endereco: ['', [Validators.required]],
      Numero: ['', [Validators.required]],
      Complemento: [''],
      Cidade: ['', [Validators.required]],
      Estado: ['', [Validators.required]],
      Pais: ['', [Validators.required]]
    });
    // final on init()

    //Verifica se o usuário clicou na caixa
    if (localStorage.getItem('updateUserClick') == 'true')
      this.updatedUserClick = true

    this.carregaPlano();
    this.updatedUser();
  }
  handleChangePage(){
    this.route.navigateByUrl('altera-senha');
  }
  updatedUser() {
    const userID = +localStorage.getItem('userID');

    if (userID) {
      this._userService.buscarUsuario(userID)
        .subscribe(
          (usuario) => {
            this.updated = usuario.updatedUser;
          }
        );
    }

    if (userID != null)
      this.loading = false;
  }

  carregaPlano() {
    const userID = +localStorage.getItem('userID');
    if (userID) {
      this._userService.buscarUsuario(userID)
        .subscribe(
          (usuario) => {
            this.populaDadosForm(usuario);
            this.$usuario = usuario;
          }
        );
    } else {
      this.toastr.errorToastr('Erro ao carregar perfil', 'Erro',
        {
          position: 'top-right',
          toastTimeout: 4000
        }
      );
    }
  }

  cancelarPlano(planId: number) {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      data: 'info|Deseja realmenta cancelar o plano? Após o cancelamento o plano não estará mais disponivel em sua conta'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //Criando obj de cancelamento
        let plano: Pagamento = {
          UserID: +localStorage.getItem('userID'),
          ListPlanID: [planId]
        };
        this._pagamentoService.cancelarPlano(plano)
          .subscribe(
            res => {
              localStorage.removeItem('exames');
              this.carregaPlano();
              this.toastr.successToastr('Plano cancelado com sucesso.', 'Sucesso!',
                {
                  position: 'top-right',
                  toastTimeout: 4000
                });
            },
            err => {
              this.toastr.errorToastr('Erro ao cancelar plano, tente novamente ou entre em contato através do nosso suporte (suporte.nreport@ionic.health).', 'Ops!',
                {
                  position: 'top-right',
                  toastTimeout: 4000
                });
            },
          );
      }
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.formCadastro.get(field).valid && this.formCadastro.get(field).touched) ||
      (this.formCadastro.get(field).untouched && this.formSubmitAttempt)
    );
  }

  populaDadosForm(usuario: any) {
    if (!usuario) return;

    this.formCadastro.patchValue({
      id: usuario.id,
      Name: usuario.name,
      Email: usuario.email,
      Celular: usuario.phone,
      Senha: usuario.senha,
      CEP: usuario.zipCode === 0 ? '' : usuario.zipCode,
      Endereco: usuario.address,
      Numero: usuario.number === 0 ? '' : usuario.number,
      Complemento: usuario.complement,
      Cidade: usuario.city,
      Estado: usuario.state,
      Pais: usuario.country
    });

    this.planos = usuario.planList;
  }

  onSubmit() {
    var cel = this.formCadastro.get('Celular').value.replace(/[^\d]/g, "")
    if (cel.length < 11)
      return this.toastr.errorToastr('Por favor preencha o celular corretamente', 'Desculpe!',
        {
          position: 'top-right',
          toastTimeout: 4000
        }
      );

    this._userService.atualizaUsuario(this.formCadastro.value)
      .subscribe(
        res => {
          this.formCadastro.enable();
          this.toastr.successToastr('Usuário atualizado com sucesso!', 'Sucesso!',
            {
              position: 'top-right',
              toastTimeout: 4000
            }
          );
        }, err => {
          console.log(err);

          this.formCadastro.enable();
          this.toastr.errorToastr('Erro ao atualizar dados', 'Desculpe!',
            {
              position: 'top-right',
              toastTimeout: 4000
            }
          );
        },
      );
    // }

    this.formSubmitAttempt = true;
  }

  limpaDadosEndereco() {
    this.formCadastro.patchValue({
      rua: null,
      numero: null,
      complemento: null,
      distrito: null,
      cidade: null,
      estado: null,
      paises: null,
      // cep: null
    });
  }

  populaDadosFormCEP(dados: Endereco) {
    this.formCadastro.patchValue({
      Endereco: dados.logradouro,
      Bairro: dados.bairro,
      Cidade: dados.localidade,
      Estado: dados.uf,
      Pais: 'Brasil',
      // cep: dados.cep
    });
  }

  verificaCEP(zipcode: string): boolean {
    if (zipcode.length === 8) {
      return true;
    }

    return false;
  }

  maskCep(cep: string) {
    cep = cep.replace(/\D/g, "");
    cep = cep.replace(/[^\w\s]/gi, "");

    this.formCadastro.patchValue({
      CEP: cep
    });
  }

  buscaCEP(cep: string) {
    cep = cep.replace(/\D/g, "");

    this.formCadastro.patchValue({
      CEP: cep
    });

    if (cep !== '') {
      if (this.verificaCEP(cep)) {
        // this.toastr.infoToastr('Buscando CEP...',"CEP OK!!!");
        this._viaCEPService.getEnderecoPorCEP(cep)
          .subscribe(
            address => {
              if (address.erro === true) {
                this.formCadastro = undefined;
                this.toastr.errorToastr('CEP não encontrado', 'Desculpe',
                  {
                    position: 'top-right',
                    toastTimeout: 4000
                  }
                );
                this.limpaDadosEndereco();
              } else {
                this.endereco = address;
                this.populaDadosFormCEP(address);
                this.toastr.successToastr('Endereço encontrado', 'Tudo OK',
                  {
                    position: 'top-right',
                    toastTimeout: 4000
                  }
                );
              }
            },
            error => {
              this.toastr.errorToastr('Ha algo de errado no CEP digitado.', 'Desculpe',
                {
                  position: 'top-right',
                  toastTimeout: 4000
                }
              );
              this.formCadastro = undefined;
              this.limpaDadosEndereco();
            }
          );
      } else {
        this.toastr.errorToastr('Digite um CEP válido', 'Desculpe',
          {
            position: 'top-right',
            toastTimeout: 4000
          }
        );
        this.endereco = undefined;
      }
    }
  }
}
