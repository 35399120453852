import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-email-confirmacao',
  templateUrl: './email-confirmacao.component.html',
  styleUrls: ['./email-confirmacao.component.css']
})
export class EmailConfirmacaoComponent implements OnInit {

  @ViewChild('code1') code1: ElementRef;
  @ViewChild('code2') code2: ElementRef;
  @ViewChild('code3') code3: ElementRef;
  @ViewChild('code4') code4: ElementRef;

  email: string;
  userID: number;
  $usuario: any;
  public loading: boolean = true;

  constructor(
    private _userService: UserService,
    private toastr: ToastrManager,
    private route: Router,
    private _authService: AuthService
  ) { }

  ngOnInit() {
    this.userID = +localStorage.getItem('userID');
    this._userService.buscarUsuario(this.userID).subscribe((usuario) => {
      this.$usuario = usuario;
      this.email = this.$usuario.email;
      this.loading = false;
    });
  }

  goCodeImput(stepper: MatStepper) {
    this.enviaEmailConfirmacao();

    stepper.selected.completed = true;
    stepper.next();
  }

  logout() {
    this._authService.logout();
  }

  enviaEmailConfirmacao() {
    this._userService.enviaCodigoConfirmacao(this.userID).subscribe(
      res => {
        console.log(res);
        this.toastr.successToastr('Verifique seu e-mail.', 'Código enviado!');
        
      }, err => {
        this.toastr.errorToastr('Por favor tente novamente.', 'Código não enviado!');
        console.log(err);
      }
    )
  }

  verificarCodigoConfirmacao() {
    var codigoVerificacao = '' + this.code1.nativeElement.value + this.code2.nativeElement.value + this.code3.nativeElement.value + this.code4.nativeElement.value;
    this.$usuario.confirmationCode = codigoVerificacao;

    this._userService.verificaConfirmationCode(this.$usuario).subscribe(
      res => {
        if (res) {
          this._userService.emailConfirmado.next(true);
          this.toastr.successToastr(':)', 'Código verificado!');
          this.route.navigate(['/laudos']);
        } else {
          this._userService.emailConfirmado.next(false);
          this.toastr.errorToastr('Verifique novamente.', 'Código inválido!');
        }
      }, err => {
        console.log(err);
      }
    )
  }

  @HostListener('keyup', ['$event']) public onKeyup(event): void {
    var key = event.which;
    if (event.keyCode != 8 && (key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
      if (event.target.nextSibling) {
        event.target.nextSibling.focus();
        event.target.nextSibling.select();
      } else {
        event.target.parentNode.firstChild.focus();
        event.target.parentNode.firstChild.select();
      }
    }
  }
}
