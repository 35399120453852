import { Directive, HostListener } from '@angular/core';
import { ConnectionService } from '../services/connection.service';
import { MedicalReportLogService } from '../services/MedicalReportLog.service';
import { PouchDBService } from '../services/pouchdb.service';
import { getAnalytics, logEvent } from "firebase/analytics";

@Directive({
  selector: '[auditFocus]'
})
export class AuditFocusDirective {

  @HostListener('focus', ['$event']) auditFocus(event: any) {
    // Aqui a gente pode pegar as informacoes do elemento que disparou o evento
    debugger;
    const id = event.target.id;
    let value = '';
    const nomeUsuario = localStorage.getItem('nomeUsuario');
    const tipoImagem = localStorage.getItem('TipoImagem');
    const usuario = +localStorage.getItem('userID');

    if(event.target.value != undefined)
      value = event.target.value;
    else
      value = event.target.innerText;

    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition(position => {
        //TODO: enviar lat/long para a API quando estiver prota
        // console.log(position.coords);
      });
    }

    const obj = {
      UserID: usuario,
      MedicalReportTypeID: +id, //this.medicalReportTypeID,
      ContentReport: value,
      MedicalReportLogTypeID: 2
    };

    if (obj){
      this._pouch.saveLaudo(obj, "LogMedicalReport");
      this._medicalReportLogService.logEventFirebase(obj);
    }
  }

  constructor(
    private _medicalReportLogService: MedicalReportLogService,
    private _pouch: PouchDBService) { }

}
