import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatSelect } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from 'src/environments/environment';
import { MedicalReportConfig } from '../models/BO/MedicalReportConfig';
import { ArrayDropDown } from '../models/Helper/ArrayDropDown';
import { ConfigClinicaService } from '../services/config-clinica.service';
import { ConfigLaudoService } from '../services/config-laudo.service';

@Component({
  selector: 'app-config-clinica',
  templateUrl: './config-clinica.component.html',
  styleUrls: ['./config-clinica.component.css']
})
export class ConfigClinicaComponent implements OnInit {
  @ViewChild('nomeClinica') nomeClinica: ElementRef;
  @ViewChild('formatoLogo') formatoLogo: MatSelect;
  @ViewChild('tamanhoLogoQuadrado') tamanhoLogoQuadrado: MatSelect;
  @ViewChild('tamanhoLogoRetangulo') tamanhoLogoRetangulo: MatSelect;
  @ViewChild('alturaPersonalizada') alturaPersonalizada: ElementRef;
  @ViewChild('larguraPersonalizada') larguraPersonalizada: ElementRef;
  @ViewChild('ckInverteLogo') ckInverteLogo: MatCheckbox;



  showAndHideTerms: any;
  srcPNG:any = null;
  htmlContent:string ='';
  enableEditor:boolean = false;
  tagPNG:string = '';
  inverterLado:string='';
  tagNomeClinica:string = '';
  alturaLogoEmPX:string = '';
  larguraLogoEmPX:string = '';
  sahQuadrado:boolean= false;
  sahRetangulo:boolean= false;
  sahTamanhoPersonalizado: boolean = false;
  formatos!:any;
  listaDeRegras:MedicalReportConfig[];

  tamanhosRetangulo:any = [
    {value:{width:140,height:70}, viewValue:'Pequeno'},
    {value:{width:180,height:90}, viewValue:'Médio'},
    {value:{width:220,height:120}, viewValue:'Grande'},
  ];

  tamanhosQuadrado:any = [
    {value:{width:80,height:80}, viewValue:'Pequeno'},
    {value:{width:120,height:120}, viewValue:'Médio'},
    {value:{width:160,height:160}, viewValue:'Grande'},
  ];

  constructor(
    private _configLaudo:ConfigLaudoService,
    private _configClinic:ConfigClinicaService
  ) { }

  async ngOnInit() {
    this.formatos = [
      { value: 'quadrado', viewValue: 'Quadrado' },
      { value: 'retangulo', viewValue: 'Retangular' }
    ];
    const userID = +localStorage.getItem('userID');
    this._configLaudo.buscaConfig(userID.toString()).toPromise().then(config=>{
      this.listaDeRegras = config;
    });
    this._configClinic.handleGetClinicConfig();
    this._configClinic.objComConfiguracoesClinica.subscribe(res=>{
      if(res !== undefined && res !== ''){
        this.handleFormatClinicConfig(res);
      }
    })
  }

  onFileSelected(e){
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0])
    reader.onload=()=>{
      this.srcPNG = reader.result;
      this.handleCreateTagPNG();
      this.handlePreview();
    }
  }

  onFormatoLogoBlur(){
      if(this.formatoLogo.value == 'quadrado'){
        this.sahRetangulo = false;
        this.sahQuadrado = true;
      }
      if(this.formatoLogo.value == 'retangulo'){
        this.sahQuadrado = false;
        this.sahRetangulo = true;
      }
    }

  onTamanhoSelected(){
    if(this.sahQuadrado){
      this.larguraLogoEmPX = this.tamanhoLogoQuadrado.value.width;
      this.alturaLogoEmPX = this.tamanhoLogoQuadrado.value.height;
    }
    if(this.sahRetangulo){
      this.larguraLogoEmPX = this.tamanhoLogoRetangulo.value.width;
      this.alturaLogoEmPX = this.tamanhoLogoRetangulo.value.height;
    }

    this.handleCreateTagPNG();
  }

  onTamanhoPersonalizado(){
    this.sahTamanhoPersonalizado = !this.sahTamanhoPersonalizado;
  }

  onBlurTamanho(){
    if(this.larguraPersonalizada && this.larguraPersonalizada.nativeElement.value != '')
      this.larguraLogoEmPX = this.larguraPersonalizada.nativeElement.value;
    if(this.alturaPersonalizada && this.alturaPersonalizada.nativeElement.value != '')
      this.alturaLogoEmPX = this.alturaPersonalizada.nativeElement.value;
    if(this.alturaPersonalizada.nativeElement.value && this.larguraPersonalizada.nativeElement.value)
      this.handleCreateTagPNG();
  }

  onInverterChecked(){
    if(this.ckInverteLogo.checked){
      this.inverterLado = this.ckInverteLogo.value;
    }else{
      this.inverterLado = '';
    }
    this.handleCreateTagPNG();
  }

  async handleFormatClinicConfig(clinicConfig){
    let tagCompleta:string = clinicConfig.clinicLogo;
      let indiceDaImg = tagCompleta.indexOf(">") + 1;
      let indiceFinalImg = tagCompleta.indexOf('Logo') + 6;
      let sliceAltura= tagCompleta.substr(51,3);
      sliceAltura = sliceAltura.replace(/[^0-9]/g, '');


      this.tagPNG = tagCompleta.slice(indiceDaImg, indiceFinalImg);
      this.formatarTagPNG();
      this.tagNomeClinica = tagCompleta.slice(indiceFinalImg, -6);
      this.nomeClinica.nativeElement.value = clinicConfig.clinicName;
      this.alturaLogoEmPX = sliceAltura;
      this.handlePreview();
  }

  handleCreateTagPNG(){
    this.tagPNG = `<img style="width:${this.larguraLogoEmPX}px;height:${this.alturaLogoEmPX}px;position:absolute;${this.inverterLado}" src=${this.srcPNG} alt='Logo'>`;
    this.handlePreview();
  }

  onSubmit(){
    const userID = +localStorage.getItem('userID');
    const companyID= +localStorage.getItem('companyID');
    const objToPost={
      ClinicName: this.nomeClinica.nativeElement.value,
      ClinicLogo: this.htmlContent,
      UserID: userID,
      CompanyID:companyID
    }
    this._configClinic.handlePostClinicConfig(objToPost, this.listaDeRegras);
  }

  onNameSelected(){
    this.tagNomeClinica=`<h2 style="display:flex;justify-content:center;align-items:center;text-align:center;color:black;flex-grow:3;"><b>${this.nomeClinica.nativeElement.value}</b></h2>`
    this.handlePreview();
  }

  handlePreview(){
    this.enableEditor=true;
    this.htmlContent=`<div style="display:flex;margin-bottom:10px;height:${this.alturaLogoEmPX}px;">${this.tagPNG}${this.tagNomeClinica}</div>`
  }

  formatarTagPNG(){
    this.larguraLogoEmPX = this.tagPNG.substr(18,3);
    let sliceSrcPNG= this.tagPNG.indexOf('src=')+4;
    let sliceSrcPNGEnd= this.tagPNG.indexOf(' alt=');
    if(this.tagPNG.includes('right')) this.ckInverteLogo.checked=true;
    this.srcPNG = this.tagPNG.slice(sliceSrcPNG, sliceSrcPNGEnd);
  }
}
